import { KubernetesRawYamlGitSourceComponentKeyValues } from "@octopusdeploy/legacy-action-properties/src/KubernetesRawYamlGitSourceComponentProperties";
import type { KubernetesRawYamlGitSourceComponentProperties } from "@octopusdeploy/legacy-action-properties/src/KubernetesRawYamlGitSourceComponentProperties";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { branchToShowByDefault, Permission, toGitBranchShort } from "@octopusdeploy/octopus-server-client";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import type { SummaryNode } from "~/components/form/index";
import { FormSectionHeading, Note, required, Summary } from "~/components/form/index";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
export interface KubernetesRawYamlGitSourceComponentProps extends ActionEditProps<KubernetesRawYamlGitSourceComponentProperties> {
    expandedByDefault: boolean;
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<void>;
}
function KubernetesRawYamlGitSourceComponent(props: KubernetesRawYamlGitSourceComponentProps) {
    const propertyKeyValues = KubernetesRawYamlGitSourceComponentKeyValues;
    const setCredentialType = useCallback((val: string) => {
        props.setProperties({ [propertyKeyValues.CredentialsType]: val });
    }, [props, propertyKeyValues.CredentialsType]);
    const getDefaultBranchName = (): string => {
        const shortBranchName = toGitBranchShort(branchToShowByDefault);
        const defaultBranchPropValue = props.properties[propertyKeyValues.DefaultBranch];
        if (!defaultBranchPropValue) {
            return shortBranchName;
        }
        return defaultBranchPropValue;
    };
    const defaultBranchName = getDefaultBranchName();
    const defaultBranch = props.properties[propertyKeyValues.DefaultBranch];
    useEffect(() => {
        if (!defaultBranch) {
            props.setProperties({ [propertyKeyValues.DefaultBranch]: defaultBranchName });
        }
    }, [defaultBranch, props, defaultBranchName, propertyKeyValues.DefaultBranch]);
    const credentialsType = props.properties[propertyKeyValues.CredentialsType];
    useEffect(() => {
        if (!credentialsType) {
            setCredentialType("Library");
        }
    }, [credentialsType, setCredentialType]);
    const [gitCredentials, setGitCredentials] = useState(props.gitCredentials);
    useEffect(() => setGitCredentials(props.gitCredentials), [props.gitCredentials]);
    const isUsingLibraryAuth = (): boolean => props.properties[propertyKeyValues.CredentialsType] === "Library";
    const authSummary = (): SummaryNode => {
        if (props.properties[propertyKeyValues.CredentialsType]) {
            if (isUsingLibraryAuth()) {
                const id = props.properties[propertyKeyValues.CredentialsId];
                const credential = gitCredentials.find((c) => c.Id === id);
                return Summary.summary(React.Children.toArray([
                    <span>Using library credential</span>,
                    <span>
                            <strong>{credential ? ` ${credential.Name}` : ""}</strong>
                        </span>,
                ]));
            }
            else {
                return Summary.summary(React.Children.toArray([
                    <span>
                            Authenticated as an <strong>Anonymous</strong> user
                        </span>,
                ]));
            }
        }
        return Summary.placeholder("Enter authentication details");
    };
    const repoUrlSummary = (): SummaryNode => {
        const url = props.properties[propertyKeyValues.Url];
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    };
    const filePathSummary = (): SummaryNode => {
        const filePaths = props.properties[propertyKeyValues.CustomResourceYamlFileName];
        return filePaths ? Summary.summary(filePaths) : Summary.placeholder("Provide a path to your YAML file(s)");
    };
    const branchSummary = (): SummaryNode => {
        const defaultBranchName = getDefaultBranchName();
        if (!defaultBranchName) {
            return Summary.placeholder("Configure default branch");
        }
        return Summary.summary(<span>
                Default branch is <strong>{defaultBranchName}</strong>
            </span>);
    };
    return (<div>
            <FormSectionHeading title="Git Repository Details"/>
            <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.Git.Repository" isExpandedByDefault={props.expandedByDefault} title="Authentication" summary={authSummary()} help={"Choose the file(s) in Git which contain the Kubernetes resource YAML."}>
                <RadioButtonGroup value={props.properties[propertyKeyValues.CredentialsType]} onChange={(val) => setCredentialType(val)} error={props.getFieldError(propertyKeyValues.CredentialsType)} accessibleName="GitCredentialType">
                    <RadioButton value={"Library"} label="Library" accessibleName="GitCredentialTypeLibrary"/>
                    {isUsingLibraryAuth() && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>You need the 'GitCredentialView' permission to change the Git credential.</Note>}>
                            <GitCredentialSelect items={gitCredentials} onChange={(Id) => props.setProperties({ [propertyKeyValues.CredentialsId]: Id })} value={props.properties[propertyKeyValues.CredentialsId]} onRequestRefresh={props.refreshGitCredentials} helperText="Select the Git credential to use" data-testid="gitCredentialSelect"/>
                            <Note> Use credential from the Git credential library </Note>
                        </PermissionCheck>)}
                    <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                </RadioButtonGroup>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Url" title="Repository URL" summary={repoUrlSummary()} help="Add the Git repository where you store your YAML files" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="Url" localNames={props.localNames} value={props.properties[propertyKeyValues.Url]} onChange={(Url) => (props.properties[propertyKeyValues.Url] = Url)} label="Repository URL" error={props.getFieldError(propertyKeyValues.Url)} validate={required("Enter a Git repository URL.")} accessibleName={"URL for Git repository"}/>
                <Note>
                    The HTTPS URL to your git repo. E.g. <code>https://github.com/OctopusDeploy/OctopusClients.git</code>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={branchSummary()} help="Specify the default branch you want to use" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="DefaultBranch" localNames={props.localNames} value={props.properties[propertyKeyValues.DefaultBranch]} onChange={(DefaultBranch) => (props.properties[propertyKeyValues.DefaultBranch] = DefaultBranch)} label="Default Branch" error={props.getFieldError(propertyKeyValues.DefaultBranch)} validate={required("Enter the default branch for your Git repository.")} accessibleName={"Name of the default branch on the Git repository"}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Path" title="Path" summary={filePathSummary()} help="Provide a path to your YAML file(s)" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="Path" localNames={props.localNames} value={props.properties[propertyKeyValues.CustomResourceYamlFileName]} onChange={(FilePaths) => (props.properties[propertyKeyValues.CustomResourceYamlFileName] = FilePaths)} label="Path" error={props.getFieldError("Path")} validate={required("Enter the path to your YAML file(s).")} accessibleName={"Path to your YAML files in the Git repository"}/>
                <Note>Use semicolons to separate multiple paths. This field also supports glob expressions.</Note>
            </ExpandableFormSection>
        </div>);
}
export default KubernetesRawYamlGitSourceComponent;
