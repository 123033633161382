import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { Note } from "~/components/form";
import { OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Variables.svg");
export const CommonProjectVariableOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Define <ExternalLink href="DocumentationVariables">variables</ExternalLink> with values that change based on the scope you've assigned to the variables and the scope of your deployments.
                </p>
                <p>
                    <ExternalVideoLink href="OnboardingProjectVariablesVideo"/>
                </p>
                <ImageWithPlaceholder src={image} alt={"Variables"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Sharing variables between projects?"}>
                    Instead of defining variables for each project, you can define a set of variables into a <ExternalLink href="LibraryVariableSets">Library Variable Set</ExternalLink> and then access them from every project that needs them.
                </Note>
            </TermsText>
        </OverviewContainer>);
};
CommonProjectVariableOverview.displayName = "CommonProjectVariableOverview"
export const CommonProjectVariableTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="DocumentationVariables">Variables</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonProjectVariableTopics.displayName = "CommonProjectVariableTopics"
