/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import SelectWithAddRefresh from "../SelectWithAddRefresh/SelectWithAddRefresh";
import styles from "./style.module.less";
type AccountSelectProps = FormFieldProps<string> & {
    multiple?: boolean;
    fixedLabel?: boolean;
    type: AccountType | AccountType[];
    items: AccountResource[];
    placeholder?: string;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
};
interface TextState {
    error?: string;
    refreshing: boolean;
}
export class AccountSelect extends React.Component<AccountSelectProps, TextState> {
    constructor(props: AccountSelectProps) {
        super(props);
        this.state = {
            error: null!,
            refreshing: false,
        };
    }
    handleChange = (accountId: string | undefined) => {
        const value = accountId === "" ? null : accountId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };
    getItems() {
        const map = (acc: AccountResource) => ({ value: acc.Id, text: acc.Name });
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];
        return this.props.items.filter((a) => types.find((t) => a.AccountType === t)).map(map);
    }
    selectionRenderer = (accountId: string) => {
        const account = this.props.items.find((acc) => acc.Id === accountId);
        if (!account) {
            return accountId;
        }
        return <div className={styles.innerContainer}>{account.Name}</div>;
    };
    onlyTypeIs(type: string) {
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];
        return types.length === 1 && types[0] === type;
    }
    render() {
        const { items, onChange, onValidate, onRequestRefresh, ...otherProps } = this.props;
        let warning = "";
        if (this.onlyTypeIs(AccountType.AzureSubscription)) {
            warning = "Only Azure Management Certificate Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.AzureServicePrincipal)) {
            warning = "Only Azure Service Principal Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.AmazonWebServicesAccount)) {
            warning = "Only Amazon Web Service Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.GoogleCloudAccount)) {
            warning = "Only Google Cloud Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.SshKeyPair)) {
            warning = "Only SSH Key Pair Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.Token)) {
            warning = "Only Token Accounts may be selected.";
        }
        if (this.onlyTypeIs(AccountType.UsernamePassword)) {
            warning = "Only Username/Password Accounts may be selected.";
        }
        return (<div className={styles.outerContainer}>
                <SelectWithAddRefresh addUrl={`#${routeLinks.infrastructure.accounts.root}`} onRequestRefresh={this.onRequestRefresh} label="Select account" {...otherProps} allowFilter={true} onChange={this.handleChange} error={this.state.error || this.props.error} items={this.getItems()} selectionRenderer={this.selectionRenderer}/>
                {warning && <Note>{warning}</Note>}
            </div>);
    }
    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        }
        finally {
            this.setState({ refreshing: false });
        }
    };
    static displayName = "AccountSelect";
}
export const BoundAccountSelect = withBoundField(AccountSelect);
export default AccountSelect;
