import React from "react";
import { withTheme } from "~/components/Theme";
import { Note } from "~/components/form";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./Registrations.module.less";
export const OverviewContainer: React.StatelessComponent<{}> = (props) => {
    return <div className={styles.overviewContainer}>{props.children}</div>;
};
OverviewContainer.displayName = "OverviewContainer"
export const TopicsContainer: React.StatelessComponent<{}> = (props) => {
    return <div className={styles.topicsContainer}>{props.children}</div>;
};
TopicsContainer.displayName = "TopicsContainer"
export const OverviewHighlightText: React.StatelessComponent<{}> = (props) => {
    return <div className={styles.overviewHighlight}>{props.children}</div>;
};
OverviewHighlightText.displayName = "OverviewHighlightText"
export const TermsText: React.StatelessComponent<{}> = (props) => {
    return <div className={styles.terms}>{props.children}</div>;
};
TermsText.displayName = "TermsText"
export const LearnMoreResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.docs}>
            <h5>Learn more</h5>
            {props.children}
        </div>);
};
LearnMoreResources.displayName = "LearnMoreResources"
export const SupportResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.support}>
            <h5>Support</h5>
            {props.children}
        </div>);
};
SupportResources.displayName = "SupportResources"
export const NewToOctopusResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.newToOctopus}>
            <h5>Getting started</h5>
            {props.children}
        </div>);
};
NewToOctopusResources.displayName = "NewToOctopusResources"
export const NeedHelpResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.needHelp}>
            <h5>Need some help?</h5>
            {props.children}
        </div>);
};
NeedHelpResources.displayName = "NeedHelpResources"
export const TutorialResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.tutorials}>
            <h5>Tutorials</h5>
            {props.children}
        </div>);
};
TutorialResources.displayName = "TutorialResources"
export const WebinarResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.webinar}>
            <h5>Webinar</h5>
            {props.children}
        </div>);
};
WebinarResources.displayName = "WebinarResources"
export const VideoResources: React.StatelessComponent<{}> = (props) => {
    return (<div className={styles.video}>
            <h5>Video</h5>
            {props.children}
        </div>);
};
VideoResources.displayName = "VideoResources"
export const CommonNeedHelpInformation: React.StatelessComponent<{}> = (props) => withTheme((theme) => (<div className={styles.helpInformation}>
            <Note>Look out for this icon to access help and related resources</Note>
            <span style={{ top: "0.5rem", position: "relative", marginLeft: "0.25rem" }}>
                <ThirdPartyIcon iconType={ThirdPartyIconType.HelpOutline} color={theme.iconDark}/>
            </span>
        </div>));
CommonNeedHelpInformation.displayName = "CommonNeedHelpInformation"
