import React from "react";
import { ExpandableFormSection, Note, Summary, Text } from "~/components/form";
type HelpSidebarSupportLinkProps = {
    link?: string;
    error?: string;
    onChange: (link: string) => void;
};
export function HelpSidebarSupportLink({ link, onChange, error }: HelpSidebarSupportLinkProps) {
    return (<ExpandableFormSection key="HelpSidebarSupportLink" errorKey="HelpSidebarSupportLink" title="Customize the Help Sidebar" summary={link ? Summary.summary(link) : Summary.default("Octopus support")} help="Customize the Help Sidebar support link in the Octopus web-portal UI.">
            <Text value={link || ""} onChange={onChange} label="Support Link" error={error} autoFocus={true}/>
            <Note style={{ marginTop: "1rem" }}>
                The sidebar includes a link to the Octopus support website by default. This feature allows you to customize the link destination. For example, in cases where you'd prefer users contact your own support system. If the sidebar feature
                is disabled, this support link is located in the top profile menu.
            </Note>
        </ExpandableFormSection>);
}
