/* eslint-disable no-eq-null */
import type { ResourceCollection, UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ReactNode } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import List from "~/components/List";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import Avatar from "~/primitiveComponents/dataDisplay/Avatar";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { InviteOctoIdUserDialogLink } from "./InviteOctoIdUserDialogLink";
import { isOctopusIdEnabled } from "./identityProviders";
import styles from "./style.module.less";
interface UsersState extends DataBaseComponentState {
    usersResponse?: ResourceCollection<UserResource>;
    isOctoIdEnabled?: boolean;
}
class UserList extends List<UserResource> {
}
export class Users extends DataBaseComponent<RouteComponentProps<{}>, UsersState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const usersResponse = await repository.Users.list();
            this.setState({ usersResponse, isOctoIdEnabled: await isOctopusIdEnabled() });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<PaperLayout title="Users" busy={this.state.busy} errors={this.errors} sectionControl={this.createUserAction()}>
                {this.state.usersResponse && (<UserList initialData={this.state.usersResponse} onRow={(item: UserResource) => this.buildUserRow(item)} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["filter"]} match={this.props.match} filterHintText="Filter by user..."/>)}
            </PaperLayout>);
    }
    private createUserAction(): ReactNode {
        const regularActions = [
            <PermissionCheck permission={[Permission.UserEdit]}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add users" href={`${this.props.match.url}/create`}/>
            </PermissionCheck>,
        ];
        const octoIdActions = [
            <PermissionCheck permission={[Permission.UserEdit]}>
                <NavigationButton type={NavigationButtonType.Secondary} label="Create service account" href={`${this.props.match.url}/create`}/>
            </PermissionCheck>,
            <PermissionCheck permission={[Permission.UserEdit]}>
                <InviteOctoIdUserDialogLink label="Invite user"/>
            </PermissionCheck>,
        ];
        const isOctoIdEnabled = this.state.isOctoIdEnabled;
        const actionSection = <ActionList actions={!!isOctoIdEnabled ? octoIdActions : regularActions}/>;
        return isOctoIdEnabled != null ? actionSection : null;
    }
    private buildUserRow(user: UserResource): ReactNode {
        return (<div className={styles.row}>
                <div className={styles.avatar}>
                    <Avatar avatarLink={user && user.Links && user.Links.Avatar} isService={user && user.IsService} size={40}/>
                </div>
                <div>
                    <ListTitle>{user.DisplayName}</ListTitle>
                    <div>{user.EmailAddress || user.Username}</div>
                </div>
            </div>);
    }
    private filter(filter: string, resource: UserResource) {
        return (!filter ||
            filter.length === 0 ||
            !resource ||
            resource.Username.toLowerCase().includes(filter.toLowerCase()) ||
            (resource.EmailAddress && resource.EmailAddress.toLowerCase().includes(filter.toLowerCase())) ||
            resource.DisplayName.toLowerCase().includes(filter.toLowerCase()));
    }
    static displayName = "Users";
}
export default Users;
