import type { GetDynamicEnvironmentsOverviewActiveProjectFilter, DynamicEnvironmentOverview, DynamicEnvironmentOverviewState } from "@octopusdeploy/octopus-server-client";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import BusyFromPromise from "~/components/BusyFromPromise";
import ActionButton from "~/components/Button";
import { FilterTextChip } from "~/components/Chips";
import type { Errors } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/index";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { ErrorPanel, Select } from "~/components/form";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import routeLinks from "~/routeLinks";
import { DynamicEnvironmentStateIconWithDescription } from "../DynamicEnvironmentIcons/DynamicEnvironmentStateIconWithDescription";
import { getDynamicEnvironmentStateDescription } from "../DynamicEnvironmentIcons/getDynamicEnvironmentStateDescription";
import { deleteEnvironmentMenuItem } from "../DynamicEnvironmentMenuItems/deleteEnvironmentMenuItem";
import { deprovisionEnvironmentMenuItem } from "../DynamicEnvironmentMenuItems/deprovisionEnvironmentMenuItem";
import styles from "./style.module.less";
export function getDynamicEnvironmentActiveProjectFilterDescription(filter: GetDynamicEnvironmentsOverviewActiveProjectFilter) {
    switch (filter) {
        case "WithActiveProjects":
            return "With active projects";
        case "WithoutActiveProjects":
            return "Without active projects";
        default:
            return "Unknown";
    }
}
export type SortOrderOption = {
    value: SortOptions;
    text: string;
};
export const sortOrders: SortOrderOption[] = [
    { text: "Last activity: latest - oldest", value: "latestActivityDesc" },
    { text: "Last activity: oldest - latest", value: "latestActivityAsc" },
    { text: "Environment name: A - Z", value: "environmentNameAsc" },
    { text: "Environment name: Z - A", value: "environmentNameDesc" },
    { text: "Active projects: low - high", value: "activeProjectCountAsc" },
    { text: "Active projects: high - low", value: "activeProjectCountDesc" },
];
export function formatRelativeLatestActivityTime(latestActivityTime: string) {
    return moment(latestActivityTime).fromNow();
}
export type SortOptions = "latestActivityAsc" | "latestActivityDesc" | "environmentNameAsc" | "environmentNameDesc" | "activeProjectCountAsc" | "activeProjectCountDesc";
type DynamicEnvironmentsOverviewSortProps = {
    sortOrder: SortOptions;
    onSortOrderChange: (sort: SortOptions) => void;
};
function DynamicEnvironmentsOverviewSort({ sortOrder, onSortOrderChange }: DynamicEnvironmentsOverviewSortProps) {
    function selectionRenderer(_: string, menuItem: DropdownMenuOption) {
        return <div className={styles.sortBySelection}>{menuItem.text}</div>;
    }
    function onSortOrderChangeInternal(newValue: string | undefined) {
        if (newValue === undefined)
            return;
        if (newValue === "latestActivityAsc")
            onSortOrderChange("latestActivityAsc");
        else if (newValue === "latestActivityDesc")
            onSortOrderChange("latestActivityDesc");
        else if (newValue === "environmentNameAsc")
            onSortOrderChange("environmentNameAsc");
        else if (newValue === "environmentNameDesc")
            onSortOrderChange("environmentNameDesc");
        else if (newValue === "activeProjectCountAsc")
            onSortOrderChange("activeProjectCountAsc");
        else if (newValue === "activeProjectCountDesc")
            onSortOrderChange("activeProjectCountDesc");
        else
            throw new Error("Unknown sort option");
    }
    return (<label className={styles.dynamicEnvironmentsSortBySelect}>
            <div className={styles.dynamicEnvironmentsSortByLabel}>Sort by</div>
            <Select className={styles.sortSelect} allowClear={false} items={sortOrders} value={sortOrder} onChange={onSortOrderChangeInternal} sortItems={false} selectionRenderer={selectionRenderer}/>
        </label>);
}
type DynamicEnvironmentsTableProps = {
    dynamicEnvironments: DynamicEnvironmentOverview[];
    onDeprovisionEnvironment: (environmentId: string) => Promise<void>;
    onDeleteEnvironment: (environmentId: string) => Promise<void>;
};
function DynamicEnvironmentsOverviewTable({ dynamicEnvironments, onDeprovisionEnvironment, onDeleteEnvironment }: DynamicEnvironmentsTableProps) {
    function CreateOverflowMenuItems(environmentId: string, environmentName: string, environmentState: DynamicEnvironmentOverviewState) {
        const overflowMenuItems: Array<MenuItem> = [];
        if (environmentState === "Active" || environmentState === "DeprovisioningFailed") {
            overflowMenuItems.push(deprovisionEnvironmentMenuItem(environmentId, environmentName, onDeprovisionEnvironment));
        }
        overflowMenuItems.push(deleteEnvironmentMenuItem(environmentId, environmentName, onDeleteEnvironment));
        return overflowMenuItems;
    }
    return (<DataTable title="Dynamic Environments">
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Environment name</DataTableHeaderColumn>
                    <DataTableHeaderColumn>State</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Last activity</DataTableHeaderColumn>
                    <DataTableHeaderColumn className={styles.activeProjectCountCell}>Active projects</DataTableHeaderColumn>
                    <DataTableHeaderColumn />
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
                {dynamicEnvironments.map((de) => (<DataTableRow key={de.EnvironmentId}>
                        <DataTableRowColumn className={styles.dynamicEnvironmentsTableRowColumn}>
                            <div className={styles.dynamicEnvironmentsTableRowColumnData}>
                                <InternalLink to={routeLinks.infrastructure.dynamicEnvironment(de.EnvironmentId).overview}>{de.EnvironmentName}</InternalLink>
                            </div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentsTableRowColumn}>
                            <div className={classnames(styles.dynamicEnvironmentsTableRowColumnData, styles.dynamicEnvironmentStateCell)}>
                                <DynamicEnvironmentStateIconWithDescription state={de.EnvironmentState}/>
                            </div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentsTableRowColumn}>
                            <div className={styles.dynamicEnvironmentsTableRowColumnData}>
                                {de.LatestActivityTime && <ToolTip content={moment(de.LatestActivityTime).format("dddd[,] DD MMMM YYYY[,] HH:mm:ss [GMT]ZZ")}>{formatRelativeLatestActivityTime(de.LatestActivityTime)}</ToolTip>}
                            </div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentsTableRowColumn}>
                            <div className={classnames(styles.dynamicEnvironmentsTableRowColumnData, styles.activeProjectCountCell)}>{de.ActiveProjectCount}</div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentsTableRowColumn}>
                            <div className={classnames(styles.dynamicEnvironmentsTableRowColumnData, styles.overflowMenuCell)}>
                                <OverflowMenu accessibleName="dynamicEnvironmentOverviewOverflowMenu" menuItems={CreateOverflowMenuItems(de.EnvironmentId, de.EnvironmentName, de.EnvironmentState)}/>
                            </div>
                        </DataTableRowColumn>
                    </DataTableRow>))}
            </DataTableBody>
        </DataTable>);
}
type DynamicEnvironmentsOverviewFooterProps = {
    dynamicEnvironments: DynamicEnvironmentOverview[];
    totalCount: number | undefined;
    busy: Promise<void> | undefined;
    onLoadMore: () => void;
};
function DynamicEnvironmentsOverviewFooter({ dynamicEnvironments, totalCount, busy, onLoadMore }: DynamicEnvironmentsOverviewFooterProps) {
    const loadMoreEnabled = totalCount != undefined && totalCount > dynamicEnvironments.length;
    return <div className={styles.dynamicEnvironmentsFooter}>{loadMoreEnabled && <BusyFromPromise promise={busy}>{(isBusy: boolean) => <ActionButton label="Load more" onClick={onLoadMore} disabled={isBusy}/>}</BusyFromPromise>}</div>;
}
export type DynamicEnvironmentsOverviewFilter = {
    name?: string;
    state: Array<DynamicEnvironmentOverviewState>;
    activeProjects: Array<GetDynamicEnvironmentsOverviewActiveProjectFilter>;
};
class DynamicEnvironmentsOverviewAdvancedFilterLayout extends AdvancedFilterLayout<DynamicEnvironmentsOverviewFilter> {
}
type DynamicEnvironmentsOverviewProps = {
    dynamicEnvironments: DynamicEnvironmentOverview[];
    totalCount: number | undefined;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    sortOrder: SortOptions;
    filter: DynamicEnvironmentsOverviewFilter;
    onFilterChange: (filter: DynamicEnvironmentsOverviewFilter) => void;
    onSortOrderChange: (sort: SortOptions) => void;
    onLoadMore: () => void;
    onDeprovisionEnvironment: (environmentId: string) => Promise<void>;
    onDeleteEnvironment: (environmentId: string) => Promise<void>;
};
export const defaultDynamicEnvironmentsOverviewFilter: DynamicEnvironmentsOverviewFilter = {
    state: [],
    activeProjects: [],
};
const DynamicEnvironmentStateMultiSelect = MultiSelect<SelectItem>();
const allDynamicEnvironmentStates: Array<DynamicEnvironmentOverviewState> = ["Active", "Deprovisioned", "Deprovisioning", "DeprovisioningFailed"];
const DynamicEnvironmentActiveProjectsMultiSelect = MultiSelect<SelectItem>();
const allDynamicEnvironmentActiveProjectFilters: Array<GetDynamicEnvironmentsOverviewActiveProjectFilter> = ["WithActiveProjects", "WithoutActiveProjects"];
const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => <FilterTextChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} filterText={r.Name}/>;
export function DynamicEnvironmentsOverview({ dynamicEnvironments, totalCount, busy, sortOrder, errors, filter, onFilterChange, onDeleteEnvironment, onDeprovisionEnvironment, onLoadMore, onSortOrderChange }: DynamicEnvironmentsOverviewProps) {
    function getFilterChips() {
        const chips = [];
        chips.push(...filter.state.map((s) => <FilterTextChip filterText={getDynamicEnvironmentStateDescription(s)}/>));
        chips.push(...filter.activeProjects.map((ap) => <FilterTextChip filterText={getDynamicEnvironmentActiveProjectFilterDescription(ap)}/>));
        return chips.length > 0 ? chips : null;
    }
    return (<>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
            <DynamicEnvironmentsOverviewAdvancedFilterLayout defaultFilter={defaultDynamicEnvironmentsOverviewFilter} filter={filter} onFilterReset={() => onFilterChange(defaultDynamicEnvironmentsOverviewFilter)} additionalHeaderFilters={[
            <FilterSearchBox placeholder={"Search by environment name..."} onChange={(newValue) => {
                    onFilterChange({
                        ...filter,
                        name: newValue,
                    });
                }}/>,
        ]} filterByChips={getFilterChips()} additionalHeaderControls={[<DynamicEnvironmentsOverviewSort sortOrder={sortOrder} onSortOrderChange={onSortOrderChange}/>]} filterSections={[
            {
                render: (<>
                                <DynamicEnvironmentStateMultiSelect accessibleName="dynamicEnvironmentsOverviewStateFilter" items={allDynamicEnvironmentStates.map((s) => ({ Id: s, Name: getDynamicEnvironmentStateDescription(s) }))} value={filter.state} onChange={(newStates) => {
                        onFilterChange({
                            ...filter,
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            state: newStates as DynamicEnvironmentOverviewState[],
                        });
                    }} renderChip={chipRenderer} label="State"/>

                                <DynamicEnvironmentActiveProjectsMultiSelect accessibleName="dynamicEnvironmentsOverviewActiveProjectsFilter" items={allDynamicEnvironmentActiveProjectFilters.map((f) => ({ Id: f, Name: getDynamicEnvironmentActiveProjectFilterDescription(f) }))} value={filter.activeProjects} onChange={(newValues) => {
                        onFilterChange({
                            ...filter,
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            activeProjects: newValues as GetDynamicEnvironmentsOverviewActiveProjectFilter[],
                        });
                    }} renderChip={chipRenderer} label="Active projects"/>
                            </>),
            },
        ]} renderContent={() => (<>
                        <DynamicEnvironmentsOverviewTable dynamicEnvironments={dynamicEnvironments} onDeleteEnvironment={onDeleteEnvironment} onDeprovisionEnvironment={onDeprovisionEnvironment}/>
                        <DynamicEnvironmentsOverviewFooter dynamicEnvironments={dynamicEnvironments} totalCount={totalCount} busy={busy} onLoadMore={onLoadMore}/>
                    </>)}/>
        </>);
}
