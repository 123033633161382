/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { StepIconProps } from "@material-ui/core";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import type { ProjectResource, MigrateProjectVariablesToGitCommand, MigrateProjectVariablesToGitSummary, GitBranch, GitRef } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, isGitBranch, toGitBranch, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import classNames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ActionEvent, AnalyticErrorCallback } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise";
import ActionButton, { ActionButtonType } from "~/components/Button";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { CustomWizardDialogLayout, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { BooleanRadioButtonGroup, ErrorPanel, Note, RadioButton, Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import StringHelper from "~/utils/StringHelper";
import { GitRefDropDownMode } from "../../../GitRefDropDown/GitRefDropDown";
import { GitRefSelector } from "../../../GitRefSelector/GitRefSelector";
import { getFormattedCommitMessage } from "../../../VersionControl/CommitMessageWithDetails";
import styles from "./style.module.less";
enum ExecutionState {
    NotStarted = "NotStarted",
    Executing = "Executing",
    Success = "Success",
    Error = "Error"
}
interface MigrateProjectVariablesDialogProps {
    open: boolean;
    close: () => void;
}
class MigrateProjectVariablesWizard extends DataBaseComponent<MigrateProjectVariablesDialogProps> {
    constructor(props: MigrateProjectVariablesDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <MigrateProjectVariablesWizardInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "MigrateProjectVariablesWizard";
}
interface MigrateProjectVariablesDialogInternalProps extends MigrateProjectVariablesDialogProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}
function useMigrationSummary(doBusyTask: DoBusyTask, project: ProjectResource) {
    const [migrationSummary, setMigrationSummary] = useState<MigrateProjectVariablesToGitSummary>();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const summary = await repository.Projects.getMigrateVariablesToGitSummary(project);
        setMigrationSummary(summary);
    }, [project]);
    return migrationSummary;
}
function useMigrationExecutor(doBusyTask: DoBusyTask, project: ProjectResource, setExecutionState: (state: ExecutionState) => void) {
    const trackAction = useAnalyticTrackedActionDispatch();
    const migrator = useCallback((command: MigrateProjectVariablesToGitCommand) => {
        const ev: ActionEvent = {
            action: Action.Save,
            resource: "Variables",
            isDefaultBranch: command.AnalyticsInfo.IsDefaultBranch,
            commitMessage: command.AnalyticsInfo.IsDefaultCommitMessage,
        };
        return trackAction("Move Variables to Git", ev, async (analyticErrorCallback: AnalyticErrorCallback) => {
            await doBusyTask(async () => await repository.Projects.migrateVariablesToGit(project, command), {
                onError: (error) => {
                    analyticErrorCallback(error);
                    setExecutionState(ExecutionState.Error);
                },
                onSuccess: () => setExecutionState(ExecutionState.Success),
            });
        });
    }, [doBusyTask, project, setExecutionState, trackAction]);
    return migrator;
}
const MigrateProjectVariablesWizardInternal: React.FC<MigrateProjectVariablesDialogInternalProps> = (props: MigrateProjectVariablesDialogInternalProps) => {
    const defaultCommitSummary = "Migrate project variables to Git";
    const stepNames = ["Getting started", "Select a branch", "Review & migrate"];
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
        throw new Error("Can not migrate variables for Database projects");
    }
    const defaultBranch: GitBranch = toGitBranch(project.PersistenceSettings.DefaultBranch);
    const [executionState, setExecutionState] = useState<ExecutionState>(ExecutionState.NotStarted);
    const [createNewBranch, setCreateNewBranch] = useState(false);
    const [existingBranchGitRef, setExistingBranchGitRef] = useState<GitRef>(defaultBranch);
    const [newBranchName, setNewBranchName] = useState("");
    const [isUsingDefaultBranch, setIsUsingDefaultBranch] = useState<Boolean>(true);
    useEffect(() => {
        const usingDefaultBranch = !createNewBranch && isGitBranch(existingBranchGitRef) && existingBranchGitRef === defaultBranch;
        setIsUsingDefaultBranch(usingDefaultBranch);
    }, [createNewBranch, existingBranchGitRef, defaultBranch]);
    const [commitSummary, setCommitSummary] = useState("");
    const [commitDetails, setCommitDetails] = useState("");
    const migrationSummary = useMigrationSummary(props.doBusyTask, project);
    const executeMigration = useMigrationExecutor(props.doBusyTask, project, setExecutionState);
    const [canProgress, setCanProgress] = useState<CanProgress>({});
    useEffect(() => {
        if (createNewBranch && StringHelper.isNullOrWhiteSpace(newBranchName)) {
            setCanProgress({ 1: false });
        }
        else {
            setCanProgress({ 1: true });
        }
    }, [createNewBranch, newBranchName]);
    const migrate = () => {
        if (!isGitBranch(existingBranchGitRef)) {
            throw new Error(`Selected GitRef is not a branch: ${existingBranchGitRef}`);
        }
        setExecutionState(ExecutionState.Executing);
        const branch = createNewBranch ? newBranchName : existingBranchGitRef;
        const formattedCommitMessage = getFormattedCommitMessage({
            summary: commitSummary,
            details: commitDetails,
        }, defaultCommitSummary);
        const command: MigrateProjectVariablesToGitCommand = {
            Branch: branch,
            CreateBranch: createNewBranch,
            CommitMessage: formattedCommitMessage,
            AnalyticsInfo: {
                IsDefaultBranch: branch === defaultBranch,
                IsDefaultCommitMessage: !!commitSummary,
            },
        };
        executeMigration(command);
    };
    const dispatchAction = useAnalyticActionDispatch();
    const refreshAndClose = () => {
        if (executionState === ExecutionState.NotStarted) {
            // The user has cancelled without trying to complete the migration. Ideally we would like to send which step the user was on, but we can't get that info right now without a major refactor.
            // TODO: Add which step the user was on (e.g. { Step: "Getting started" } ).
            dispatchAction("Cancel Moving Variables", { resource: "Variables", action: Action.Cancel });
        }
        else if (executionState === ExecutionState.Error) {
            // The user has tried to migrate, hit an error and then canceled.
            dispatchAction("Cancel Moving Variables", { resource: "Variables", action: Action.Cancel });
        }
        if (executionState === ExecutionState.Success || executionState === ExecutionState.Error) {
            props.doBusyTask(async () => {
                if (executionState === ExecutionState.Success) {
                    const branch = createNewBranch ? toGitBranch(newBranchName) : existingBranchGitRef;
                    await projectContext.actions.onBranchSelected(project, branch);
                }
                await projectContext.actions.refreshModel();
                props.close();
            });
        }
        props.close();
    };
    const runbookInfoNote = !isUsingDefaultBranch && <InfoNote>Runbooks only use variables from the default branch. After migration, merge this branch to the default before running any Runbooks.</InfoNote>;
    return (<CustomWizardDialogLayout frame={SmallDialogFrame} open={props.open} close={refreshAndClose} renderPage={(renderProps) => <MigrateProjectVariablesWizardLayout {...renderProps} executionState={executionState} stepNames={stepNames} execute={migrate} busy={props.busy} canProgress={canProgress} errors={props.errors}/>}>
            <MigrateProjectVariablesWizardStep>
                {migrationSummary && (<Callout title="Ready to migrate" type={CalloutType.Success}>
                        This project is ready to migrate variables to Git
                    </Callout>)}
                <p>You are about to migrate your project variables to Git. Octopus will:</p>
                <ul>
                    <li> Convert variables to OCL and move them to your Git repository </li>
                    <li> Keep current and future sensitive variables in the Octopus database </li>
                    <li> Use variables from the default branch for runbooks</li>
                </ul>
                <p>
                    This process cannot be reversed. Read the <ExternalLink href="ConfigAsCodeVariables">Git variables documentation</ExternalLink> for more details.
                </p>
            </MigrateProjectVariablesWizardStep>
            <MigrateProjectVariablesWizardStep>
                <p> Choose a branch to migrate the variables to: </p>
                <BooleanRadioButtonGroup key="branchOptions" onChange={setCreateNewBranch} value={createNewBranch}>
                    <RadioButton key="existingBranch" value={false} label="Existing branch"/>
                    {!createNewBranch && (<div>
                            <GitRefSelector style={"white"} key={`${existingBranchGitRef}`} initialGitRef={existingBranchGitRef} onChange={setExistingBranchGitRef} mode={GitRefDropDownMode.BranchesOnly} allowBranchCreation={false} project={project}/>
                            {runbookInfoNote}
                        </div>)}
                    <RadioButton key="newBranch" value={true} label="New branch"/>
                    {createNewBranch && (<div>
                            <Text 
        // There is some weird business going on with the height of the text box. I think it has to do with the
        // lack of label and usePlaceholderAsLabel=false. After clearing the text the size briefly increases to
        // 37px. Fixing the height to stop it bouncing around.
        style={{ marginTop: "0", height: "37px" }} key="newBranchName" id="newBranchName" name="NewBranchName" value={newBranchName} onChange={setNewBranchName} placeholder="New branch name" accessibleName="New branch name" usePlaceholderAsLabel={false}/>
                            <Note>
                                The new branch will be based on default branch (<code>{toGitRefShort(defaultBranch)}</code>) in your repository
                            </Note>
                            {runbookInfoNote}
                        </div>)}
                </BooleanRadioButtonGroup>
            </MigrateProjectVariablesWizardStep>
            <MigrateProjectVariablesWizardStep>
                {(executionState === ExecutionState.NotStarted || executionState === ExecutionState.Executing) && (<>
                        <h3>Review Migration</h3>
                        <MigrationReviewList summary={migrationSummary} createNewBranch={createNewBranch} branchName={createNewBranch ? newBranchName : existingBranchGitRef} defaultBranch={defaultBranch}/>
                        {runbookInfoNote}
                        <h3>Enter a Commit Message</h3>
                        <Text key="summary" id="summary" name="Summary" label="Summary" placeholder={defaultCommitSummary} value={commitSummary} disabled={executionState === ExecutionState.Executing} onChange={setCommitSummary} autoFocus={true} accessibleName={"Commit summary"} customMargins="0 0 0.5rem 0"/>
                        <Text key="details" id="details" name="Details" label="Optional description" disabled={executionState === ExecutionState.Executing} value={commitDetails} onChange={setCommitDetails} multiline={true} accessibleName={"Optional commit description"}/>
                    </>)}
                {executionState === ExecutionState.Success && (<>
                        <Callout title="Success" type={CalloutType.Success}>
                            Variables have been migrated to your Git repository successfully
                        </Callout>
                        <h3> Next step </h3>
                        <p>
                            Variables have only been migrated to the <code>{createNewBranch ? newBranchName : existingBranchGitRef}</code> branch. You will need to merge this branch with other branches that need these variables.
                        </p>
                        <p>
                            Need help? See <ExternalLink href="ConfigAsCodeEap">Git variables documentation</ExternalLink> for more details.
                        </p>
                    </>)}
                {executionState === ExecutionState.Error && (<>
                        <p>The migration has failed. Review the configuration and try again. </p>
                        <p>
                            Need help? See <ExternalLink href="ConfigAsCodeEap">Git variables documentation</ExternalLink> for more details.
                        </p>
                    </>)}
            </MigrateProjectVariablesWizardStep>
        </CustomWizardDialogLayout>);
};
MigrateProjectVariablesWizardInternal.displayName = "MigrateProjectVariablesWizardInternal"
interface InfoNoteProps {
    children?: React.ReactNode;
}
const InfoNote: React.FC<InfoNoteProps> = (props: InfoNoteProps) => {
    return (<Note className={styles.infoNote}>
            <em className={classNames(styles.icon, "fa-solid", "fa-info-circle")} aria-hidden="true"/>
            {props.children}
        </Note>);
};
InfoNote.displayName = "InfoNote"
interface MigrationReviewListProps {
    summary?: MigrateProjectVariablesToGitSummary;
    createNewBranch: boolean;
    branchName: string | undefined;
    defaultBranch: string;
}
const MigrationReviewList: React.FC<MigrationReviewListProps> = (props: MigrationReviewListProps) => {
    if (!props.summary) {
        return <></>;
    }
    const anyTextVariables = props.summary.TextVariableCount > 0;
    const anySensitiveVariables = props.summary.SensitiveVariableCount > 0;
    const newBranchItem = (<li>
            New branch <code>{props.branchName}</code> will be created from <code>{toGitRefShort(props.defaultBranch)}</code>
        </li>);
    const textVariableSummaryItem = (<li>
            {props.summary.TextVariableCount} {pluralize("value", props.summary.TextVariableCount)} will be migrated to a new <code>variables.ocl</code> file on the <code>{toGitRefShort(props.branchName)}</code> branch
        </li>);
    const emptyTextVariableSummaryItem = (<li>
            An empty <code>variables.ocl</code> file will be created on <code>{toGitRefShort(props.branchName)}</code>. There are no variables to migrate.
        </li>);
    const sensitiveVariablesSummaryItem = (<li>
            {props.summary.SensitiveVariableCount} sensitive {pluralize("value", props.summary.SensitiveVariableCount)} will remain in the Octopus database
        </li>);
    return (<ul className={styles.migrationReviewList}>
            {props.createNewBranch && newBranchItem}
            {anyTextVariables ? textVariableSummaryItem : emptyTextVariableSummaryItem}
            {anySensitiveVariables && sensitiveVariablesSummaryItem}
        </ul>);
};
MigrationReviewList.displayName = "MigrationReviewList"
const MigrateProjectVariablesWizardStep: React.FC<{
    children: React.ReactNode;
}> = (props: {
    children: React.ReactNode;
}) => {
    return <div className={styles.wizardStepContentContainer}> {props.children} </div>;
};
MigrateProjectVariablesWizardStep.displayName = "MigrateProjectVariablesWizardStep"
interface MigrateProjectVariablesWizardLayoutProps extends MigrateProjectVariablesWizardStepListProps, MigrateProjectVariablesWizardLayoutButtonsProps {
    busy?: Promise<void>;
    errors?: Errors;
    content: React.ReactNode;
}
const MigrateProjectVariablesWizardLayout: React.FC<MigrateProjectVariablesWizardLayoutProps> = (props: MigrateProjectVariablesWizardLayoutProps) => {
    return (<DialogLayout title={"Migrate Variables To Git"} actions={<MigrateProjectVariablesWizardButtons {...props}/>} busy={props.busy} closeDialog={props.close}>
            <MigrateProjectVariablesWizardStepper {...props}/>
            <div className={styles.wizardContent}>
                {props.errors && (<ErrorPanel message={props.errors.message} errors={props.errors.errors} parsedHelpLinks={props.errors.parsedHelpLinks} helpText={props.errors.helpText} helpLink={props.errors.helpLink} statusCode={props.errors.statusCode}/>)}
                {props.content}
            </div>
        </DialogLayout>);
};
MigrateProjectVariablesWizardLayout.displayName = "MigrateProjectVariablesWizardLayout"
interface MigrateProjectVariablesWizardStepListProps {
    currentPageIndex: number;
    pageCount: number;
    stepNames: string[];
    executionState: ExecutionState;
}
const MigrateProjectVariablesWizardStepper: React.FC<MigrateProjectVariablesWizardStepListProps> = (props: MigrateProjectVariablesWizardStepListProps) => {
    return (<div className={styles.wizardStepper}>
            <Stepper alternativeLabel orientation="horizontal" activeStep={props.currentPageIndex}>
                {props.stepNames.map((name, index) => {
            const isFinalStep = index === props.stepNames.length - 1;
            const error = isFinalStep && props.executionState === ExecutionState.Error;
            const completed = isFinalStep && props.executionState === ExecutionState.Success;
            const stepIconBaseProps = isFinalStep ? { error, completed } : {};
            const stepIconProps: Partial<StepIconProps> = {
                ...stepIconBaseProps,
                classes: {
                    completed: styles.wizardIconStepCompleted,
                },
            };
            return (<Step key={index}>
                            <StepLabel StepIconProps={stepIconProps}>{name}</StepLabel>
                        </Step>);
        })}
            </Stepper>
        </div>);
};
MigrateProjectVariablesWizardStepper.displayName = "MigrateProjectVariablesWizardStepper"
interface CanProgress {
    [key: number]: boolean;
}
interface MigrateProjectVariablesWizardLayoutButtonsProps {
    currentPageIndex: number;
    canProgress: CanProgress;
    busy?: Promise<void>;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    moveNext: () => void;
    movePrevious: () => void;
    close: () => void;
    executionState: ExecutionState;
    execute: () => void | Promise<void>;
}
const MigrateProjectVariablesWizardButtons: React.FC<MigrateProjectVariablesWizardLayoutButtonsProps> = (props: MigrateProjectVariablesWizardLayoutButtonsProps) => {
    const isOnFinalPage = !props.hasNextPage;
    const canProgressCurrentPage = props.canProgress[props.currentPageIndex] === undefined || props.canProgress[props.currentPageIndex];
    return (<div>
            {props.executionState === ExecutionState.NotStarted && <ActionButton className={styles.wizardButton} type={ActionButtonType.Secondary} label="Cancel" onClick={props.close}/>}
            {props.executionState === ExecutionState.NotStarted && props.hasPreviousPage && <ActionButton className={styles.wizardButton} type={ActionButtonType.Secondary} label="Previous" onClick={props.movePrevious}/>}
            {isOnFinalPage && props.executionState === ExecutionState.NotStarted && <ActionButton className={styles.wizardButton} type={ActionButtonType.Primary} label="Migrate" onClick={props.execute}/>}
            {isOnFinalPage && props.executionState === ExecutionState.Executing && <ActionButton className={styles.wizardButton} disabled={true} type={ActionButtonType.Primary} label="Migrating"/>}
            {isOnFinalPage && props.executionState === ExecutionState.Success && <ActionButton className={styles.wizardButton} type={ActionButtonType.Primary} label="Done" onClick={props.close}/>}
            {isOnFinalPage && props.executionState === ExecutionState.Error && <ActionButton className={styles.wizardButton} type={ActionButtonType.Primary} label="Close" onClick={props.close}/>}
            {!isOnFinalPage && (<BusyFromPromise promise={props.busy}>{(busy) => <ActionButton className={styles.wizardButton} disabled={busy || !canProgressCurrentPage} type={ActionButtonType.Primary} label={"Next"} onClick={props.moveNext}/>}</BusyFromPromise>)}
        </div>);
};
MigrateProjectVariablesWizardButtons.displayName = "MigrateProjectVariablesWizardButtons"
export default MigrateProjectVariablesWizard;
