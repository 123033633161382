/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission, ControlType } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { repository } from "~/clientInstance";
import ActionTemplateParameterList from "~/components/ActionTemplateParametersList/ActionTemplateParametersList";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
export default class ProjectVariableTemplates extends FormBaseComponent<RouteComponentProps<ProjectRouteParams>, OptionalFormBaseComponentState<ProjectResource>, ProjectResource> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadProject(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<FormPaperLayout title={"Project Variable Templates"} breadcrumbTitle={this.state.model?.Name} model={this.state.model} cleanModel={this.state.cleanModel} busy={this.state.busy} errors={this.errors} onSaveClick={async () => this.save()}>
                <SectionNote>
                    Project variable templates define which variable values are required for each tenant connected to this project. They allow tenants to provide different variable values for each project/environment combination. Learn more
                    about&nbsp;
                    <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                {this.state.model && (<TransitionAnimation>
                        <Section>
                            <ActionTemplateParameterList parameters={this.state.model ? this.state.model.Templates : []} name="template" editPermission={{
                    permission: Permission.VariableEdit,
                    project: this.state.model.Id,
                    wildcard: true,
                }} excludedControlTypes={[ControlType.StepName, ControlType.Package]} onParametersChanged={(Templates) => this.setState({
                    model: {
                        ...this.state.model!,
                        Templates,
                    },
                })}/>
                        </Section>
                    </TransitionAnimation>)}
            </FormPaperLayout>);
    }
    private async loadProject() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        this.setModel(project);
    }
    private save() {
        return this.doBusyTask(async () => {
            await repository.Projects.modify(this.state.model!);
            await this.loadProject();
        });
    }
    static displayName = "ProjectVariableTemplates";
}
