import { CustomMenu, useMenuState, MenuItemInternalLink } from "@octopusdeploy/design-system-components";
import type { ResourcesById, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import keycode from "keycode";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { RaisedButtonProps } from "material-ui";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import RaisedButton from "material-ui/RaisedButton";
import * as React from "react";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import { repository, session } from "~/clientInstance";
import ActionButton, { IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { ActionButtonType } from "~/components/Button/index";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import type { OctopusTheme } from "~/components/Theme/index";
import { useOctopusTheme } from "~/components/Theme/index";
import Divider from "~/primitiveComponents/dataDisplay/Divider/Divider";
import routeLinks from "~/routeLinks";
import type { ArrayAtLeastLength } from "~/utils/ArrayAtLeastLength/ArrayAtLeastLength";
import { isArrayLengthAtLeast } from "~/utils/ArrayAtLeastLength/ArrayAtLeastLength";
import styles from "./DeployButton.module.less";
export interface DeployButtonProps {
    projectSlug: string;
    projectId: string;
    releaseVersion: string;
    nextDeployments: string[];
    environmentsById: ResourcesById<EnvironmentResource>;
    tenantedDeploymentMode: TenantedDeploymentMode;
}
export function DeployButton({ nextDeployments = [], tenantedDeploymentMode, environmentsById, projectSlug, releaseVersion, projectId }: DeployButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const dispatchDeployReleaseAnalyticsEvent = useCallback(() => {
        const ev: ActionEvent = {
            action: Action.Deploy,
            resource: "Deploy Release",
        };
        dispatchAction("Initiate Deployment", ev);
    }, [dispatchAction]);
    const theme = useOctopusTheme();
    const getEnvironmentLink = useGetEnvironmentLink(projectSlug, releaseVersion);
    const visibleEnvironments = useVisibleEnvironments(environmentsById, projectId, nextDeployments);
    if (isArrayLengthAtLeast(visibleEnvironments, 2)) {
        return (<DeployToMultipleEnvironmentsButton tenantedDeploymentMode={tenantedDeploymentMode} getEnvironmentLink={getEnvironmentLink} visibleEnvironments={visibleEnvironments} dispatchDeployReleaseAnalyticsEvent={dispatchDeployReleaseAnalyticsEvent} environmentsById={environmentsById}/>);
    }
    else if (visibleEnvironments.length === 1) {
        const singleEnvironment = visibleEnvironments[0];
        const environment = environmentsById[singleEnvironment];
        return (<InternalLink to={getEnvironmentLink(singleEnvironment)}>
                <RaisedButton {...createDeployButtonStyle(theme)} label={`Deploy to ${environment ? environment.Name : ""}...`} onClick={dispatchDeployReleaseAnalyticsEvent}/>
            </InternalLink>);
    }
    return null;
}
function useGetEnvironmentLink(projectSlug: string, releaseVersion: string): (environmentId: string | undefined) => string {
    return useCallback((environments: string | undefined) => {
        const deploymentLinks = routeLinks.project(projectSlug).release(releaseVersion).deployments;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return deploymentLinks.create(environments ? DeploymentCreateGoal.To : null!, environments);
    }, [projectSlug, releaseVersion]);
}
function useVisibleEnvironments(environmentsById: ResourcesById<EnvironmentResource>, projectId: string, nextDeployments: string[]) {
    return useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const isAuthorizedToCreateDeployment = (environmentId: string) => session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission: Permission.DeploymentCreate, environmentId, projectId: projectId, tenantId: "*" });
        const isKnownEnvironment = (environmentId: string) => environmentsById.hasOwnProperty(environmentId);
        return nextDeployments.filter(isKnownEnvironment).filter(isAuthorizedToCreateDeployment);
    }, [environmentsById, nextDeployments, projectId]);
}
interface DeployToMultipleEnvironmentsButtonProps {
    dispatchDeployReleaseAnalyticsEvent: () => void;
    tenantedDeploymentMode: TenantedDeploymentMode;
    visibleEnvironments: ArrayAtLeastLength<string, 2>;
    getEnvironmentLink: (environment: string | undefined) => string;
    environmentsById: ResourcesById<EnvironmentResource>;
}
function DeployToMultipleEnvironmentsButton({ dispatchDeployReleaseAnalyticsEvent, tenantedDeploymentMode, visibleEnvironments, getEnvironmentLink, environmentsById }: DeployToMultipleEnvironmentsButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const [filter, setFilter] = useState("");
    const firstFilteredOptionRef = useRef<HTMLAnchorElement | null>(null);
    const onKeyDown = useCallback((ev: Event) => {
        switch (keycode(ev)) {
            case "tab":
                ev.preventDefault();
                firstFilteredOptionRef.current?.focus();
                break;
            case "down":
                firstFilteredOptionRef.current?.focus();
                break;
        }
    }, []);
    const filteredEnvironments = useMemo(() => visibleEnvironments.filter((id) => environmentsById[id].Name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())), [environmentsById, filter, visibleEnvironments]);
    const canDeployToMultipleEnvironments = tenantedDeploymentMode !== TenantedDeploymentMode.Tenanted;
    return (<>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Deploy to..." onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Deploy release"} menuState={menuState}>
                {canDeployToMultipleEnvironments && (<>
                        <DeployToAllAvailableEnvironmentsMenuItem filteredEnvironments={filteredEnvironments} getEnvironmentLink={getEnvironmentLink} onClick={dispatchDeployReleaseAnalyticsEvent}/>
                        <Divider />
                        <EnvironmentsFilter filter={filter} setFilter={setFilter} autoFocus={true} onKeyDown={onKeyDown}/>
                    </>)}
                {filteredEnvironments.map((e, index) => {
            const environment = environmentsById[e];
            return <MenuItemInternalLink key={e} path={getEnvironmentLink(e)} label={environment.Name} onClick={dispatchDeployReleaseAnalyticsEvent} size={"compact"} ref={index === 0 ? firstFilteredOptionRef : undefined}/>;
        })}
            </CustomMenu>
        </>);
}
function createDeployButtonStyle(theme: OctopusTheme): Partial<RaisedButtonProps> {
    return {
        labelColor: theme.primaryButtonText,
        backgroundColor: theme.primaryButtonBackground,
        labelStyle: {
            fontSize: "0.8125rem",
            whiteSpace: "nowrap",
        },
    };
}
interface DeployToAllAvailableEnvironmentsMenuItemProps {
    filteredEnvironments: string[];
    getEnvironmentLink: (environment: string | undefined) => string;
    onClick: () => void;
}
// The forwardRef here is not important, but needed to prevent console errors that would otherwise say a ref hasn't been appropriately forwarded.
// This requirement has been removed in later versions of material-ui (v5 onwards) and doesn't remove any functionality given the way we are using these components.
// See https://github.com/mui-org/material-ui/pull/25691/files#diff-ad4b8459eb1cd3c5e6882eb705e3e341e551bd7bf4ab9a6941ef12017eb1cb06L177-L188
const DeployToAllAvailableEnvironmentsMenuItem = forwardRef(({ filteredEnvironments, getEnvironmentLink, onClick }: DeployToAllAvailableEnvironmentsMenuItemProps, ref: unknown) => {
    const label = filteredEnvironments.length === 2 ? "Deploy to both environments..." : `Deploy to all ${filteredEnvironments.length} environments...`;
    return <MenuItemInternalLink label={label} path={getEnvironmentLink(filteredEnvironments.join(","))} onClick={onClick} size={"compact"}/>;
});
interface EnvironmentsFilterProps {
    filter: string;
    setFilter: (newFilter: string) => void;
    autoFocus: boolean;
    onKeyDown: (event: Event) => void;
}
function EnvironmentsFilter({ filter, setFilter, autoFocus, onKeyDown }: EnvironmentsFilterProps) {
    const theme = useOctopusTheme();
    return (<div className={styles.deployButtonFilterContainer}>
            <FilterSearchBox autoFocus={autoFocus} value={filter} placeholder="Filter..." onKeyDown={(e) => {
            //There is special handling for keydown events in menu items so we have to stop this from propagating.
            e.stopPropagation();
            onKeyDown(e);
        }} onChange={setFilter} fullWidth={true} containerClassName={styles.filterFieldContainer} iconColor={theme.whiteConstant} iconStyle={{ width: "20px", height: "20px", top: "14px" }}/>
        </div>);
}
