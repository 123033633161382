import { css } from "@emotion/css";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
interface LeftoversProps {
    count?: number | null;
    name: string;
    showAll?: boolean;
    onShowAllChanged?: () => void;
}
export function Leftovers(props: LeftoversProps) {
    function handleOnClick() {
        if (props.onShowAllChanged)
            props.onShowAllChanged();
    }
    if (props.showAll && props.onShowAllChanged) {
        return (<span className={styleExpandable} onClick={handleOnClick}>
                Show summary
            </span>);
    }
    if (!props.count)
        return <></>;
    return (<span className={props.onShowAllChanged ? styleExpandable : style} onClick={handleOnClick}>
            +{props.count} {props.name}
            {props.count > 1 && "s"}
        </span>);
}
const style = css({
    marginLeft: space[4],
    fontSize: fontSize.medium,
    color: themeTokens.color.table.cell.text.subtle,
});
const styleExpandable = css({
    marginLeft: space[4],
    fontSize: fontSize.medium,
    color: themeTokens.color.table.cell.text.link,
    cursor: "pointer",
});
