/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MultiTenancyStatusResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { configurationActions } from "../../configuration/reducers/configurationArea";
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
}
interface AddTenantDialogProps {
    title: string;
    cloneId?: string;
    cloneTenantName?: string;
    tenantCreated(tenant: TenantResource): any;
}
interface AddTenantDialogState extends DataBaseComponentState {
    name: string;
    description?: string;
}
class AddTenantDialog extends DataBaseComponent<GlobalConnectedProps & GlobalDispatchProps & AddTenantDialogProps, AddTenantDialogState> {
    constructor(props: GlobalConnectedProps & GlobalDispatchProps & AddTenantDialogProps) {
        super(props);
        this.state = {
            name: "",
        };
    }
    save = async () => {
        return this.doBusyTask(async () => {
            const args = { clone: this.props.cloneId };
            const result = await repository.Tenants.create({
                Name: this.state.name,
                Description: this.state.description,
                TenantTags: [],
                ProjectEnvironments: {},
            }, args);
            if (!this.props.isMultiTenancyEnabled) {
                const status = await repository.Tenants.status();
                this.props.onSpaceMultiTenancyStatusFetched(status);
            }
            this.props.tenantCreated(result);
            return true;
        });
    };
    render() {
        return (<SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={this.save}>
                <Text label="New tenant name" value={this.state.name} onChange={(value) => this.setState({ name: value })} validate={required("Please enter a tenant name")} autoFocus={true}/>
                <Text label="Description" value={this.state.description || ""} onChange={(value) => this.setState({ description: value })}/>
                {this.props.cloneId && (<Callout type={CalloutType.Information} title="Scoping" key="clone-warning">
                        Targets, Accounts, Certificates, Permissions and other resources that are scoped to <strong>{this.props.cloneTenantName}</strong> will not be automatically updated to include this new Tenant.
                    </Callout>)}
            </SaveDialogLayout>);
    }
    static displayName = "AddTenantDialog";
}
const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => bindActionCreators({ onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched }, dispatch);
export default connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(AddTenantDialog);
