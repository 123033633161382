/* eslint-disable no-eq-null */
import { OctoLink } from "@octopusdeploy/utilities";
import * as React from "react";
import { useAnalyticExternalLinkDispatch } from "~/analytics/Analytics";
export interface ExternalLinkProps extends React.HTMLProps<HTMLElement> {
    href: string;
    showIcon?: boolean;
    openInSelf?: boolean;
    onClick?: () => void;
    className?: string;
    trackAnalytics?: boolean;
    downloadFileName?: string;
}
const ExternalLink: React.SFC<ExternalLinkProps> = (props: ExternalLinkProps) => {
    const linkRef = React.useRef<HTMLAnchorElement | null>(null);
    const dispatchLink = useAnalyticExternalLinkDispatch();
    if (props.href == null) {
        return null;
    }
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, {}>) => {
        if (props.onClick) {
            props.onClick();
        }
        if (props.trackAnalytics === true) {
            if (linkRef.current) {
                dispatchLink(linkRef.current.innerText, props.href);
            }
        }
        e.stopPropagation();
    };
    const linkProps = props.openInSelf ? { target: "_self" } : { target: "_blank", rel: "noopener noreferrer" };
    const downloadProps = props.downloadFileName ? { download: props.downloadFileName } : {};
    return (<a ref={linkRef} className={props.className} href={formatUrl(props.href)} {...linkProps} onClick={onClick} {...downloadProps}>
            {props.children}
            {props.showIcon && <ExternalLinkIcon />}
        </a>);
};
ExternalLink.displayName = "ExternalLink"
export function ExternalLinkIcon() {
    return <em style={{ marginLeft: "0.250rem" }} className="fa-solid fa-external-link" aria-hidden="true"/>;
}
ExternalLink.defaultProps = {
    showIcon: true,
    openInSelf: false,
    trackAnalytics: true,
};
export function formatUrl(href: string) {
    if (parseUrl(href)) {
        return href;
    }
    return OctoLink.Create(href);
    function parseUrl(url: string): boolean {
        try {
            // We need these .startsWith checks because IE11 will assume URL("someString") is relative to the current
            // domain and won't throw (like every other browser in the universe).
            if (url.startsWith("http://") || url.startsWith("https://")) {
                const ignored = new URL(url);
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            return false;
        }
    }
}
export default ExternalLink;
