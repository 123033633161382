import { InsightsGranularity, InsightsTimeRange } from "@octopusdeploy/octopus-server-client";
export const insightsCadenceOptions = [
    {
        value: "lastYearMonthly",
        text: "Last year, monthly",
    },
    {
        value: "lastYearWeekly",
        text: "Last year, weekly",
    },
    {
        value: "lastQuarterWeekly",
        text: "Last quarter, weekly",
    },
    {
        value: "lastQuarterDaily",
        text: "Last quarter, daily",
    },
    {
        value: "lastMonthDaily",
        text: "Last month, daily",
    },
] as const;
export type InsightsCadence = (typeof insightsCadenceOptions)[number]["value"];
type InsightsCadenceDefinition = {
    timeRange: InsightsTimeRange;
    granularity: InsightsGranularity;
};
export const insightsCadenceLookup: Record<InsightsCadence, InsightsCadenceDefinition> = {
    lastYearMonthly: {
        timeRange: InsightsTimeRange.LastYear,
        granularity: InsightsGranularity.Monthly,
    },
    lastYearWeekly: {
        timeRange: InsightsTimeRange.LastYear,
        granularity: InsightsGranularity.Weekly,
    },
    lastQuarterWeekly: {
        timeRange: InsightsTimeRange.LastQuarter,
        granularity: InsightsGranularity.Weekly,
    },
    lastQuarterDaily: {
        timeRange: InsightsTimeRange.LastQuarter,
        granularity: InsightsGranularity.Daily,
    },
    lastMonthDaily: {
        timeRange: InsightsTimeRange.LastMonth,
        granularity: InsightsGranularity.Daily,
    },
} as const;
export const insightsCadenceXAxisTickInterval: Record<InsightsCadence, number> = {
    lastYearMonthly: 0,
    lastYearWeekly: 6,
    lastQuarterWeekly: 1,
    lastQuarterDaily: 12,
    lastMonthDaily: 3,
};
