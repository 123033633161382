import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbookTriggersOverview, CommonProjectRunbookTriggersTopics } from "../../CommonComponents/CommonProjectRunbookTriggersView";
export const ProjectRunbooksTriggersRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.triggers,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTriggersOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTriggersTopics />;
    },
};
