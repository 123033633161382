import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import type { OctopusTheme } from "~/components/Theme";
import styles from "./style.module.less";
interface OverviewCardTitleProps {
    link: string | undefined;
    theme: OctopusTheme;
    count: number | null;
    title: string;
}
export const OverviewCardTitle = ({ link, theme, count, title }: OverviewCardTitleProps) => {
    const titleContent = (<>
            {title} {count !== null && <span className={styles.cardCount}>({count.toLocaleString()})</span>}
        </>);
    return link ? (<InternalLink to={link} color={theme.whiteConstant}>
            {titleContent}
        </InternalLink>) : (titleContent);
};
