import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, LifecycleResource } from "@octopusdeploy/octopus-server-client";
import { useEffect, useState } from "react";
import * as React from "react";
import LifecycleMap from "~/areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import { repository } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import ActionButton from "~/components/Button/ActionButton";
import { ActionButtonType } from "~/components/Button/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import Section from "~/components/Section";
import type { ResourcesById } from "~/components/StyleGuide/Data/environments";
import Note from "~/primitiveComponents/form/Note/Note";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
interface SelectLifecycleProps {
    lifecycles: LifecycleResource[];
    selectedLifecycleId: string;
    environmentsById: ResourcesById<EnvironmentResource>;
    handleLifecycleChange: (value: string | undefined) => void;
    showNote?: boolean;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
}
const SelectLifecycle = ({ lifecycles, selectedLifecycleId, environmentsById, handleLifecycleChange, showNote, doBusyTask, busy }: SelectLifecycleProps) => {
    const [selectedLifecyclePreview, setSelectedLifecyclePreview] = useState<LifecycleResource>();
    const [showLifecycleMap, setShowLifecycleMap] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            const selectedLifecycle = lifecycles.find((l) => l.Id === selectedLifecycleId);
            if (!selectedLifecycle) {
                throw new Error(`Could not find lifecycle with id ${selectedLifecycleId}.`);
            }
            await doBusyTask(async () => {
                const selectedLifecyclePreview = await repository.Lifecycles.preview(selectedLifecycle);
                setSelectedLifecyclePreview(selectedLifecyclePreview);
            });
        })();
    }, [lifecycles, selectedLifecycleId, doBusyTask]);
    return (<React.Fragment>
            <Select value={selectedLifecycleId} onChange={handleLifecycleChange} items={lifecycles.map((l) => ({ value: l.Id, text: l.Name }))} autoFocus label="Lifecycle"/>
            {showNote && (<Note>
                    Lifecycles determine which environments the project can be deployed to, and the promotion rules between those environments.{" "}
                    <InternalLink to={routeLinks.library.lifecycles} openInSelf={false}>
                        Create or modify lifecycles
                    </InternalLink>
                </Note>)}
            {Object.keys(environmentsById).length > 0 && <ActionButton type={ActionButtonType.Ternary} label={showLifecycleMap ? "Hide lifecycle" : "Show lifecycle"} onClick={() => setShowLifecycleMap(!showLifecycleMap)}/>}
            {showLifecycleMap && (<BusyFromPromise promise={busy}>
                    {(busy: boolean) => (<Section>
                            {busy && <CircularProgress size="large"/>}
                            {showLifecycleMap && selectedLifecyclePreview !== undefined && !busy && <LifecycleMap environmentsById={environmentsById ?? []} lifecyclePreview={selectedLifecyclePreview} showDescription={true}/>}
                        </Section>)}
                </BusyFromPromise>)}
        </React.Fragment>);
};
export default SelectLifecycle;
