import * as React from "react";
import styles from "../../../components/form/Sections/style.module.less";
interface SectionHeadingProps {
    title: string | JSX.Element;
}
class SectionHeading extends React.Component<SectionHeadingProps, {}> {
    render() {
        return (<div className={styles.sectionHeading}>
                <div className={styles.sectionHeadingTitle}>{this.props.title}</div>
            </div>);
    }
    static displayName = "SectionHeading";
}
export default SectionHeading;
