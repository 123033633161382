/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import Task from "~/areas/tasks/components/Task/Task";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import DataBaseComponent from "~/components/DataBaseComponent";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import ImportExportMenu from "./ImportExportMenu";
import styles from "./style.module.less";
class ExportTaskDetailsInternal extends DataBaseComponent<RouteComponentProps<{
    taskId: string;
}>, {
    space?: SpaceResource;
}> {
    taskId: string;
    constructor(props: RouteComponentProps<{
        taskId: string;
    }>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId!)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }
    render() {
        return (<main id="maincontent">
                <ContextualHelpLayout>
                    <AreaTitle title="Projects" link={routeLinks.projects.root}>
                        <ActionList actions={[<ImportExportMenu />]}/>
                    </AreaTitle>
                    {this.state.space !== undefined && !this.isSpaceManager() && (<Callout title="Permissions" type={CalloutType.Danger}>
                            Only Space Managers can perform an export or import.
                        </Callout>)}
                    {this.state.space !== undefined && this.isSpaceManager() && (<div className={styles.container}>
                            <Task taskId={this.taskId} delayRender={() => false}/>
                        </div>)}
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "ExportTaskDetailsInternal";
}
const ExportTaskDetails: React.FC = () => {
    const match = useRouteMatch<{
        taskId: string;
    }>();
    return <ExportTaskDetailsInternal location={useLocation()} history={useHistory()} match={match!}/>;
};
ExportTaskDetails.displayName = "ExportTaskDetails"
export { ExportTaskDetails };
