import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentDurationOverview, CommonInsightsDeploymentDurationTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentDurationViews";
import pageIds from "~/pageIds";
export const ProjectInsightDeploymentDurationRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.deploymentDuration,
    renderOverview: (props) => (<>
            <CommonInsightsDeploymentDurationOverview /> <CommonProjectInsightsHelp />
        </>),
    renderTopics: (props) => <CommonInsightsDeploymentDurationTopics />,
};
