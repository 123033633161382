/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import styles from "./styles.module.less";
interface ActionTemplateCardListProps {
    children: any;
}
const ActionTemplateCardList: React.StatelessComponent<ActionTemplateCardListProps> = (props: ActionTemplateCardListProps) => {
    return <ol className={styles.templates}>{props.children}</ol>;
};
ActionTemplateCardList.displayName = "ActionTemplateCardList"
export default ActionTemplateCardList;
