import * as React from "react";
import { Switch, withRouter } from "react-router-dom";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import pageIds from "../../../../pageIds";
import type { RunbookRoutingProps } from "./RunbookRoutingProps";
import RunbooksLayout from "./RunbooksLayout";
export const OperationsRunbooksPage = withPage({ page: pageIds.project().operations.runbooks })(RunbooksLayout);
class RunbooksRoute extends React.Component<RunbookRoutingProps> {
    render() {
        return (<Switch>
                <ReloadableRoute path={this.props.path} exact={true} render={() => <OperationsRunbooksPage />}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "RunbooksRoute";
}
const EnhancedRunbooksRoute = withRouter(RunbooksRoute);
export { EnhancedRunbooksRoute };
