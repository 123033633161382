/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DashboardFilter, DashboardResource, DeploymentTemplateResource, ProjectResource, DashboardRenderMode, ChannelResource, LifecycleResource, OverviewReleaseBff } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { Errors } from "../../../../components/DataBaseComponent";
import DashboardDataCube from "./DashboardDataCube";
import type { DashboardFilters, DataCube } from "./DataCube";
import { DimensionTypes } from "./DataCube";
import ProgressionDataCube from "./ProgressionDataCube";
export type RenderDashboardProps = DashboardDataSourceState & {
    errors: Errors | undefined;
};
interface DashboardDataSourceProps {
    project?: ProjectResource;
    filters: DashboardFilters;
    dashboardRenderMode?: DashboardRenderMode; //TODO - this prop was added so that it forces a rerender of the menu item, probably find another way to fix this
    render(props: RenderDashboardProps): JSX.Element;
}
type DashboardDataSourceConnectedProps = DashboardDataSourceProps;
type DashboardDataSourceState = DataBaseComponentState & {
    projectLimit?: number;
    cube?: DataCube;
    hasInitialLoaded: boolean;
};
const refreshIntervalInMs = 6000;
class DashboardDataSource extends DataBaseComponent<DashboardDataSourceConnectedProps, DashboardDataSourceState> {
    private refreshFunction: () => Promise<DataCube> = undefined!;
    private project: ProjectResource | undefined;
    private filters: DashboardFilters = undefined!;
    private refresh: Refresh = undefined!;
    constructor(props: DashboardDataSourceConnectedProps) {
        super(props);
        this.state = {
            hasInitialLoaded: false,
        };
    }
    async componentDidMount() {
        this.project = this.props.project;
        this.filters = this.props.filters;
        await this.doBusyTask(async () => {
            this.setupDashboard();
            this.refresh = await this.startRefreshLoop(this.refreshCube, refreshIntervalInMs, true, timeOperationOptions.forRefresh());
            this.setState({ hasInitialLoaded: true });
        });
    }
    async UNSAFE_componentWillReceiveProps(nextProps: DashboardDataSourceProps) {
        let shouldRefresh = false;
        if (nextProps.filters !== this.props.filters) {
            shouldRefresh = true;
        }
        if (nextProps.project !== this.props.project) {
            shouldRefresh = true;
        }
        if (!shouldRefresh) {
            return;
        }
        this.project = nextProps.project;
        this.filters = nextProps.filters;
        await this.doBusyTask(async () => {
            this.setupDashboard();
            if (this.refresh) {
                await this.refresh();
            }
        });
    }
    render() {
        return this.props.render({ ...this.state, errors: this.errors });
    }
    private loadDashboardData(releaseId?: string): Promise<DashboardResource> {
        const args: DashboardFilter = this.project ? { projectId: this.project.Id, showAll: true } : { highestLatestVersionPerProjectAndEnvironment: true };
        if (releaseId) {
            args.releaseId = releaseId;
        }
        return repository.Dashboards.getDashboard(args);
    }
    private loadProgressionData() {
        return repository.Progression.getProgression(this.project!);
    }
    private loadChannels() {
        return repository.Channels.allFromProject(this.project!);
    }
    private async loadReleases(releaseId?: string): Promise<OverviewReleaseBff[]> {
        if (releaseId) {
            return Promise.resolve([await repository.Releases.get(releaseId)]);
        }
        //TODO: @team-fire-and-motion - Remove this BFF once we update the release data model and no longer require this optimization
        if (session.featureToggles?.includes("OverviewReleaseBffFeatureToggle")) {
            return await repository.Projects.getBffOverviewReleases(this.project!);
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const result = (await repository.Projects.getReleases(this.project!)).Items as unknown as Promise<OverviewReleaseBff[]>;
        return result;
    }
    private filteredRelease() {
        if (this.filters[DimensionTypes.Release]) {
            return Object.keys(this.filters[DimensionTypes.Release])[0];
        }
        return null;
    }
    private async loadPromotionData(release: OverviewReleaseBff): Promise<DeploymentTemplateResource> {
        return repository.Releases.getDeploymentTemplate(release);
    }
    private async loadLifecycles(channelsAsync: Promise<ChannelResource[]>): Promise<LifecycleResource[]> {
        const channels = await channelsAsync;
        const lifecycleIds = channels.map((channel) => channel.LifecycleId);
        const lifecycles = await repository.Lifecycles.allById(lifecycleIds);
        return Object.keys(lifecycles).map((id) => lifecycles[id]);
    }
    private async listTenantsWithMissingVariables(): Promise<string[]> {
        const hasLibraryVariableSetView = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" });
        if (hasLibraryVariableSetView) {
            const missingVariables = await repository.Tenants.missingVariables({ projectId: this.project!.Id }, false);
            return missingVariables.map((mv) => mv.TenantId);
        }
        return Promise.resolve([]);
    }
    private async mainDashboardRefresh(): Promise<DataCube> {
        const data = await this.loadDashboardData();
        this.setState({ projectLimit: data.ProjectLimit || 200 });
        return new DashboardDataCube(data, data.Tenants, [], [], null!, [], [], Promise.resolve([]));
    }
    private async tenantedProjectDashboardRefresh(): Promise<DataCube> {
        const releaseId = this.filteredRelease();
        const releases = await this.loadReleases(releaseId!);
        const channelsLoader = this.loadChannels();
        const [data, channels, promotions, tagSets, lifecycles] = await Promise.all([
            this.loadDashboardData(releaseId!),
            channelsLoader,
            releaseId ? this.loadPromotionData(releases[0]) : Promise.resolve(null),
            tenantTagsets.getAll(),
            this.loadLifecycles(channelsLoader),
        ]);
        const missingVariableTenantsPromise = this.listTenantsWithMissingVariables();
        return new DashboardDataCube(data!, data!.Tenants, releases, channels!, promotions!, tagSets!, lifecycles!, missingVariableTenantsPromise, this.project, releaseId!);
    }
    private async projectDashboardRefresh(): Promise<DataCube> {
        const data = await this.loadProgressionData();
        const progressionData = new ProgressionDataCube(data, this.project!);
        const allChannels = await this.loadChannels();
        progressionData.addAdditionalChannels(allChannels);
        return progressionData;
    }
    private setupDashboard() {
        if (!this.project) {
            this.refreshFunction = this.mainDashboardRefresh;
        }
        else if (this.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) {
            this.refreshFunction = this.tenantedProjectDashboardRefresh;
        }
        else {
            this.refreshFunction = this.projectDashboardRefresh;
        }
    }
    private refreshCube = async () => {
        return { cube: await this.refreshFunction() };
    };
    static displayName = "DashboardDataSource";
}
export default DashboardDataSource;
export { DashboardDataSourceState };
export function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length === 0 : false;
}
export function dashboardDataHasProjects(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length > 0 : false;
}
export function dashboardDataHasEnvironments(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.environmentIndex).length > 0 : false;
}
