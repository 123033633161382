enum ActionKeys {
    // noinspection SpellCheckingInspection
    FORM_PAPER_LAYOUT_MOUNTED = "FORM_PAPER_LAYOUT_MOUNTED",
    FORM_PAPER_LAYOUT_DIRTYCHANGED = "FORM_PAPER_LAYOUT_DIRTYCHANGED",
    OTHER_ACTION = "__any_other_action_type__"
}
interface IOnSaveClickAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED;
    saveLabel?: string;
    onSaveClick?: () => Promise<void>;
}
interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}
interface IDirtyChangedAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED;
    dirty: boolean;
}
type ActionTypes = IOnSaveClickAction | IOtherAction | IDirtyChangedAction;
export function createFormPaperLayoutMountedAction(onSaveClick: (() => Promise<void>) | undefined, saveLabel: string | undefined): IOnSaveClickAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED, onSaveClick, saveLabel };
}
export function createFormPaperLayoutDirtyChangedAction(dirty: boolean): IDirtyChangedAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED, dirty };
}
export interface FormPaperLayoutReducerState {
    onSaveClick?: () => Promise<void>;
    saveLabel?: string;
    dirty: boolean;
}
const formPaperLayoutReducer = (state: FormPaperLayoutReducerState = { onSaveClick: undefined, saveLabel: undefined, dirty: false }, action: ActionTypes): FormPaperLayoutReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_PAPER_LAYOUT_MOUNTED:
            return { ...state, onSaveClick: action.onSaveClick, saveLabel: action.saveLabel };
        case ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED:
            return state.dirty === action.dirty ? state : { ...state, dirty: action.dirty };
        default:
            return state;
    }
};
export default formPaperLayoutReducer;
