import type { ProjectResource, ProjectDeploymentFrequencyDeployment } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import { TrendTable } from "~/areas/insights/components/Reports/TrendTables/TrendTable/TrendTable";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
interface Props {
    title: string;
    note: ReactNode;
    data: ProjectDeploymentFrequencyDeployment[];
    hideTimeSince?: boolean;
    project: ProjectResource;
}
export function ProjectDeploymentFrequencyTrendTable({ title, note, data, hideTimeSince, project }: Props) {
    const isTenanted = project.TenantedDeploymentMode != TenantedDeploymentMode.Untenanted;
    return (<TrendTable title={title} isEmpty={data.length === 0} note={note} headerContent={<DataTableRow>
                    {isTenanted && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    {!hideTimeSince && <DataTableHeaderColumn>Time since previous successful deployment</DataTableHeaderColumn>}
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            {isTenanted && <DataTableRowColumn>{r.TenantName}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(project.Slug).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <ToolTip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(project.Slug).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </ToolTip>
                            </DataTableRowColumn>
                            {!hideTimeSince && <DataTableRowColumn>{formatDurationInWords(r.TimeSincePreviouslySuccessfulDeployment)}</DataTableRowColumn>}
                        </DataTableRow>))}
                </>}/>);
}
