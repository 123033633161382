import type { TerraformProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import Note from "../../../primitiveComponents/form/Note/Note";
import RadioButtonGroup from "../../../primitiveComponents/form/RadioButton/RadioButtonGroup";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import ExternalLink from "../../Navigation/ExternalLink";
import { RadioButton, ExpandableFormSection, Summary } from "../../form";
import { default as FormSectionHeading } from "../../form/Sections/FormSectionHeading";
import type { ActionEditProps } from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
export class TerraformJSONPlan extends BaseComponent<ActionEditProps<TerraformProperties, ScriptPackageProperties>> {
    render() {
        return (<div>
                <FormSectionHeading title="Plan Output Format"/>
                <ExpandableFormSection errorKey="Octopus.Action.Terraform.PlanJsonOutput" isExpandedByDefault={this.props.expandedByDefault} title="Output Format" help="Specify the output for the plan operation" summary={this.planOutputSummary()}>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Terraform.PlanJsonOutput"]} onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Terraform.PlanJsonOutput"]: val })} error={this.props.getFieldError("Octopus.Action.Terraform.PlanJsonOutput")}>
                        <RadioButton value={"False"} label="Plain text output"/>
                        <RadioButton value={"True"} label="JSON output"/>
                    </RadioButtonGroup>
                    <Note>
                        Plain text output captures the written description of the changes, which are typically presented in a manual intervention step or external notification as is. JSON output captures the changes as JSON blobs, which can be
                        inspected and parsed to generate custom reports. See <ExternalLink href={"OctostacheJsonParsing"}>the documentation</ExternalLink> for more details.
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    planOutputSummary() {
        const source = this.props.properties["Octopus.Action.Terraform.PlanJsonOutput"];
        if (source === "True") {
            return Summary.summary("Plan generates JSON output");
        }
        return Summary.summary("Plan generates plain text output");
    }
    static displayName = "TerraformJSONPlan";
}
