/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { TomcatDeployProperties } from "@octopusdeploy/legacy-action-properties";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "~/components/form";
import { BoundSensitive } from "~/components/form/Sensitive/Sensitive";
import { required } from "~/components/form/Validators";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import ExternalLink from "../../Navigation/ExternalLink";
export default class TomcatFeatureTemplate extends BaseComponent<ActionEditProps<TomcatDeployProperties>, never> {
    deploymentSummary() {
        const properties = this.props.properties;
        if (properties["Tomcat.Deploy.Name"]) {
            return Summary.summary(<span>
                    Deploying the package to the <strong>{this.props.properties["Tomcat.Deploy.Name"]}</strong> context
                </span>);
        }
        return Summary.placeholder(<span>Specify the Tomcat deployment context</span>);
    }
    advancedSummary() {
        const properties = this.props.properties;
        return Summary.summary(<span>
                Deploying the application in a {this.props.properties["Tomcat.Deploy.Enabled"] === "True" ? "started" : "stopped"} state
                {properties["Tomcat.Deploy.Version"] && (<span>
                        &nbsp;with the version <strong>{properties["Tomcat.Deploy.Version"]}</strong>
                    </span>)}
            </span>);
    }
    tomcatSummary() {
        const properties = this.props.properties;
        if (properties["Tomcat.Deploy.Controller"]) {
            return Summary.summary(<span>
                    <span>
                        Deploying to Tomcat Manager hosted at <strong>{properties["Tomcat.Deploy.Controller"]}</strong>
                    </span>
                    {properties["Tomcat.Deploy.User"] && (<span>
                            &nbsp;with the user <strong>{properties["Tomcat.Deploy.User"]}</strong>
                        </span>)}
                </span>);
        }
        return Summary.placeholder(<span>Specify the Tomcat manager URL, credentials and the state of the application</span>);
    }
    componentDidMount() {
        const properties: ActionProperties = {};
        if (!this.props.properties["Tomcat.Deploy.Controller"]) {
            properties["Tomcat.Deploy.Controller"] = "http://localhost:8080/manager";
        }
        if (!this.props.properties["Tomcat.Deploy.Enabled"]) {
            properties["Tomcat.Deploy.Enabled"] = "True";
        }
        this.props.setProperties(properties, true);
    }
    render() {
        const properties = this.props.properties;
        return (<div>
                <ExpandableFormSection errorKey="Tomcat.Deploy.Controller|Tomcat.Deploy.User|Tomcat.Deploy.Password" isExpandedByDefault={this.props.expandedByDefault} title="Tomcat Details" summary={this.tomcatSummary()} help="Specify the Tomcat manager URL and credentials.">
                    <VariableLookupText localNames={this.props.localNames} value={properties["Tomcat.Deploy.Controller"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.Controller"]: x })} label="Tomcat Manager URL" validate={required("Please enter a Tomcat Manager URL")} error={this.props.getFieldError("Tomcat.Deploy.Controller")}/>
                    <Note>
                        This is the URL of the Tomcat Manager instance that the package will be uploaded to. This URL is relative to the host that is executing the step. So if the Tentacle is on the same host as Tomcat, then this value may be
                        something like <em>http://localhost:8080/manager</em>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Tomcat.Deploy.User"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.User"]: x })} label="Management user" validate={required("Please enter a Tomcat Manager user")} error={this.props.getFieldError("Tomcat.Deploy.User")}/>
                    <Note>
                        The username to use with the management interface. This user must be assigned to the <em>manager-script</em> group in the <em>tomcat-users.xml</em> file.
                    </Note>
                    <BoundSensitive variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} value={properties["Tomcat.Deploy.Password"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.Password"]: x })} label="Management password" error={this.props.getFieldError("Tomcat.Deploy.Password")}/>
                    <Note>The password to use with the management interface.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Tomcat.Deploy.Name" isExpandedByDefault={this.props.expandedByDefault} title="Tomcat Deployment" summary={this.deploymentSummary()} help="Specify the Tomcat deployment context.">
                    <VariableLookupText localNames={this.props.localNames} value={properties["Tomcat.Deploy.Name"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.Name"]: x })} label="Context path" error={this.props.getFieldError("Tomcat.Deploy.Name")}/>
                    <Note>
                        This field defines the context path of the deployed artifact. For example, setting this field to <code>myapp</code> will result in the deployment being having the context path <code>/myapp</code> in Tomcat. Set the value to{" "}
                        <code>/</code> to deploy to the root context.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Tomcat.Deploy.Version|Tomcat.Deploy.Enabled" isExpandedByDefault={this.props.expandedByDefault} title="Advanced Options" summary={this.advancedSummary()} help="Specify the Tomcat deployment version and the state of the application.">
                    <VariableLookupText localNames={this.props.localNames} value={properties["Tomcat.Deploy.Version"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.Version"]: x })} label="Deployment version"/>
                    <Note>
                        Tomcat versions are used with <ExternalLink href="TomcatParallelDeployments">parallel deployments</ExternalLink>.
                        <p>Leave this field blank to to leave the Tomcat version undefined, in which case you will overwrite any existing deployment with the same context path, and parallel deployments will not be enabled.</p>
                        <p>
                            Alternatively you can define a custom Tomcat version. These versions are compared as plain strings, meaning traditional version strings like <code>1.0.1</code> may not be suitable as this format is not guaranteed to
                            correctly identify the latest version using string comparisons. A date string like <code>{"#{ | NowDateUtc \"yyMMddHHmmss\"}"}</code> is a good option, as this format produces the correct result when compared as strings.
                        </p>
                    </Note>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"True"} value={properties["Tomcat.Deploy.Enabled"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Deploy.Enabled"]: x as string })}>
                        <RadioButton value="True" label="Leave the application running" isDefault={true}/>
                        <RadioButton value="False" label="Stop the application"/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "TomcatFeatureTemplate";
}
