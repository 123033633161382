import type { RunbookRetentionPeriod } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import React from "react";
import ComponentRow from "~/components/ComponentRow";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Text, Summary, ExpandableFormSection, BooleanRadioButtonGroup, minimum, Note, RadioButton } from "~/components/form";
import ParseHelper from "~/utils/ParseHelper";
interface RunbookRetentionPolicyControlProps {
    retentionPolicy: RunbookRetentionPeriod;
    onRetentionPolicyChange(policy: RunbookRetentionPeriod): void;
}
export class RunbookRetentionPolicyControl extends React.Component<RunbookRetentionPolicyControlProps> {
    retentionPolicySummary() {
        let result = "";
        if (this.props.retentionPolicy.ShouldKeepForever) {
            result = "Keep all";
        }
        else {
            result = this.props.retentionPolicy.QuantityToKeep === 1 ? "Keep 1 run per environment" : "Keep " + this.props.retentionPolicy.QuantityToKeep + " runs per environment";
        }
        return Summary.summary(result);
    }
    updateRetentionPolicy(updater: (policy: RunbookRetentionPeriod) => void) {
        const policy = cloneDeep(this.props.retentionPolicy);
        updater(policy);
        this.props.onRetentionPolicyChange(policy);
    }
    render() {
        return (<ExpandableFormSection errorKey="retentionPolicy" title="Retention Policy" summary={this.retentionPolicySummary()} help="Change the retention policy.">
                <BooleanRadioButtonGroup value={this.props.retentionPolicy.ShouldKeepForever} onChange={(val) => this.updateRetentionPolicy((pol) => {
                pol.ShouldKeepForever = val;
                pol.QuantityToKeep = val ? 0 : 100;
            })} label="How many runs should we keep per environment?">
                    <RadioButton value={true} label="Keep all"/>
                    <RadioButton value={false} label="Keep a limited number" isDefault={true}/>
                </BooleanRadioButtonGroup>
                {!this.props.retentionPolicy.ShouldKeepForever && (<ComponentRow>
                        <span>
                            <Text type="number" min={1} value={this.props.retentionPolicy.QuantityToKeep ? this.props.retentionPolicy.QuantityToKeep.toString() : ""} onChange={(x) => this.updateRetentionPolicy((pol) => (pol.QuantityToKeep = ParseHelper.safeParseInt(x)))} validate={minimum("Please provide a value greater than zero.", 1)}/>
                        </span>
                    </ComponentRow>)}
                <br />
                <Note>
                    Retention policies dictate how long runs are kept for. For more information please see <ExternalLink href="RetentionPolicies">retention policies</ExternalLink> documentation.
                </Note>
            </ExpandableFormSection>);
    }
    static displayName = "RunbookRetentionPolicyControl";
}
