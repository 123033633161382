import { Permission } from "@octopusdeploy/octopus-server-client";
import type { EnvironmentsSummaryResource, EnvironmentResource, TenantResource, SummaryResource } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import type { RouteComponentProps } from "react-router";
import type { Dispatch, Action } from "redux";
import URI from "urijs";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action as AnalyticAction, useAnalyticActionDispatch } from "~/analytics/Analytics";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { FeatureToggle, Feature } from "~/components/FeatureToggle";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import DeploymentTargetTypeMultiSelect from "~/components/MultiSelect/DeploymentTargetTypeMultiselect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import { NoResults } from "~/components/NoResults/NoResults";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import routeLinks from "~/routeLinks";
import { machineActions } from "../../reducers/machines";
import { defaultEnvironmentSummaryFilter, environmentSummaryFilterToQuery, environmentSummaryQueryToFilter, createEnvironmentSummaryArgs } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import type { EnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import type { EnvironmentSummaryQuery } from "../EnvironmentsLayout/EnvironmentSummaryQuery";
import OnboardingDeploymentTargets from "../EnvironmentsLayout/OnboardingDeploymentTargets";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import { BaseMachinesLayout } from "./BaseMachinesLayout";
import type { MachinesLayoutProps, MachinesLayoutState } from "./BaseMachinesLayout";
import DeploymentTargetsSummarySection from "./DeploymentTargetsSummarySection";
const { machineCleared } = machineActions;
interface DeploymentTargetsLayoutProps extends MachinesLayoutProps<EnvironmentSummaryFilter, EnvironmentSummaryQuery>, RouteComponentProps {
    filterToQuery: (filter: EnvironmentSummaryFilter) => EnvironmentSummaryQuery;
    initialData: InitialData;
    defaultFilter: EnvironmentSummaryFilter;
    onClearMachine(): void;
    dispatchAction: AnalyticActionDispatcher;
    deploymentTargetRegistrations: EndpointRegistration[];
}
interface DeploymentTargetsLayoutState extends MachinesLayoutState<EnvironmentSummaryFilter> {
    environmentsSummary: EnvironmentsSummaryResource;
    filter: EnvironmentSummaryFilter;
}
interface InitialData {
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    hasMachines: boolean;
    machineShellNames: string[];
    environmentsSummary: EnvironmentsSummaryResource;
    deploymentTargetRegistrations: EndpointRegistration[];
}
const Title = "Deployment Targets";
const DeploymentTargetsLayoutFormPage = FormPage<InitialData>();
const DeploymentTargetsLayout: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const query = URI(props.location.search).search(true) as EnvironmentSummaryQuery;
    const filter = environmentSummaryQueryToFilter(query);
    const dispatchAction = useAnalyticActionDispatch();
    return (<DeploymentTargetsLayoutFormPage title={Title} load={async () => {
            const defaultArgs = createEnvironmentSummaryArgs(filter);
            const environmentsSummary = repository.Environments.summary(defaultArgs);
            const hasMachines = repository.Machines.list({ take: 0 }).then((result) => result.TotalResults > 0);
            const environments = repository.Environments.all();
            const machineRoles = repository.MachineRoles.all();
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const machineShellNames = repository.MachineShells.all();
            const tagIndex = tenantTagsets.getTagIndex();
            const targetRegistrations = endpointRegistry.getAllRegistrations();
            return {
                environments: await environments,
                machineRoles: await machineRoles,
                tenants: await tenants,
                tagIndex: await tagIndex,
                machineShellNames: await machineShellNames,
                deploymentTargetRegistrations: await targetRegistrations,
                hasMachines: await hasMachines,
                environmentsSummary: await environmentsSummary,
            };
        }} renderWhenLoaded={(data) => (<DeploymentTargetsLayoutConnected initialFilter={filter} defaultFilter={defaultEnvironmentSummaryFilter} filterToQuery={environmentSummaryFilterToQuery} initialData={data} hasMachines={data.hasMachines} dispatchAction={dispatchAction} deploymentTargetRegistrations={data.deploymentTargetRegistrations} {...props}/>)} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
DeploymentTargetsLayout.displayName = "DeploymentTargetsLayout"
class DeploymentTargetsLayoutInternal extends BaseMachinesLayout<DeploymentTargetsLayoutProps, DeploymentTargetsLayoutState, EnvironmentSummaryFilter, EnvironmentSummaryQuery> {
    constructor(props: DeploymentTargetsLayoutProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            filter: props.initialFilter,
            queryFilter: props.initialFilter,
            environmentsSummary: this.props.initialData.environmentsSummary,
        };
    }
    async componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }
    protected getNameFilter(searchHintText: string): JSX.Element[] {
        return [
            <FilterSearchBox placeholder={searchHintText} value={this.state.filter.machinePartialName} onChange={(x) => {
                    this.setFilterState({ machinePartialName: x }, this.onFilterChange);
                }} autoFocus={true}/>,
        ];
    }
    protected getSummaries(): SummaryResource {
        return this.props.initialData.environments && this.state.environmentsSummary;
    }
    protected isFiltering() {
        return !isEqual(this.state.filter, this.props.defaultFilter);
    }
    protected extraFilters(): React.ReactNode[] {
        return [
            <EnvironmentMultiSelect accessibleName="environmentFilter" key="filterEnvironment" items={this.props.initialData.environments} value={this.state.filter.environmentIds} onChange={(x) => {
                    this.setFilterState({ environmentIds: x }, this.onFilterChange);
                }}/>,
            <RoleMultiSelect accessibleName="roleFilter" key="filterRole" items={this.props.initialData.machineRoles ? this.props.initialData.machineRoles : []} value={this.state.filter.roles} onChange={(x) => {
                    this.setFilterState({ roles: x }, this.onFilterChange);
                }}/>,
            <FeatureToggle feature={Feature.MultiTenancy} key="filterMultiTenancy">
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    <TenantMultiSelect accessibleName="tenantFilter" value={this.state.filter.tenantIds} items={this.props.initialData.tenants} onChange={(x) => {
                    this.setFilterState({ tenantIds: x }, this.onFilterChange);
                }}/>
                    <TenantTagMultiSelect value={this.state.filter.tenantTags} doBusyTask={this.doBusyTask} onChange={(x) => {
                    this.setFilterState({ tenantTags: x }, this.onFilterChange);
                }}/>
                </PermissionCheck>
            </FeatureToggle>,
            <ShellNameMultiSelect accessibleName="shellNameFilter" key="filterShellName" items={this.props.initialData.machineShellNames ?? []} value={this.state.filter.shellNames} onChange={(x) => {
                    this.setFilterState({ shellNames: x }, this.onFilterChange);
                }}/>,
            <DeploymentTargetTypeMultiSelect accessibleName="deploymentTargetTypeFilter" key="filterDeploymentTargetType" items={this.props.initialData.deploymentTargetRegistrations ?? []} value={this.state.filter.deploymentTargetTypes} onChange={(x) => {
                    this.setFilterState({ deploymentTargetTypes: x }, this.onFilterChange);
                }}/>,
        ];
    }
    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*" key="perms">
                <NavigationButton href={routeLinks.infrastructure.machines.new()} label="Add deployment target" type={NavigationButtonType.Primary} onClick={() => this.props.dispatchAction("Add Deployment Target", { resource: "Deployment Target", action: AnalyticAction.Add })}/>
            </PermissionCheck>,
        ];
    }
    protected renderOnboarding(): JSX.Element {
        return <OnboardingDeploymentTargets />;
    }
    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const environmentsSummaries = this.props.initialData.environments && this.state.environmentsSummary;
        if (environmentsSummaries) {
            machinesExpander = (<DeploymentTargetsSummarySection key="allMachines" environmentsSummary={environmentsSummaries} filter={this.state.filter} tenants={this.props.initialData.tenants} tagIndex={this.props.initialData.tagIndex} environments={this.props.initialData.environments}/>);
        }
        if (this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length === 0) {
            machinesExpander = (<Section>
                    <NoResults />
                </Section>);
        }
        return machinesExpander;
    }
}
const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        title: Title,
        itemDescriptions: "deployment targets",
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};
const DeploymentTargetsLayoutConnected = withRouter(connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(DeploymentTargetsLayoutInternal));
export default DeploymentTargetsLayout;
