import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryLifecycleOverview, CommonLibraryLifecycleTopics } from "../../CommonComponents/CommonLibraryLifecycleViews";
export const LibraryLifecycleRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.lifecycle,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryLifecycleOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryLifecycleTopics />;
    },
};
