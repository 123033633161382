import type { TenantResource, TenantsFilterOptions, TenantsOverviewTenant } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import PermissionCheck from "app/components/PermissionCheck/PermissionCheck";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import URI from "urijs";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import AddTenantDialog from "~/areas/tenants/Tenants/AddTenantDialog";
import Onboarding from "~/areas/tenants/Tenants/Onboarding";
import TenantsDataTable from "~/areas/tenants/components/DataTable/TenantsDataTable";
import TenantsFilter from "~/areas/tenants/components/Filtering/TenantsFilter";
import NumberedPagingBar, { tenantsPagingSelectionKey } from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import { ActionButtonType } from "~/components/Button";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/index";
import PaperLayout from "~/components/PaperLayout/index";
import routeLinks from "~/routeLinks";
import Toolbar from "../components/Toolbar/Toolbar";
import styles from "./tenants-v2.module.less";
type TenantsV2Props = {
    trackAction: AnalyticActionDispatcher;
} & RouteComponentProps;
interface TenantsV2State extends DataBaseComponentState {
    tenants?: TenantsOverviewTenant[];
    filteredTenantCount: number | null;
    totalTenantCount: number;
    redirectTo: string;
    pageSize: number;
    pageNumber: number;
    filter: TenantsFilterOptions;
    initialLoadComplete: Boolean;
}
class TenantsV2Internal extends DataBaseComponent<TenantsV2Props, TenantsV2State> {
    constructor(props: TenantsV2Props) {
        super(props);
        this.state = {
            initialLoadComplete: false,
            filteredTenantCount: null,
            totalTenantCount: 0,
            redirectTo: "",
            pageSize: Number(localStorage.getItem(tenantsPagingSelectionKey) ?? "30"),
            pageNumber: 1,
            filter: {
                filterByName: "",
                filterByTags: [],
                filterByProject: undefined,
                filterByEnvironment: undefined,
            },
        };
    }
    async componentDidMount() {
        await this.initialLoad();
    }
    createFilterFromQueryString(): TenantsFilterOptions {
        const query = new URI().search(this.props.location.search).search(true);
        return {
            ...this.state.filter,
            filterByName: query.search || "",
            filterByTags: query.tags ? (Array.isArray(query.tags) ? query.tags : [query.tags]) : [],
            filterByEnvironment: query.environment || undefined,
            filterByProject: query.project || undefined,
        };
    }
    updateQueryStringFromFilter(filter: TenantsFilterOptions) {
        const query: TenantsFilterQuery = {};
        if (filter.filterByTags.length > 0) {
            query.tags = filter.filterByTags;
        }
        if (filter.filterByName !== "") {
            query.search = filter.filterByName;
        }
        if (filter.filterByProject) {
            query.project = filter.filterByProject;
        }
        if (filter.filterByEnvironment) {
            query.environment = filter.filterByEnvironment;
        }
        const newQuery = new URI().search(query).search();
        if (this.props.location.search !== newQuery) {
            this.props.history.replace({ ...this.props.history, search: newQuery });
        }
    }
    trackCreationAttempt() {
        this.props.trackAction("Open Add New Tenant Dialog", { resource: "Tenant", action: Action.View });
    }
    onTenantCreated(tenant: TenantResource) {
        this.props.trackAction("Success", { resource: "Tenant", action: Action.Add });
        this.setState({ redirectTo: routeLinks.tenant(tenant.Id).root });
    }
    addTenant(label: string) {
        return (<PermissionCheck permission={Permission.TenantCreate}>
                <OpenDialogButton type={ActionButtonType.Primary} label={label} onClick={() => this.trackCreationAttempt()}>
                    <AddTenantDialog title="Add New Tenant" tenantCreated={(t) => this.onTenantCreated(t)}/>
                </OpenDialogButton>
            </PermissionCheck>);
    }
    async initialLoad() {
        await this.doBusyTask(async () => {
            const filterFromQuery = this.createFilterFromQueryString();
            const response = await repository.Tenants.tenantsOverview(this.state.pageNumber, this.state.pageSize, filterFromQuery);
            this.setState({ initialLoadComplete: true, filter: filterFromQuery, tenants: response.Tenants, filteredTenantCount: response.FilteredTenantCount, totalTenantCount: response.TotalTenantCount });
        });
    }
    async onPagingSelectionChange(newPageNumber: number, newPageSize: number) {
        await this.doBusyTask(async () => {
            const response = await repository.Tenants.tenantsOverview(newPageNumber, newPageSize, this.state.filter);
            this.setState({ tenants: response.Tenants, filteredTenantCount: response.FilteredTenantCount, totalTenantCount: response.TotalTenantCount, pageNumber: newPageNumber, pageSize: newPageSize });
        });
    }
    async onFilterChanged(newFilter: TenantsFilterOptions) {
        await this.doBusyTask(async () => {
            const defaultPageAfterFilterChanged = 1;
            const response = await repository.Tenants.tenantsOverview(defaultPageAfterFilterChanged, this.state.pageSize, newFilter);
            this.updateQueryStringFromFilter(newFilter);
            this.setState({ tenants: response.Tenants, filteredTenantCount: response.FilteredTenantCount, totalTenantCount: response.TotalTenantCount, filter: newFilter, pageNumber: defaultPageAfterFilterChanged });
        });
    }
    render() {
        const { tenants, filteredTenantCount, totalTenantCount, redirectTo, initialLoadComplete } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={redirectTo} push={true}/>;
        }
        const paginationTotalCount = filteredTenantCount === null ? totalTenantCount : filteredTenantCount;
        let pageContent = tenants && totalTenantCount > 0 ? (<>
                    <div className={styles.eapContainer}>
                        <span className={styles.eapFeedbackCta}>
                            We're currently working on improving the tenant overview dashboard, and we would love to get your input. Please <ExternalLink href="https://oc.to/NewTenantOverviewFeedback">share your feedback</ExternalLink> and let's team
                            up to make this feature even better.
                        </span>
                    </div>
                    <TenantsFilter filter={this.state.filter} onFilterChanged={this.onFilterChanged.bind(this)} doBusyTask={this.doBusyTask}>
                        <div className={styles.tenantsContainer}>
                            <Toolbar showCount={filteredTenantCount} totalCount={totalTenantCount}/>
                            <TenantsDataTable tenants={tenants} totalItems={totalTenantCount}>
                                <NumberedPagingBar totalItems={paginationTotalCount} pageNumber={this.state.pageNumber} onPagingSelectionChange={(newPageNumber, newPageSize) => this.onPagingSelectionChange(newPageNumber, newPageSize)}/>
                            </TenantsDataTable>
                        </div>
                    </TenantsFilter>
                </>) : (<Onboarding />);
        if (!initialLoadComplete) {
            pageContent = <></>;
        }
        return (<main id="maincontent">
                <AreaTitle link={routeLinks.tenants} title="Tenants">
                    <div className={styles.actionContainer}>{this.addTenant("Add Tenant")}</div>
                </AreaTitle>

                <ContextualHelpLayout>
                    <PaperLayout title={null} fullWidth={true} flatStyle={true} busy={this.state.busy} errors={this.errors}>
                        {pageContent}
                    </PaperLayout>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "TenantsV2Internal";
}
interface TenantsFilterQuery {
    search?: string;
    tags?: string[];
    project?: string;
    environment?: string;
}
export const TenantsV2 = withRouter(TenantsV2Internal);
