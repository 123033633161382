import type { MaintenanceConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { ActionButton, ActionButtonType } from "~/components/Button/ActionButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { Section } from "~/components/Section/Section";
import { Note } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface MaintenanceState extends DataBaseComponentState {
    configuration?: MaintenanceConfigurationResource;
}
export class Maintenance extends DataBaseComponent<{}, MaintenanceState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.MaintenanceConfiguration.get();
            this.setState({ configuration });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    setMaintenanceMode(value: boolean) {
        return this.doBusyTask(async () => {
            const configuration = await repository.MaintenanceConfiguration.get();
            configuration.IsInMaintenanceMode = value;
            await repository.MaintenanceConfiguration.modify(configuration);
            this.setState({ configuration });
        });
    }
    render() {
        const config = this.state.configuration;
        const sectionControl = config && (<ActionButton type={ActionButtonType.Primary} label={config.IsInMaintenanceMode ? "Disable Maintenance Mode" : "Enable Maintenance Mode"} busyLabel={config.IsInMaintenanceMode ? "Disabling..." : "Enabling..."} disabled={this.state.busy} onClick={() => this.setMaintenanceMode(!config.IsInMaintenanceMode)}/>);
        const body = config && (<Section>
                <Note>
                    When maintenance mode is enabled, users with the <strong>Administer System</strong> permission will be able to make changes and initiate deployments, just like normal. All other users will be prevented from making changes or
                    starting new tasks. Learn about <ExternalLink href="MaintenanceMode">maintenance mode</ExternalLink>.
                </Note>
                <p>
                    Maintenance mode is currently <strong>{config.IsInMaintenanceMode ? "Enabled" : "Disabled"}</strong>
                </p>
            </Section>);
        return (<PaperLayout title="Maintenance" busy={this.state.busy} errors={this.errors} sectionControl={sectionControl}>
                {body}
            </PaperLayout>);
    }
    static displayName = "Maintenance";
}
export default Maintenance;
