import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import MarkdownDescription from "~/components/MarkdownDescription";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import styles from "./style.module.less";
interface WorkerPoolListItemProps {
    pool: WorkerPoolResource;
    selected?: boolean;
    showSelection?: boolean;
    onClick(): void;
}
const WorkerPoolSummary: React.StatelessComponent<WorkerPoolListItemProps> = (props) => {
    const pool = props.pool;
    return (<div className={styles.container} onClick={props.onClick}>
            {props.showSelection && (<div className={styles.select}>
                    <RadioButtonGroup value={props.selected} noMargin={true} onChange={noOp}>
                        <RadioButton value={true}/>
                    </RadioButtonGroup>
                </div>)}
            <div className={styles.summary}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        <ListTitle>{pool.Name}</ListTitle>
                    </div>
                </div>
                {pool.Description && (<div className={styles.row}>
                        <MarkdownDescription markup={pool.Description}/>
                    </div>)}
            </div>
        </div>);
};
WorkerPoolSummary.displayName = "WorkerPoolSummary"
export default WorkerPoolSummary;
