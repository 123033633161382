import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { ProjectCurrentBranchDisplay } from "~/areas/projects/components/ProjectCurrentBranchDisplay/ProjectCurrentBranchDisplay";
import { useProjectContext } from "~/areas/projects/context";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import ActionList from "~/components/ActionList";
import { ActionListWithSubItem } from "~/components/ActionListWithSubItem/ActionListWithSubItem";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import { FormTitleForRunbook } from "../../Runbooks/LastPublishedChip";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import PublishButton from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useOptionalRunbookContext, useRunbookContext } from "../../Runbooks/RunbookContext";
import ContextAddStepNavigationButton from "../../Steps/ContextAddStepNavigationButton";
import { GitRepositoryErrorCallout } from "../../Variables/GitRepositoryErrorCallout";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProcessPaperLayout } from "./ProcessPaperLayout";
interface ProcessContextPaperLayoutPropsInternal {
    processType: ProcessType;
    overflowMenuItems: Array<MenuItem | MenuItem[]>;
    doBusyTask: DoBusyTask;
}
type ProcessContextPaperLayoutProps = ProcessContextPaperLayoutPropsInternal & PaperLayoutProps & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = ({ children, doBusyTask, ...rest }) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const hasSteps = processContext.selectors.hasSteps();
    const projectSlug = rest.match.params.projectSlug;
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksProcessContextPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} projectSlug={projectSlug} doBusyTask={doBusyTask} {...rest}>
                {children}
            </RunbooksProcessContextPaperLayout>);
    }
    else {
        return (<DeploymentProcessContextPaperLayout title={"Process"} projectSlug={projectSlug} doBusyTask={doBusyTask} statusSection={<ProjectStatus doBusyTask={doBusyTask}/>} {...rest}>
                {children}
            </DeploymentProcessContextPaperLayout>);
    }
};
ProcessContextPaperLayout.displayName = "ProcessContextPaperLayout"
export const EnhancedProcessContextPaperLayout = withRouter(ProcessContextPaperLayout);
const DeploymentProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & {
    projectSlug: string;
}> = ({ projectSlug, ...props }) => {
    const processContext = useProcessContext();
    const actions = processContext.selectors.hasSteps() ? [<ContextAddStepNavigationButton />] : [];
    const isPageVersionControlled = useIsPageVersionControlled();
    return (<ProcessPaperLayout sectionControl={<ActionListWithSubItem actions={actions} overflowMenuItems={props.overflowMenuItems} subItem={isPageVersionControlled && <ProjectCurrentBranchDisplay />}/>} {...props}>
            {props.children}
        </ProcessPaperLayout>);
};
DeploymentProcessContextPaperLayout.displayName = "DeploymentProcessContextPaperLayout"
const RunbooksProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & {
    projectSlug: string;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ projectSlug, hasSteps, doBusyTask, ...props }) => {
    const runbooksContext = useRunbookContext();
    const title = <FormTitleForRunbook runbookName={runbooksContext.state.runbook?.Name ?? ""}/>;
    const actions = [<PublishButton doBusyTask={doBusyTask}/>, <ContextualRunNowButton isDisabled={!hasSteps}/>, <ContextAddStepNavigationButton />];
    if (props.overflowMenuItems.length > 0) {
        actions.push(<OverflowMenu menuItems={props.overflowMenuItems}/>);
    }
    return (<RunbooksPaperLayout title={title} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(projectSlug).operations.runbooks} sectionControl={<ActionList actions={actions}/>} {...props}>
            <GitRepositoryErrorCallout calloutType={CalloutType.Danger} additionalIntroText="The runbook can not be published until the error is resolved."></GitRepositoryErrorCallout>
            {props.children}
        </RunbooksPaperLayout>);
};
RunbooksProcessContextPaperLayout.displayName = "RunbooksProcessContextPaperLayout"
