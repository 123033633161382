import * as React from "react";
import CannotCommitCallout from "~/areas/projects/components/VersionControl/CannotCommitCallout";
import { useProjectContext } from "~/areas/projects/context/index";
import styles from "./styles.module.less";
export function GitCallouts() {
    const projectContext = useProjectContext();
    return (<div className={styles.callouts}>
            <CannotCommitCallout gitRef={projectContext.state.gitRef}/>
        </div>);
}
