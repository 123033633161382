/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { MachineModelHealthStatus, SummaryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import InternalLink from "~/components/Navigation/InternalLink";
import styles from "../style.module.less";
import { HealthStatusContentIconContainer } from "./HealthStatusContentIconContainer";
import { HealthStatusContentRow } from "./HealthStatusContentRow";
const disabledComponentKey = "Disabled";
interface HealthStatusContentProps {
    summaryResource: SummaryResource;
    link: (obj: object) => string;
}
export const HealthStatusContent = ({ summaryResource, link }: HealthStatusContentProps) => {
    return (<div className={styles.cardContentContainerRows}>
            {Object.keys(summaryResource.MachineHealthStatusSummaries).map((k: string) => {
            const status = k as keyof typeof MachineModelHealthStatus;
            return <HealthStatusContentRow key={k} summaryResource={summaryResource} link={link} status={status}/>;
        })}
            <div className={styles.healthStatusRowsContainer} key={disabledComponentKey}>
                <HealthStatusContentIconContainer>
                    <DisabledMachineIcon iconSize={"medium"}/>
                </HealthStatusContentIconContainer>

                <div className={styles.healthStatusName}>
                    <InternalLink to={link({ isDisabled: "true" })}>Disabled</InternalLink>
                </div>
                <div className={styles.healthStatusMachinesCount}>{summaryResource.TotalDisabledMachines.toLocaleString()}</div>
            </div>
        </div>);
};
