import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbookTopics } from "../../CommonComponents/CommonProjectRunbooksViews";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
export const ProjectRunbooksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runsList,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        The <b>runs</b> section is where you can check on the status and history of the background tasks <b>associated with this runbook</b> that your Octopus Server is managing.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTopics />;
    },
};
