import type { StepConfigurationExportInputs } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { asStepConfigurationExportInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs/src/Schema/InputJsonSchema";
import * as React from "react";
import CodeEditor, { TextFormat } from "~/components/CodeEditor/CodeEditor";
import CopyToClipboard from "~/components/CopyToClipboardButton";
import LargeInfoDialogLayout from "~/components/DialogLayout/LargeInfoDialogLayout";
import { useStepDataEnricher } from "~/components/StepPackageEditor/Inputs/OctopusServerEnricher";
interface ExportConfigurationDialogProps {
    name: string;
    description: string;
    inputs: unknown;
    inputSchema?: InputJsonSchema;
    generateExports: (configuredInputs: StepConfigurationExportInputs<unknown>) => string;
}
export const ExportConfigurationDialog = (props: ExportConfigurationDialogProps) => {
    const enricher = useStepDataEnricher();
    if (props.inputSchema === undefined) {
        return <></>;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const inputs = props.inputs as ObjectRuntimeInputs<unknown>;
    const inputSchema = convertFromJsonSchemaToInputSchema(props.inputSchema, inputs);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const configurationInputs = asStepConfigurationExportInputs(inputSchema.properties, inputs, enricher) as StepConfigurationExportInputs<unknown>;
    const editorText = props.generateExports(configurationInputs);
    return (<LargeInfoDialogLayout title={props.name} additionalActions={[<CopyToClipboard value={editorText}/>]}>
            {props.description}
            <CodeEditor value={editorText} language={TextFormat.YAML} allowFullScreen={false} autoFocus={true} readOnly={true}/>
        </LargeInfoDialogLayout>);
};
