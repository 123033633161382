import * as React from "react";
import BusyRefreshIndicator from "~/components/BusyRefreshIndicator";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import routeLinks from "~/routeLinks";
import MissingVariablesIcon from "../../../tenants/MissingVariablesIcon/MissingVariablesIcon";
import styles from "./style.module.less";
interface TenantsMissingVariablesNotifierProps {
    missingVariableTenantsPromise: Promise<string[]>;
    rowId: string;
}
interface TenantsMissingVariablesNotifierState extends DataBaseComponentState {
    tenantsMissingVariables: string[];
}
class TenantsMissingVariablesNotifier extends DataBaseComponent<TenantsMissingVariablesNotifierProps, TenantsMissingVariablesNotifierState> {
    constructor(props: TenantsMissingVariablesNotifierProps) {
        super(props);
        this.state = {
            tenantsMissingVariables: [],
        };
    }
    componentDidMount = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };
    render = () => {
        const { rowId } = this.props;
        const { tenantsMissingVariables, busy } = this.state;
        const hasMissingVariable = tenantsMissingVariables.indexOf(rowId) !== -1;
        return busy ? (<BusyRefreshIndicator show={true}/>) : (hasMissingVariable && (<div className={styles.variableAlert}>
                    <InternalLink to={routeLinks.tenant(rowId).variables()}>
                        <MissingVariablesIcon show={true}/>
                    </InternalLink>
                </div>));
    };
    reload = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };
    static displayName = "TenantsMissingVariablesNotifier";
}
export default TenantsMissingVariablesNotifier;
