import type { BuiltInFeedResource } from "@octopusdeploy/octopus-server-client";
import { getServerEndpoint } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { client, repository } from "~/clientInstance";
import ActionButton from "~/components/Button";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { CustomInfoDialogLayout, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Section from "~/components/Section";
interface Props {
    label: string;
    asSecondaryButton?: boolean;
}
interface State {
    showDialog: boolean;
    builtInResource?: BuiltInFeedResource;
}
export class HowToPushYourPackageDialogLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
        };
    }
    async componentDidMount() {
        this.setState({
            builtInResource: await repository.Feeds.getBuiltIn(),
        });
    }
    render() {
        const spaceId = repository.spaceId ?? "[Space ID]";
        const nuGetPushLink = this.state.builtInResource?.Links.NuGetPush ?? "/Spaces-1/nuget/packages";
        const packageUploadLink = client.getLink("PackageUpload");
        return (<>
                <CustomDialog open={this.state.showDialog} close={this.closeDialog} render={(renderProps) => (<CustomInfoDialogLayout title="How to Push Packages" open={this.state.showDialog} close={() => this.setState({ showDialog: false })} frame={SmallDialogFrame}>
                            <Section>
                                Use the <code>Upload package</code> button to add a package to the library. Alternatively use a command line to push packages using the Octopus CLI, a NuGet client, Curl or Powershell.
                            </Section>
                            <Section sectionHeader="Octopus CLI">
                                Octopus CLI allows you to push zip archives in addition to nuget packages. It also uses <ExternalLink href="PackageDeltaCompression">delta compression</ExternalLink> for faster uploads.
                                <pre>
                                    octo.exe push --server=<strong>{getServerEndpoint()}</strong> --apiKey <em>&lt;Your API Key&gt;</em> --space="{spaceId}" --package "YourApp.1.1.0.zip"
                                </pre>
                            </Section>
                            <Section sectionHeader="NuGet client">
                                <pre>
                                    NuGet.exe push <em>YourApp.nupkg</em> -ApiKey <em>&lt;Your API Key&gt;</em> -Source <strong>{client.resolveUrlWithSpaceId(nuGetPushLink)}</strong>
                                </pre>
                            </Section>
                            <Section sectionHeader="PowerShell">
                                <pre>
                                    $wc = new-object System.Net.WebClient <br />
                                    $wc.UploadFile("
                                    <strong>
                                        {client.resolveUrlWithSpaceId(packageUploadLink)}?apiKey=<em>&lt;Your API Key&gt;</em>
                                    </strong>
                                    ", "<em>YourApp.1.0.0.zip</em>")
                                </pre>
                            </Section>
                            <Section sectionHeader="Curl">
                                <pre>
                                    curl -X POST <strong>{client.resolveUrlWithSpaceId(packageUploadLink)}</strong> -H "X-Octopus-ApiKey: <em>&lt;Your API Key&gt;</em>" -F "data=@Demo.1.0.0.zip"
                                </pre>
                            </Section>
                            <Section>
                                <ExternalLink href="OnboardingPackagingCommandLineLearnMore">Learn more about pushing packages to the built-in repository.</ExternalLink>
                                <br />
                                <ExternalLink href={"ApiKey"}>Learn how to create an API Key.</ExternalLink>
                            </Section>
                        </CustomInfoDialogLayout>)}/>
                {this.props.asSecondaryButton ? (<ActionButton onClick={this.showDialog} label={this.props.label}/>) : (<a href="#" onClick={this.showDialog}>
                        {this.props.label}
                    </a>)}
            </>);
    }
    private showDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({ showDialog: true });
    };
    private closeDialog = () => {
        this.setState({ showDialog: false });
    };
    static displayName = "HowToPushYourPackageDialogLink";
}
