/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TagResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import ActionList from "~/components/ActionList";
import { ActionButton, ActionButtonType } from "~/components/Button/ActionButton";
import Tag from "~/components/Tag/Tag";
import { withTheme } from "~/components/Theme";
import ColorPicker from "~/primitiveComponents/form/ColorPicker/ColorPicker";
import Text from "~/primitiveComponents/form/Text/Text";
import type TagModel from "./TagModel";
import styles from "./style.module.less";
interface TagItemEditProps {
    tag: TagModel;
    onChange(tag: TagModel): void;
}
interface TagItemEditState {
    name: string;
    description: string;
    color: string;
    isEditing?: boolean;
}
export default class TagItemEdit extends React.Component<TagItemEditProps, TagItemEditState> {
    constructor(props: TagItemEditProps) {
        super(props);
        this.state = this.initialReadView(props);
    }
    initialReadView(props: TagItemEditProps) {
        return {
            description: props.tag.description,
            name: props.tag.name,
            color: props.tag.color,
            isEditing: false,
        };
    }
    handleUpdate = () => {
        this.props.onChange({
            ...this.props.tag,
            name: this.state.name,
            description: this.state.description,
            color: this.state.color,
        });
        this.setState({ isEditing: false });
    };
    tagModelToResource(tag: TagModel): TagResource {
        return {
            Id: tag.originalId,
            Name: tag.name,
            SortOrder: tag.sortOrder,
            Color: tag.color,
            Description: tag.description,
            CanonicalTagName: null!,
        };
    }
    handleDelete = (deleteFlag: boolean) => {
        this.props.onChange({
            ...this.props.tag,
            deleted: deleteFlag,
        });
    };
    renderReadView(): JSX.Element {
        const tag = this.props.tag;
        const tagResource = this.tagModelToResource(tag);
        return withTheme((theme) => (<div className={styles.existingTagcontainer}>
                <div>
                    <Tag tagName={tagResource.Name} tagColor={tagResource.Color} description={tagResource.Description} borderColor={theme.divider}/>
                </div>
                <div className={cn(styles.description, tag.deleted ? styles.deleted : "")}>{tag.description}</div>
                <div className={styles.actions}>
                    {tag.deleted ? (<ActionButton type={ActionButtonType.Secondary} label="Undo" onClick={() => this.handleDelete(false)}/>) : (<ActionList actions={[
                    <ActionButton type={ActionButtonType.Secondary} label="Edit" onClick={() => this.setState({ isEditing: true })}/>,
                    <ActionButton label="Delete" type={ActionButtonType.Secondary} onClick={() => this.handleDelete(true)}/>,
                ]}/>)}
                </div>
            </div>));
    }
    renderWriteView(): JSX.Element {
        return (<div className={styles.newTagContainer}>
                <div>
                    <Text value={this.state.name} label="New tag name" onChange={(name) => this.setState({ name })}/>
                    <Text value={this.state.description} label="Optional description" onChange={(description) => this.setState({ description })}/>
                </div>
                <div>
                    <ColorPicker value={this.state.color} label="Color" defaultColor="#6e6e6e" onChange={(color) => this.setState({ color })}/>
                    <ActionButton type={ActionButtonType.Secondary} label="Cancel" onClick={() => this.setState(this.initialReadView(this.props))}/>
                    <ActionButton type={ActionButtonType.Secondary} disabled={this.state.name === ""} label="Update" onClick={this.handleUpdate}/>
                </div>
            </div>);
    }
    render() {
        if (this.state.isEditing) {
            return this.renderWriteView();
        }
        else {
            return this.renderReadView();
        }
    }
    static displayName = "TagItemEdit";
}
