import type { PackageReferenceProperties, ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import ExternalLink from "../../Navigation/ExternalLink";
import { ExpandableFormSection, Summary } from "../../form";
import { VariableLookupText } from "../../form/VariableLookupText";
/* Note on Octopus Variable names:
Currently we don't have a good solution to supporting both old and new Octopus Variable names
so for the time being, even though this feature has been renamed to structured configuration variables
from json configuration variables, we're going to continue using the json based variables names
*/
const StringProperties = {
    "Octopus.Action.Package.JsonConfigurationVariablesTargets": "",
};
type StructuredConfigurationProperties = {
    [P in keyof typeof StringProperties]: string;
};
class StructuredConfigurationComponentProperties {
    supportedDataTypes?: ("JSON" | "YAML" | "XML" | "Java")[];
    cannotSupplyTargets?: boolean;
    defaultTargetsGlob?: string;
}
type StructuredConfigurationVariablesEditProps<P = PackageReferenceProperties> = ActionEditProps<StructuredConfigurationProperties, P> & StructuredConfigurationComponentProperties;
export default class StructuredConfigurationVariablesEdit<P> extends BaseComponent<StructuredConfigurationVariablesEditProps<P>, never> {
    readonly allFilesGlobPattern: string = "**/*";
    shouldComponentUpdate(nextProps: StructuredConfigurationVariablesEditProps<P>) {
        const configurationChanged = nextProps.supportedDataTypes != this.props.supportedDataTypes || nextProps.cannotSupplyTargets != this.props.cannotSupplyTargets || nextProps.defaultTargetsGlob != this.props.defaultTargetsGlob;
        const shouldUpdate = configurationChanged || ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
        if (shouldUpdate) {
            // if the user isn't supplying targets but something is set, we need to set the glob pattern back to the default
            if (nextProps.cannotSupplyTargets && this.areTargetsSet(nextProps) && nextProps.properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"] !== (nextProps.defaultTargetsGlob ?? this.allFilesGlobPattern)) {
                nextProps.setProperties({
                    ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: nextProps.defaultTargetsGlob ?? this.allFilesGlobPattern,
                });
            }
        }
        return shouldUpdate;
    }
    areTargetsSet(properties: StructuredConfigurationVariablesEditProps<P>) {
        return (properties.properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"] || "").length > 0;
    }
    summary() {
        const enabled = this.areTargetsSet(this.props);
        if (this.props.cannotSupplyTargets) {
            return Summary.summary(enabled ? "Enabled" : "Disabled");
        }
        if (enabled) {
            return Summary.summary(<span>Variable replacement will be performed on the target files</span>);
        }
        else {
            return Summary.placeholder("No target files provided");
        }
    }
    render() {
        const properties = this.props.properties;
        return (<ExpandableFormSection errorKey="jsonvariables" isExpandedByDefault={this.props.expandedByDefault} title="Structured Configuration Variables" summary={this.summary()} help={this.getHelpText()}>
                {!this.props.cannotSupplyTargets && (<>
                        <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"]} onChange={(x) => {
                    this.props.setProperties({
                        ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: x,
                    });
                }} multiline={true} error={this.props.getFieldError("Octopus.Action.Package.JsonConfigurationVariablesTargets")} label="Target files"/>
                        <Note>
                            Target files need to be new line seperated, relative to the package contents. Extended wildcard syntax is supported. E.g., <em>appsettings.json</em>, <em>Config\*.xml</em>, <em>**\specific-folder\*.yaml.</em> Learn more
                            about <ExternalLink href="StructuredConfigurationVariables">Structured Configuration Variables</ExternalLink> and view <ExternalLink href="StructuredVariables">examples</ExternalLink>.
                        </Note>
                    </>)}
                {this.props.cannotSupplyTargets && (<>
                        <Checkbox label="Enable Structured Configuration Variables" value={(this.props.properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"] || "").length > 0} onChange={(x) => this.props.setProperties({
                    ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: x ? this.props.defaultTargetsGlob ?? this.allFilesGlobPattern : "",
                })}/>
                        <Note>
                            Learn more about <ExternalLink href="StructuredConfigurationVariables">Structured Configuration Variables</ExternalLink> and view <ExternalLink href="StructuredVariables">examples</ExternalLink>.
                        </Note>
                    </>)}
            </ExpandableFormSection>);
    }
    private getHelpText() {
        let formatTypes: string = "";
        let supportedDataTypes = this.props.supportedDataTypes;
        if (supportedDataTypes === undefined || supportedDataTypes.length === 0) {
            supportedDataTypes = ["JSON", "YAML", "XML", "Java"];
        }
        if (supportedDataTypes.length === 1) {
            formatTypes = supportedDataTypes[0];
        }
        else if (supportedDataTypes.length === 2) {
            formatTypes = supportedDataTypes.join(" and ");
        }
        else {
            formatTypes = supportedDataTypes.slice(0, -1).join(", ").concat(", and ", supportedDataTypes.slice(-1)[0]);
        }
        return this.props.cannotSupplyTargets ? `Uses variables to replace values in your ${formatTypes} sources` : `Specify ${formatTypes} files for variable replacement.`;
    }
    static displayName = "StructuredConfigurationVariablesEdit";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.JsonConfigurationVariables",
    title: "Structured Configuration Variables",
    description: "Replace settings in JSON, YAML, XML, and Java Properties files with variables defined in Octopus.",
    edit: StructuredConfigurationVariablesEdit,
    priority: 15,
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"];
    },
});
