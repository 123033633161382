import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "~/routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface RouteParams {
    machineId: string;
}
const EditMachine: React.SFC<RouteComponentProps<{
    machineId: string;
}>> = (props) => (<Switch>
        <ReloadableRoute path={`${props.match.path}/connection`}>
            <InternalRedirect to={{ pathname: routeLinks.infrastructure.deploymentTarget(props.match.params.machineId).connection, search: props.location.search }}/>
        </ReloadableRoute>
        <ReloadableRoute path={`${props.match.path}/deployments`}>
            <InternalRedirect to={{ pathname: routeLinks.infrastructure.deploymentTarget(props.match.params.machineId).deployments, search: props.location.search }}/>
        </ReloadableRoute>
        <ReloadableRoute path={`${props.match.path}/runbookruns`}>
            <InternalRedirect to={{ pathname: routeLinks.infrastructure.deploymentTarget(props.match.params.machineId).runbookRuns, search: props.location.search }}/>
        </ReloadableRoute>
        <ReloadableRoute path={`${props.match.path}/events`}>
            <InternalRedirect to={{ pathname: routeLinks.infrastructure.deploymentTarget(props.match.params.machineId).events, search: props.location.search }}/>
        </ReloadableRoute>
        <ReloadableRoute>
            <InternalRedirect to={{ pathname: routeLinks.infrastructure.deploymentTarget(props.match.params.machineId).root, search: props.location.search }}/>
        </ReloadableRoute>
    </Switch>);
EditMachine.displayName = "EditMachine"
export class V3MachineRoutes extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        return (<Switch>
                <ReloadableRoute path={"/machines/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.machines.create(), search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/machines/:machineId"} component={EditMachine}/>
                <ReloadableRoute>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.machines.root, search: this.props.location.search }}/>
                </ReloadableRoute>
            </Switch>);
    }
    static displayName = "V3MachineRoutes";
}
export default V3MachineRoutes;
