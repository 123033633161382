import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentFailureRateOverview, CommonInsightsDeploymentFailureRateTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentFailureRateViews";
import pageIds from "~/pageIds";
export const ProjectInsightsDeploymentFailureRateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.failureRate,
    renderOverview: (props) => (<>
            <CommonInsightsDeploymentFailureRateOverview /> <CommonProjectInsightsHelp />
        </>),
    renderTopics: (props) => <CommonInsightsDeploymentFailureRateTopics />,
};
