import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Project.svg");
export const CommonProjectOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    A <ExternalLink href="OnboardingProjectsLearnMore">project</ExternalLink> is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of
                    deploying your software.
                </p>
                <ImageWithPlaceholder src={image} alt={"Project"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonProjectOverview.displayName = "CommonProjectOverview"
export const CommonProjectTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonProjectTopics.displayName = "CommonProjectTopics"
