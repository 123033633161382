/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RunbookSnapshotResource, ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import List from "~/components/List";
import PaperLayout from "~/components/PaperLayout/index";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { WithRunbookContextInjectedProps } from "../RunbookContext";
import { withRunbookContext } from "../RunbookContext";
class RunbookSnapshotsList extends List<RunbookSnapshotResource> {
}
export interface RunbookRouteProps {
    runbookId: string;
}
interface RunbookSnapshotsState extends DataBaseComponentState {
    runbookSnapshotsResponse: ResourceCollection<RunbookSnapshotResource>;
}
type RunbookSnapshotsProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;
class RunbookSnapshotsInternal extends DataBaseComponent<RunbookSnapshotsProps, RunbookSnapshotsState> {
    private match: any = null;
    constructor(props: RunbookSnapshotsProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            runbookSnapshotsResponse: null!,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotsProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }
        await this.doBusyTask(async () => {
            const runbookSnapshotsResponse = await repository.Runbooks.getRunbookSnapshots(runbook, { skip: 0, take: 30 });
            this.setState({
                runbookSnapshotsResponse,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook) {
            return <PaperLayout busy={true} errors={this.errors}/>;
        }
        return (<PaperLayout busy={this.state.busy} errors={this.errors} title="Snapshots" breadcrumbTitle={runbook && runbook.Name} breadcrumbPath={runbook && routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook.Id).root}>
                {this.state.runbookSnapshotsResponse && (<RunbookSnapshotsList initialData={this.state.runbookSnapshotsResponse} onRow={(item: any) => this.buildRow(runbook, item)} match={this.match} onRowRedirectUrl={(runbookSnapshot: RunbookSnapshotResource) => `${this.match.url}/${runbookSnapshot.Id}`} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>)}
            </PaperLayout>);
    }
    private filter(filter: string, resource: RunbookSnapshotResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildRow(runbook: RunbookResource, runbookSnapshot: RunbookSnapshotResource) {
        return (<ListTitle>
                {runbookSnapshot.Name}
                {runbook && runbook.PublishedRunbookSnapshotId === runbookSnapshot.Id && (<>
                        &nbsp;
                        <RunbookSnapshotPublishedChip />
                    </>)}
            </ListTitle>);
    }
    static displayName = "RunbookSnapshotsInternal";
}
export default withRunbookContext(withProjectContext(RunbookSnapshotsInternal));
