/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Grid, Dialog } from "@material-ui/core";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import ActionButton, { ActionButtonType } from "~/components/Button";
import type { Errors } from "~/components/DataBaseComponent";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import KeyboardHandler, { Key } from "~/components/KeyboardHandler";
import { Note } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import type { CommitSummaryAndDetailsProps } from "../../VersionControl/CommitDialog";
import { CommitSummaryAndDetails } from "../../VersionControl/CommitDialog";
import styles from "./style.module.less";
interface CommitDialogProps {
    open: boolean;
    defaultSummary: string;
    commitMessage: CommitMessageWithDetails;
    setCommitMessage: (commitMessage: CommitMessageWithDetails) => void;
    errors?: Errors;
    project: ProjectResource;
    onCancel?: () => void;
    onClose: () => void;
    onNext: () => Promise<boolean | undefined>;
}
const CommitMessageStep: React.FC<CommitSummaryAndDetailsProps> = (props) => {
    return <CommitSummaryAndDetails {...props}/>;
};
CommitMessageStep.displayName = "CommitMessageStep"
// TODO: Frontend and UX review - Once our functional equivalents are in place for DataBaseComponent, let's
// review this CommitDialog and see if we can get busy indicators showing here also.
const CommitDialog: React.FC<CommitDialogProps> = ({ open, commitMessage, defaultSummary, setCommitMessage, errors, project, onCancel, onClose, onNext }) => {
    const commitChanges = async () => {
        onClose();
        await onNext();
    };
    const close = () => {
        if (onCancel) {
            onCancel();
        }
        onClose();
    };
    const actions = [<ActionButton key="Commit" label="Commit" title="Commit" onClick={commitChanges} type={ActionButtonType.Save} disabled={!commitMessage}/>];
    const additionalActions = [<ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={close}/>];
    const onEnter = (event: KeyboardEvent): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            commitChanges();
            return true;
        }
        return false;
    };
    const onCtrlEnter = (event: KeyboardEvent) => {
        commitChanges();
        return true;
    };
    const keyboardRegistrations = [
        { key: Key.Enter, onKeyPressed: onEnter },
        { key: Key.CtrlEnter, onKeyPressed: onCtrlEnter },
    ];
    return (<Dialog open={open} fullWidth>
            <KeyboardHandler registrations={keyboardRegistrations}>
                <DialogLayout actions={actions} additionalActions={additionalActions} title={"Configure Version Control"} closeDialog={close}>
                    {open && (<Grid container direction="column" alignItems="center" className={styles.commitDialog}>
                            <Note>Configuring this project with version control will allow you to create branches and commit different versions to your repo.</Note>
                            <Callout title="Warning" type={CalloutType.Warning}>
                                <p>
                                    You are about to connect the <b>{project.Name}</b> project to a Git repository.
                                </p>
                                <p>Octopus will commit the project's deployment settings, deployment process, and non-sensitive variables to the default branch.</p>
                                <p>This is an irreversible process.</p>
                            </Callout>
                            <CommitMessageStep commitMessage={commitMessage} defaultSummary={defaultSummary} onCommitMessageChanged={(value: CommitMessageWithDetails) => setCommitMessage(value)}/>
                        </Grid>)}
                </DialogLayout>
            </KeyboardHandler>
        </Dialog>);
};
CommitDialog.displayName = "CommitDialog"
export default CommitDialog;
