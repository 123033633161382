import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { LocationDescriptor } from "history";
import React, { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject from "~/areas/projects/components/Projects/AddProject";
import DialogWithHelpPanel from "~/components/DialogWithHelpPanel/DialogWithHelpPanel";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect";
import routeLinks from "~/routeLinks";
import addProjectImage from "./assets/img-addproject.svg";
interface AddNewProjectDialogProps {
    open: boolean;
    close: (project?: ProjectResource) => void;
    projectGroupId?: string;
}
function AddNewProjectDialog({ open, close, projectGroupId }: AddNewProjectDialogProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const [navigateTo, setNavigateTo] = useState<LocationDescriptor | undefined>(undefined);
    const onProjectCreated = (project: ProjectResource, option?: ProjectCreatedOption) => {
        const projectProcessUrl = option?.vcsRedirect ? routeLinks.project(project.Slug).settings.versionControl : routeLinks.project(project.Slug).deployments.process.root;
        setNavigateTo(`${projectProcessUrl}?newlyCreatedProject=true`);
        close(project);
    };
    const onToggleHelp = () => {
        dispatchAction("Toggle First Project Dialog Help Section", { action: Action.Toggle, resource: "Project" });
    };
    if (navigateTo) {
        return <InternalRedirect to={navigateTo}/>;
    }
    const helpPanelContent = (<span>
            <strong>Projects</strong> let you manage multiple software applications, each with its own deployment process.
        </span>);
    return (<DialogWithHelpPanel helpPanelImage={addProjectImage} helpPanelContent={helpPanelContent} helpPanelAltImageText={"Add New Project"} open={open} close={close} onToggleHelp={onToggleHelp}>
            <AddProject projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel={"Project Name"} groupId={projectGroupId} onCancelled={close}/>
        </DialogWithHelpPanel>);
}
export default AddNewProjectDialog;
