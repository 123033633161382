/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { generatePath } from "react-router";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import pageIds from "../../../../pageIds";
import { runbookLinks } from "../OperationsRoute";
import OperationsOverviewLayout from "./OperationsOverviewLayout";
import RunbookContextLayout from "./RunbookContextLayout";
import RunbookOverviewLayout from "./RunbookOverviewLayout";
import { RunbookSettingsAndProcessRoutes } from "./RunbookRoutesWithBranching";
import type { RunbookRoutingProps } from "./RunbookRoutingProps";
import RunbookRunNowLayout from "./RunbookRunNowLayout";
import RunbookRunsListLayout from "./RunbookRunsListLayout";
import { RunbookSnapshotsRoute } from "./RunbookSnapshots/RunbookSnapshotsRoute";
import RunbooksLayout from "./RunbooksLayout";
export const OperationsOverviewPage = withPage({ page: pageIds.project().operations.root })(OperationsOverviewLayout);
export const OperationsRunbooksPage = withPage({ page: pageIds.project().operations.runbooks })(RunbooksLayout);
export const RunbookOverviewPage = withPage({ page: pageIds.project().runbook.root })(RunbookOverviewLayout);
export const RunbookRunsListPage = withPage({ page: pageIds.project().runbook.runsList })(RunbookRunsListLayout);
export const RunbookRunNowPage = withPage({ page: pageIds.project().runbook.runNow })(RunbookRunNowLayout);
class RunbookRoutes extends React.Component<RunbookRoutingProps> {
    render() {
        const links = runbookLinks(this.props.path);
        return (<Switch>
                <ReloadableRoute path={links.root} render={(props) => (<RunbookContextLayout {...props}>
                            {(context) => (<Switch>
                                    <ReloadableRoute exact={true} path={links.root} render={(props: RouteComponentProps<any>) => {
                        return <InternalRedirect to={`${generatePath(links.overview, props.match.params)}${props.location.search}`}/>;
                    }}/>
                                    <ReloadableRoute path={links.overview} component={RunbookOverviewPage}/>
                                    <ReloadableRoute path={links.runslist} component={RunbookRunsListPage}/>
                                    <ReloadableRoute path={links.runNow.root} exact={true} component={RunbookRunNowPage}/>
                                    <ReloadableRoute path={links.runNow.runbookSnapshot(":runbookSnapshotId")} exact={true} component={RunbookRunNowPage}/>
                                    <ReloadableRoute path={links.runbookSnapshots} component={RunbookSnapshotsRoute}/>
                                    <RunbookSettingsAndProcessRoutes path={links.root}/>
                                    <RedirectAs404 />
                                </Switch>)}
                        </RunbookContextLayout>)}/>
            </Switch>);
    }
    static displayName = "RunbookRoutes";
}
const EnhancedRunbookRoutes = withRouter(RunbookRoutes);
export { EnhancedRunbookRoutes };
