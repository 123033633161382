import { compact } from "lodash";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle";
import type { NavItem } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import routeLinks from "~/routeLinks";
import { UxGuidelinesLogo } from "./UxGuide";
interface UxGuidelinesLayoutProps {
    children: React.ReactNode;
}
const UxGuidelinesLayout: React.FC<UxGuidelinesLayoutProps> = ({ children }) => {
    const navLinks: Array<NavItem | null> = [];
    navLinks.push(Navigation.navItem("Overview", routeLinks.uxGuide.guidelines.overview));
    navLinks.push(Navigation.navItem("Cheat Sheet", routeLinks.uxGuide.guidelines.cheatsheet));
    navLinks.push(Navigation.navItem("Guidelines", routeLinks.uxGuide.guidelines.guidelines));
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.uxGuide.guidelines.overview} title={"UX Guidelines"} breadcrumbTitle={"UX Guide"} breadcrumbPath={routeLinks.uxGuide.root}/>
            <NavigationSidebarLayout navLinks={compact(navLinks)} image={UxGuidelinesLogo} content={children}/>
        </main>);
};
UxGuidelinesLayout.displayName = "UxGuidelinesLayout"
export default UxGuidelinesLayout;
