/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Repository, MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import type { ListMachinesArgs, CommunicationStyle, ResourceCollection, MachineResource, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { each } from "lodash";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import type { EnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import type { HealthStatusRecord } from "./BaseAllMachinesSummary";
import type { MachineQuery } from "./MachineQuery";
export interface BaseInfrastructureFilter {
    isDisabled: boolean;
    healthStatuses: MachineModelHealthStatus[];
    commStyles: CommunicationStyle[];
    partialName: string | undefined;
}
export const defaultBaseInfrastructureFilter: BaseInfrastructureFilter = {
    isDisabled: false,
    healthStatuses: [],
    commStyles: [],
    partialName: undefined,
};
export interface MachineFilter {
    partialName: string | undefined;
    environmentIds: string[];
    roles: string[];
    workerPoolIds: string[];
    isDisabled: boolean;
    healthStatuses: MachineModelHealthStatus[];
    commStyles: CommunicationStyle[];
    tenantIds: string[];
    tenantTags: string[];
    shellNames: string[];
    deploymentTargetTypes: string[];
}
export const createMachineHealthMap = <T extends MachineResource>(machinesResponse: ResourceCollection<T>, takeSize: number, fastLookupObject: {
    [key: string]: PagingCollection<MachineResource>;
} = {}) => {
    const objValues = Object.keys(MachineModelHealthStatus).map((k: string) => MachineModelHealthStatus[k as keyof typeof MachineModelHealthStatus]);
    const names = objValues.filter((v) => typeof v === "string") as string[];
    each(names, (statusText) => {
        const status = statusText as MachineModelHealthStatus;
        const machines = machinesResponse.Items.filter((x) => x.HealthStatus === status);
        const machinesForHealthStatus = toPagingCollection(machines, takeSize);
        fastLookupObject[status] = machinesForHealthStatus;
    });
    // Insert "Disabled" separately.
    const disabledMachines = machinesResponse.Items.filter((x) => x.IsDisabled);
    const disabledMachinesResourceCollection = toPagingCollection(disabledMachines, takeSize);
    fastLookupObject["Disabled"] = disabledMachinesResourceCollection;
    return fastLookupObject as HealthStatusRecord<T>;
};
export const toPagingCollection = <T extends MachineResource>(machines: T[], takeSize: number): PagingCollection<T> => {
    // Emulate a paging response so this will work with our List easily.
    const totalResults = machines.length;
    const itemsPerPage = takeSize;
    const numberOfPages = Math.max(1, Math.ceil(totalResults / itemsPerPage));
    const lastPageNumber = numberOfPages - 1;
    const pageItems = machines.slice(0, itemsPerPage);
    return {
        ItemType: "Machine",
        TotalResults: totalResults,
        ItemsPerPage: itemsPerPage,
        NumberOfPages: numberOfPages,
        LastPageNumber: lastPageNumber,
        Items: pageItems,
    };
};
export const machineFilterToQuery = (filter: MachineFilter): MachineQuery => ({
    partialName: filter.partialName,
    environmentIds: filter.environmentIds,
    roles: filter.roles,
    workerPoolIds: filter.workerPoolIds,
    isDisabled: filter.isDisabled ? "true" : "",
    healthStatuses: filter.healthStatuses,
    commStyles: filter.commStyles,
    tenantIds: filter.tenantIds,
    tenantTags: filter.tenantTags,
    shellNames: filter.shellNames,
    deploymentTargetTypes: filter.deploymentTargetTypes,
});
export const machineQueryToFilter = (query: MachineQuery): MachineFilter => {
    return {
        partialName: query.partialName,
        environmentIds: arrayValueFromQueryString(query.environmentIds),
        healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[],
        commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[],
        roles: arrayValueFromQueryString(query.roles),
        workerPoolIds: arrayValueFromQueryString(query.workerPoolIds),
        isDisabled: query.isDisabled === "true",
        tenantIds: arrayValueFromQueryString(query.tenantIds),
        tenantTags: arrayValueFromQueryString(query.tenantTags),
        shellNames: arrayValueFromQueryString(query.shellNames),
        deploymentTargetTypes: arrayValueFromQueryString(query.deploymentTargetTypes),
    };
};
export const createMachinesListRequestArgs = (filter: EnvironmentSummaryFilter, healthStatusFilter: string | null, isDisabledFilter: boolean): ListMachinesArgs => {
    const skip = 0; // Don't skip, just increase the take size.
    const take = Repository.takeAll; // No paging, just take all, because we need to group our response data by health status.
    // Here we take the machinePartialName from the filter, as this is where we bind the worker name from the UI.
    // We map this to partialName on the returned args, as this is where the API expects the worker name for the query.
    const partialName = filter.machinePartialName;
    const shellNames = filter.shellNames ? filter.shellNames.join(",") : undefined;
    const applicableHealthStatusFilters = filter.healthStatuses.length > 0 ? filter.healthStatuses : healthStatusFilter ? [healthStatusFilter] : undefined;
    const healthStatuses = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : undefined;
    const isDisabled = filter.isDisabled ? filter.isDisabled : isDisabledFilter;
    const commStyles = filter.commStyles.length > 0 ? filter.commStyles.join(",") : undefined;
    const roles = filter.roles ? filter.roles.join(",") : undefined;
    const tenantIds = filter.tenantIds.length > 0 ? filter.tenantIds.join(",") : undefined;
    const tenantTags = filter.tenantTags.length > 0 ? filter.tenantTags.join(",") : undefined;
    const environmentIds = filter.environmentIds.length > 0 ? filter.environmentIds.join(",") : undefined;
    const deploymentTargetTypes = filter.deploymentTargetTypes.length > 0 ? filter.deploymentTargetTypes.join(",") : undefined;
    return { skip, take, partialName, shellNames, healthStatuses, isDisabled, commStyles, roles, tenantIds, tenantTags, environmentIds, deploymentTargetTypes };
};
