import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Note from "~/primitiveComponents/form/Note/Note";
import type { CategoryDefinition } from "./endpointRegistry";
const category: CategoryDefinition = {
    category: "Kubernetes Cluster",
    title: (<React.Fragment>
            Let's setup a <strong>Kubernetes Cluster</strong> target.
        </React.Fragment>),
    help: (<Note>
            Learn more about <ExternalLink href="KubernetesTarget">Kubernetes Targets</ExternalLink>
        </Note>),
    displayOrder: 40,
};
export default category;
