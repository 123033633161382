/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AnonymousVcsCredentials, ConvertProjectToVersionControlledCommand, GitBranchResource, GitCredentialResource, GitPersistenceSettings, PreviewProtectedBranchesRequest, ProjectResource, ReferenceVcsCredentials, ResourceCollection, UsernamePasswordVcsCredentials, VersionControlCompatibilityResponse, } from "@octopusdeploy/octopus-server-client";
import { AuthenticationType, branchToShowByDefault, getBasePathToShowByDefault, HasGitPersistenceSettings, IsUsingAnonymousAuth, IsUsingReferenceAuth, IsUsingUsernamePasswordAuth, Permission, PersistenceSettingsType, ProcessType, Repository, toGitBranch, toGitBranchShort, } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import type { CancelConfigureGitOptions } from "~/areas/projects/components/ProjectSettings/VersionControl/Analytics/useNotifyCancelConfigureGit";
import { useNotifyCancelConfigureGit } from "~/areas/projects/components/ProjectSettings/VersionControl/Analytics/useNotifyCancelConfigureGit";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { ConfigureGitOptions } from "~/areas/projects/components/VersionControl/Analytics/useNotifyConfigureGit";
import { useNotifyConfigureGit } from "~/areas/projects/components/VersionControl/Analytics/useNotifyConfigureGit";
import type { GitSettingsUpdateOptions } from "~/areas/projects/components/VersionControl/Analytics/useNotifyGitSettingsUpdate";
import { useNotifyGitSettingsUpdate } from "~/areas/projects/components/VersionControl/Analytics/useNotifyGitSettingsUpdate";
import { client, repository } from "~/clientInstance";
import ActionButton, { ActionButtonType } from "~/components/Button";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import type { PrimaryActionProps } from "~/components/FormPaperLayout/FormPaperLayout";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, RadioButton, Summary, Text, UnstructuredFormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import Sensitive from "~/components/form/Sensitive/Sensitive";
import { required } from "~/components/form/Validators";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import type { WithProjectContextInjectedProps } from "../../../context";
import { withProjectContext } from "../../../context";
import { isVersionControlledProcess } from "../../Process/Common/CommonProcessHelpers";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
import type { CommitMessageWithDetails } from "../../VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "../../VersionControl/CommitMessageWithDetails";
import CommitDialog from "./CommitDialog";
import { GitChip } from "./GitChip";
import GitCredentialSelect from "./GitCredentialSelect";
import { SettingsNoteSection } from "./SettingsNoteSection";
import TestConnectionButton from "./TestConnectionButton";
import { UnsupportedFeaturesSection } from "./UnsupportedFeaturesSection";
import styles from "./style.module.less";
interface VersionControlSettingsState extends OptionalFormBaseComponentState<GitPersistenceSettings> {
    project?: ProjectResource;
    gitCredentials: GitCredentialResource[];
    commitDialogActive: boolean;
    commitMessage: CommitMessageWithDetails;
    vcsCompatibilityReport: VersionControlCompatibilityResponse | null;
    protectedBranches: ResourceCollection<GitBranchResource> | null;
    initialCommitBranchName: string | undefined;
    saveForm: () => Promise<boolean>;
}
interface VersionControlSettingsAnalyticsProps {
    updateVcsSettingsEventDispatcher: (options: GitSettingsUpdateOptions) => Promise<ProjectResource>;
    configureVcsEventDispatcher: (options: ConfigureGitOptions) => void;
    cancelConfiguringVcsEventDispatcher: (options: CancelConfigureGitOptions) => void;
}
interface VersionControlSettingsBranchProtectionProps {
    branchProtectionsAreEnabled: boolean;
}
type VersionControlSettingsProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;
type VersionControlSettingsPropsInternal = VersionControlSettingsProps & VersionControlSettingsAnalyticsProps & VersionControlSettingsBranchProtectionProps;
const defaultCommitMessage = "Initial commit of deployment process";
class AuthenticationTypeRadioButtonGroup extends RadioButtonGroup<AuthenticationType> {
}
class VersionControlSettingsInternal extends FormBaseComponent<VersionControlSettingsPropsInternal, VersionControlSettingsState, GitPersistenceSettings> {
    constructor(props: VersionControlSettingsPropsInternal) {
        super(props);
        this.state = {
            commitDialogActive: false,
            gitCredentials: [],
            commitMessage: { summary: "", details: "" },
            vcsCompatibilityReport: null,
            protectedBranches: null,
            initialCommitBranchName: undefined,
            saveForm: () => Promise.resolve(false),
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Deployment);
            const vcsCompatibilityReport = !isVersionControlled ? await repository.Projects.vcsCompatibilityReport(project) : null;
            this.setState({
                project,
                vcsCompatibilityReport,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });
            await this.refreshGitCredentials();
            if (this.props.branchProtectionsAreEnabled && project.IsVersionControlled) {
                await this.refreshProtectedBranches();
            }
        });
    }
    buildModel(project: ProjectResource): GitPersistenceSettings {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.defaultVersionControlSettings(project);
        }
        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: project.PersistenceSettings.Url,
            DefaultBranch: project.PersistenceSettings.DefaultBranch || toGitBranchShort(branchToShowByDefault),
            BasePath: project.PersistenceSettings.BasePath || getBasePathToShowByDefault(project.Name),
            Credentials: project.PersistenceSettings.Credentials,
            ConversionState: project.PersistenceSettings.ConversionState,
            ProtectedDefaultBranch: project.PersistenceSettings.ProtectedDefaultBranch,
            ProtectedBranchNamePatterns: project.PersistenceSettings.ProtectedBranchNamePatterns ?? [],
        };
    }
    handleSaveClick = async () => {
        if (!this.state.model) {
            throw Error("Tried to save with an empty model");
        }
        const model = this.state.model;
        await this.doBusyTask(async () => {
            if (!this.state.project) {
                throw new Error("No Project loaded");
            }
            if (HasGitPersistenceSettings(this.state.project.PersistenceSettings)) {
                await this.saveProject({ ...this.state.project, PersistenceSettings: model });
            }
            else {
                const formattedMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
                const vcsSettingsAndCommitMessage: ConvertProjectToVersionControlledCommand = {
                    CommitMessage: formattedMessage,
                    VersionControlSettings: model,
                    InitialCommitBranchName: this.state.initialCommitBranchName,
                };
                await this.convertProjectToVcs(this.state.project, vcsSettingsAndCommitMessage);
                // Select the initial commit branch, or the default branch after converting
                const initialGitRef = this.state.initialCommitBranchName ? toGitBranch(this.state.initialCommitBranchName) : toGitBranch(model.DefaultBranch);
                await this.props.projectContext.actions.refreshModel(initialGitRef);
            }
        });
    };
    handleBasePathChange = async (basePath: string) => {
        // We want to ensure the `.octopus/` prefix is applied to the base path,
        // despite not allowing the portal to configure the root
        this.setModelState({ BasePath: basePath ? `.octopus/${basePath}` : `.octopus` });
    };
    getBasePath = (): string => {
        // The `.octopus/` base path is expected to always be set on the incoming BasePath
        // despite not being (currently) configurable.
        // To ensure it isn't configurable, we strip the root directory from the value bound to the textbox
        if (!this.state.model) {
            return "";
        }
        let basePath = this.state.model.BasePath;
        if (basePath.startsWith(".octopus")) {
            basePath = basePath.substring(".octopus".length);
        }
        if (basePath.startsWith("/")) {
            return basePath.substring(1);
        }
        return basePath;
    };
    getBranchNamePatterns = (): string => {
        return this.state.model?.ProtectedBranchNamePatterns.join(", ") ?? "";
    };
    getCredentialName = async (id: string): Promise<string> => {
        return (await repository.GitCredentials.get(id)).Name;
    };
    refreshGitCredentials = async () => {
        if (hasPermission(Permission.GitCredentialView)) {
            // SelectWithAddRefresh does not have a filter re-search capability. Not going to break that
            // ground here, instead just get all of the credentials and show them in the list.
            const gitCredentials = await repository.GitCredentials.list({ take: Repository.takeAll });
            this.setState({
                gitCredentials: gitCredentials.Items,
            });
        }
        else {
            this.setState({
                gitCredentials: [],
            });
        }
    };
    onDefaultBranchProtectedUpdated = async (newValue: boolean) => {
        this.setState({ initialCommitBranchName: undefined });
        this.setModelState({ ProtectedDefaultBranch: newValue }, async () => {
            await this.refreshProtectedBranches();
        });
    };
    onBranchNamePatternsUpdated = async (newValue: string) => {
        const newPatterns = newValue
            .split(",")
            .map((p) => p.trim())
            .filter((p) => p); // Remove empty items
        this.setModelState({ ProtectedBranchNamePatterns: newPatterns });
        await this.refreshProtectedBranches();
    };
    refreshProtectedBranches = async () => {
        if (!this.state.project || !this.state.model) {
            return;
        }
        if (this.state.model.ProtectedBranchNamePatterns.length > 0 || this.state.model.ProtectedDefaultBranch) {
            const request: PreviewProtectedBranchesRequest = {
                defaultBranch: this.state.model.DefaultBranch,
                protectedDefaultBranch: this.state.model.ProtectedDefaultBranch,
                protectedBranchNamePatterns: this.state.model.ProtectedBranchNamePatterns,
            };
            const branches = await repository.Projects.previewProtectedBranches(this.state.project, request);
            this.setState({ protectedBranches: branches });
        }
        else {
            this.setState({ protectedBranches: null });
        }
    };
    render() {
        const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
        const baseLibraryRadioButton = (<RadioButton value={AuthenticationType.Reference} key={AuthenticationType.Reference} label={"Library"} disabled={!canViewLibraryGitCredentials} isDefault={canViewLibraryGitCredentials} accessibleName={"Use library credentials"}/>);
        const libraryRadioButtonWithOptionalToolTip = canViewLibraryGitCredentials ? (baseLibraryRadioButton) : (<ToolTip content={"Requires Git Credential View permission"} position="left">
                {baseLibraryRadioButton}
            </ToolTip>);
        const isExpanded = this.state.model && !this.state.model.Url;
        const formFields = this.state.model && this.state.project && (<>
                <TransitionAnimation>
                    {this.state.project.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                            <Callout type={CalloutType.Warning} title={"This project is currently disabled"}/>
                        </UnstructuredFormSection>)}
                    <ExpandableFormSection errorKey="Url" title="Git Repository" summary={this.summary()} help="Add the Git repository where you want to store this project's deployment process" isExpandedByDefault={isExpanded}>
                        <Callout type={CalloutType.Information} title="Repository must be initialized">
                            <div>If you are creating a new repository, it must be initialized with at least one branch</div>
                        </Callout>
                        <Text key="Url" value={this.state.model.Url} onChange={(Url) => this.setModelState({ Url })} label="URL" error={this.getFieldError("Url")} validate={required("Enter a Git repository URL.")} autoFocus={true} disabled={!!this.state.busy} accessibleName={"URL for Git repository"}/>
                        <Note>
                            The HTTPS URL to your git repo. E.g. <code>https://github.com/acme/hello-world.git</code>
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Authentication" title="Authentication" summary={this.authSummary()} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                        <AuthenticationTypeRadioButtonGroup value={this.state.model.Credentials.Type} onChange={(authType) => {
                if (authType === AuthenticationType.Reference) {
                    this.setCredentialsForReference({ Type: AuthenticationType.Reference });
                }
                else if (authType === AuthenticationType.UsernamePassword) {
                    this.setCredentialsForUsernamePassword({ Type: AuthenticationType.UsernamePassword });
                }
                else if (authType === AuthenticationType.Anonymous) {
                    this.setCredentialsForAnonymous({ Type: AuthenticationType.Anonymous });
                }
            }} disabled={!!this.state.busy} accessibleName={"Authentication for the Git repository"}>
                            {libraryRadioButtonWithOptionalToolTip}
                            {IsUsingReferenceAuth(this.state.model.Credentials) && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>Currently configured with a library credential. You do not have permission to change the credential or repository URL</Note>}>
                                    <GitCredentialSelect items={this.state.gitCredentials} onChange={(Id) => this.setCredentialsForReference({ Id })} value={this.state.model.Credentials.Id} onRequestRefresh={this.refreshGitCredentials}/>
                                    <Note> Use credential from the Git credential library </Note>
                                </PermissionCheck>)}

                            <RadioButton value={AuthenticationType.UsernamePassword} key={AuthenticationType.UsernamePassword} label={"Username/Password"} isDefault={!canViewLibraryGitCredentials} accessibleName={"Authenticate using username and password"}/>
                            {IsUsingUsernamePasswordAuth(this.state.model.Credentials) && (<>
                                    <Text key="Username" value={this.state.model.Credentials.Username} onChange={(Username) => this.setCredentialsForUsernamePassword({ Username })} label="Username" error={this.getFieldError("Username")} validate={required("Enter authentication details.")} disabled={!!this.state.busy}/>
                                    <Sensitive key="Password" value={this.state.model.Credentials.Password} onChange={(Password) => this.setCredentialsForUsernamePassword({ Password })} label="Password or Personal Access Token" error={this.getFieldError("Password")} disabled={!!this.state.busy}/>
                                    <Note>If you are using a personal access token, ensure it is scoped with read and write access for the repo.</Note>
                                </>)}
                            <RadioButton value={AuthenticationType.Anonymous} key={AuthenticationType.Anonymous} label={"Anonymous"} accessibleName={"No authentication"}/>
                            {IsUsingAnonymousAuth(this.state.model.Credentials) && <Note>When no credentials are required (e.g. locally hosted repositories).</Note>}
                        </AuthenticationTypeRadioButtonGroup>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={this.basePathSummary()} help="The directory where Octopus should store the project files in the repository" isExpandedByDefault={isExpanded}>
                        <Text inputProps={{
                startAdornment: (<>
                                        <code>.octopus</code>
                                        &nbsp;/
                                    </>),
            }} key="BasePath" value={this.getBasePath()} onChange={this.handleBasePathChange} label="Git File Storage Directory" error={this.getFieldError("BasePath")} disabled={!!this.state.busy} accessibleName={"Directory in the repository where Octopus related files should be stored"}/>
                        <Note>This controls where in your repository the Octopus configuration files will be written.</Note>
                        <Note>
                            If this is the only Octopus project which will be persisted in the repository, <code>.octopus</code> is a good option. If multiple Octopus projects will be stored in the same repository, adding the project name to the path
                            is the recommended convention, e.g. <code>.octopus/acme</code>
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={this.branchSummary()} help="Enter the details for your branches" isExpandedByDefault={isExpanded}>
                        <Text key="DefaultBranch" value={this.state.model.DefaultBranch} onChange={(DefaultBranch) => this.setModelState({ DefaultBranch })} label="Default Branch" error={this.getFieldError("DefaultBranch")} validate={required("Enter a default branch.")} disabled={!!this.state.busy} accessibleName={"Name of the default branch on the Git repository"}/>
                        <Note>Runbooks will use variables from the default branch</Note>

                        <FeatureToggleVisibility toggle={"BranchProtectionsFeatureToggle"}>
                            <Checkbox value={this.state.model.ProtectedDefaultBranch} onChange={this.onDefaultBranchProtectedUpdated} label={"Default branch is protected"} note={"If the default branch is protected, you may not have permission to push to it."} disabled={!!this.state.busy} accessibleName={"Default branch is protected"}/>

                            {this.state.model.ProtectedDefaultBranch && !this.state.project.IsVersionControlled && (<>
                                    <Text key={"InitialCommitBranchName"} value={this.state.initialCommitBranchName ?? ""} onChange={(initialCommitBranchName) => this.setState({ initialCommitBranchName })} label={"Initial Commit Branch"} error={this.getFieldError("InitialCommitBranchName")} disabled={!!this.state.busy} accessibleName={"Name of the branch on the Git repository where the OCL files will be committed to initially"}/>
                                    <Note>If the branch does not exist, it will be created.</Note>
                                </>)}

                            {this.state.project.IsVersionControlled && (<>
                                    {/* It'd be nice if we could use a Multi-Select here, but we don't have a use for the search functionality and dropdown */}
                                    {/* For now, we're using a text box and just splitting the value by ",". It's a bit sketchy, but it's better than doing it in the backend */}
                                    {/* We can look into a new component like the multi-select later */}
                                    <DebounceText value={this.getBranchNamePatterns()} onChange={this.onBranchNamePatternsUpdated} debounceDelay={500} accessibleName={"Branch name patterns"} label={"Protected branch patterns"}/>
                                    <Note>
                                        This setting only applies within Octopus and will not affect your protected branches in Git. Use wildcard syntax to specify the range of branches to include. Multiple patterns can be separated by a comma.
                                    </Note>

                                    {this.state.protectedBranches && this.state.protectedBranches.TotalResults > 0 && (<div className={styles.branchList}>
                                            Applies to {this.state.protectedBranches.TotalResults} branches
                                            <br />
                                            <div className={styles.branchListItems}>
                                                {this.state.protectedBranches.Items.map((branch) => (<GitRefChip key={branch.CanonicalName} vcsRef={{ GitRef: branch.CanonicalName }}/>))}
                                            </div>
                                        </div>)}
                                </>)}
                        </FeatureToggleVisibility>
                    </ExpandableFormSection>
                </TransitionAnimation>
            </>);
        const configurationDialog = this.state.model && this.state.project && this.state.commitDialogActive && (<CommitDialog open={this.state.commitDialogActive} defaultSummary={defaultCommitMessage} commitMessage={this.state.commitMessage} setCommitMessage={(commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage })} errors={this.errors} project={this.state.project} onNext={() => {
                // TODO: try and clone the repository, passing the commit message.
                // if that succeeds, save the VCS settings
                return this.state.saveForm();
            }} onCancel={() => {
                this.props.cancelConfiguringVcsEventDispatcher({
                    hasInitialCommitBranch: false,
                });
            }} onClose={() => {
                this.setState({ commitDialogActive: false });
            }}/>);
        const hasErrors = this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false;
        const enabled = this.state.project?.IsVersionControlled ?? false;
        const isVersionControlled = this.state.project ? this.state.project.IsVersionControlled : false;
        return (<FormPaperLayout title={[<span key="title">Version Control Settings&nbsp;</span>, <GitChip key="chip" size="small" enabled={enabled}/>]} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} saveButtonLabel={enabled ? "Save" : "Configure..."} saveButtonBusyLabel={enabled ? "Saving" : "Configuring..."} savePermission={this.editPermission()} onSaveClick={this.handleSaveClick} forceDisableFormSaveButton={hasErrors} forceDisabledReason={"Conversion errors must be resolved"} customPrimaryAction={(primaryActionProps: PrimaryActionProps) => {
                // For this page (unlike most form layouts) we only save after a successful clone.
                // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
                // In order to do that, here we're storing a reference to the default form save function
                // to call later on from the dialog.
                const onClick = (e: React.MouseEvent) => {
                    if (enabled) {
                        primaryActionProps.onClick();
                    }
                    else {
                        e.preventDefault();
                        this.setState({
                            saveForm: primaryActionProps.onClick as () => Promise<boolean>,
                            commitDialogActive: true,
                        });
                    }
                };
                return <ActionButton type={ActionButtonType.Save} label={primaryActionProps.label} disabled={primaryActionProps.disabled} busyLabel={primaryActionProps.busyLabel} onClick={onClick}/>;
            }} saveText="Your project now supports version control" secondaryAction={!hasErrors && this.state.model && this.state.project && <TestConnectionButton disabled={this.state.busy || !this.state.model.Url} project={this.state.project} model={this.state.model}/>} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                <SettingsNoteSection isVersionControlled={isVersionControlled}/>
                {this.state.vcsCompatibilityReport && <UnsupportedFeaturesSection vcsCompatibilityReport={this.state.vcsCompatibilityReport}/>}
                {formFields}
                {configurationDialog}
            </FormPaperLayout>);
    }
    private setCredentialsForReference<K extends keyof ReferenceVcsCredentials>(state: Pick<ReferenceVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }
    private setCredentialsForUsernamePassword<K extends keyof UsernamePasswordVcsCredentials>(state: Pick<UsernamePasswordVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }
    private setCredentialsForAnonymous<K extends keyof AnonymousVcsCredentials>(state: Pick<AnonymousVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }
    private summary(): SummaryNode {
        const url = this.state.model && this.state.model.Url;
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    }
    private authSummary(): SummaryNode {
        if (this.state.model) {
            if (IsUsingReferenceAuth(this.state.model.Credentials)) {
                const { Id: id } = this.state.model.Credentials;
                const credential = this.state.gitCredentials.find((c) => c.Id == id);
                return Summary.summary(React.Children.toArray([
                    <span>Using library credential</span>,
                    <span>
                            <strong>{credential ? " " + credential.Name : ""}</strong>
                        </span>,
                ]));
            }
            else if (IsUsingUsernamePasswordAuth(this.state.model.Credentials)) {
                const { Username: username, Password: password } = this.state.model.Credentials;
                if (username && password && password.HasValue) {
                    return Summary.summary(React.Children.toArray([
                        <span>Authenticated with username&nbsp;</span>,
                        <span>
                                <strong>{username}</strong>
                            </span>,
                    ]));
                }
            }
            else {
                return Summary.summary(React.Children.toArray([
                    <span>
                            Authenticated as an <strong>Anonymous</strong> user
                        </span>,
                ]));
            }
        }
        return Summary.placeholder("Enter authentication details");
    }
    private basePathSummary(): SummaryNode {
        const basePathToShowByDefault = this.state.project ? getBasePathToShowByDefault(this.state.project.Name) : "";
        if (!this.state.model || this.state.model.DefaultBranch === basePathToShowByDefault) {
            return Summary.default(basePathToShowByDefault);
        }
        if (!this.state.model.BasePath) {
            return Summary.placeholder("Enter a base path");
        }
        return Summary.summary(this.state.model.BasePath);
    }
    private branchSummary(): SummaryNode {
        const defaultBranchName = this.defaultBranchName();
        // The default branch is the only required field in here, so if that's
        // not set, reminding the user to set a value there should be all that
        // we need to show.
        if (!defaultBranchName) {
            return Summary.placeholder("Configure default branch");
        }
        if (!this.props.branchProtectionsAreEnabled) {
            return Summary.summary(<span>
                    Default branch is <strong>{defaultBranchName}</strong>
                </span>);
        }
        const defaultBranchProtected = this.state.model?.ProtectedDefaultBranch ?? false;
        const totalProtectedBranchPatterns = this.state.model?.ProtectedBranchNamePatterns.length ?? 0;
        let summary = "";
        if (defaultBranchProtected || totalProtectedBranchPatterns > 0) {
            summary = `protected branches are configured`;
        }
        else {
            summary = `no protected branches`;
        }
        return Summary.summary(<span>
                Default branch is <strong>{defaultBranchName}</strong>, {summary}
            </span>);
    }
    private defaultBranchName(): string | undefined {
        const shortBranchName = toGitBranchShort(branchToShowByDefault);
        if (!this.state.model || this.state.model.DefaultBranch === shortBranchName) {
            return shortBranchName;
        }
        if (!this.state.model.DefaultBranch) {
            return undefined;
        }
        return this.state.model.DefaultBranch;
    }
    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }
    private async saveProject(project: ProjectResource) {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Cannot save project when persistence settings are not git settings");
        }
        const result = await this.props.updateVcsSettingsEventDispatcher({
            hasProtectedBranches: project.PersistenceSettings.ProtectedDefaultBranch || project.PersistenceSettings.ProtectedBranchNamePatterns.length > 0,
            updateAction: async (_: AnalyticErrorCallback) => await repository.Projects.save(project),
        });
        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });
        if (!this.errors?.errors.length) {
            window.location.reload();
        }
    }
    private async convertProjectToVcs(project: ProjectResource, command: ConvertProjectToVersionControlledCommand) {
        this.props.configureVcsEventDispatcher({
            hasInitialCommitBranch: this.state.initialCommitBranchName !== undefined,
        });
        await repository.Projects.convertToVcs(project, command);
        const updatedProject = await repository.Projects.get(project.Id);
        client.dispatchEvent({ type: "ProjectModified", project: updatedProject });
        if (!HasGitPersistenceSettings(updatedProject.PersistenceSettings))
            throw new Error("Converted Project is not version controlled");
        await this.props.projectContext.actions.onVersionControlEnabled(updatedProject);
        this.setState({
            model: this.buildModel(updatedProject),
            cleanModel: this.buildModel(updatedProject),
            project: updatedProject,
        });
    }
    private defaultVersionControlSettings(project: ProjectResource): GitPersistenceSettings {
        const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
        const defaultReference: ReferenceVcsCredentials = {
            Type: AuthenticationType.Reference,
            Id: "",
        };
        const defaultUsernamePassword: UsernamePasswordVcsCredentials = {
            Type: AuthenticationType.UsernamePassword,
            Username: "",
            Password: {
                HasValue: false,
            },
        };
        const defaultCredentials = canViewLibraryGitCredentials ? defaultReference : defaultUsernamePassword;
        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: "",
            DefaultBranch: toGitBranchShort(branchToShowByDefault),
            BasePath: getBasePathToShowByDefault(project.Name),
            Credentials: defaultCredentials,
            ConversionState: { VariablesAreInGit: false },
            ProtectedDefaultBranch: false,
            ProtectedBranchNamePatterns: [],
        };
    }
    static displayName = "VersionControlSettingsInternal";
}
export function VersionControlSettingsInternalWithAnalytics(props: VersionControlSettingsProps) {
    const configureVcsEventDispatcher = useNotifyConfigureGit();
    const updateVcsSettingsEventDispatcher = useNotifyGitSettingsUpdate();
    const cancelConfiguringVcsEventDispatcher = useNotifyCancelConfigureGit();
    const branchProtectionsAreEnabled = isFeatureToggleEnabled("BranchProtectionsFeatureToggle");
    return (<VersionControlSettingsInternal {...props} cancelConfiguringVcsEventDispatcher={cancelConfiguringVcsEventDispatcher} updateVcsSettingsEventDispatcher={updateVcsSettingsEventDispatcher} configureVcsEventDispatcher={configureVcsEventDispatcher} branchProtectionsAreEnabled={branchProtectionsAreEnabled}/>);
}
export default withProjectContext(VersionControlSettingsInternalWithAnalytics);
