import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import type { OpenDialogProps } from "~/components/Dialog/OpenDialogConnect";
import { OpenDialogConnect } from "~/components/Dialog/OpenDialogConnect";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import styles from "./style.module.less";
interface OpenDialogImageProps {
    image: string;
    imageAlt: string;
    thumbnailWidth: number;
    thumbnailHeight: number;
    summary?: React.ReactNode;
}
class OpenDialogImageInternal extends React.Component<OpenDialogImageProps & OpenDialogProps, never> {
    private uniqueId: string;
    constructor(props: OpenDialogImageProps & OpenDialogProps) {
        super(props);
        this.uniqueId = dialogIdGenerator.next();
    }
    render() {
        const isOpen = this.props.openDialogs[this.uniqueId];
        const { image, imageAlt, thumbnailWidth, thumbnailHeight, summary } = this.props;
        return (<div className={styles.buttonDialogContainer}>
                <a href="#" onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                e.preventDefault();
                this.props.openDialog(this.uniqueId);
            }}>
                    <img src={image} alt={imageAlt} width={thumbnailWidth} height={thumbnailHeight}/>
                </a>
                <Dialog open={isOpen} wide={true}>
                    <InfoDialogLayout title={imageAlt} errors={undefined} busy={undefined}>
                        {summary}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={image} alt={imageAlt} width={"100%"}/>
                        </div>
                    </InfoDialogLayout>
                </Dialog>
            </div>);
    }
    static displayName = "OpenDialogImageInternal";
}
const OpenDialogImage = OpenDialogConnect.to(OpenDialogImageInternal);
export default OpenDialogImage;
