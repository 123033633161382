import { SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import { OctoLink } from "@octopusdeploy/utilities";
import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { useExternalLinksWithAnalytics } from "~/components/Navigation/ExternalLink/useExternalLinksWithAnalytics";
export function TentacleDownloadButton() {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const menuItems = useExternalLinksWithAnalytics([
        { type: "external-link", label: "Windows 32-bit/x86 installer", href: OctoLink.Create("LatestTentacleWinX86") },
        { type: "external-link", label: "Windows 64-bit/x64 installer", href: OctoLink.Create("LatestTentacleWinX64") },
        { type: "external-link", label: "Need a custom version?", href: OctoLink.Create("TentacleDownloadPage") },
    ]);
    return (<>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Download Tentacle" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <SimpleMenu compact={true} menuState={menuState} items={menuItems} accessibleName={"Download tentacle"}/>
        </>);
}
