import type { TriggerResource } from "@octopusdeploy/octopus-server-client";
import { TriggerActionType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import PaperLayout from "~/components/PaperLayout/index";
import routeLinks from "~/routeLinks";
interface ProjectTriggerRedirectState extends DataBaseComponentState {
    redirectTo: string;
}
interface ProjectTriggerRedirectProps {
    triggerId: string;
}
export class ProjectTriggerRedirect extends DataBaseComponent<ProjectTriggerRedirectProps, ProjectTriggerRedirectState> {
    constructor(props: ProjectTriggerRedirectProps) {
        super(props);
        this.state = {
            redirectTo: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectTrigger = await repository.ProjectTriggers.get(this.props.triggerId);
            const redirectTo = this.getRedirectTo(projectTrigger);
            this.setState({
                redirectTo,
            });
        });
    }
    getRedirectTo(projectTrigger: TriggerResource) {
        const projectId = projectTrigger.ProjectId;
        switch (projectTrigger.Action.ActionType) {
            case TriggerActionType.AutoDeploy:
                return routeLinks.project(projectId).trigger(projectTrigger.Id);
            case TriggerActionType.DeployLatestRelease:
            case TriggerActionType.DeployNewRelease:
                return routeLinks.project(projectId).scheduledTrigger(projectTrigger.Id);
            case TriggerActionType.RunRunbook:
                return routeLinks.project(projectId).operations.scheduledTrigger(projectTrigger.Id);
        }
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={{ pathname: this.state.redirectTo }}/>;
    }
    static displayName = "ProjectTriggerRedirect";
}
