import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonInfrastructureMachinePolicyOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingMachinePoliciesLearnMore">Machine policies</ExternalLink> are groups of settings that can be applied to Tentacle and SSH endpoints to modify their behavior.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonInfrastructureMachinePolicyOverview.displayName = "CommonInfrastructureMachinePolicyOverview"
export const CommonInfrastructureMachinePolicyTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingMachinePoliciesLearnMore">Machine Policies</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonInfrastructureMachinePolicyTopics.displayName = "CommonInfrastructureMachinePolicyTopics"
