import type { ResourceCollection, ProxyResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import List from "~/components/List";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
class ProxyList extends List<ProxyResource> {
}
interface ProxiesLayoutInnerProps extends RouteComponentProps<void> {
    initialData: InitialData;
}
interface InitialData {
    proxiesResponse: ResourceCollection<ProxyResource>;
}
const Title = "Machine Proxies";
const ProxiesLayoutFormPage = FormPage<InitialData>();
const ProxiesLayout: React.FC<RouteComponentProps<void>> = (props: RouteComponentProps<void>) => {
    return (<ProxiesLayoutFormPage title={Title} load={async () => {
            return { proxiesResponse: await repository.Proxies.list() };
        }} renderWhenLoaded={(data) => <ProxiesLayoutInner initialData={data} {...props}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
ProxiesLayout.displayName = "ProxiesLayout"
class ProxiesLayoutInner extends DataBaseComponent<ProxiesLayoutInnerProps, DataBaseComponentState> {
    constructor(props: ProxiesLayoutInnerProps) {
        super(props);
        this.state = {};
    }
    render() {
        const addButton = (<PermissionCheck permission={Permission.ProxyCreate}>
                <ActionList actions={[
                <ToolTip content={"Modify the proxy used by the Octopus Server for web requests"}>
                            <NavigationButton type={NavigationButtonType.Secondary} label="Modify Web Request Proxy" href={routeLinks.configuration.setting("server-proxy")}/>
                        </ToolTip>,
                <NavigationButton type={NavigationButtonType.Primary} label="Add Proxy" href={routeLinks.infrastructure.proxies.create}/>,
            ]}/>
            </PermissionCheck>);
        return (<InfrastructureLayout {...this.props}>
                <PaperLayout title={Title} sectionControl={addButton}>
                    <ProxyList initialData={this.props.initialData.proxiesResponse} onRow={(item: ProxyResource) => this.buildProxyRow(item)} onFilter={this.Filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} match={this.props.match} filterHintText="Filter by name..."/>
                </PaperLayout>
            </InfrastructureLayout>);
    }
    private Filter(filter: string, resource: ProxyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildProxyRow(proxy: ProxyResource) {
        return <ListTitle>{proxy.Name}</ListTitle>;
    }
    static displayName = "ProxiesLayoutInner";
}
export default ProxiesLayout;
