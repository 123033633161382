import type { PackageReference } from "@octopusdeploy/step-inputs";
import type { RuntimePackageSelection } from "@octopusdeploy/step-runtime-inputs";
export function convertToRuntimePackageSelection(packageSelection: PackageReference): RuntimePackageSelection {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return packageSelection as unknown as RuntimePackageSelection;
}
export function convertFromRuntimePackageSelection(packageSelection: RuntimePackageSelection): PackageReference {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return packageSelection as unknown as PackageReference;
}
