/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { GetBlockingTasksResponse, SpaceResource, TaskDetailsResource, TaskResource, TaskStatusMessages } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import TaskProgress from "~/areas/tasks/components/Task/TaskProgress";
import TaskQueueCallout from "~/areas/tasks/components/Task/TaskQueueCallout";
import TaskQueueMessages from "~/areas/tasks/components/Task/TaskQueueMessages";
import TaskQueueOrderedList from "~/areas/tasks/components/Task/TaskQueueOrderedList";
import styles from "~/areas/tasks/components/Task/TaskSummary/style.module.less";
import { client, repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PageDivider from "~/components/PageDivider/index";
import Section from "~/components/Section/index";
interface TaskQueueProps {
    task: TaskResource;
    taskDetails: TaskDetailsResource;
}
interface TaskQueueState {
    tasksAtLimit: TaskResource[];
    queueTasks: TaskResource[];
    space?: SpaceResource;
    messages?: TaskStatusMessages;
}
const taskQueueDefaultState: TaskQueueState = {
    tasksAtLimit: [],
    queueTasks: [],
    space: undefined,
    messages: undefined,
};
export default class TaskQueue extends DataBaseComponent<TaskQueueProps, TaskQueueState & DataBaseComponentState> {
    constructor(props: TaskQueueProps) {
        super(props);
        this.state = { ...taskQueueDefaultState };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.startRefreshLoop(this.refresh, 5000));
    }
    refresh = async () => {
        const { task } = this.props;
        if (task.HasBeenPickedUpByProcessor) {
            return { ...taskQueueDefaultState };
        }
        const spaceRequest = repository.Spaces.get(this.props.task.SpaceId!);
        const messagesRequest = repository.Tasks.getTaskStatusMessages(this.props.task);
        const blockingTasks = await client.get<GetBlockingTasksResponse>(task.Links["BlockedBy"]);
        return {
            tasksAtLimit: blockingTasks.TasksAtLimit,
            queueTasks: blockingTasks.TasksInQueue,
            space: await spaceRequest,
            messages: await messagesRequest,
        };
    };
    render() {
        const { queueTasks, messages, space, tasksAtLimit } = this.state;
        if (queueTasks === undefined) {
            return null;
        }
        const { task, taskDetails } = this.props;
        if (task.HasBeenPickedUpByProcessor || task.HasPendingInterruptions) {
            return null;
        }
        return (<>
                <TaskQueueMessages messages={messages}/>
                <TaskQueueCallout space={space} queueLength={queueTasks.length} tasksAtLimit={tasksAtLimit}/>
                <PageDivider>&nbsp;</PageDivider>
                <Section sectionHeader="Task Progress">
                    <div className={styles.taskProgress}>
                        <TaskProgress details={taskDetails}/>
                    </div>
                    <TaskQueueOrderedList currentTask={task} tasksInQueue={queueTasks}/>
                </Section>
            </>);
    }
    static displayName = "TaskQueue";
}
