import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import DataLoader from "~/components/DataLoader";
import { getPlaceholderActionList } from "../Common/CommonProcessHelpers";
import { ProcessPaperLayout } from "../CustomPaperLayouts/ProcessPaperLayout";
interface ProcessContextFormPageProps<TData> {
    title: string;
    load(): Promise<TData>;
    renderWhenLoaded(data: TData): React.ReactNode;
    processType: ProcessType;
}
export function ProcessContextFormPage<TData>() {
    const DataLoaderInternal = DataLoader<TData>();
    return class LoadInner extends React.Component<ProcessContextFormPageProps<TData>> {
        render() {
            return (<DataLoaderInternal load={this.props.load} renderWhenLoaded={this.props.renderWhenLoaded} renderAlternate={({ errors, busy }) => <ProcessPaperLayout processType={this.props.processType} title={this.props.title} errors={errors} busy={busy} sectionControl={getPlaceholderActionList(this.props.processType)}/>}/>);
        }
    };
}
