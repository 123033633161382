import { isDeploymentTarget } from "@octopusdeploy/octopus-server-client";
import type { VariablesScopedToDocumentResource, MachineResource, BasicRepository, NewMachineResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { SinglyScopedVariableWarnings } from "~/areas/variables/SinglyScopedVariableWarnings/SinglyScopedVariableWarnings";
import { ScopeType } from "~/areas/variables/VariableSorting/sortVariables";
import { repository } from "~/clientInstance";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
interface DeleteMachineDialogLayoutProps<TResource extends MachineResource, TNewResource extends NewMachineResource> {
    machine: MachineResource;
    machineTypeFriendlyName: string;
    repository: BasicRepository<TResource, TNewResource>;
    onDelete(): void;
}
export const DeleteMachineDialogLayout: React.FC<DeleteMachineDialogLayoutProps<MachineResource, NewMachineResource>> = (props) => {
    const [variablesScopedToThisMachine, setVariablesScopedToThisMachine] = useState<VariablesScopedToDocumentResource>();
    const [isLoading, setIsLoading] = useState(true);
    const onLoad = async () => {
        setVariablesScopedToThisMachine(variablesScopedToThisMachine);
        if (isDeploymentTarget(props.machine)) {
            const variablesScopedToThisMachine = (await repository.Machines.variablesScopedOnlyToThisDeploymentTarget(props.machine)).Resource;
            setVariablesScopedToThisMachine(variablesScopedToThisMachine);
        }
        setIsLoading(false);
    };
    const onDeleteClick = async () => {
        await props.repository.del(props.machine);
        props.onDelete();
        return true;
    };
    return (<DeleteDialogLayout title={`Are you sure you want to delete this ${props.machineTypeFriendlyName}?`} onDeleteClick={onDeleteClick} onLoad={onLoad} renderContent={() => (<React.Fragment>
                    {variablesScopedToThisMachine && <SinglyScopedVariableWarnings variablesScopedToThisDocument={variablesScopedToThisMachine} documentType={ScopeType.Machine}/>}
                    {!isLoading && (<React.Fragment>
                            <p>Deleting this machine is permanent. There is no going back.</p>
                            <p>Do you wish to continue?</p>
                        </React.Fragment>)}
                </React.Fragment>)}></DeleteDialogLayout>);
};
DeleteMachineDialogLayout.displayName = "DeleteMachineDialogLayout"
