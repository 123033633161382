import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import { useTrackedPageEventDispatcher } from "~/analytics/TrackedPageEventDispatcher";
import { createActionTypeField, createBoolField, createResourceTypeField } from "~/analytics/fields";
import { GitEventFieldKeys } from "./GitEventFields";
export interface GitSettingsUpdateOptions {
    hasProtectedBranches: boolean;
    updateAction: (cb: AnalyticErrorCallback) => Promise<ProjectResource>;
}
export function useNotifyGitSettingsUpdate(): (options: GitSettingsUpdateOptions) => Promise<ProjectResource> {
    const dispatcher = useTrackedPageEventDispatcher();
    return React.useCallback((options: GitSettingsUpdateOptions) => dispatcher(
    // Even though we use 'Git' rather than 'VCS' throughout the application,
    // these events should retain their existing name so that usages in Amplitude
    // (graphs/reports etc.) keep working as expected.
    "Update VCS Settings", {
        ...createResourceTypeField("Version Control Settings"),
        ...createActionTypeField(Action.Commit),
        ...createBoolField(GitEventFieldKeys.ProtectedBranches, options.hasProtectedBranches),
    }, options.updateAction), [dispatcher]);
}
