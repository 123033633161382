import { pageId } from "@octopusdeploy/portal-routes";
export default () => {
    return {
        root: pageId({
            area: "Configuration",
            id: "Configuration",
            name: "Configuration",
        }),
        userInvites: pageId({ area: "Configuration", id: "Configuration.UserInvites", name: "Invite Users" }),
        auditBaseRoute: pageId({ area: "Configuration", id: "Configuration.Audit", name: "Audit" }),
        auditArchive: pageId({
            area: "Configuration",
            id: "Configuration.AuditArchive",
            name: "Audit Archive",
        }),
        audit: pageId({ area: "Configuration", id: "Configuration.Audit", name: "Audit" }),
        machineEventsForUser: pageId({ area: "Configuration", id: "Configuration.AuditMachine", name: "Audit Machine" }),
        eventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditProject", name: "Audit Project" }),
        eventsForTenant: pageId({ area: "Configuration", id: "Configuration.AuditTenant", name: "Audit Tenant" }),
        eventsForEnvironment: pageId({ area: "Configuration", id: "Configuration.AuditEnvironment", name: "Audit Environment" }),
        eventsForUser: pageId({ area: "Configuration", id: "Configuration.AuditUser", name: "Audit User" }),
        eventsRegardingAny: pageId({ area: "Configuration", id: "Configuration.AuditRegardingAny", name: "Audit Any Document" }),
        deploymentProcessEventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditDeploymentProcess", name: "Audit Deployment Process" }),
        variableSetEventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditVariableSet", name: "Audit Variable Set" }),
        libraryVariableSetEventsRegardingAny: pageId({ area: "Configuration", id: "Configuration.AuditLibraryVariableSet", name: "Audit Library Variable Set" }),
        backup: pageId({ area: "Configuration", id: "Configuration.Backup", name: "Backup" }),
        git: pageId({ area: "Configuration", id: "Configuration.Git", name: "Git" }),
        features: pageId({ area: "Configuration", id: "Configuration.Features", name: "Features" }),
        license: pageId({ area: "Configuration", id: "Configuration.License", name: "License" }),
        smtp: pageId({ area: "Configuration", id: "Configuration.Smtp", name: "SMTP" }),
        nodes: {
            root: pageId({ area: "Configuration", id: "Configuration.Nodes", name: "Nodes" }),
            serverSettings: pageId({ area: "Configuration", id: "Configuration.NodesServerSettings", name: "Node Server Settings" }),
            config: pageId({ area: "Configuration", id: "Configuration.NodesConfig", name: "Nodes Configuration" }),
        },
        thumbprint: pageId({
            area: "Configuration",
            id: "Configuration.Thumbprint",
            name: "Thumbprint",
        }),
        maintenance: pageId({ area: "Configuration", id: "Configuration.Maintenance", name: "Maintenance" }),
        letsEncrypt: {
            root: pageId({ area: "Configuration", id: "Configuration.LetsEncrypt", name: "Let's Encrypt" }),
            configure: pageId({ area: "Configuration", id: "Configuration.LetsEncryptConfigure", name: "Configure Let's Encrypt" }),
        },
        testPermissions: pageId({ area: "Configuration", id: "Configuration.TestPermissions", name: "Test Permissions" }),
        testPermission: pageId({ area: "Configuration", id: "Configuration.TestPermission", name: "Test Permission" }),
        subscriptions: {
            root: pageId({ area: "Configuration", id: "Configuration.Subscriptions", name: "Subscriptions" }),
            create: pageId({ area: "Configuration", id: "Configuration.SubscriptionsNew", name: "Add New Subscription" }),
        },
        subscription: pageId({ area: "Configuration", id: "Configuration.Subscription", name: "Subscription" }),
        users: {
            root: pageId({ area: "Configuration", id: "Configuration.Users", name: "Users" }),
            create: pageId({ area: "Configuration", id: "Configuration.UsersNew", name: "Add New User" }),
        },
        user: pageId({ area: "Configuration", id: "Configuration.User", name: "User" }),
        spaces: {
            root: pageId({ area: "Configuration", id: "Configuration.Spaces", name: "Spaces" }),
            create: pageId({ area: "Configuration", id: "Configuration.SpacesNew", name: "Add New Space" }),
        },
        space: pageId({ area: "Configuration", id: "Configuration.Space", name: "Space" }),
        teams: {
            root: pageId({ area: "Configuration", id: "Configuration.Teams", name: "Teams" }),
            redirect: pageId({ area: "Configuration", id: "Configuration.TeamsRedirect", name: "Teams Redirect" }),
        },
        team: pageId({ area: "Configuration", id: "Configuration.Team", name: "Team" }),
        roles: {
            root: pageId({ area: "Configuration", id: "Configuration.Roles", name: "User Roles" }),
            create: pageId({ area: "Configuration", id: "Configuration.RolesNew", name: "Add New User Role" }),
        },
        role: pageId({ area: "Configuration", id: "Configuration.Role", name: "User Role" }),
        diagnostics: {
            root: pageId({ area: "Configuration", id: "Configuration.Diagnostics", name: "Diagnostics" }),
            logs: {
                root: pageId({ area: "Configuration", id: "Configuration.DiagnosticLogs", name: "Diagnostic Logs" }),
                autoDeploy: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsAutoDeploy",
                    name: "Auto Deploy Diagnostic Logs",
                }),
                scheduledDeploy: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsScheduledDeploy",
                    name: "Scheduled Deploy Diagnostic Logs",
                }),
                machineCleanup: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsMachineCleanup",
                    name: "Machine Cleanup Diagnostic Logs",
                }),
                subscription: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsSubscription",
                    name: "Subscription Diagnostic Logs",
                }),
            },
        },
        settings: {
            root: pageId({ area: "Configuration", id: "Configuration.Settings", name: "Settings" }),
        },
        extensions: {
            root: pageId({ area: "Configuration", id: "Configuration.Extensions", name: "Extensions" }),
        },
        setting: (settingId: string) => {
            return {
                root: pageId({ area: "Configuration", id: `Configuration.Setting.${settingId}`, name: "Setting" }),
            };
        },
        performance: pageId({ area: "Configuration", id: "Configuration.Performance", name: "Performance" }),
        telemetry: pageId({ area: "Configuration", id: "Configuration.Telemetry", name: "Telemetry" }),
    };
};
