import runbookRunRouteLinks from "~/areas/runbookRuns/runbookRunRouteLinks";
import configurationRouteLinks from "./areas/configuration/components/ConfigurationRoutes/configurationRouteLinks";
import dashboardRouteLinks from "./areas/dashboard/DashboardOverview/dashboardRouteLinks";
import deploymentRouteLinks from "./areas/deployments/deploymentRouteLinks";
import infrastructureRouteLinks from "./areas/infrastructure/components/InfrastructureRoutes/infrastructureRouteLinks";
import insightsRouteLinks from "./areas/insights/components/InsightsRoutes/insightsRouteLinks";
import libraryRouteLinks from "./areas/library/components/LibraryRoutes/libraryRouteLinks";
import projectGroupRouteLinks from "./areas/projects/components/ProjectGroupLayout/projectGroupRouteLinks";
import projectRouteLinks from "./areas/projects/components/ProjectsRoutes/projectRouteLinks";
import taskRouteLinks from "./areas/tasks/components/TaskRoutes/taskRouteLinks";
import tenantRouteLinks from "./areas/tenants/TenantsRoutes/tenantRouteLinks";
import userProfileRouteLinks from "./areas/users/UserProfileRoutes/userProfileRouteLinks";
// This regex is used in the function resolveStringPathWithSpaceId,
// such that it gets replaced with the spaceId from the route at link navigation time
const routeLinks = parameterizedRouteLinks(":spaceId(Spaces-[0-9]+)?");
function spaceRouteLinks(spaceId: string | null | undefined) {
    return parameterizedRouteLinks(spaceId);
}
function routeLinksWithoutSpace() {
    return parameterizedRouteLinks();
}
function parameterizedRouteLinks(spaceId?: string | null | undefined) {
    const root = spaceId ? `/${spaceId}` : "";
    return {
        forSpace: spaceRouteLinks,
        withoutSpace: routeLinksWithoutSpace,
        root,
        dashboard: dashboardRouteLinks(root),
        ...projectRouteLinks(root),
        ...tenantRouteLinks(root),
        ...taskRouteLinks(root),
        ...projectGroupRouteLinks(root),
        ...deploymentRouteLinks(root),
        ...runbookRunRouteLinks(root),
        currentUser: userProfileRouteLinks(root, ""),
        library: libraryRouteLinks(root),
        infrastructure: infrastructureRouteLinks(root),
        configuration: configurationRouteLinks(root),
        space: (id: string) => `/${id}`,
        styleGuide: `${root}/style-guide`,
        uxGuide: {
            root: `${root}/ux-guide`,
            guidelines: {
                root: `${root}/ux-guide/guidelines`,
                overview: `${root}/ux-guide/guidelines/overview`,
                guidelines: `${root}/ux-guide/guidelines/guidelines`,
                cheatsheet: `${root}/ux-guide/guidelines/cheatsheet`,
            },
            patterns: {
                root: `${root}/ux-guide/patterns`,
                overview: `${root}/ux-guide/patterns/overview`,
                whatever: `${root}/ux-guide/patterns/whatever`,
            },
        },
        legacy: {
            environments: `${root}/environments`,
            accounts: `${root}/accounts`,
            machines: `${root}/machines`,
            manage: {
                console: `${root}/manage/console`,
            },
        },
        noPermissions: `${root}/noPermissions`,
        insights: insightsRouteLinks(root),
    };
}
export default routeLinks;
