import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentLeadTimeOverview, CommonInsightsDeploymentLeadTimeTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentLeadTimeViews";
import pageIds from "~/pageIds";
export const ProjectInsightDeploymentLeadTimeRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.leadTime,
    renderOverview: (props) => (<>
            <CommonInsightsDeploymentLeadTimeOverview /> <CommonProjectInsightsHelp />
        </>),
    renderTopics: (props) => <CommonInsightsDeploymentLeadTimeTopics />,
};
