import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../routeLinks";
import DeploymentToProjectTaskRedirect from "./DeploymentToProjectTaskRedirect";
export default class DeploymentRoutes extends React.Component<RouteComponentProps<{}>> {
    render() {
        return (<Switch>
                <ReloadableRoute path={routeLinks.deployment(":deploymentId").root} component={DeploymentToProjectTaskRedirect}/>
            </Switch>);
    }
    static displayName = "DeploymentRoutes";
}
