/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Collapse } from "@material-ui/core";
import cn from "classnames";
import type { Reducer } from "react";
import * as React from "react";
import { useReducer } from "react";
import type { ActionType } from "typesafe-actions";
import { getType, createAction } from "typesafe-actions";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import Section from "~/components/Section";
import { useOctopusTheme } from "~/components/Theme";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs";
import { combineConstantReducers } from "~/utils/Reducers/combineConstantReducers";
import styles from "./PackagingInstructionSelector.module.less";
import type { PackagingRegistration, PackagingInstruction } from "./Registry";
import { usePackagingInstructions, usePackagingRegistrations } from "./Registry";
import usePackagingRegistration from "./Registry/usePackageRegistration";
//#region state
type RegistrationState = string;
type InstructionState = string;
interface PackagingSelectorState {
    selectedRegistration?: RegistrationState;
    selectedInstruction?: InstructionState;
}
const PackagingSelectorActions = {
    selectRegistration: createAction("SELECT/REGISTRATION", (registration: PackagingRegistration) => registration)(),
    selectInstruction: createAction("SELECT/INSTRUCTION", (instruction: PackagingInstruction) => instruction)(),
};
type PackagingSelectorAction = ActionType<typeof PackagingSelectorActions>;
const autoSelectInstruction = (current: string, instructions: PackagingInstruction[]) => {
    const sortedInstructions = instructions.sort((a, b) => a.displayOrder - b.displayOrder);
    const firstInstruction = sortedInstructions[0] && sortedInstructions[0].name;
    if (!current) {
        return firstInstruction;
    }
    const found = sortedInstructions.filter((x) => current === x.name);
    return found.length > 0 ? found[0].name : firstInstruction;
};
const registrationReducer: Reducer<RegistrationState | undefined, PackagingSelectorAction> = (state = null!, action) => {
    switch (action.type) {
        case getType(PackagingSelectorActions.selectRegistration):
            return action.payload ? action.payload.id : null!;
    }
    return state;
};
const instructionReducer: Reducer<InstructionState | undefined, PackagingSelectorAction> = (state = null!, action) => {
    switch (action.type) {
        case getType(PackagingSelectorActions.selectRegistration):
            return autoSelectInstruction(state, action.payload && action.payload.instructions);
        case getType(PackagingSelectorActions.selectInstruction):
            return action.payload ? action.payload.name : null!;
    }
    return state;
};
const packagingSelectorReducer = combineConstantReducers<PackagingSelectorState, PackagingSelectorAction>({
    selectedRegistration: registrationReducer,
    selectedInstruction: instructionReducer,
});
//#endregion
export const PackagingGroupTitle: React.SFC<{
    className?: string;
}> = ({ className, children }) => <div className={cn(styles.groupHeading, className)}>{children}</div>;
PackagingGroupTitle.displayName = "PackagingGroupTitle"
export const PackagingGroupHelp: React.SFC<{
    className?: string;
}> = ({ className, children }) => <div className={cn(styles.groupHelp, className)}>{children}</div>;
PackagingGroupHelp.displayName = "PackagingGroupHelp"
type ActiveItemButtonProps = {
    active: boolean;
    label: string;
    onClick?: () => void;
    style?: object;
    icon?: React.ComponentType;
};
const ActiveItemButton: React.SFC<ActiveItemButtonProps> = ({ onClick, label, active, style }) => {
    const theme = useOctopusTheme();
    const labelProps = active ? { color: theme.whiteConstant } : undefined;
    return <ActionButton type={ActionButtonType.Category} labelProps={labelProps} label={label} onClick={onClick} className={cn({ [styles.active]: active })} style={style}/>;
};
ActiveItemButton.displayName = "ActiveItemButton"
const PackagingActions: React.SFC<{
    className?: string;
}> = ({ children, className }) => <div className={cn(styles.actions, className)}>{children}</div>;
PackagingActions.displayName = "PackagingActions"
interface PackagingInstructionViewProps {
    registrationId: string;
    instruction: string;
    onSelect: (instruction: PackagingInstruction) => void;
}
const PackagingInstructionView: React.FC<PackagingInstructionViewProps> = ({ registrationId, onSelect, instruction }) => {
    const instructions = usePackagingInstructions(registrationId).sort((a, b) => a.displayOrder - b.displayOrder);
    const registration = usePackagingRegistration(registrationId);
    const handleSelect = (value: string) => {
        const found = instructions.filter((x) => x.name === value);
        if (found.length > 0) {
            onSelect(found[0]);
        }
    };
    return (<Collapse in={!!instruction} timeout="auto" unmountOnExit={true}>
            {instruction && (<div className={styles.contentContainer}>
                    <div className={styles.instructionsIntro}>
                        How would you like to package this <b>{registration.name}</b> application?
                    </div>
                    <Section>
                        <ControlledTabsContainer value={instruction} onChange={handleSelect}>
                            {instructions.map((x) => (<TabItem label={x.name} value={x.name} key={x.name}>
                                    <TransitionAnimation key={`${registrationId}-${instruction}`}>{x.render()}</TransitionAnimation>
                                </TabItem>))}
                        </ControlledTabsContainer>
                    </Section>
                </div>)}
        </Collapse>);
};
PackagingInstructionView.displayName = "PackagingInstructionView"
type PackagingInstructionSelectorProps = {};
export const PackagingInstructionSelector: React.SFC<PackagingInstructionSelectorProps> = (props) => {
    const registrations = usePackagingRegistrations();
    const [state, dispatch] = useReducer(packagingSelectorReducer, { selectedInstruction: null!, selectedRegistration: null! });
    return (<>
            <div>
                What type of <b>software application</b> do you want to package?
            </div>
            <Section>
                <PackagingActions className={styles.paperActions}>
                    {registrations.map((x) => (<ActiveItemButton label={x.name} onClick={() => dispatch(PackagingSelectorActions.selectRegistration(x))} key={x.id} active={state.selectedRegistration === x.id}/>))}
                </PackagingActions>
            </Section>
            <PackagingInstructionView onSelect={(instruction) => dispatch(PackagingSelectorActions.selectInstruction(instruction))} instruction={state.selectedInstruction!} registrationId={state.selectedRegistration!}/>
        </>);
};
PackagingInstructionSelector.displayName = "PackagingInstructionSelector"
