import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import UxGuidelines from "./UxGuidelines/UxGuidelines";
import UxGuidelinesCheatSheet from "./UxGuidelines/UxGuidelinesCheatSheet";
import UxGuidelinesOverview from "./UxGuidelines/UxGuidelinesOverview";
import UxGuidelinesLayout from "./UxGuidelinesLayout";
import UxPatternsOverview from "./UxPatterns/UxPatternsOverview";
import UxPatternsWhatever from "./UxPatterns/UxPatternsWhatever";
import UxPatternsLayout from "./UxPatternsLayout";
import { UxGuide } from ".";
const UxGuidelinesOverviewPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelinesOverview);
const UxGuidelinesPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelines);
const UxGuidelinesCheatSheetPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelinesCheatSheet);
const UxPatternsOverviewPage = renderWithLayout(UxPatternsLayout)(UxPatternsOverview);
const UxPatternsWhateverPage = renderWithLayout(UxPatternsLayout)(UxPatternsWhatever);
const UxGuidePage = withPage({ page: pageIds.uxGuide })(UxGuide);
export class UxRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        return (<Switch>
                <ReloadableRoute path={routeLinks.uxGuide.guidelines.root}>
                    <Switch>
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.guidelines.cheatsheet} component={UxGuidelinesCheatSheetPage}/>
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.guidelines.guidelines} component={UxGuidelinesPage}/>
                        <ReloadableRoute exact={true} path={[routeLinks.uxGuide.guidelines.overview, routeLinks.uxGuide.guidelines.root]} component={UxGuidelinesOverviewPage}/>
                        <RedirectAs404 />
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.uxGuide.patterns.root}>
                    <Switch>
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.patterns.whatever} component={UxPatternsWhateverPage}/>
                        <ReloadableRoute exact={true} path={[routeLinks.uxGuide.patterns.overview, routeLinks.uxGuide.patterns.root]} component={UxPatternsOverviewPage}/>
                        <RedirectAs404 />
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.uxGuide.root} component={UxGuidePage}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "UxRoutes";
}
export default UxRoutes;
