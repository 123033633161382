/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Switch } from "react-router-dom";
import DynamicEnvironmentSettings from "~/areas/projects/components/ProjectSettings/DynamicEnvironments/DynamicEnvironmentSettings";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import ProjectSettings from "./ProjectSettings";
import VersionControlSettings from "./VersionControl/VersionControlSettings";
const ProjectSettingsPage = withPage({ page: pageIds.project().settings.root })(ProjectSettings);
const VersionControlSettingsPage = withPage({ page: pageIds.project().settings.versionControl })(VersionControlSettings);
const DynamicEnvironmentSettingsPage = withPage({ page: pageIds.project().settings.dynamicEnvironments })(DynamicEnvironmentSettings);
const ProjectSettingsRoute: React.FC = (props: any) => {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const match = props.match;
    return (<Switch>
            <ReloadableRoute path={match.path} exact component={ProjectSettingsPage}/>
            <ReloadableRoute path={`${match.path}/versioncontrol`} component={VersionControlSettingsPage}/>
            {dynamicEnvironmentsEnabled && <ReloadableRoute path={`${match.path}/dynamicenvironments`} component={DynamicEnvironmentSettingsPage}/>}
            <InternalRedirect from={match.path} to={match.url}/>
        </Switch>);
};
ProjectSettingsRoute.displayName = "ProjectSettingsRoute"
export default ProjectSettingsRoute;
