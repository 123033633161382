import { logger } from "@octopusdeploy/logging";
import { getResolver } from "@octopusdeploy/octopus-server-client";
import type { AnalyticSession } from "@octopusdeploy/portal-analytics";
import { Environment } from "@octopusdeploy/utilities";
import amplitude from "amplitude-js";
import URI from "urijs";
import { anonymizeString } from "~/analytics/anonymizeString";
import type { ThemePaletteType } from "~/theme/index";
import { resolveApplicationRootUri } from "~/utils/resolveApplicationRootUri";
export class AmplitudeSession implements AnalyticSession {
    private api: amplitude.AmplitudeClient;
    public constructor(private readonly installationId: string, userId: string, initialTheme: ThemePaletteType) {
        this.api = amplitude.getInstance();
        this.api.options.apiEndpoint = resolveTelemetryEndpointFrom(window.location);
        this.api.options.forceHttps = !Environment.isInDevelopmentMode();
        // The real API token is injected in the Telemetry Proxy before forwarding to Amplitude, but Amplitude.JS requires one anyway.
        this.api.init("dummy-token");
        this.api.setUserId(userId);
        this.setTheme(initialTheme);
    }
    track<EventProperties extends {}>(name: string, event: EventProperties): void {
        this.api.logEvent(name, { ...event });
    }
    end() {
        try {
            this.api.setUserId(null);
            this.api.regenerateDeviceId();
        }
        catch (err) {
            logger.error(err, "(Amplitude) error ending session");
        }
    }
    setTheme(theme: ThemePaletteType) {
        this.api.setUserProperties({ Theme: theme });
    }
    anonymize(value: string): string {
        return anonymizeString(`${this.installationId}-${value}`);
    }
}
export function resolveTelemetryEndpointFrom(location: Location | string) {
    const baseUri = resolveApplicationRootUri(location);
    const endpointUri = new URI(getResolver(`${baseUri}`)("~/api/telemetry/process"));
    return `${endpointUri.host()}/${endpointUri.segment().join("/")}`;
}
