/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PollingTentacleEndpointResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { WindowsPollingTentacleCallout, LinuxPollingTentacleCallout } from "~/areas/infrastructure/components/TentacleCallout";
import NavigationButton, { NavigationButtonType } from "~/components/Button/NavigationButton";
import { PollingTarget } from "~/components/Images/MachineSettings/PollingTarget";
import { ExpandableFormSection, Summary, Text, Note } from "~/components/form";
import routeLinks from "~/routeLinks";
import LinuxCateogry from "./LinuxCategoryDefinition";
import { InfoRegistrationCards } from "./RegistrationCards";
import TentacleThumbprint from "./TentacleThumbprint";
import WindowsCategory from "./WindowsCategoryDefinition";
import type { MachineRegistration, CategorizedEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey, EndpointSelectionScope } from "./endpointRegistry";
import styles from "./styles.module.less";
interface TentacleActiveEndpointProps {
    endpoint: PollingTentacleEndpointResource;
    serverThumbprint: string | null;
    onChange(newValue: PollingTentacleEndpointResource): void;
}
export class TentacleActiveEndpoint extends React.Component<TentacleActiveEndpointProps> {
    render() {
        return (<div>
                <TentacleThumbprint endpoint={this.props.endpoint} serverThumbprint={this.props.serverThumbprint} onChange={(endpoint) => {
                this.props.onChange(endpoint as PollingTentacleEndpointResource);
            }}/>
                <ExpandableFormSection errorKey="Uri" title="Subscription ID" focusOnExpandAll summary={this.props.endpoint.Uri ? Summary.summary(this.props.endpoint.Uri) : Summary.placeholder("No uri specified")} help="The unique polling subscription id used by the Tentacle.">
                    <Text label="Subscription ID" placeholder="poll://abcdefghijklmnopqrst/" value={this.props.endpoint.Uri} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.Uri = x;
                this.props.onChange(endpoint);
            }} type="url"/>
                    <Note>The subscription id is a random 20 character id that is used to queue messages from the server to the Polling Tentacle. This should match the value in the Tentacle config file.</Note>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "TentacleActiveEndpoint";
}
const WindowsTentacleActiveDialogView: React.SFC<{}> = () => (<div>
        <WindowsPollingTentacleCallout />
        <h3 style={{ marginTop: "2rem" }}>After installation</h3>
        <p>The new Polling Tentacle will automatically show up in the Deployment Targets list.</p>
    </div>);
WindowsTentacleActiveDialogView.displayName = "WindowsTentacleActiveDialogView"
const LinuxTentacleActiveDialogView: React.SFC<{}> = () => (<div>
        <LinuxPollingTentacleCallout />
        <h3 style={{ marginTop: "2rem" }}>After installation</h3>
        <p>The new Polling Tentacle will automatically show up in the Deployment Targets list.</p>
    </div>);
LinuxTentacleActiveDialogView.displayName = "LinuxTentacleActiveDialogView"
const TentacleAdditionalActions: React.SFC<{
    scope: EndpointSelectionScope;
}> = ({ scope }) => {
    const deploymentTargetLink = scope === EndpointSelectionScope.DeploymentTarget;
    const workerLink = scope === EndpointSelectionScope.Worker;
    return (<React.Fragment>
            {deploymentTargetLink && (<NavigationButton key="deployment-targets" type={NavigationButtonType.Secondary} label="View Deployment Targets" href={routeLinks.infrastructure.machines.filtered({ commStyles: [CommunicationStyle.TentacleActive] })}/>)}
            {workerLink && <NavigationButton key="workers" type={NavigationButtonType.Secondary} label="View Workers" href={routeLinks.infrastructure.workerMachines.filtered({ commStyles: [CommunicationStyle.TentacleActive] })}/>}
        </React.Fragment>);
};
TentacleAdditionalActions.displayName = "TentacleAdditionalActions"
export default TentacleActiveEndpoint;
function createLogo(registration: CategorizedEndpointRegistration): React.ReactElement {
    return <PollingTarget className={styles.centreThumbnail} title={registration.name}/>;
}
const activeTentacleEndpointRegistration: MachineRegistration = {
    key: EndpointRegistrationKey.TentacleActive,
    displayOrder: 10,
    categories: [WindowsCategory, LinuxCateogry],
    name: "Polling Tentacle",
    communicationStyle: CommunicationStyle.TentacleActive,
    discoverable: false,
    renderCard: InfoRegistrationCards.basic(({ category }) => (category.category === WindowsCategory.category ? <WindowsTentacleActiveDialogView /> : <LinuxTentacleActiveDialogView />), ({ registration }) => ({
        logo: createLogo(registration),
        registrationName: registration.name,
        description: "A Polling target will actively poll the Octopus Server for tasks to perform.",
    }), ({ scope }) => <TentacleAdditionalActions scope={scope}/>),
};
export { activeTentacleEndpointRegistration };
