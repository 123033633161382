import * as React from "react";
import StringHelper from "~/utils/StringHelper";
import RunbooksNavigationTabs from "../RunbooksNavigationTabs";
import type { TabbedPaperLayoutProps } from "./TabbedPaperLayout";
import TabbedPaperLayout from "./TabbedPaperLayout";
type RunbooksPaperLayoutProps = TabbedPaperLayoutProps;
const RunbooksPaperLayout: React.FC<RunbooksPaperLayoutProps> = ({ tabNavigation = RunbooksNavigationTabs, breadcrumbTitle = "Runbooks", title = StringHelper.ellipsis, disableAnimations = true, fullWidth = true, ...rest }) => (<TabbedPaperLayout breadcrumbTitle={breadcrumbTitle} title={title} tabNavigation={tabNavigation} disableAnimations={disableAnimations} fullWidth={fullWidth} {...rest}/>);
RunbooksPaperLayout.displayName = "RunbooksPaperLayout"
export default RunbooksPaperLayout;
export { RunbooksPaperLayout };
