import cn from "classnames";
import * as React from "react";
import styles from "./CustomDialogTitleBar.module.less";
interface CustomDialogTitleProps {
    title: string;
    actions?: React.ReactNode;
    className?: string;
}
export const CustomDialogTitleBar: React.SFC<CustomDialogTitleProps> = ({ title, actions, className }) => {
    return (<div className={cn(styles.titleBar, className)}>
            <div className={styles.title}>{title}</div>
            <div className={styles.actions}>{actions}</div>
        </div>);
};
CustomDialogTitleBar.displayName = "CustomDialogTitleBar"
export default CustomDialogTitleBar;
