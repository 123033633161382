import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { Note } from "~/components/form";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer } from "./CommonViews";
const HelpImageTargets = require("../../Images/DeploymentTargets.svg");
export const CommonDeploymentTargetOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment targets</ExternalLink> represent the servers, machines and cloud services where your software and services will be deployed.
                </p>
                <p>
                    <ExternalVideoLink href="OnboardingDeploymentTargetsVideo"/>
                </p>
                <ImageWithPlaceholder src={HelpImageTargets} alt={"Deployment targets"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Target roles"}>
                    Multiple deployment targets can exist with an environment and these targets can be tagged with <ExternalLink href="TargetRoles">target roles</ExternalLink>. Target roles are used during the deployment process to specify which
                    targets are to be deployed to.
                </Note>
            </TermsText>
        </OverviewContainer>);
};
CommonDeploymentTargetOverview.displayName = "CommonDeploymentTargetOverview"
export const CommonDeploymentTargetTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="KubeTargets">Windows Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="SSHTargets">Linux Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="AzureTargets">Azure</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="">Kubernetes Cluster</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="OfflinePackageDrop">Offline Package Drops</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="CloudRegionTargets">Cloud Regions</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="TargetRoles">Target Roles</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="StepPackage">Step Package</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonDeploymentTargetTopics.displayName = "CommonDeploymentTargetTopics"
