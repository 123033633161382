import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import MissingImportExportPermissionCallout from "app/areas/projects/components/ImportExport/MissingImportExportPermissionCallout";
import * as React from "react";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import ImportExportMenu from "./ImportExportMenu";
import styles from "./style.module.less";
interface ImportExportTasksState extends DataBaseComponentState {
    space?: SpaceResource;
}
class ImportExportTasks extends DataBaseComponent<{}, ImportExportTasksState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    render() {
        const isSpaceManager = this.isSpaceManager();
        const overflowMenu = <ImportExportMenu />;
        return (<main id="maincontent">
                <AreaTitle title="Projects" link={routeLinks.projects.root}>
                    <ActionList actions={[overflowMenu]}/>
                </AreaTitle>
                <ContextualHelpLayout>
                    <div className={styles.container}>
                        <Tasks renderLayout={(layoutProps) => (<PaperLayout title="Import/Export Tasks" busy={layoutProps.busy} errors={this.errors} fullWidth={true}>
                                    {this.state.space && !isSpaceManager && <MissingImportExportPermissionCallout />}
                                    {this.state.space && isSpaceManager && (<>
                                            <div className={styles.layoutContainer}>{layoutProps.children}</div>
                                        </>)}
                                </PaperLayout>)} restrictToTaskTypes={["ExportProjects", "ImportProjects"]} hideScriptConsoleAction={true} hideAdvancedFilters={true}/>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "ImportExportTasks";
}
export default ImportExportTasks;
