/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import AllVariablesRoute from "./AllVariables/AllVariablesRoute";
import CommonVariableTemplates from "./CommonVariableTemplates";
import LibraryVariableSets from "./LibraryVariableSets";
import ProjectVariableTemplates from "./ProjectVariableTemplates";
import ProjectVariablesRoute from "./ProjectVariables/ProjectVariablesRoute";
import VariablePreviewRoute from "./VariablePreview/VariablePreviewRoute";
export const ProjectVariableTemplatesPage = withPage({ page: pageIds.project().variables.projectTemplates })(ProjectVariableTemplates);
export const CommonVariableTemplatesPage = withPage({ page: pageIds.project().variables.commonTemplates })(CommonVariableTemplates);
export const LibraryVariableSetsPage = withPage({ page: pageIds.project().variables.library })(LibraryVariableSets);
export default class VariablesRoute extends React.Component<RouteComponentProps<{}>> {
    render() {
        const match = this.props.match;
        return (<Switch>
                <AllVariablesRoute path={`${match.path}/all`}/>
                <ReloadableRoute path={`${match.path}/projectvariabletemplates`} component={ProjectVariableTemplatesPage}/>
                <ReloadableRoute path={`${match.path}/commonvariabletemplates`} component={CommonVariableTemplatesPage}/>
                <ReloadableRoute path={`${match.path}/library`} component={LibraryVariableSetsPage}/>
                <VariablePreviewRoute path={`${match.path}/preview`}/>
                <ProjectVariablesRoute path={`${match.path}`}/>
            </Switch>);
    }
    static displayName = "VariablesRoute";
}
