/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import * as React from "react";
import CopyValueToClipboard from "~/components/CopyToClipboardButton/CopyValueToClipboard";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomDialogContent, CustomSaveDialogActions, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { DatePicker } from "~/components/form";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import Select from "~/primitiveComponents/form/Select/Select";
import Text from "~/primitiveComponents/form/Text/Text";
enum ExpiryOffset {
    Never = "Never",
    ThirtyDays = "30 days",
    SixtyDays = "60 days",
    NinetyDays = "90 days",
    OneHundredAndEightyDays = "180 days (default)",
    OneYear = "1 year",
    TwoYears = "2 years",
    Custom = "Custom"
}
interface AddApiKeyState extends DataBaseComponentState {
    purpose: string;
    expiryOffset: ExpiryOffset;
    customExpiryDate?: Date;
}
interface AddApiKeyProps extends RenderProps {
    apiKey?: string;
    onAdd(purpose: string, expires: Date | null): Promise<any>;
    onClose(): void;
}
class AddApiKeyDialogLayout extends DataBaseComponent<AddApiKeyProps, AddApiKeyState> {
    constructor(props: AddApiKeyProps) {
        super(props);
        this.state = {
            purpose: "",
            expiryOffset: ExpiryOffset.OneHundredAndEightyDays,
        };
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return (<CustomSaveDialogLayout {...this.props} frame={SmallDialogFrame} close={this.props.close} open={this.props.open} renderTitle={() => <CustomSaveDialogTitleBar title="Generate New API Key"/>} busy={this.state.busy} errors={this.errors} onSaveClick={this.saveAndShowKey} renderActions={(renderProps) => (<CustomDialogActions actions={<CustomSaveDialogActions close={renderProps.close} saveButtonLabel={this.props.apiKey ? "Close" : "Generate New"} hideCancel={!!this.props.apiKey} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission}/>}/>)} renderContent={(renderProps) => <CustomDialogContent>{this.props.apiKey ? this.renderResult() : this.renderSetupApiKey()}</CustomDialogContent>}/>);
    }
    private saveAndShowKey = async () => {
        if (this.props.apiKey) {
            this.props.onClose();
            return false;
        }
        if (this.state.expiryOffset === ExpiryOffset.Custom && !this.state.customExpiryDate) {
            this.setValidationErrors("There was a problem with your request.", { customDate: "Please select a date" });
            return false;
        }
        const expires = this.determineExpiry();
        await this.doBusyTask(() => this.props.onAdd(this.state.purpose, expires));
        return false;
    };
    private changeExpiryOffset = (newValue?: string) => {
        this.clearErrors();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const expiryOffset = newValue as ExpiryOffset;
        this.setState({ expiryOffset, customExpiryDate: undefined });
    };
    private selectDate = (customExpiryDate?: Date) => {
        this.clearErrors();
        this.setState({ customExpiryDate });
    };
    private determineExpiry(): Date | null {
        if (this.state.expiryOffset === ExpiryOffset.Never) {
            return null;
        }
        if (this.state.expiryOffset === ExpiryOffset.Custom) {
            return moment(this.state.customExpiryDate).endOf("day").toDate();
        }
        return moment()
            .endOf("day")
            .add(...this.state.expiryOffset.split(" "))
            .toDate();
    }
    private renderResult() {
        return (this.props.apiKey && (<div>
                    <p>Your new API key is:</p>
                    <CopyValueToClipboard value={this.props.apiKey}/>
                    <p>API keys cannot be retrieved once they are created. Make sure you save this key in a safe place like a password management tool.</p>
                </div>));
    }
    private renderSetupApiKey() {
        const expiryItems: Item[] = [ExpiryOffset.Never, ExpiryOffset.ThirtyDays, ExpiryOffset.SixtyDays, ExpiryOffset.NinetyDays, ExpiryOffset.OneHundredAndEightyDays, ExpiryOffset.OneYear, ExpiryOffset.TwoYears, ExpiryOffset.Custom].map((o) => ({
            text: o,
            value: o,
        }));
        const showCustomExpiry = this.state.expiryOffset === ExpiryOffset.Custom;
        return (<div>
                <p>
                    API keys can be used to authenticate with the Octopus Deploy REST API in place of a username and password. Using API keys lets you keep your username and password secret, but the API key itself is still sensitive information that
                    needs to be protected.
                </p>
                <Text value={this.state.purpose} onChange={(purpose) => this.setState({ purpose })} autoFocus={true} label="Purpose"/>
                <p>Record the purpose of this key so that you can revoke it when no longer required.</p>
                <h4>When should this API key expire?</h4>
                <Select items={expiryItems} value={this.state.expiryOffset} onChange={this.changeExpiryOffset} label="Set expiry date" sortItems={false}/>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {showCustomExpiry && <DatePicker value={this.state.customExpiryDate!} onChange={this.selectDate} label="Set custom expiry date" minDate={new Date()} autoOk={true} error={this.getFieldError("customDate")}/>}
            </div>);
    }
    static displayName = "AddApiKeyDialogLayout";
}
export default AddApiKeyDialogLayout;
