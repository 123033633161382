/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { GitRefResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { BooleanRadioButtonGroup, RadioButton } from "~/components/form";
import CertificateVariableSelect from "~/components/form/CertificateSelect/CertificateVariableSelect";
import DialogFormSectionHeading from "~/components/form/Sections/DialogFormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import DeletableChip from "../../Chips/DeletableChip";
import { securityProtocolItems } from "./bindingHelpers";
import type { NginxBinding } from "./bindingHelpers";
const SecurityProtocolMultiSelect = MultiSelect<SelectItem>();
interface NginxBindingState extends DataBaseComponentState {
    binding: NginxBinding | null;
    managedByOctopus: boolean;
    project?: ProjectResource | null;
}
interface NginxBindingProps {
    binding: NginxBinding;
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    onAdd(Binding: NginxBinding): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
class NginxBindingDialog extends DataBaseComponent<NginxBindingProps, NginxBindingState> {
    constructor(props: NginxBindingProps) {
        super(props);
        this.state = {
            binding: null,
            managedByOctopus: true,
            project: null,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;
            this.setState({
                binding: this.props.binding,
                managedByOctopus: !this.props.binding.certificateLocation,
                project,
            });
        });
    }
    handleProtocolChanged = async (protocol: string | undefined) => {
        await this.doBusyTask(async () => {
            this.setBindingState({ protocol: protocol! });
            if (protocol === "https") {
                this.setBindingState({ port: "443" });
            }
        });
    };
    handleManagedByOctopusChanged = async (managed: boolean) => {
        await this.doBusyTask(async () => {
            if (!managed) {
                this.setState((state) => ({
                    binding: { ...state!.binding, certificateVariable: null },
                    managedByOctopus: managed,
                }));
            }
            else {
                this.setState((state) => ({
                    binding: { ...state!.binding, thumbprint: null },
                    managedByOctopus: managed,
                }));
            }
        });
    };
    save = () => {
        const binding = this.state.binding;
        if (this.state.binding!.protocol === "https" && !this.state.managedByOctopus) {
            if (!this.state.binding!.certificateLocation || this.state.binding!.certificateLocation.trim() === "") {
                this.setValidationErrors("An SSL certificate file must be provided for HTTPS bindings.");
                return false;
            }
        }
        return this.props.onAdd(binding!);
    };
    render() {
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Add Binding"}>
                {this.state.binding && (<div>
                        <BoundSelect variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"http"} value={this.state.binding.protocol} onChange={this.handleProtocolChanged} items={[
                    { value: "http", text: "HTTP" },
                    { value: "https", text: "HTTPS" },
                ]} placeholder="Protocol" label="Protocol"/>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.port} onChange={(x) => this.setBindingState({ port: x })} label="Port"/>
                        <Note>The TCP port number that this binding will listen on.</Note>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.ipAddress} onChange={(x) => this.setBindingState({ ipAddress: x })} label="IP address"/>
                        <Note>
                            The IP address that the binding will listen on. Use <code>*</code> for any address, or specify an address such as <code>10.0.0.1</code>. If using a IPv6 address remember to enclose in square brackets such as{" "}
                            <code>[::1]</code>.
                        </Note>

                        {this.state.binding.protocol !== "http" && (<div>
                                <DialogFormSectionHeading title="Certificate"/>
                                <BooleanRadioButtonGroup label="Is the HTTPS certificate managed by Octopus or externally?" value={this.state.managedByOctopus} onChange={this.handleManagedByOctopusChanged}>
                                    <RadioButton value={true} label="Certificate managed by Octopus" isDefault={true}/>
                                    <Note>If the certificate is managed by Octopus, it will be automatically imported into the Windows Certificate Store.</Note>
                                    <RadioButton value={false} label="Certificate managed externally"/>
                                    <Note>If managed externally, the certificate thumbprint is configured. The certificate must have been imported into the Windows Certificate Store.</Note>
                                </BooleanRadioButtonGroup>

                                {!this.state.managedByOctopus && (<div>
                                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.certificateLocation!} onChange={(x) => this.setBindingState({ certificateLocation: x })} label="Server certificate location"/>
                                        <Note>Location of the server certificate file to use.</Note>
                                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.certificateKeyLocation!} onChange={(x) => this.setBindingState({ certificateKeyLocation: x })} label="Server certificate private key location"/>
                                        <Note>Location of the server certificate private key file to use.</Note>
                                    </div>)}
                                {this.state.managedByOctopus && (<div>
                                        {this.state.project ? (<CertificateVariableSelect projectId={this.props.projectId} gitRef={this.props.gitRef} doBusyTask={this.doBusyTask} value={this.state.binding.certificateVariable} onChange={(x) => this.setBindingState({ certificateVariable: x })}/>) : (<VariableLookupText localNames={this.props.localNames} value={this.state.binding.certificateVariable} onChange={(x) => this.setBindingState({ certificateVariable: x })} label="Certificate variable"/>)}
                                        <Note>A certificate variable.</Note>
                                    </div>)}
                                <SecurityProtocolMultiSelect value={this.state.binding.securityProtocols!} items={securityProtocolItems} label="Enabled SSL protocols" renderChip={(i, d) => (<DeletableChip deleteButtonAccessibleName={`Delete ${i.Name}`} onRequestDelete={d}>
                                            {i.Name}
                                        </DeletableChip>)} onChange={(values: string[]) => {
                        this.setBindingState({ securityProtocols: values });
                    }}/>
                                <Note>
                                    <div>
                                        Since NGINX version <strong>1.0.5</strong>, the defaults used are: <code>SSLv3</code>, <code>TLSv1</code>.
                                    </div>
                                    <div>
                                        Since NGINX versions <strong>1.1.13</strong> and <strong>1.0.12</strong>, the defaults used are: <code>SSLv3</code>, <code>TLSv1</code>, <code>TLSv1.1</code> and <code>TLSv1.2</code>.
                                    </div>
                                </Note>
                            </div>)}

                        <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} title="Binding status" value={this.state.binding.enabled.toString()} onChange={(x) => this.setBindingState({ enabled: x as any })} label="Enabled" note={<span>Disable this binding to skip it (the binding will not be added).</span>}/>
                    </div>)}
            </OkDialogLayout>);
    }
    private setBindingState<K extends keyof NginxBinding>(state: Pick<NginxBinding, K>, callback?: () => void) {
        this.setChildState1("binding", state);
    }
    static displayName = "NginxBindingDialog";
}
export default NginxBindingDialog;
