/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { IProcessResource, IExecutionResource, Form, OctopusError, ISnapshotResource, ReleaseResource, TenantResource, TenantedDeploymentMode, RunbookRunPreviewResource, GetBffDeploymentPreview } from "@octopusdeploy/octopus-server-client";
import { isReleaseResource, isRunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, DoBusyTaskOptions } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import UnstructuredFormSection from "~/components/form/Sections/UnstructuredFormSection";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { DeploymentModelType } from "../../Runbooks/RunbookRunNowLayout";
import DeploymentResults from "./DeploymentResults/DeploymentResults";
import type { DeploymentRequestModel } from "./deploymentRequestModel";
import type { ActionToggleInfo } from "./deploymentStepsWorker";
import DeploymentStepsWorker from "./deploymentStepsWorker";
interface DeploymentPreviewProps {
    release: ISnapshotResource;
    tenantedDeploymentMode: TenantedDeploymentMode;
    stepActionIdsToSkip: string[];
    deployments: DeploymentRequestModel[];
    promptVariableForm: Form;
    tenantsWithMissingVariables: string[];
    allTenants: TenantResource[];
    modelType: DeploymentModelType;
    isExpandedByDefault?: boolean;
    getDeploymentPreview: (environmentId: string, tenantId: string) => GetBffDeploymentPreview | RunbookRunPreviewResource | undefined;
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
    onDoingBusyTask(action: () => Promise<any>, options: DoBusyTaskOptions): Promise<boolean>;
}
export interface DeploymentStepsDetails {
    deployment: DeploymentRequestModel;
    stepsForSelectedDeployment: ActionToggleInfo[];
    actions: ActionToggleInfo[];
}
interface DeploymentPreviewState extends DataBaseComponentState {
    selectedDeployment: DeploymentRequestModel;
    tenants: TenantResource[];
    isSingleDeployment: boolean;
    deploymentTargetType: DeploymentTargetType;
    process: IProcessResource;
    deploymentsAndSteps: DeploymentStepsDetails[];
}
export interface DeploymentMachineInfo {
    id: string;
    machineIds: string[];
    deploymentType: DeploymentType;
}
export enum DeploymentType {
    Environment,
    Tenant
}
export enum DeploymentTargetType {
    AllApplicable,
    IncludeSpecific,
    ExcludeSpecific
}
type Props = DeploymentPreviewProps & WithProjectContextInjectedProps;
class DeploymentPreviewInternal extends DataBaseComponent<Props, DeploymentPreviewState> {
    /**
     * Use memoization to remove unnecessary network calls as the UI is updated.
     * This allows us to regenerate the state by excluding or including machines,
     * and not call back to the server for deployment process info that will not
     * have changed.
     */
    repositoryDeploymentProcessesGet = _.memoize((release: ReleaseResource) => this.props.projectContext.state.projectContextRepository.DeploymentProcesses.getForRelease(release));
    repositoryRunbookProcessGet = _.memoize((id: string) => repository.RunbookProcess.get(id));
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedDeployment: null!,
            tenants: [],
            isSingleDeployment: false,
            deploymentTargetType: DeploymentTargetType.AllApplicable,
            process: null!,
            deploymentsAndSteps: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.loadData(this.props);
            await timeOperation(timeOperationOptions.for("DeploymentPreview"), () => this.loadChildData(this.props));
        });
    }
    async UNSAFE_componentWillReceiveProps(nextProps: DeploymentPreviewProps) {
        if (!(_.isEqual(nextProps.deployments, this.props.deployments) && _.isEqual(nextProps.release, this.props.release))) {
            await timeOperation(timeOperationOptions.for("DeploymentPreview"), () => this.loadChildData(nextProps));
        }
        this.loadData(nextProps);
    }
    render() {
        const numberOfDeployments = this.props.deployments.length;
        const deploymentsLabel = this.props.modelType === DeploymentModelType.Deployment ? "deployments" : "runs";
        return (<div>
                {numberOfDeployments > 0 && (<div>
                        <FormSectionHeading title="Preview and customize"/>
                        {!this.state.isSingleDeployment && (<UnstructuredFormSection>
                                <b>{numberOfDeployments}</b> {deploymentsLabel} will be created. These {deploymentsLabel} can be configured and previewed below.
                            </UnstructuredFormSection>)}
                        <DeploymentResults deployments={this.props.deployments} tenants={this.state.tenants} stepActionIdsToSkip={this.props.stepActionIdsToSkip} promptVariableForm={this.props.promptVariableForm} tenantsWithMissingVariables={this.props.tenantsWithMissingVariables} onIncludeSpecificMachinesSelected={this.props.onIncludeSpecificMachinesSelected} onExcludeSpecificMachinesSelected={this.props.onExcludeSpecificMachinesSelected} onAllTargetsSelected={this.props.onAllTargetsSelected} process={this.state.process} deploymentsAndSteps={this.state.deploymentsAndSteps} getDeploymentPreview={this.props.getDeploymentPreview} modelType={this.props.modelType} isExpandedByDefault={this.props.isExpandedByDefault}/>
                    </div>)}
            </div>);
    }
    private loadData(props: DeploymentPreviewProps) {
        const tenantIds = props.deployments.map((dep) => dep.tenantId);
        const tenants = props.allTenants.filter((tenant) => tenantIds.includes(tenant.Id));
        const isSingleDeployment = props.deployments.length === 1;
        const selectedDeployment = props.deployments[0];
        this.setState(() => {
            return {
                tenants,
                isSingleDeployment,
                selectedDeployment,
            };
        });
    }
    /**
     * Retrieve the information that child components like <DeploymentResultItem> and
     * <ActionPreview> will event ually use to display the actual steps and machines.
     * We gather this data here, instead of allowing the child elements to generate this
     * data themselves, to prevent unnecessary network calls in child component
     * componentDidMount() functions.
     *
     * See https://github.com/OctopusDeploy/Issues/issues/4193 for why this code
     * lives here.
     * @param props
     */
    private async loadChildData(props: DeploymentPreviewProps) {
        await this.loadProcess(props);
        const deploymentsAndSteps = this.loadInitialDeploymentSteps(props);
        this.updateDeploymentStepsAndActions(deploymentsAndSteps);
    }
    /**
     * process is used by ActionPreview
     * @param props
     */
    private async loadProcess(props: DeploymentPreviewProps) {
        // process is used by ActionPreview
        if (isReleaseResource(props.release)) {
            await this.repositoryDeploymentProcessesGet(props.release).then((process) => {
                this.setState(() => {
                    return {
                        process: process,
                    };
                });
            });
        }
        else if (isRunbookSnapshotResource(props.release)) {
            await this.repositoryRunbookProcessGet(props.release.FrozenRunbookProcessId).then((process) => {
                this.setState(() => {
                    return {
                        process: process,
                    };
                });
            });
        }
    }
    /**
     * Load the deployments without any steps or actions
     * @param props
     */
    private loadInitialDeploymentSteps(props: DeploymentPreviewProps): DeploymentStepsDetails[] {
        // deploymentsAndSteps is used by DeploymentResultItem
        // the stepsForSelectedDeployment and actions will be null
        // until they are resolved
        const resultMap = _.keyBy(props.deployments, (d) => this.deploymentKey(d));
        const deploymentsAndSteps = props.deployments
            .sort((a, b) => {
            const aIsError = resultMap[this.deploymentKey(a)] && this.isError(resultMap[this.deploymentKey(a)].response!);
            const bIsError = resultMap[this.deploymentKey(b)] && this.isError(resultMap[this.deploymentKey(b)].response!);
            return aIsError === bIsError ? 0 : aIsError ? -1 : 1;
        })
            .map((deployment) => ({ deployment, stepsForSelectedDeployment: null!, actions: null! }));
        // Display the UI with some initial info
        this.setState(() => {
            return {
                deploymentsAndSteps,
            };
        });
        return deploymentsAndSteps;
    }
    private updateDeploymentStepsAndActions(deploymentsAndSteps: DeploymentStepsDetails[]) {
        deploymentsAndSteps.forEach((deploymentDetails) => this.loadDeploymentStepsAndActions(deploymentDetails));
        this.setState((existingState) => {
            return {
                deploymentsAndSteps,
            };
        });
    }
    private loadDeploymentStepsAndActions(deploymentDetails: DeploymentStepsDetails) {
        deploymentDetails.stepsForSelectedDeployment = this.getActionToggleInfos(deploymentDetails.deployment.request.EnvironmentId, deploymentDetails.deployment.tenantId!);
        const deploymentInfo = deploymentDetails.deployment ? deploymentDetails.deployment.request : null;
        if (deploymentInfo) {
            deploymentDetails.actions = this.getActionToggleInfos(deploymentInfo.EnvironmentId, deploymentInfo.TenantId!);
        }
    }
    private getActionToggleInfos(environmentId: string, tenantId: string) {
        const deploymentPreview = this.props.getDeploymentPreview(environmentId, tenantId);
        if (deploymentPreview === undefined) {
            return [];
        }
        return DeploymentStepsWorker.getDeploymentSteps([deploymentPreview]);
    }
    private deploymentKey(deployment: DeploymentRequestModel) {
        return deployment.tenantId ? deployment.tenantId : deployment.request.EnvironmentId;
    }
    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return response && (response as OctopusError).ErrorMessage !== undefined;
    }
    static displayName = "DeploymentPreviewInternal";
}
export const DeploymentPreview = withProjectContext(DeploymentPreviewInternal);
