/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import Collapse from "@material-ui/core/Collapse";
import type { OctopusError } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import styles from "~/areas/infrastructure/components/LoadingScreen/style.module.less";
import ActionButton, { ActionButtonType } from "~/components/Button/index";
import { LoadingImage } from "~/components/Images/LoadingCowboy/LoadingImage";
import { Note } from "~/components/form/index";
interface LoadingScreenProps {
    inProgressMessage: string;
    errorDetails: OctopusError | null;
}
export default function LoadingScreen({ inProgressMessage, errorDetails }: LoadingScreenProps) {
    const [showFullErrorMessage, setShowFullErrorMessage] = useState<boolean>(false);
    const toggleFullErrorMessage = () => {
        setShowFullErrorMessage(!showFullErrorMessage);
    };
    return (<div className={styles.container}>
            <div className={styles.loading}>
                <div className={styles.inProgressMessage}>{inProgressMessage}</div>
                <LoadingImage width={"18.75rem"}/>
                {errorDetails && (<div className={styles.errorInfoPanel}>
                        <ActionButton onClick={toggleFullErrorMessage} label={showFullErrorMessage ? "Hide Details" : "Show Details"} type={ActionButtonType.Ternary}/>
                    </div>)}
            </div>

            <Collapse in={showFullErrorMessage} timeout="auto" unmountOnExit={true}>
                {errorDetails && (<div className={styles.errorInfoPanelDetails}>
                        <h3>{errorDetails.ErrorMessage}</h3>
                        <Note>
                            {errorDetails.Errors &&
                (errorDetails.Errors.length > 1 ? (<div>
                                        Errors:{" "}
                                        {errorDetails.Errors.map((x) => (<li>{x}</li>))}
                                    </div>) : (<div>{errorDetails.Errors[0]}</div>))}
                            {errorDetails.FullException && <div>FullException: {errorDetails.FullException}</div>}
                        </Note>
                    </div>)}
            </Collapse>
        </div>);
}
