/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import MaterialDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as React from "react";
import { useOctopusTheme } from "~/components/Theme";
import type { Theme } from "~/theme";
import ErrorContextProvider from "../ErrorContext/ErrorContext";
interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
    fullScreen?: boolean;
    title?: React.ReactNode;
    actions?: React.ReactNode;
}
const DialogRaw: React.FC<DialogProps> = (props) => {
    const themeContext = useOctopusTheme();
    const useStyles = makeStyles((theme: Theme) => ({
        paper: ({ wide }: DialogProps) => ({
            maxWidth: wide ? "1000px" : "600px",
            width: "100%",
            backgroundColor: themeContext.paper1,
        }),
    }));
    const { open, children, fullScreen, title, actions } = props;
    const classes = useStyles(props);
    return (<MaterialDialog classes={{ paperWidthSm: classes.paper }} open={open || false} fullScreen={fullScreen}>
            {open && (<ErrorContextProvider>
                    {title && <DialogTitle>{title}</DialogTitle>}
                    {children}
                    {actions && <DialogActions>{actions}</DialogActions>}
                </ErrorContextProvider>)}
        </MaterialDialog>);
};
DialogRaw.displayName = "DialogRaw"
const Dialog = withMobileDialog<DialogProps>()(DialogRaw);
Dialog.displayName = "Dialog";
export default Dialog;
