import { HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, GitRef } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import React from "react";
import { GetCommitButton } from "~/areas/projects/components/VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import type { PrimaryActionProps } from "~/components/FormPaperLayout/FormPaperLayout";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/Drawer/Drawer";
interface CloudConnectionsDrawerProps {
    open: boolean;
    actionName: string;
    onSubmit: (commitMessage?: CommitMessageWithDetails) => Promise<void>;
    onLoad: () => Promise<void>;
    cancel: () => void;
    setShowDrawer: (show: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    okDisabled?: boolean | null;
    project: ProjectResource;
    gitRef?: GitRef;
}
interface CloudConnectionsDrawerState extends DataBaseComponentState {
    commitMessage: CommitMessageWithDetails;
}
export class CloudConnectionsDrawer extends DataBaseComponent<PropsWithChildren<CloudConnectionsDrawerProps>, CloudConnectionsDrawerState> {
    constructor(props: PropsWithChildren<CloudConnectionsDrawerProps>) {
        super(props);
        this.state = { commitMessage: { details: "", summary: `Update cloud connections` } };
    }
    submit = () => {
        this.doBusyTask(async () => {
            await this.props.onSubmit();
        });
    };
    componentDidMount() {
        if (this.props.onLoad) {
            this.doBusyTask(async () => {
                await this.props.onLoad();
            });
        }
    }
    getGitPrimaryAction() {
        if (this.props.gitRef && HasVariablesInGit(this.props.project.PersistenceSettings)) {
            const actionProps: PrimaryActionProps = {
                busyLabel: "Committing",
                disabled: this.props.okDisabled ?? false,
                label: "Commit",
                onClick: async () => {
                    const savingPromise = this.props.onSubmit(this.state.commitMessage);
                    this.setState({ busy: savingPromise });
                    await savingPromise;
                    return true;
                },
            };
            return (<GetCommitButton project={this.props.project} gitRef={this.props.gitRef} canCommitToGitRef={true} defaultCommitMessage={`Update cloud connections`} commitMessage={this.state.commitMessage} updateCommitMessage={(commitMessage) => this.setState({ commitMessage })} commitMessageAccessibleName={"Commit message for saving the cloud connections"} commitDetailsAccessibleName={"Commit details for saving the cloud connections"} commitButtonAccessibleName={"Commit changes to the cloud connections"} quickCommitAccessibleName={`Confirm ${this.props.actionName}`} actionButtonProps={actionProps} 
            // New branches cannot be made from this area because
            // unsaved changes could be made to the underlying
            // project editor on the original branch.
            disableNewBranchCreation={true}/>);
        }
        return undefined;
    }
    render() {
        return (<DrawerWithForm busy={this.state.busy} errors={this.errors} onSubmit={this.submit} cancel={this.props.cancel} open={this.props.open} actionName={this.props.actionName} okDisabled={this.props.okDisabled} okLabel={"Save"} customPrimaryAction={this.getGitPrimaryAction()}>
                {this.props.children}
            </DrawerWithForm>);
    }
    static displayName = "CloudConnectionsDrawer";
}
