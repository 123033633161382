/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormElement, InterruptionResource } from "@octopusdeploy/octopus-server-client";
import { FormControlType } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import { ActionButton, ActionButtonType } from "~/components/Button";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import FormResourceInput from "~/components/FormResourceInput/FormResourceInput";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { goToLog } from "../TaskLog/TaskLogUtil";
import styles from "./style.module.less";
interface InterruptionComponentProps {
    interruption: InterruptionResource;
    takeResponsibility(): Promise<void>;
    submitInterruption(values: {
        [name: string]: any;
    }): Promise<any>;
}
type InterruptionProps = InterruptionComponentProps & RouteComponentProps<any>;
interface InterruptionState {
    responsibleUser: string;
    isOpen: boolean;
    values: {
        [name: string]: any;
    };
    isSubmitting: boolean;
}
//eslint-disable-next-line react/no-unsafe
class Interruption extends React.Component<InterruptionProps, InterruptionState> {
    constructor(props: InterruptionProps) {
        super(props);
        this.state = {
            isOpen: false,
            isSubmitting: false,
            values: {},
            responsibleUser: null!,
        };
    }
    async UNSAFE_componentWillReceiveProps(newProps: InterruptionComponentProps) {
        if (this.props.interruption.ResponsibleUserId !== newProps.interruption.ResponsibleUserId && newProps.interruption.HasResponsibility) {
            this.setState({ isOpen: true });
        }
        if (!isEqual(this.props.interruption, newProps.interruption)) {
            this.setState({ values: newProps.interruption.Form.Values });
        }
        if (!!newProps.interruption.ResponsibleUserId && !this.props.interruption.HasResponsibility && this.props.interruption.ResponsibleUserId !== newProps.interruption.ResponsibleUserId) {
            const user = await repository.Users.get(this.props.interruption.ResponsibleUserId);
            this.setState({ responsibleUser: user.DisplayName });
        }
    }
    async componentDidMount() {
        if (this.props.interruption.HasResponsibility) {
            this.setState({ isOpen: true, values: this.props.interruption.Form.Values });
        }
        else if (this.props.interruption.ResponsibleUserId) {
            const user = await repository.Users.get(this.props.interruption.ResponsibleUserId);
            this.setState({ responsibleUser: user.DisplayName });
        }
    }
    updateFormElementValue = (element: FormElement) => {
        return (value: string) => {
            const newValues = { ...this.state.values, [element.Name]: value };
            this.setState({ values: newValues });
        };
    };
    submitInterrupt = (element: FormElement) => {
        return async (value: string) => {
            const values = { ...this.state.values, [element.Name]: value };
            const elementsWithValues = [FormControlType.Checkbox, FormControlType.TextArea, FormControlType.VariableValue];
            this.props.interruption.Form.Elements.forEach((otherElements) => {
                if (elementsWithValues.indexOf(otherElements.Control.Type) !== -1) {
                    values[otherElements.Name] = values[otherElements.Name] || "";
                }
            });
            this.setState({ isSubmitting: true });
            try {
                await this.props.submitInterruption(values);
            }
            finally {
                this.setState({ isSubmitting: false });
            }
        };
    };
    renderAssignmentButton() {
        const interruption = this.props.interruption;
        if (!interruption.CanTakeResponsibility || interruption.HasResponsibility) {
            return null;
        }
        if (interruption.ResponsibleUserId) {
            return (<OpenDialogButton label="Assign to me" type={ActionButtonType.Primary} disabled={this.state.isSubmitting}>
                    <SaveDialogLayout title="Confirm Re-assignment" saveButtonLabel="Yes" cancelButtonLabel="No" busy={this.state.isSubmitting} errors={null} onSaveClick={async () => {
                    this.setState({ isSubmitting: true });
                    try {
                        await this.props.takeResponsibility();
                    }
                    finally {
                        this.setState({ isSubmitting: false });
                    }
                    return true;
                }}>
                        <div>Someone else is already assigned to this interruption. Are you sure you wish to assign it to yourself?</div>
                    </SaveDialogLayout>
                </OpenDialogButton>);
        }
        return <ActionButton label="Assign to me" type={ActionButtonType.Primary} onClick={() => this.props.takeResponsibility()}/>;
    }
    render() {
        const interruption = this.props.interruption;
        const isAssignedToMe = interruption.HasResponsibility;
        const isSubmitting = this.state.isSubmitting;
        const who = interruption.ResponsibleUserId ? isAssignedToMe ? <strong>you</strong> : <span>{this.state.responsibleUser}</span> : <em>no one</em>;
        const styling = (controlType: FormControlType) => {
            if (controlType === FormControlType.TextArea) {
                return styles.notes;
            }
            else if (controlType === FormControlType.SubmitButtonGroup) {
                return styles.actions;
            }
            return null;
        };
        return (<Callout title={interruption.Title} type={CalloutType.Warning}>
                {!interruption.IsLinkedToOtherInterruption && (<div>
                        {!interruption.ResponsibleUserId && (<div>
                                This task is waiting for manual intervention and <strong>must be assigned</strong> before proceeding.
                            </div>)}
                        {interruption.ResponsibleUserId && <div>This task is waiting for manual intervention.</div>}
                    </div>)}
                <div className={styles.headerLinks}>
                    <p>
                        Assigned to: <strong>{who}</strong>
                    </p>
                    <div className={styles.assignmentAction}>{this.renderAssignmentButton()}</div>
                </div>
                <div className={styles.headerLinks}>
                    <ActionList actions={[
                <ActionButton label={this.state.isOpen ? "Hide Details" : "Show Details"} type={ActionButtonType.Ternary} onClick={() => this.setState({ isOpen: !this.state.isOpen })}/>,
                interruption.CorrelationId && <ActionButton label="View Log" type={ActionButtonType.Ternary} onClick={() => goToLog(this.props, interruption.CorrelationId)}/>,
            ]}/>
                </div>
                {(this.state.isOpen || interruption.IsLinkedToOtherInterruption) && (<div className={styles.formElements}>
                        {interruption.Form.Elements.map((element, idx) => (<div className={styling(element.Control.Type)} key={idx}>
                                <FormResourceInput element={element} disabled={!isAssignedToMe || isSubmitting} onButtonSelected={this.submitInterrupt(element)} onChanged={this.updateFormElementValue(element)} value={this.state.values[element.Name]}/>
                            </div>))}
                    </div>)}
            </Callout>);
    }
    static displayName = "Interruption";
}
export default withRouter(Interruption);
