import * as React from "react";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
interface StringCheckBoxProps extends FormFieldProps<string> {
    trueValue?: string;
    falseValue?: string;
    title?: string | JSX.Element;
    note?: string | JSX.Element;
    disabled?: boolean;
    label?: string | JSX.Element;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
}
export default class StringCheckbox extends React.Component<StringCheckBoxProps, {}> {
    handleChange = (isChecked: boolean) => {
        const trueValue = this.props.trueValue || "True";
        const falseValue = this.props.falseValue || "False";
        const value = isChecked ? trueValue : falseValue;
        if (this.props.validate) {
            const validateResult = this.props.validate(value);
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    render() {
        const { value, onChange, onValidate, validate, note, ...otherProps } = this.props;
        const val = (value || "").toLowerCase() === "true";
        return <Checkbox value={val} title={this.props.title} onChange={this.handleChange} note={this.props.note} {...otherProps}/>;
    }
    static displayName = "StringCheckbox";
}
export const BoundStringCheckbox = withBoundField(StringCheckbox);
