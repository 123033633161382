import * as React from "react";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout";
import { ProjectContextFormPaperLayout } from "../Process/CustomPaperLayouts/ProjectContextFormPaperLayout";
export const DeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...props }) => {
    return (<ProjectContextFormPaperLayout title="Deployment Settings" saveText="Deployment settings updated" saveButtonLabel="Save" saveButtonBusyLabel="Saving" {...props}>
            {children}
        </ProjectContextFormPaperLayout>);
};
DeploymentSettingsFormPaperLayout.displayName = "DeploymentSettingsFormPaperLayout"
export const VersionControlledDeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...props }) => {
    return (<DeploymentSettingsFormPaperLayout {...props} saveButtonLabel="Commit" saveButtonBusyLabel="Committing" customPrimaryAction={props.customPrimaryAction}>
            {children}
        </DeploymentSettingsFormPaperLayout>);
};
VersionControlledDeploymentSettingsFormPaperLayout.displayName = "VersionControlledDeploymentSettingsFormPaperLayout"
