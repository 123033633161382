import type { SimpleMenuItem } from "@octopusdeploy/design-system-components/dist/src/index";
import { useAnalyticExternalLinkDispatch } from "~/analytics/Analytics";
export function useExternalLinksWithAnalytics(items: SimpleMenuItem[]): SimpleMenuItem[] {
    const dispatchLink = useAnalyticExternalLinkDispatch();
    return items.map((item) => {
        if (item.type !== "external-link") {
            return item;
        }
        const { type, onClick, label, href } = item;
        return {
            type,
            label,
            href,
            onClick: () => {
                dispatchLink(label, href);
                onClick?.();
            },
        };
    });
}
