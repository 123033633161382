import * as React from "react";
import ScrollUpButton from "react-scroll-up-button";
import styles from "./style.module.less";
interface ScrollUpButtonProps {
    StopPosition?: number;
    ShowAtPosition?: number;
    EasingType?: string;
    AnimationDuration?: string;
    ContainerClassName?: string;
    TransitionClassName?: string;
    style?: string;
    ToggledStyle?: string;
}
export default class ScrollToTop extends React.Component<ScrollUpButtonProps> {
    render() {
        return (<ScrollUpButton StopPosition={0} ShowAtPosition={150} EasingType="easeOutCubic" AnimationDuration={500} TransitionClassName={styles.ScrollUpButton__Toggled} ContainerClassName={styles.ScrollUpButton__Container}>
                <span>
                    <i className="fa-solid fa-chevron-up"/>
                </span>
            </ScrollUpButton>);
    }
    static displayName = "ScrollToTop";
}
