import MaterialDrawer from "@material-ui/core/Drawer";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { PropsWithChildren, ReactNode } from "react";
import * as React from "react";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import FormComponent from "~/components/FormComponent/FormComponent";
import { ErrorPanel } from "~/components/form/index";
import ActionList from "../../../components/ActionList";
import { ActionButton, ActionButtonType } from "../../../components/Button";
import styles from "./style.module.less";
type Props = {
    children: ReactNode;
    open: boolean;
    actionName: string;
    onClose: () => void;
    onOkClick: () => void;
    okLabel?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    okDisabled?: Promise<any> | boolean | null;
    customPrimaryAction?: React.ReactNode;
};
interface DrawerWithFormProps {
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    actionName: string;
    onSubmit: () => void;
    cancel: () => void;
    open: boolean;
    okLabel?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    okDisabled?: Promise<any> | boolean | null;
    customPrimaryAction?: React.ReactNode;
}
export function DrawerWithForm({ cancel, onSubmit, busy, errors, open, children, actionName, okDisabled, customPrimaryAction }: PropsWithChildren<DrawerWithFormProps>) {
    return (<Drawer open={open} actionName={actionName} onClose={cancel} onOkClick={onSubmit} okDisabled={okDisabled} customPrimaryAction={customPrimaryAction}>
            <FormComponent onFormSubmit={onSubmit}>
                <BusyFromPromise promise={busy}>{(busy: boolean) => <LinearProgress show={busy}/>}</BusyFromPromise>
                {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
                {children}
            </FormComponent>
        </Drawer>);
}
export const Drawer = ({ open, onClose, actionName, children, onOkClick, okLabel, okDisabled, customPrimaryAction }: Props) => (<MaterialDrawer classes={{ paper: styles.container }} anchor="right" open={open} onClose={onClose}>
        <header className={styles.header}>
            <h2 className={styles.title}>{actionName}</h2>
            <ActionList actions={[
        <ActionButton type={ActionButtonType.Secondary} label="Cancel" accessibleName={`Cancel ${actionName}`} onClick={onClose}/>,
        customPrimaryAction ?? <ActionButton type={ActionButtonType.Primary} label={okLabel ?? "Ok"} accessibleName={`Confirm ${actionName}`} onClick={onOkClick} disabled={okDisabled}/>,
    ]}/>
        </header>

        <section className={styles.content}>{children}</section>
    </MaterialDrawer>);
