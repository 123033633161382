import { Page } from "@octopusdeploy/portal-routes";
import type { PageDetailProps } from "@octopusdeploy/portal-routes";
import React from "react";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
type FromPropsCallback<TProps> = (mapPage: TProps) => PageDetailProps;
export const withPageFromProps = <TInnerProps>(Component: React.ComponentType<TInnerProps>, mapPageProps: FromPropsCallback<TInnerProps>) => {
    const WithPageFromRouteProps: React.FC<TInnerProps> = (componentProps) => {
        const mappedProps = mapPageProps(componentProps);
        return (<ErrorContextProvider>
                <Page area={mappedProps.page.Area} id={mappedProps.page.Id} name={mappedProps.page.Name}>
                    <Component {...componentProps}/>
                </Page>
            </ErrorContextProvider>);
    };
    WithPageFromRouteProps.displayName = "WithPageFromRouteProps"
    return WithPageFromRouteProps;
};
