/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as H from "history";
import * as React from "react";
import type { match as Match, RouteComponentProps, RouteProps } from "react-router";
import { withRouter } from "react-router";
import { generatePath } from "react-router-dom";
import BaseComponent from "~/components/BaseComponent/index";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
type SlugSafeRedirectProps = {
    parameter: string;
    regexp: RegExp;
    getRealParam(param: string, location?: H.Location, match?: Match<any>): Promise<string>;
    loadingComponent(): React.ReactElement<any>;
} & RouteProps & RouteComponentProps<any>;
type SlugSafeRedirectState = {
    refreshId: number;
    paramValue?: string;
};
class SlugSafeRedirect extends BaseComponent<SlugSafeRedirectProps, SlugSafeRedirectState> {
    refreshId: number = 0;
    constructor(props: SlugSafeRedirectProps) {
        super(props);
        this.state = { refreshId: 0 };
    }
    async UNSAFE_componentWillMount() {
        const { parameter, regexp, match } = this.props;
        this.refreshId += 1;
        const param = match.params[parameter];
        if (param && regexp.test(param)) {
            try {
                const paramValue = await this.props.getRealParam(param, this.props.location, this.props.match);
                this.setState({ paramValue, refreshId: this.refreshId });
            }
            catch (ex) {
                this.setState({ paramValue: param, refreshId: this.refreshId });
            }
        }
        else {
            this.setState({ paramValue: param, refreshId: this.refreshId });
        }
    }
    render() {
        if (this.isLoading()) {
            return this.props.loadingComponent();
        }
        if (this.isRedirecting()) {
            return this.redirect();
        }
        return React.Children.only(this.props.children);
    }
    isLoading() {
        return this.state.refreshId !== this.refreshId;
    }
    isRedirecting() {
        const urlParam = this.props.match.params[this.props.parameter];
        return urlParam !== this.state.paramValue;
    }
    redirect() {
        const newPath = this.calculateNewPath(this.props);
        const redirect = {
            ...this.props.location,
            pathname: newPath,
            state: {
                ...(typeof this.props.location.state === "object" ? this.props.location.state : {}),
                [this.props.parameter]: this.state.paramValue,
            },
        };
        return <InternalRedirect to={redirect} push={false}/>;
    }
    calculateNewPath(props: SlugSafeRedirectProps): string {
        const newUrl = generatePath(props.match.path, {
            ...props.match.params,
            [props.parameter]: this.state.paramValue,
        });
        return newUrl + props.location.pathname.substr(props.match.url.length);
    }
    static displayName = "SlugSafeRedirect";
}
export default withRouter(SlugSafeRedirect);
