import * as React from "react";
import PagingButton from "~/areas/tenants/components/Paging/PagingButton";
import PagingSelection from "~/areas/tenants/components/Paging/PagingSelection";
import useLocalStorage from "~/hooks/useLocalStorage";
import styles from "./style.module.less";
interface NumberedPagingBarProps {
    totalItems: number;
    pageNumber: number;
    onPagingSelectionChange: (newPageNumber: number, newPageSize: number) => void;
}
export const tenantsPagingSelectionKey = "octoTenantsPagingSelection";
export default function NumberedPagingBar({ totalItems, pageNumber, onPagingSelectionChange }: NumberedPagingBarProps) {
    const [pageSize, setPageSize] = useLocalStorage(tenantsPagingSelectionKey, 30);
    function createPagingButton(index: number) {
        return <PagingButton key={index} isActive={pageNumber === index} label={index.toLocaleString()} onPageSelected={() => onPageNumberChange(index)}/>;
    }
    function createEllipsis() {
        return <span className={styles.pagingEllipsis}>...</span>;
    }
    function onPageSizeChange(newPageSize: number) {
        setPageSize(newPageSize);
        onPagingSelectionChange(1, newPageSize);
    }
    function onPageNumberChange(newPageNumber: number) {
        onPagingSelectionChange(newPageNumber, pageSize);
    }
    const totalPages = totalItems === 0 ? 1 : Math.ceil(totalItems / pageSize);
    if (totalPages <= 1) {
        return <React.Fragment></React.Fragment>;
    }
    const showLeftEllipsis = pageNumber > 4 && totalPages > 7;
    const showRightEllipsis = pageNumber <= totalPages - 4 && totalPages > 7;
    const pageButtonsToRender: JSX.Element[] = [];
    pageButtonsToRender.push(createPagingButton(1)); // Always show first
    if (showLeftEllipsis && showRightEllipsis) {
        pageButtonsToRender.push(createEllipsis());
        pageButtonsToRender.push(createPagingButton(pageNumber - 1));
        pageButtonsToRender.push(createPagingButton(pageNumber));
        pageButtonsToRender.push(createPagingButton(pageNumber + 1));
        pageButtonsToRender.push(createEllipsis());
    }
    else if (showLeftEllipsis) {
        pageButtonsToRender.push(createEllipsis());
        pageButtonsToRender.push(createPagingButton(totalPages - 4));
        pageButtonsToRender.push(createPagingButton(totalPages - 3));
        pageButtonsToRender.push(createPagingButton(totalPages - 2));
        pageButtonsToRender.push(createPagingButton(totalPages - 1));
    }
    else if (showRightEllipsis) {
        pageButtonsToRender.push(createPagingButton(2));
        pageButtonsToRender.push(createPagingButton(3));
        pageButtonsToRender.push(createPagingButton(4));
        pageButtonsToRender.push(createPagingButton(5));
        pageButtonsToRender.push(createEllipsis());
    }
    else {
        for (let i = 2; i < totalPages; i++) {
            pageButtonsToRender.push(createPagingButton(i));
        }
    }
    pageButtonsToRender.push(createPagingButton(totalPages)); // Always show last
    return (<div className={styles.pagingBar}>
            <PagingSelection pageSize={pageSize} onPageSizeChanged={onPageSizeChange}/>
            <PagingButton key={-1} isActive={false} icon={<em className="fa-solid fa-angle-left"/>} disabled={pageNumber === 1} onPageSelected={() => onPageNumberChange(pageNumber - 1)}/>
            {pageButtonsToRender}
            <PagingButton key={-2} isActive={false} icon={<em className="fa-solid fa-angle-right"/>} disabled={pageNumber === totalPages} onPageSelected={() => onPageNumberChange(pageNumber + 1)}/>
        </div>);
}
