import React from "react";
import type { FormFieldProps } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
interface SingleLineTextInputProps extends FormFieldProps<string> {
    label: string;
    localNames?: string[];
    warning?: string;
    defaultValueIndicator: JSX.Element | undefined;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const SingeLineTextInput: React.FC<SingleLineTextInputProps> = (props) => {
    const { value, label, localNames, warning, defaultValueIndicator, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <VariableLookupText value={value} localNames={localNames} warning={warning} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
SingeLineTextInput.displayName = "SingeLineTextInput"
export default SingeLineTextInput;
