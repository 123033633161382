/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { GitCredentialResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Onboarding from "~/areas/library/components/GitCredentials/Onboarding";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import List from "~/components/List";
import MarkdownDescription from "~/components/MarkdownDescription";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { NavigationButton, NavigationButtonType } from "../../../../components/Button/NavigationButton";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
class GitCredentialList extends List<GitCredentialResource> {
}
interface GitCredentialsListPropsInternal extends RouteComponentProps<{}> {
    dispatchAction: AnalyticActionDispatcher;
}
interface GitCredentialsListState extends DataBaseComponentState {
    gitCredentials: ResourceCollection<GitCredentialResource>;
}
class GitCredentialsListInternal extends DataBaseComponent<GitCredentialsListPropsInternal, GitCredentialsListState> {
    constructor(props: GitCredentialsListPropsInternal) {
        super(props);
        this.state = {
            gitCredentials: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const gitCredentials = await repository.GitCredentials.list();
        this.setState({
            gitCredentials,
        });
    }
    filter(filter: string, gitCredential: GitCredentialResource) {
        if (!filter || filter.length === 0 || !gitCredential) {
            return true;
        }
        return gitCredential.Name.toLowerCase().includes(filter.toLowerCase());
    }
    buildGitCredentialRow(gitCredential: GitCredentialResource) {
        return (<div>
                <ListTitle>{gitCredential.Name}</ListTitle>
                <MarkdownDescription markup={gitCredential.Description!}/>
            </div>);
    }
    render() {
        const addButton = (<PermissionCheck permission={Permission.GitCredentialEdit}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Git Credential" href={`${this.props.match.url}/create`} onClick={() => this.props.dispatchAction("Add Git Credential", { action: Action.Add, resource: "Git Credential" })}/>
            </PermissionCheck>);
        return (<LibraryLayout {...this.props}>
                <PaperLayout title="Git Credentials" sectionControl={addButton} busy={this.state.busy} errors={this.errors}>
                    {this.state.gitCredentials && (<>
                            {this.state.gitCredentials.TotalResults == 0 && <Onboarding />}
                            {this.state.gitCredentials.TotalResults > 0 && (<GitCredentialList initialData={this.state.gitCredentials} onRow={(item) => this.buildGitCredentialRow(item)} onFilter={this.filter} apiSearchParams={["name"]} filterSearchEnabled={true} filterHintText="Filter by name..." match={this.props.match}/>)}
                        </>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    static displayName = "GitCredentialsListInternal";
}
export function GitCredentialsList(props: RouteComponentProps<{}>) {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitCredentialsListInternal {...props} dispatchAction={dispatchAction}/>;
}
export default GitCredentialsList;
