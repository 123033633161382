import cn from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import { FrameWrapper } from "~/components/DialogLayout/Custom/index";
import styles from "./styles.module.less";
interface CustomTransparentDialogProps {
    className?: string;
}
function CustomTransparentDialogFrame({ className, children }: PropsWithChildren<CustomTransparentDialogProps>) {
    return (<FrameWrapper>
            <div className={cn(styles.transparentFrame, className)}>{children}</div>
        </FrameWrapper>);
}
export default CustomTransparentDialogFrame;
