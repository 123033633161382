import type { MeanTimeToRecoveryDeployment, MeanTimeToRecoveryTrends } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import { TrendTable } from "./TrendTable/TrendTable";
import styles from "./TrendTables.module.less";
interface Props {
    trends: MeanTimeToRecoveryTrends;
}
export function TimeToRecoveryTrendTables({ trends }: Props) {
    return (<>
            <LongestTimeToRecovery data={trends.LongestTimeToRecovery}/>
            <LargestNumberOfDeploymentsUntilRecovery data={trends.LargestNumberOfDeploymentsUntilRecovery}/>
        </>);
}
interface DeploymentTableProps {
    data: MeanTimeToRecoveryDeployment[];
}
function LongestTimeToRecovery({ data }: DeploymentTableProps) {
    const hasTenants = data.some((d) => d.TenantName !== null);
    return (<TrendTable title="Longest time to recovery" isEmpty={data.length === 0} note={<span>
                    The successful deployment with the longest <ExternalLink href="InsightsMeanTimeToRecovery">mean time to recovery</ExternalLink> following a failure for each project, environment and tenant. The first 10 in descending order by mean
                    time to recovery are shown.
                </span>} headerContent={<DataTableRow>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    {hasTenants && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Time to Recovery</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).root}>{r.ProjectName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.EnvironmentName}</DataTableRowColumn>
                            {hasTenants && <DataTableRowColumn>{r.TenantName ?? "Untenanted"}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <ToolTip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </ToolTip>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{formatDurationInWords(r.TimeToRecovery)}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
function LargestNumberOfDeploymentsUntilRecovery({ data }: DeploymentTableProps) {
    const hasTenants = data.some((d) => d.TenantName !== null);
    return (<TrendTable title="Largest number of deployments until recovery" isEmpty={data.length === 0} note={<span>
                    The successful deployment with the most <ExternalLink href="InsightsMeanTimeToRecovery">deployments until recovery</ExternalLink> following a failure for each project, environment and tenant. The first 10 in descending order by
                    the count are shown.
                </span>} headerContent={<DataTableRow>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    {hasTenants && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn className={styles.numberColumn}>Deployments until Recovery</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).root}>{r.ProjectName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.EnvironmentName}</DataTableRowColumn>
                            {hasTenants && <DataTableRowColumn>{r.TenantName ?? "Untenanted"}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <ToolTip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </ToolTip>
                            </DataTableRowColumn>
                            <DataTableRowColumn className={styles.numberColumn}>{r.DeploymentsUntilRecovery}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
