import React, { useState } from "react";
import { useParams } from "react-router";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { client, repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import useLocalStorage from "~/hooks/useLocalStorage";
import routeLinks from "~/routeLinks";
import { CloseButton } from "./CloseButton";
import { ProjectStatusItem } from "./ProjectStatusItem";
import styles from "./style.module.less";
interface ProjectStatusProps {
    doBusyTask: DoBusyTask;
    numberOfSteps?: number;
}
export type TaskState = "Current" | "Pending" | "Done";
export const ProjectStatus = ({ doBusyTask, numberOfSteps }: ProjectStatusProps): JSX.Element => {
    const dispatchAction = useAnalyticActionDispatch();
    const { spaceId, projectSlug: projectName } = useParams<{
        spaceId: string;
        projectSlug: string;
    }>();
    const isProjectBasedActivationFeatureEnabled = isFeatureToggleEnabled("ProjectBasedActivationFeatureToggle");
    const [dismissed, setDismissed] = useLocalStorage(`Octopus.Project.${spaceId}.${projectName}.Status.Dismissed`, false);
    const [hasLoadedStatus, setHasLoadedStatus] = React.useState<boolean>(false);
    const [hasEnvironments, setHasEnvironments] = React.useState<boolean>(false);
    const [hasSteps, setHasSteps] = React.useState<boolean>(false);
    const [hasSuccessfulDeployment, setHasSuccessfulDeployment] = React.useState(false);
    const [hasSuccessfullyDeployAProject, setHasSuccessfullyDeployAProject] = React.useState(false);
    const [isIntendedAsVcsProject, setIsIntendedAsVcsProject] = React.useState(false);
    const [isVersionControlled, setIsVersionControlled] = useState(false);
    const statusItems = getStatusItems(projectName, hasSuccessfullyDeployAProject, hasEnvironments, hasSteps, isIntendedAsVcsProject, isVersionControlled);
    const getProjectStatus = React.useCallback(async (projectName: string, spaceId: string) => {
        const projectStatus = await repository.Projects.getProjectStatus(projectName, spaceId);
        try {
            const projectIntents = await repository.Projects.getProjectIntents(projectName, spaceId);
            setIsIntendedAsVcsProject(projectIntents?.IsVcsProject === true);
            // eslint-disable-next-line no-empty
        }
        catch (e: unknown) { }
        setHasSteps(projectStatus.HasSteps);
        setHasSuccessfulDeployment(projectStatus.HasBeenSuccessfullyDeployed);
        setHasEnvironments(projectStatus.HasEnvironments);
        const runbookOnly = !projectStatus.HasSteps && projectStatus.HasRunbooks;
        if ((projectStatus.HasBeenSuccessfullyDeployed || runbookOnly) && !dismissed) {
            setDismissed(true);
        }
        setHasLoadedStatus(true);
        setHasSuccessfullyDeployAProject(projectStatus.HasSuccessfulDeploymentsInSpace);
        setIsVersionControlled(projectStatus.IsVersionControlled);
    }, [dismissed, setDismissed]);
    let currentStep = "initial";
    if (hasSuccessfulDeployment) {
        currentStep = "dismissed";
    }
    else if (isIntendedAsVcsProject && !isVersionControlled && !hasSteps) {
        currentStep = "vcs";
    }
    else if (hasSteps && hasEnvironments) {
        currentStep = "deployment";
    }
    else if (hasEnvironments) {
        currentStep = "process";
    }
    else {
        currentStep = "environment";
    }
    const activeItem = statusItems.findIndex((item) => item.status === currentStep);
    const closeProjectStatus = () => {
        setDismissed(true);
        dispatchAction("Project Status Bar Dismissed", { action: Action.Toggle, resource: "Project Status Bar" });
    };
    React.useEffect(() => client.subscribe((event) => {
        if (event.type === "EnvironmentCreated" || event.type === "DeploymentProcessModified" || event.type === "ReleaseProgressionModified") {
            getProjectStatus(projectName, spaceId);
        }
        else if (event.type === "ProjectModified") {
            setIsVersionControlled(event.project.IsVersionControlled);
        }
    }), [getProjectStatus, projectName, spaceId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (isProjectBasedActivationFeatureEnabled) {
            const environments = await repository.Environments.list({ take: 0 });
            setHasEnvironments(environments.TotalResults > 0);
            getProjectStatus(projectName, spaceId);
        }
    }, [numberOfSteps]);
    // To prevent a flash of the status bar when it should be dismissed,
    // render nothing until we have fetched the status data
    if (!hasLoadedStatus || dismissed || hasSuccessfulDeployment)
        return <></>;
    return (<FeatureToggleVisibility toggle={"ProjectBasedActivationFeatureToggle"}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.title}>Project status</div>
                    {statusItems.map((item, index) => (<ProjectStatusItem name={item.label} state={getItemState(index, activeItem)} href={item.href} key={item.label} eventName={item.eventName} eventAction={item.eventAction}/>))}
                    <CloseButton onClick={closeProjectStatus}/>
                </div>
            </div>
        </FeatureToggleVisibility>);
};
function getItemState(index: number, activeItem: number): TaskState {
    if (index < activeItem)
        return "Done";
    if (index > activeItem)
        return "Pending";
    return "Current";
}
interface StatusItemEventAction {
    action: Action;
    resource: string;
}
interface StatusItem {
    status: string;
    label: string;
    href: string;
    eventName: string;
    eventAction: StatusItemEventAction;
}
function getStatusItems(project: string, hasSuccessfullyDeployAProject: boolean, hasEnvironments: boolean, hasDeploymentProcesses: boolean, isIntendedForVcs: boolean = false, isVersionControlled: boolean = false): StatusItem[] {
    const environmentLabel = hasSuccessfullyDeployAProject ? "Environments" : "Add environments";
    const statusItems: StatusItem[] = [];
    const projectStatusItem: StatusItem = {
        status: "project",
        label: "Add project",
        href: routeLinks.projects.root,
        eventName: "Click On Project Status Bar",
        eventAction: { action: Action.Add, resource: "Project" },
    };
    const environmentStatusItem: StatusItem = {
        status: "environment",
        label: environmentLabel,
        href: routeLinks.infrastructure.environments.root,
        eventName: "Click On Project Status Bar",
        eventAction: { action: Action.Add, resource: "Environment" },
    };
    const vcsStatusItem: StatusItem = {
        status: "vcs",
        label: "Configure Version Control",
        href: routeLinks.project(project).settings.versionControl,
        eventName: "Click On Project Status Bar",
        eventAction: { action: Action.Configure, resource: "Project" },
    };
    const deploymentProcessStatusItem: StatusItem = {
        status: "process",
        label: "Create deployment process",
        href: routeLinks.project(project).deployments.process.root,
        eventName: "Click On Project Status Bar",
        eventAction: { action: Action.Add, resource: "Deployment Process" },
    };
    const releaseAndDeployStatusItem: StatusItem = {
        status: "deployment",
        label: "Create release and deploy",
        href: routeLinks.project(project).deployments.releaseCreate,
        eventName: "Click On Project Status Bar",
        eventAction: { action: Action.Add, resource: "Release" },
    };
    statusItems.push(projectStatusItem);
    if (!hasEnvironments && hasDeploymentProcesses) {
        statusItems.push(deploymentProcessStatusItem);
    }
    statusItems.push(environmentStatusItem);
    if (isIntendedForVcs) {
        // Show if: no deployment process AND not version controlled
        // no deployment process AND version controlled
        // has deployment process AND version controlled
        // Hide if: has deployment process AND not version controlled
        if (!hasDeploymentProcesses || isVersionControlled) {
            statusItems.push(vcsStatusItem);
        }
    }
    if (hasEnvironments || !hasDeploymentProcesses) {
        statusItems.push(deploymentProcessStatusItem);
    }
    statusItems.push(releaseAndDeployStatusItem);
    return statusItems;
}
