import type { GoogleCloudAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ActionButton } from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import { ExpandableFormSection, Summary, FormSectionHeading, SensitiveFileUpload } from "~/components/form";
import { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { repository } from "../../../../clientInstance";
import ExternalLink from "../../../../components/Navigation/ExternalLink";
import Note from "../../../../primitiveComponents/form/Note/Note";
import AccountEditBase from "./AccountEditBase";
import type { AccountEditModel } from "./AccountEditBase";
import SaveAndTestAccountDialog from "./SaveAndTestAccountDialog";
import type { SaveAndTestAccountProps } from "./SaveAndTestAccountDialog";
interface GoogleCloudAccountModel extends AccountEditModel {
    jsonKey: SensitiveValue;
}
class GoogleCloudAccountEdit extends AccountEditBase<GoogleCloudAccountResource, GoogleCloudAccountModel> {
    getPartialModel(account?: GoogleCloudAccountResource): Partial<GoogleCloudAccountModel> {
        if (!account) {
            return {
                jsonKey: {
                    HasValue: false,
                },
            };
        }
        return {
            jsonKey: account.JsonKey,
        };
    }
    getPartialResource(): (Partial<GoogleCloudAccountResource> & {
        AccountType: AccountType;
    }) | undefined {
        return {
            AccountType: AccountType.GoogleCloudAccount,
            JsonKey: this.state.model.jsonKey,
        };
    }
    jsonKeySummary() {
        return this.state.model.jsonKey && this.state.model.jsonKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No key file provided");
    }
    customSecondaryAction(): React.ReactElement {
        return (<div>
                <ActionButton onClick={() => this.handleSaveClick(true)} label="Save and Test"/>
                {this.state.accountData !== undefined && (<Dialog open={this.state.showTestDialog}>
                        <SaveAndTestGoogleCloudAccountDialog onOkClick={() => this.testDone()} accountId={this.state.accountData.account.Id}/>
                    </Dialog>)}
            </div>);
    }
    customExpandableFormSections(): React.ReactElement[] {
        const baseElements: React.ReactElement[] = [];
        baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="keyFile" errorKey="keyFile" title="Key File" summary={this.jsonKeySummary()} help="The json key file to use when authenticating against Google Cloud.">
                <SensitiveFileUpload value={this.state.model.jsonKey} onChange={(jsonKey) => this.setModelState({ jsonKey })} label="Key File" error={this.getFieldError("KeyFile")}/>
                <Note>
                    Refer to the <ExternalLink href="GoogleCloudServiceAccountKey">Google cloud documentation</ExternalLink> for information on generating json key for service accounts.
                </Note>
            </ExpandableFormSection>);
        return baseElements;
    }
}
const SaveAndTestGoogleCloudAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={(accId) => repository.Tasks.createTestGoogleCloudAccountTask(accId)}/>;
};
SaveAndTestGoogleCloudAccountDialog.displayName = "SaveAndTestGoogleCloudAccountDialog"
export default GoogleCloudAccountEdit;
