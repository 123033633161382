/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
import type { OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import BuildInformationTable from "~/components/BuildInformation/BuildInformationTable";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import routeLinks from "../../../../routeLinks";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
interface BuildInformationDetailState extends DataBaseComponentState {
    buildInformation?: OctopusPackageVersionBuildInformationMappedResource;
    deleted?: boolean;
}
export default class BuildInformationDetail extends DataBaseComponent<RouteComponentProps<{
    id: string;
}>, BuildInformationDetailState> {
    private id: string;
    constructor(props: RouteComponentProps<{
        id: string;
    }>) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const buildInformation = await repository.BuildInformationRepository.get(this.id);
        let errors: {
            message: string;
            errors: {
                [key: string]: string;
            };
        } | undefined;
        if (buildInformation.IncompleteDataWarning) {
            errors = { message: `Unable to retrieve part of the build information for package '${buildInformation.PackageId}'.`, errors: { buildInformationIncomplete: buildInformation.IncompleteDataWarning } };
        }
        this.setState({ buildInformation });
        if (errors) {
            this.setValidationErrors(errors.message, errors.errors);
        }
    }
    render() {
        const buildInformation = this.state.buildInformation;
        const title = this.state.buildInformation && buildInformation!.PackageId + " " + buildInformation!.Version;
        const actions = [
            <OverflowMenu menuItems={[OverflowMenuItems.deleteItemDefault(title!, this.handleDeleteConfirm, { permission: Permission.BuildInformationAdminister, project: "*" }, "Deleting build information means it will be unavailable for future releases")]}/>,
        ];
        return (<LibraryLayout {...this.props}>
                <PaperLayout title={title} breadcrumbTitle={buildInformation && buildInformation.PackageId} breadcrumbPath={routeLinks.library.buildInformationRepository.versions((buildInformation && buildInformation.PackageId)!)} sectionControl={<ActionList actions={actions}/>} busy={this.state.busy} errors={this.errors}>
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.buildInformationRepository.root}/>}
                    {buildInformation && <BuildInformationTable data={buildInformation}/>}
                </PaperLayout>
            </LibraryLayout>);
    }
    private handleDeleteConfirm = async () => {
        await repository.BuildInformationRepository.deleteMany([this.id]);
        this.setState({ deleted: true });
        return true;
    };
    static displayName = "BuildInformationDetail";
}
