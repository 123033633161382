import React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
export const CommonInsightsDeploymentFrequencyOverview: React.FC = (props) => (<OverviewContainer>
        <OverviewHighlightText>
            <p>
                <b>Deployment frequency</b> is a measure of how often code is deployed to a given environment.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                The deployment frequency is the total number of successful deployments for a given time period.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>);
CommonInsightsDeploymentFrequencyOverview.displayName = "CommonInsightsDeploymentFrequencyOverview"
export const CommonInsightsDeploymentFrequencyTopics: React.FC = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="InsightsDeploymentFrequency">Deployment frequency</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonInsightsDeploymentFrequencyTopics.displayName = "CommonInsightsDeploymentFrequencyTopics"
