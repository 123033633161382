import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import type { AccountReference, EmptyInitialValue } from "@octopusdeploy/step-inputs";
import type { AccountTypeDefinition, ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { AccountSelectorComponent } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import type { DeploymentTargetInputDependencies } from "~/components/StepPackageDeploymentTargetEditor/DeploymentTargetInputDependencies";
import { convertFromRuntimeAccountSelection, convertToRuntimeAccountSelection } from "~/components/StepPackageEditor/Inputs/Components/AccountSelector/AccountSelectionConverters";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import { Note } from "../../Note/Note";
import { getSchemaForInputAtPath } from "../../schemaTraversal";
export function getAccountSelectorSummary<StepInputs>(content: AccountSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>, accounts: AccountResource[]): InputSummary {
    const inputAccessor = createInputValueAccessor(content.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const accountId = convertToRuntimeAccountSelection(inputValue);
        const account = accounts.find((acc) => acc.Id === accountId);
        return { isDefaultValue: false, value: account?.Name ?? accountId };
    }
    // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
    throw new Error("Account selection cannot be bound");
}
interface AccountSelectorProps<StepInputs> {
    configuredStepUIProps: AccountSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: DeploymentTargetInputDependencies;
    getFieldError: (path: PathToInput) => string;
}
export function getErrorPathForAccountReference(component: AccountSelectorComponent): PathToInput[] {
    return [getPathToInput(component.input)];
}
function getSchemaForAccountInputAtPath<StepInputs>(pathToInput: PathToInput, inputSchema: PlainObjectTypeDefinition): AccountTypeDefinition[] {
    const inputSchemaAtPath = getSchemaForInputAtPath(pathToInput, inputSchema);
    if (inputSchemaAtPath.type === "account")
        return [inputSchemaAtPath];
    else
        throw Error("The provided input to the schema is not an account type");
}
export function AccountSelector<StepInputs>(props: AccountSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, AccountReference | EmptyInitialValue>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    const inputAccountSchemas = getSchemaForAccountInputAtPath(inputPath, props.getInputSchema(props.inputs));
    const accountTypes = inputAccountSchemas.map((schema) => {
        switch (schema.accountType) {
            case "AmazonWebServicesAccount":
                return AccountType.AmazonWebServicesAccount;
            case "AzureServicePrincipal":
                return AccountType.AzureServicePrincipal;
            case "GoogleCloudAccount":
                return AccountType.GoogleCloudAccount;
            case "SshKeyPair":
                return AccountType.SshKeyPair;
            case "Token":
                return AccountType.Token;
            case "UsernamePassword":
                return AccountType.UsernamePassword;
            default:
                exhaustiveCheck(schema.accountType, "Unsupported account type");
        }
    });
    if (isNotBoundValue(inputValue)) {
        return (<>
                <AccountSelect label={props.configuredStepUIProps.label} onRequestRefresh={props.dependencies.refreshAccounts} value={convertToRuntimeAccountSelection(inputValue)} type={accountTypes} allowClear={true} onChange={(newValue) => {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeAccountSelection(newValue));
                props.setInputs(updatedInputs);
            }} items={props.dependencies.accounts} error={props.getFieldError(inputPath)}/>
                <Note note={props.configuredStepUIProps.note}/>
            </>);
    }
    else {
        // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
        throw new Error("Account selection can't be bound");
    }
}
