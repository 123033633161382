/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
//TODO: Icons should be coming from either OctopusIcon or ThirdParty Icon. Look at replacing these usages.
import AddIcon from "@material-ui/icons/Add";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import CancelIcon from "@material-ui/icons/Clear";
import CropOriginal from "@material-ui/icons/CropOriginal";
import RemoveIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UploadIcon from "@material-ui/icons/Publish";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import classnames from "classnames";
import { default as MaterialIconButton } from "material-ui/IconButton";
import CopyIcon from "material-ui/svg-icons/content/content-copy";
import ExpandIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import CollapseIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import React from "react";
import styles from "~/components/IconButton/internal/styles.module.less";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { ChevronIcon, BindIcon, UnbindIcon, InsertVariableIcon, SelectLogoIcon } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import type { SharedButtonProps } from "./internal";
import { FontAwesomeIconButtonWithToolTip } from "./internal";
export enum Icon {
    UseDefaultImage = "UseDefaultImage",
    EnterFullScreen = "EnterFullScreen",
    ExitFullScreen = "ExitFullScreen",
    Refresh = "Refresh",
    Add = "Add",
    Search = "Search",
    Show = "Show",
    Hide = "Hide",
    Edit = "Edit",
    Remove = "Remove",
    Cancel = "Cancel",
    ArrowDown = "ArrowDown",
    Upload = "Upload",
    ArrowUp = "ArrowUp",
    OpenHelp = "OpenHelp",
    CloseHelp = "CloseHelp",
    CancelSmall = "CancelSmall",
    Collapse = "Collapse",
    Expand = "Expand",
    CopyToClipboard = "CopyToClipboard",
    OverflowMenu = "OverflowMenu",
    Bind = "Bind",
    Unbind = "Unbind",
    InsertVariable = "InsertVariable",
    SelectLogo = "SelectLogo",
    ChevronIcon = "ChevronIcon"
}
// IconButton is currently very inconsistent. These functions exist as a temporary measure to provide a level of custom styling to
// the icons themselves - see https://octopusdeploy.slack.com/archives/C4WNL3PQ9/p1684368060564099?thread_ts=1684199714.450689&cid=C4WNL3PQ9
type IconButtonColor = "neutral" | "primary";
function getMuiIconColorFromIconButtonColor(theme: OctopusTheme, color: IconButtonColor) {
    switch (color) {
        case "neutral":
            return theme.iconNeutral;
        case "primary":
            return theme.colorButtonIconPrimaryDefault;
        default:
            exhaustiveCheck(color, "Not all colors have been mapped");
    }
}
function getClassForIconButtonFromIconButtonColor(theme: OctopusTheme, color: IconButtonColor) {
    switch (color) {
        case "neutral":
            return css({
                color: `${theme.iconNeutral} !important`,
            });
        case "primary":
            return css({
                color: `${theme.colorButtonIconPrimaryDefault} !important`,
            });
        default:
            exhaustiveCheck(color, "Not all colors have been mapped");
    }
}
export interface IconButtonProps extends SharedButtonProps {
    id?: string;
    icon: Icon;
    customIcon?: JSX.Element;
    className?: string;
    color?: IconButtonColor;
    accessibleName?: string;
}
const IconButton: React.FC<IconButtonProps> = ({ icon, customIcon, className, color, accessibleName, ...sharedButtonProps }) => {
    const theme = useOctopusTheme();
    const muiIconColor = color ? getMuiIconColorFromIconButtonColor(theme, color) : undefined;
    const classForIconButton = color ? getClassForIconButtonFromIconButtonColor(theme, color) : undefined;
    if (!accessibleName && icon !== null) {
        accessibleName = icon;
    }
    switch (icon) {
        case Icon.EnterFullScreen:
            return <FontAwesomeIconButtonWithToolTip iconClassName="fa-expand" {...sharedButtonProps} accessibleName={accessibleName}/>;
        case Icon.ExitFullScreen:
            return <FontAwesomeIconButtonWithToolTip iconClassName="fa-expand" {...sharedButtonProps} accessibleName={accessibleName}/>;
        case Icon.UseDefaultImage:
            return <MaterialIconButtonWithToolTip icon={<CropOriginal htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Refresh:
            return <MaterialIconButtonWithToolTip icon={<RefreshIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Add:
            return <MaterialIconButtonWithToolTip icon={<AddIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Search:
            return <MaterialIconButtonWithToolTip icon={<SearchIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Show:
            return <MaterialIconButtonWithToolTip icon={<VisibilityIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Hide:
            return <MaterialIconButtonWithToolTip icon={<VisibilityOffIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Edit:
            return <MaterialIconButtonWithToolTip icon={<EditIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Cancel:
            return <MaterialIconButtonWithToolTip icon={<CancelIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.CancelSmall:
            return <MaterialIconButtonWithToolTip icon={<CancelIcon htmlColor={theme.iconNeutral}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Remove:
            return <MaterialIconButtonWithToolTip icon={<RemoveIcon />} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Upload:
            return <MaterialIconButtonWithToolTip icon={<UploadIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.ArrowDown:
            return <MaterialIconButtonWithToolTip icon={<ArrowDownIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.ArrowUp:
            return <MaterialIconButtonWithToolTip icon={<ArrowUpIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.OpenHelp:
            return <MaterialIconButtonWithToolTip icon={<HelpOutlineIcon htmlColor={theme.iconDark} style={{ fill: theme.iconDark }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.CloseHelp:
            return <MaterialIconButtonWithToolTip icon={<HelpOutlineIcon htmlColor={theme.iconDark} style={{ fill: theme.iconDark }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Collapse:
            return <MaterialIconButtonWithToolTip icon={<CollapseIcon color={muiIconColor ?? theme.iconDark}/>} {...sharedButtonProps} className={cx(className, classForIconButton)} accessibleName={accessibleName}/>;
        case Icon.Expand:
            return <MaterialIconButtonWithToolTip icon={<ExpandIcon color={muiIconColor ?? theme.iconDark}/>} {...sharedButtonProps} className={cx(className, classForIconButton)} accessibleName={accessibleName}/>;
        case Icon.CopyToClipboard:
            return <MaterialIconButtonWithToolTip icon={<CopyIcon color={theme.iconDark}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.OverflowMenu:
            return <MaterialIconButtonWithToolTip icon={<MoreVertIcon htmlColor={theme.iconDark}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Bind:
            return <MaterialIconButtonWithToolTip icon={<BindIcon />} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.Unbind:
            return <MaterialIconButtonWithToolTip icon={<UnbindIcon />} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.InsertVariable:
            return <MaterialIconButtonWithToolTip icon={<InsertVariableIcon />} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.SelectLogo:
            return <MaterialIconButtonWithToolTip icon={<SelectLogoIcon />} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
        case Icon.ChevronIcon:
            return <MaterialIconButtonWithToolTip icon={<ChevronIcon htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral }}/>} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
    }
    return <MaterialIconButtonWithToolTip icon={customIcon} {...sharedButtonProps} className={className} accessibleName={accessibleName}/>;
};
IconButton.displayName = "IconButton"
interface MaterialIconButtonWithToolTipProps extends SharedButtonProps {
    icon?: JSX.Element;
    className?: string;
}
const MaterialIconButtonWithToolTip: React.FC<MaterialIconButtonWithToolTipProps> = ({ icon, disabled, tabIndex, buttonRef, className, style = {}, onClick, toolTipContent, accessibleName, ...otherProps }) => {
    const button = (<MaterialIconButton {...otherProps} className={classnames(styles.materialUIIcon, className)} disabled={disabled} tabIndex={tabIndex} onClick={onClick} ref={buttonRef} style={{ ...style }} aria-label={accessibleName}>
            {icon}
        </MaterialIconButton>);
    if (!toolTipContent) {
        return button;
    }
    return <ToolTip content={toolTipContent}>{button}</ToolTip>;
};
MaterialIconButtonWithToolTip.displayName = "MaterialIconButtonWithToolTip"
IconButton.displayName = "IconButton";
export default IconButton;
