import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";
type ExperimentalUIProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function ExperimentalUI({ isEnabled, onChange }: ExperimentalUIProps) {
    return (<ExpandableFormSection key="IsExperimentalUIEnabled" errorKey="IsExperimentalUIEnabled" title="Experimental UI" summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")} help="Be among the first to try out new UI features">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled"/>
                <RadioButton value={false} label="Disabled" isDefault={true}/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
}
