import React = require("react");
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const image = require("../../Images/Channels.svg");
export const CommonProjectGitView: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ConfigAsCode">Config as code</ExternalLink> allows you to store your project configuration in version control.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonProjectGitView.displayName = "CommonProjectGitView"
