import type { KubernetesIngressProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import { KubernetesIngressComponent } from "~/components/Actions/kubernetes/kubernetesIngressComponent";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import StringKeyValueEditList from "~/components/EditList/KeyValueEditList";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import { JsonUtils } from "~/utils/jsonUtils";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
class KubernetesDeployIngressActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Deploy an ingress resource to Kubernetes</div>;
    }
    static displayName = "KubernetesDeployIngressActionSummary";
}
class KubernetesDeployIngressActionEdit extends BaseComponent<ActionEditProps<KubernetesIngressProperties>, never> {
    constructor(props: ActionEditProps<KubernetesIngressProperties>) {
        super(props);
    }
    render() {
        return (<div>
                <KubernetesStatusCheckComponent jobsSupported={false} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <KubernetesIngressComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} standAlone={true}/>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DefaultRulePort|Octopus.Action.KubernetesContainers.DefaultRuleServiceName" isExpandedByDefault={this.props.expandedByDefault} title="Default Rule" summary={this.defaultRuleSummary()} help={"The default rule applied when no other rule matches"}>
                    <Note>When there are no matching ingress rules, traffic can be sent to the service configured as the default rule.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-ingress#default-rule">default rules</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DefaultRulePort")} value={this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DefaultRulePort"]: val })} label={"Port"}/>
                    <VariableLookupText localNames={this.props.localNames} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DefaultRuleServiceName")} value={this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]: val })} label={"Service name"}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels" isExpandedByDefault={this.props.expandedByDefault} title="Ingress Labels" summary={this.labelsSummary()} help={"The labels applied to the ingress resource"}>
                    <Note>Labels are optional name/value pairs that are assigned to the ingress resource.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-ingress#ingress-labels">ingress labels</ExternalLink>.
                    </Note>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>
            </div>);
    }
    private defaultRuleSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"] && this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]) {
            return Summary.summary(<span>
                    Directing unmatched traffic to{" "}
                    <strong>
                        {this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]}:{this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]}
                    </strong>
                </span>);
        }
        return Summary.placeholder(<span>Define the default service to recieve unmatched traffic</span>);
    }
    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));
        if (labels.length === 0) {
            return Summary.placeholder("The service resource labels");
        }
        return Summary.summary(<span>
                Add the label{labels.length > 1 && <span>s</span>}{" "}
                {_.chain(labels)
                .flatMap((pair) => [
                <strong>
                            {pair[0]}: {pair[1]}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    static displayName = "KubernetesDeployIngressActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployIngress",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployIngressActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    edit: KubernetesDeployIngressActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => (isFeatureToggleEnabled("KubernetesDeploymentStatusFeatureToggle") ? InitialStatusCheckWithTimeoutProperties : {}),
});
