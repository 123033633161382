import type { InputPathToObject, InputPathToValue } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, createObjectValueAccessor, getPathToInput, getPathToInputObject, isInputPathToObject } from "@octopusdeploy/step-runtime-inputs";
import type { RadioButtonOption, RadioButtonsComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { getSelectedOption, getSelectedOptionForPrimitive } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/getSelectedOption";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { RadioButton } from "~/components/form";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { Note } from "../../../Note/Note";
interface RadioButtonsProps<StepInputs> extends SharedRadioButtonProps<StepInputs> {
    configuredStepUIProps: RadioButtonsComponent;
}
interface SharedRadioButtonProps<StepInputs> {
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}
export function RadioButtons<StepInputs>(props: RadioButtonsProps<StepInputs>) {
    return (<>
            {isInputPathToObject(props.configuredStepUIProps.input) ? (<DiscriminatedUnionRadioButtons<StepInputs> input={props.configuredStepUIProps.input} label={props.configuredStepUIProps.label} options={props.configuredStepUIProps.options} setInputs={props.setInputs} getFieldError={props.getFieldError} inputs={props.inputs} getInputSchema={props.getInputSchema}/>) : (<LiteralUnionRadioButtons input={props.configuredStepUIProps.input} label={props.configuredStepUIProps.label} options={props.configuredStepUIProps.options} inputs={props.inputs} getInputSchema={props.getInputSchema} setInputs={props.setInputs} getFieldError={props.getFieldError}/>)}
            <Note note={props.configuredStepUIProps.note}/>
        </>);
}
export function getRadioButtonsSummary<StepInputs>(inputs: ObjectRuntimeInputs<StepInputs>, radioButtons: RadioButtonsComponent, getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition): InputSummary {
    if (isInputPathToObject(radioButtons.input)) {
        const selectedOption = getSelectedOption(radioButtons.input, radioButtons.options, getInputSchema(inputs));
        return { isDefaultValue: false, value: selectedOption.label };
    }
    const accessor = createInputValueAccessor<StepInputs, unknown>(radioButtons.input);
    const value = accessor.getInputValue(inputs);
    const selectedOption = getSelectedOptionForPrimitive(value, radioButtons.options);
    //TODO: step-api handle Bound case
    return { isDefaultValue: false, value: selectedOption.label };
}
interface DiscriminatedUnionRadioButtonProps<StepInputs> extends SharedRadioButtonProps<StepInputs> {
    input: InputPathToObject<unknown>;
    label: string;
    options: RadioButtonOption<unknown>[];
}
export function DiscriminatedUnionRadioButtons<StepInputs>(props: DiscriminatedUnionRadioButtonProps<StepInputs>) {
    const { options, label, input } = props;
    const accessor = createObjectValueAccessor<StepInputs, unknown>(input);
    const inputPath = getPathToInputObject(input);
    const selectedOption = getSelectedOption(input, options, props.getInputSchema(props.inputs));
    return (<RadioButtonGroup value={selectedOption.label} onChange={(x) => {
            const newlySelectedOption = options.find((o) => o.label === x);
            if (!newlySelectedOption) {
                throw new Error("Selected option not found");
            }
            const newValue = newlySelectedOption.newValue;
            const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} error={props.getFieldError(inputPath)} label={label} accessibleName={label}>
            {options.map((o) => {
            return <RadioButton key={o.label} value={o.label} label={o.label}/>;
        })}
        </RadioButtonGroup>);
}
interface LiteralUnionRadioButtonProps<StepInputs> extends SharedRadioButtonProps<StepInputs> {
    input: InputPathToValue<unknown>;
    label: string;
    options: RadioButtonOption<unknown>[];
}
function LiteralUnionRadioButtons<StepInputs>(props: LiteralUnionRadioButtonProps<StepInputs>) {
    const { options, label, input } = props;
    const accessor = createInputValueAccessor<StepInputs, unknown>(input);
    const value = accessor.getInputValue(props.inputs);
    //TODO: step-api handle bound case here
    const inputPath = getPathToInput(input);
    const selectedOption = getSelectedOptionForPrimitive(value, options);
    return (<RadioButtonGroup value={selectedOption.label} onChange={(x) => {
            const newlySelectedOption = options.find((o) => o.label === x);
            if (!newlySelectedOption) {
                throw new Error("Selected option not found");
            }
            const newValue = newlySelectedOption.newValue;
            const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} error={props.getFieldError(inputPath)} label={label} accessibleName={label}>
            {options.map((o) => {
            return <RadioButton key={o.label} value={o.label} label={o.label}/>;
        })}
        </RadioButtonGroup>);
}
