/* eslint-disable @typescript-eslint/no-explicit-any */
import * as PropTypes from "prop-types";
import * as React from "react";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import { normal } from "~/fontWeights";
import type { DropdownMenuOption } from "./DropDownMenu";
import { DropDownMenu } from "./DropDownMenu";
function getStyles(theme: OctopusTheme, highContrastMode?: boolean): Record<string, React.CSSProperties> {
    return {
        root: {
            marginTop: 0,
        },
        label: {
            paddingLeft: 0,
            top: 8,
        },
        floatingLabelStyle: {
            position: "absolute",
            fontWeight: normal,
            color: highContrastMode ? theme.primaryText : theme.secondaryText,
            lineHeight: highContrastMode ? "1em" : "0.75rem",
            transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
            fontSize: highContrastMode ? "1em" : "0.75rem",
        },
        hintLabelStyle: {
            position: "absolute",
            fontWeight: normal,
            color: theme.secondaryText,
            lineHeight: "1rem",
            fontSize: "1rem",
            top: "1.5rem",
        },
        icon: {
            right: 0,
            top: 0,
            marginTop: 0,
            fill: theme.secondaryText,
        },
        hideDropDownUnderline: {
            borderTop: "none",
        },
        dropDownMenu: {
            display: "block",
            borderBottom: `1px solid ${theme.secondaryText}`,
        },
        error: {
            color: theme.dangerText,
            fontSize: highContrastMode ? "1em" : "0.75rem",
            marginTop: "3px",
        },
        container: {
            position: "relative",
            margin: "0 0 1rem 0", // Needed to match the bottom-spacing of our multi-selects. When you stack
            // various filters, this helps to keep them all consistent (example, see Task screen's advanced filters).
        },
    };
}
interface SelectFieldProps {
    floatingLabelText?: string | JSX.Element;
    highContrastMode?: boolean;
    errorText?: string;
    placeholder?: string;
    className?: string;
    allowClear?: boolean;
    autoWidth?: boolean;
    items: DropdownMenuOption[];
    style?: any;
    labelStyle?: any;
    iconStyle?: any;
    id?: string;
    underlineDisabledStyle?: any;
    menuItemStyle?: any;
    selectedMenuItemStyle?: any;
    dropDownMenuProps?: any;
    disabled?: boolean;
    listStyle?: any;
    maxHeight?: number;
    menuStyle?: any;
    onFocus?: any;
    onBlur?: any;
    onChange?: (value: string) => void;
    selectionRenderer?: any;
    value: string;
    type?: any;
    filter?: any;
    autoFocus?: boolean;
    selectedValueAccessibleName?: string;
    children?: never;
}
export class SelectField extends React.Component<SelectFieldProps, any> {
    static defaultProps: Partial<SelectFieldProps> = {
        autoWidth: false,
        disabled: false,
    };
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };
    render() {
        return withTheme((theme) => {
            const { allowClear, autoWidth, items, style, labelStyle, iconStyle, id, underlineDisabledStyle, menuItemStyle, selectedMenuItemStyle, dropDownMenuProps, disabled, listStyle, maxHeight, menuStyle, onFocus, onBlur, onChange, selectionRenderer, value, type, filter, autoFocus, floatingLabelText, errorText, placeholder, selectedValueAccessibleName, highContrastMode, ...rest } = this.props;
            if (selectedMenuItemStyle && !selectedMenuItemStyle.color) {
                selectedMenuItemStyle.color = theme.primary;
            }
            const styles = getStyles(theme, this.props.highContrastMode);
            const floatingLabelStyle = { ...styles.floatingLabelStyle };
            const hintLabelStyle = { ...styles.hintLabelStyle };
            const dropDownMenuStyle = { ...styles.dropDownMenu, ...menuStyle };
            if (errorText) {
                floatingLabelStyle.color = theme.dangerText;
                hintLabelStyle.color = theme.dangerText;
                dropDownMenuStyle.borderBottomColor = theme.dangerText;
                dropDownMenuStyle.borderBottomWidth = "2px";
            }
            const floatingLabelTextElement = floatingLabelText && <label style={floatingLabelStyle}>{floatingLabelText}</label>;
            const hintLabelTextElement = !value && placeholder && <div style={styles.hintLabelStyle}>{placeholder}</div>;
            const errorTextElement = errorText && <div style={styles.error}>{errorText}</div>;
            return (<div style={styles.container} className={this.props.className} role="combobox">
                    {floatingLabelTextElement}
                    {hintLabelTextElement}
                    <DropDownMenu allowClear={allowClear} disabled={disabled} style={dropDownMenuStyle} labelStyle={{ ...styles.label, ...labelStyle }} iconStyle={{ ...styles.icon, ...iconStyle }} menuItemStyle={menuItemStyle} selectedMenuItemStyle={selectedMenuItemStyle} underlineStyle={styles.hideDropDownUnderline} listStyle={listStyle} autoWidth={autoWidth} value={value} selectedValueAccessibleName={selectedValueAccessibleName} onChange={onChange} maxHeight={maxHeight} selectionRenderer={selectionRenderer} filter={filter} autoFocus={autoFocus} onFocus={onFocus} onBlur={onBlur} id={id} items={items} {...rest} {...dropDownMenuProps}/>
                    {errorTextElement}
                </div>);
        });
    }
    static displayName = "SelectField";
}
