import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryCertificateOverview, CommonLibraryCertificateTopics } from "../../CommonComponents/CommonLibraryCertificateViews";
export const LibraryCertificateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.certificate,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryCertificateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryCertificateTopics />;
    },
};
