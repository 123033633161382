import { ProjectContextRepository, canCommitTo, Permission, deploymentActionPackages, displayName } from "@octopusdeploy/octopus-server-client";
import type { DeploymentSettingsResource, DeploymentProcessResource, GuidedFailureMode, ProjectResource, ModifyDeploymentSettingsCommand, GitBranchResource, DeploymentActionPackageResource } from "@octopusdeploy/octopus-server-client";
import { chain, flatten } from "lodash";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import { useNotifyCreateBranch } from "~/areas/projects/components/BranchSelector/Analytics/useNotifyCreateBranch";
import type { CreateBranchDispatcher } from "~/areas/projects/components/BranchSelector/Analytics/useNotifyCreateBranch";
import type { SaveDeploymentSettingsDispatcher } from "~/areas/projects/components/DeploymentProcessSettings/Analytics/useNotifySaveDeploymentSettings";
import { useNotifySaveDeploymentSettings } from "~/areas/projects/components/DeploymentProcessSettings/Analytics/useNotifySaveDeploymentSettings";
import FailureMode from "~/areas/projects/components/Releases/Deployments/FailureMode";
import { DeploymentModelType } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { client, repository } from "~/clientInstance";
import { RoleChip } from "~/components/Chips";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { BooleanRadioButtonGroup, ExpandableFormSection, MarkdownEditor, Note, RadioButton, StringRadioButtonGroup, Summary, UnstructuredFormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { FormPaperLayoutProps } from "../../../../components/FormPaperLayout";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import type { GetCommitButtonProps } from "../VersionControl/CommitButton";
import { GetCommitButton } from "../VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "../VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "../VersionControl/CommitMessageWithDetails";
import { DeploymentSettingsFormPaperLayout, VersionControlledDeploymentSettingsFormPaperLayout } from "./DeploymentProcessSettingsFormPaperLayout";
import { isVersioningConfigurationValid } from "./InvalidConfigurationCallout";
import { ReleaseVersioning } from "./ReleaseVersioning";
export interface DeploymentSettingsModel {
    guidedFailureMode: GuidedFailureMode;
    releaseNotesTemplate: string | undefined;
    skipIfAlreadyInstalled: boolean;
    deploymentChangesTemplate: string | undefined;
    skipMachines: string;
    skipMachinesRoles: string[];
    allowDeploymentsToNoTargets: boolean;
    forcePackageDownload: boolean;
    excludeUnhealthyTargets: boolean;
    versioningStrategyTemplate: string;
    versionFromPackage: boolean;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
}
interface DeploymentSettingsState extends OptionalFormBaseComponentState<DeploymentSettingsModel> {
    machineRoles: string[];
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess?: DeploymentProcessResource;
    deploymentSettings?: DeploymentSettingsResource;
    project?: ProjectResource;
    commitMessage: CommitMessageWithDetails;
    disableDirtyFormChecking?: boolean;
}
interface DeploymentSettingsAnalyticsProps {
    createBranchDispatcher: CreateBranchDispatcher;
    saveDeploymentSettingsDispatcher: SaveDeploymentSettingsDispatcher;
}
type MatchProps = {
    match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined;
};
type DeploymentSettingsProps = MatchProps & WithProjectContextInjectedProps;
type DeploymentSettingsPropsInternal = DeploymentSettingsProps & DeploymentSettingsAnalyticsProps & {
    branchProtectionsAreEnabled: boolean;
};
export const defaultReleaseVersioningTemplate: string = "#{Octopus.Version.LastMajor}.#{Octopus.Version.LastMinor}.#{Octopus.Version.NextPatch}";
const defaultCommitMessage = "Update deployment settings";
class DeploymentProcessSettingsInternal extends FormBaseComponent<DeploymentSettingsPropsInternal, DeploymentSettingsState, DeploymentSettingsModel> {
    private openCommitDialog?: () => void;
    constructor(props: DeploymentSettingsPropsInternal) {
        super(props);
        const project = this.props.projectContext.state.model;
        this.state = {
            machineRoles: [],
            versionPackageActions: [],
            project,
            commitMessage: { summary: "", details: "" },
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: DeploymentSettingsProps) {
        if (prevProps.projectContext.state.gitRef !== this.props.projectContext.state.gitRef) {
            await this.reload();
        }
    }
    private reload = async () => await this.doBusyTask(async () => {
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        const deploymentSettings = await projectContextRepository.DeploymentSettings.get();
        const hasProcessViewPermissions = isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
            tenant: "*",
        });
        const [deploymentProcess, machineRoles] = await Promise.all([hasProcessViewPermissions ? projectContextRepository.DeploymentProcesses.get() : Promise.resolve(undefined), repository.MachineRoles.all()]);
        const versionPackageActions = deploymentActionPackages(chain(deploymentProcess && deploymentProcess.Steps)
            .flatMap((step) => step.Actions)
            .filter((action) => action.CanBeUsedForProjectVersioning)
            .value());
        this.setState({
            versionPackageActions,
            machineRoles,
            deploymentProcess,
            deploymentSettings,
            model: this.buildModel(deploymentSettings),
            cleanModel: this.buildModel(deploymentSettings),
        });
    }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    render() {
        const isVersionControlled = this.state.project?.IsVersionControlled ?? false;
        const innerLayout = this.state.model && this.state.deploymentSettings && this.state.project && (<>
                {displayCalloutIfVersioningConfigurationInvalid(this.state.project, this.state.deploymentSettings, this.state.deploymentProcess)}

                <ExpandableFormSection errorKey="versionFromPackage" title="Release Versioning" summary={this.releaseVersioningSummary()} help="Select how the next release number is generated when creating a release.">
                    <ReleaseVersioning project={this.state.project} deploymentProcess={this.state.deploymentProcess} versionFromPackage={this.state.model.versionFromPackage} versioningStrategyPackage={this.state.model.versioningStrategyPackage} versioningStrategyTemplate={this.state.model.versioningStrategyTemplate} versionPackageActions={this.state.versionPackageActions} setModelProp={(m) => this.setModelState(m)}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="releaseNotesTemplate" title="Release Notes Template" summary={this.state.model.releaseNotesTemplate ? Summary.summary("A release notes template has been specified") : Summary.placeholder("No release notes template provided")} help={this.buildReleaseNotesTemplateHelpInfo()}>
                    <MarkdownEditor value={this.state.model.releaseNotesTemplate} accessibleName="Release Notes Template" onChange={(releaseNotesTemplate) => this.setChildState1("model", { releaseNotesTemplate })}/>
                    <Note>
                        A release notes template is a convenient way to keep release notes consistent and avoid entering the same text repeatedly. The template generates default release notes, which can be modified on the create release page if
                        desired. See the <ExternalLink href="ReleaseNotes">documentation</ExternalLink> for more information.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="allowDeploymentsToNoTargets" title="Deployment Targets Required" summary={this.deploymentTargetsSummary()} help="Choose if deployments are allowed if there are no deployment targets.">
                    <BooleanRadioButtonGroup accessibleName="deployment targets allowed" value={this.state.model.allowDeploymentsToNoTargets} onChange={(allowDeploymentsToNoTargets) => this.setModelState({ allowDeploymentsToNoTargets })}>
                        <RadioButton value={true} label="Allow deployments to be created when there are no deployment targets" isDefault={true}/>
                        <RadioButton value={false} label="Deployments with no targets are not allowed"/>
                    </BooleanRadioButtonGroup>
                    <Note>
                        In many deployment scenarios, <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment. Octopus defaults to allowing deployments to start when there are no
                        deployment targets registered for this reason. To make deployment creation fail if no deployment targets are available, select Deployments with no targets are not allowed.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="skipMachines" title="Transient Deployment Targets" summary={this.skipMachinesSummary()} help="Choose to skip unavailable, or exclude unhealthy targets from the deployment.">
                    <StringRadioButtonGroup accessibleName="unavailable deployment targets" label="Unavailable Deployment targets" value={this.state.model.skipMachines} onChange={this.handleSkipMachinesChanged}>
                        <RadioButton value="None" label="Fail deployment" isDefault={true}/>
                        <RadioButton value="SkipUnavailableMachines" label="Skip and continue"/>
                        <Note>Deployment targets that are unavailable at the start of the deployment or become unavailable during the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                    <Note>
                        <ExternalLink href="DynamicInfrastructure">Read more</ExternalLink> about deploying to elastic and transient environments.
                    </Note>
                    {this.state.model.skipMachines === "SkipUnavailableMachines" && (<>
                            <RoleMultiSelect onChange={(skipMachinesRoles) => this.setModelState({ skipMachinesRoles })} value={this.state.model.skipMachinesRoles} label="Skip unavailable deployment targets only in selected roles" accessibleName="Roles to skip unavailable deployment targets" items={this.state.machineRoles}/>
                            <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                        </>)}
                    <StringRadioButtonGroup accessibleName="unhealthy deployment targets" value={this.state.model.excludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"} onChange={(skipUnhealthyTargets) => this.setModelState({ excludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })} label="Unhealthy Deployment Targets">
                        <RadioButton value="None" label="Do not exclude" isDefault={true}/>
                        <RadioButton value="ExcludeUnhealthy" label="Exclude"/>
                        <Note>Deployment targets that are unhealthy at the start of the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="deploymentChangesTemplate" title="Deployment Changes Template" summary={this.state.model.deploymentChangesTemplate ? Summary.summary("A deployment changes template has been specified") : Summary.placeholder("No deployment changes template provided")} help={this.buildDeploymentChangesTemplateHelpInfo()}>
                    <MarkdownEditor value={this.state.model.deploymentChangesTemplate} label="Deployment changes template" onChange={(deploymentChangesTemplate) => this.setChildState1("model", { deploymentChangesTemplate })}/>
                </ExpandableFormSection>

                <FailureMode guidedFailureMode={this.state.model.guidedFailureMode} onModeChanged={(guidedFailureMode) => this.setModelState({ guidedFailureMode })} title="Default Failure Mode" modelType={DeploymentModelType.Deployment}/>

                <ExpandableFormSection errorKey="forcePackageDownload" title="Force Package Download" summary={this.forcePackageDownloadSummary()} help="Choose whether to force re-downloading packages for deployments">
                    <BooleanRadioButtonGroup accessibleName="Force package download" value={this.state.model.forcePackageDownload} onChange={(forcePackageDownload) => this.setModelState({ forcePackageDownload })}>
                        <RadioButton value={false} label="Use cached packages (if available)" isDefault={true}/>
                        <RadioButton value={true} label="Re-download packages from feed"/>
                    </BooleanRadioButtonGroup>
                    <Note>Sets a default package download option when deploying a release.</Note>
                </ExpandableFormSection>
            </>);
        const overflowMenuItems: Array<MenuItem | MenuItem[]> = this.state.deploymentSettings
            ? [
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProjectDeploymentSettings(this.state.deploymentSettings.ProjectId), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]
            : [];
        const formPaperLayoutProps: FormPaperLayoutProps = {
            busy: this.state.busy,
            errors: this.errors,
            model: this.state.model,
            cleanModel: this.state.cleanModel,
            onSaveClick: this.handleSaveClick,
            savePermission: this.editPermission(),
            overFlowActions: overflowMenuItems,
            breadcrumbTitle: this.state.project?.Name,
        };
        const onNewBranchCreating = async (branchName: string) => {
            this.setState({ disableDirtyFormChecking: true });
            // TODO @team-config-as-code: Why does this check for a project?
            // How did the user even get to this page w/o a project? I suspect
            // that this check is nonsense and redundant, but need to confirm.
            if (this.state.project) {
                this.setState({ disableDirtyFormChecking: true });
                const newBranchResource = await this.props.createBranchDispatcher({
                    source: "Commit changes dialog",
                    createBranchAction: (cb: AnalyticErrorCallback) => {
                        try {
                            return this.props.projectContext.state.projectContextRepository.Branches.createBranch(this.props.projectContext.state.model, branchName, this.props.projectContext.state.gitRef?.CanonicalName ?? "");
                        }
                        catch (ex) {
                            cb(ex);
                            this.setState({ disableDirtyFormChecking: false });
                            throw ex;
                        }
                    },
                });
                await this.handleSaveClick(false, newBranchResource);
                this.props.projectContext.actions.changeGitRef(branchName);
            }
            this.setState({ disableDirtyFormChecking: false });
        };
        const commitButtonProps = { ...this.getCommitButtonProps(), onNewBranchCreating };
        if (isVersionControlled) {
            return (<VersionControlledDeploymentSettingsFormPaperLayout {...formPaperLayoutProps} disableDirtyFormChecking={this.state.disableDirtyFormChecking} customPrimaryAction={(primaryActionProps) => <GetCommitButton {...commitButtonProps} actionButtonProps={primaryActionProps}/>} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                    {innerLayout}
                </VersionControlledDeploymentSettingsFormPaperLayout>);
        }
        return (<DeploymentSettingsFormPaperLayout {...formPaperLayoutProps} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                {innerLayout}
            </DeploymentSettingsFormPaperLayout>);
    }
    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }
    buildModel(deploymentSettings: DeploymentSettingsResource): DeploymentSettingsModel {
        if (!deploymentSettings.VersioningStrategy) {
            deploymentSettings.VersioningStrategy = { Template: defaultReleaseVersioningTemplate, DonorPackage: undefined };
        }
        if (!deploymentSettings.ConnectivityPolicy) {
            deploymentSettings.ConnectivityPolicy = {
                SkipMachineBehavior: "None",
                TargetRoles: [],
                AllowDeploymentsToNoTargets: true,
                ExcludeUnhealthyTargets: false,
            };
        }
        return {
            versionFromPackage: !!deploymentSettings.VersioningStrategy.DonorPackage,
            versioningStrategyTemplate: deploymentSettings.VersioningStrategy.Template,
            versioningStrategyPackage: deploymentSettings.VersioningStrategy.DonorPackage,
            skipIfAlreadyInstalled: deploymentSettings.DefaultToSkipIfAlreadyInstalled,
            allowDeploymentsToNoTargets: deploymentSettings.ConnectivityPolicy.AllowDeploymentsToNoTargets,
            skipMachines: deploymentSettings.ConnectivityPolicy.SkipMachineBehavior,
            skipMachinesRoles: deploymentSettings.ConnectivityPolicy.TargetRoles,
            guidedFailureMode: deploymentSettings.DefaultGuidedFailureMode,
            excludeUnhealthyTargets: deploymentSettings.ConnectivityPolicy.ExcludeUnhealthyTargets,
            releaseNotesTemplate: deploymentSettings.ReleaseNotesTemplate,
            deploymentChangesTemplate: deploymentSettings.DeploymentChangesTemplate,
            forcePackageDownload: deploymentSettings.ForcePackageDownload,
        };
    }
    handleSaveClick = async (isNavigationConfirmation: boolean, newBranch?: GitBranchResource) => {
        if (this.state.model?.versionFromPackage && !this.state.model.versioningStrategyPackage) {
            this.setValidationErrors("Please select a package step to use release versioning from an included package");
            return;
        }
        if (isNavigationConfirmation && this.openCommitDialog && this.state.project?.IsVersionControlled) {
            this.openCommitDialog();
        }
        else {
            const model = this.state.model;
            if (!model || !this.state.deploymentSettings) {
                throw "no model loaded";
            }
            const settings = { ...this.state.deploymentSettings, Links: { ...this.state.deploymentSettings.Links, Self: newBranch ? newBranch.Links.DeploymentSettings : this.state.deploymentSettings.Links.Self } };
            const cm = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
            const deploymentSettings: ModifyDeploymentSettingsCommand = {
                ...settings,
                DefaultToSkipIfAlreadyInstalled: model.skipIfAlreadyInstalled,
                VersioningStrategy: {
                    DonorPackage: model.versionFromPackage ? model.versioningStrategyPackage : undefined,
                    Template: model.versionFromPackage ? "" : model.versioningStrategyTemplate,
                },
                ConnectivityPolicy: {
                    AllowDeploymentsToNoTargets: model.allowDeploymentsToNoTargets,
                    SkipMachineBehavior: model.skipMachines,
                    TargetRoles: model.skipMachinesRoles,
                    ExcludeUnhealthyTargets: model.excludeUnhealthyTargets,
                },
                DefaultGuidedFailureMode: model.guidedFailureMode,
                ReleaseNotesTemplate: model.releaseNotesTemplate,
                DeploymentChangesTemplate: model.deploymentChangesTemplate,
                ChangeDescription: cm,
                ForcePackageDownload: model.forcePackageDownload,
            };
            await this.doBusyTask(async () => {
                const repo = newBranch ? new ProjectContextRepository(client, this.props.projectContext.state.model, newBranch) : this.props.projectContext.state.projectContextRepository;
                const result = await repo.DeploymentSettings.modify(deploymentSettings);
                this.props.saveDeploymentSettingsDispatcher({
                    isDefaultBranch: this.props.projectContext.state.isDefaultBranch ?? false,
                    isVersionControlled: this.state.project?.IsVersionControlled ?? false,
                    hasCommitMessage: cm.length > 0,
                });
                // The project stored in context contains the deployment setting properties at a top level.
                // This stored model is not updated when the deployment settings are updated, so we need to refresh it to keep things consistent.
                await this.props.projectContext.actions.refreshModel();
                this.setState(() => ({
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    deploymentSettings: result,
                    disableDirtyFormChecking: false,
                }));
            });
        }
    };
    private getCommitButtonProps(): Omit<GetCommitButtonProps, "actionButtonProps"> {
        return {
            project: this.props.projectContext.state.model,
            gitRef: this.props.projectContext.state.gitRef?.CanonicalName,
            canCommitToGitRef: !this.props.branchProtectionsAreEnabled || canCommitTo(this.props.projectContext.state.gitRef),
            defaultCommitMessage,
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: "Commit message for saving the deployment settings",
            commitDetailsAccessibleName: "Commit details for saving the deployment settings",
            commitButtonAccessibleName: "Commit changes to the deployment settings",
            onNewBranchCreating: async (branchName: string) => {
                this.setState({ disableDirtyFormChecking: true });
                if (this.state.project) {
                    const newBranchResource = await this.props.projectContext.state.projectContextRepository.Branches.createBranch(this.props.projectContext.state.model, branchName, this.props.projectContext.state.gitRef?.CanonicalName ?? "");
                    await this.handleSaveClick(false, newBranchResource);
                    this.props.projectContext.actions.changeGitRef(branchName);
                    this.setState({ disableDirtyFormChecking: false });
                }
                this.setState({ disableDirtyFormChecking: false });
            },
            onInitializing: (openCommitDialog: () => void) => (this.openCommitDialog = openCommitDialog),
        };
    }
    private handleSkipMachinesChanged = (skipMachines: string) => this.setState((state) => ({
        model: {
            ...state.model,
            skipMachines,
            skipMachinesRoles: skipMachines === "None" ? [] : state.model?.skipMachinesRoles,
        },
    }));
    private deploymentTargetsSummary(): SummaryNode {
        return this.state.model?.allowDeploymentsToNoTargets ? Summary.default("Deployments may start without a deployment target") : Summary.summary("A deployment target is required before a deployment may start");
    }
    private skipMachinesSummary(): SummaryNode {
        if (this.state.model?.skipMachines !== "SkipUnavailableMachines") {
            return this.state.model?.excludeUnhealthyTargets ? Summary.summary("Deployment will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Deployment will fail if a deployment target is unavailable");
        }
        const roles = this.state.model.skipMachinesRoles;
        const summary = [this.state.model.excludeUnhealthyTargets ? <span key="skipMachines">Deployment will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Deployment will skip unavailable targets</span>];
        if (roles.length > 0) {
            summary.push(this.state.model.skipMachinesRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);
            roles.forEach((r) => summary.push(<RoleChip role={r} key={"role-" + r}/>));
        }
        return Summary.summary(React.Children.toArray(summary));
    }
    private releaseVersioningSummary(): SummaryNode {
        if (this.state.model?.versionFromPackage) {
            const versioningPackage = this.state.model?.versioningStrategyPackage;
            return !!versioningPackage && !!versioningPackage.DeploymentAction
                ? Summary.summary(<span>
                          Based on the package step <strong>{displayName(versioningPackage)}</strong>
                      </span>)
                : Summary.summary("Based on the package in a step, please select a step");
        }
        const template = this.state.model?.versioningStrategyTemplate;
        return Summary.default(template ? (<span>Based on template {template}</span>) : (<span>
                    Based on template, <strong>no template set</strong>
                </span>));
    }
    private forcePackageDownloadSummary(): SummaryNode {
        return this.state.model?.forcePackageDownload ? Summary.summary("A package will be re-downloaded from feed") : Summary.default("Use cached packages (if available)");
    }
    private buildReleaseNotesTemplateHelpInfo(): string {
        return "Enter a template for the release notes that will be used for new releases.";
    }
    private buildDeploymentChangesTemplateHelpInfo() {
        return (<span>
                Enter a template for the markdown generated for each deployment's changes. The markdown can be accessed during a deployment using the <code>Octopus.Deployment.ChangesMarkdown</code> variable.{" "}
                <ExternalLink href="DeploymentChangesTemplate">Learn more</ExternalLink>
            </span>);
    }
    static displayName = "DeploymentProcessSettingsInternal";
}
const DeploymentProcessSettings: React.FC = () => {
    const match = useRouteMatch() ?? undefined;
    const projectContext = useProjectContext();
    const createBranchDispatcher = useNotifyCreateBranch();
    const saveDeploymentSettingsDispatcher = useNotifySaveDeploymentSettings();
    const branchProtectionsAreEnabled = isFeatureToggleEnabled("BranchProtectionsFeatureToggle");
    return (<DeploymentProcessSettingsInternal match={match} projectContext={projectContext} createBranchDispatcher={createBranchDispatcher} saveDeploymentSettingsDispatcher={saveDeploymentSettingsDispatcher} branchProtectionsAreEnabled={branchProtectionsAreEnabled}/>);
};
DeploymentProcessSettings.displayName = "DeploymentProcessSettings"
function displayCalloutIfVersioningConfigurationInvalid(project: ProjectResource, deploymentSettings: DeploymentSettingsResource, deploymentProcess?: DeploymentProcessResource) {
    if (!deploymentProcess)
        return <></>;
    const actions = flatten(deploymentProcess.Steps.map((step) => step.Actions));
    const callout = isVersioningConfigurationValid(project, deploymentSettings, actions);
    if (callout) {
        return (<UnstructuredFormSection stretchContent={true}>
                <Callout type={CalloutType.Warning} title="Invalid Configuration">
                    {callout}
                </Callout>
            </UnstructuredFormSection>);
    }
}
export default DeploymentProcessSettings;
