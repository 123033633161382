import type { TagResource, MachineTenantTagSummaryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Navigate } from "~/components/Navigation/Navigate";
import Tag from "~/components/Tag";
import routeLinks from "~/routeLinks";
import styles from "../style.module.less";
interface TagSetContentRowProps {
    tag: TagResource;
    tenantTagSummaries: MachineTenantTagSummaryResource;
}
export const TagSetContentRow = ({ tag, tenantTagSummaries }: TagSetContentRowProps) => {
    const totalMachines = (tenantTagSummaries && tenantTagSummaries[tag.Id]) || 0;
    return (<>
            <div className={styles.tenantTagRowsContainer} key={tag.Name}>
                <div className={styles.tenantTagName}>
                    <Navigate render={(history) => (<Tag tagName={tag.Name} tagColor={tag.Color} key={tag.Name} description={tag.Description} onClick={() => history.navigate(routeLinks.infrastructure.machines.filtered({ tenantTags: tag.CanonicalTagName }))}/>)}/>
                </div>
                <div className={styles.tenantTagMachinesCount}>{totalMachines.toLocaleString()}</div>
            </div>
        </>);
};
