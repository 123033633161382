import type { WorkerPoolSummaryResource, WorkerPoolsSummaryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import routeLinks from "~/routeLinks";
import { ResultsLink } from "../ResultsLink";
import styles from "../style.module.less";
import { WorkerPoolsContentRow } from "./WorkerPoolContentRow";
interface WorkerPoolsContentWithThemeProps {
    summaryResource: WorkerPoolsSummaryResource;
    numberOfItemsToRender: number;
    theme: OctopusTheme;
}
const onFilter = (filter: string, resource: WorkerPoolSummaryResource) => {
    return !filter || filter.length === 0 || !resource || resource.WorkerPool.Name.toLowerCase().includes(filter.toLowerCase());
};
const WorkerPoolsContentWithTheme = ({ summaryResource, numberOfItemsToRender, theme }: WorkerPoolsContentWithThemeProps) => {
    const [filterText, setFilterText] = useState("");
    const workerSummaries = summaryResource.WorkerPoolSummaries.filter((item) => onFilter(filterText, item)).slice(0, numberOfItemsToRender);
    return (<>
            {(summaryResource.WorkerPoolSummaries.length > 1 || filterText) && (<FilterSearchBox placeholder="Filter..." onChange={(value) => setFilterText(value.toLowerCase())} containerClassName={styles.cardContentContainerFilter} inputClassName={styles.dashboardFilterInputClassName} iconColor={theme.secondaryText} iconStyle={{ width: "20px", height: "20px", top: "14px" }}/>)}
            <div className={styles.cardContentContainerRows}>
                {workerSummaries.map((workerSummary: WorkerPoolSummaryResource, index: number) => {
            return <WorkerPoolsContentRow key={workerSummary.WorkerPool.Id + "-" + index.toString()} workerSummary={workerSummary}/>;
        })}
                {<ResultsLink totalCount={summaryResource.WorkerPoolSummaries.length} renderedCount={workerSummaries.length} link={routeLinks.infrastructure.workerPools.root}/>}
            </div>
        </>);
};
interface WorkerPoolsContentProps {
    summaryResource: WorkerPoolsSummaryResource;
    numberOfItemsToRender: number;
}
export const WorkerPoolsContent = (props: WorkerPoolsContentProps) => {
    const theme = useOctopusTheme();
    return <WorkerPoolsContentWithTheme {...props} theme={theme}/>;
};
