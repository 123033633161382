import type { SummaryView } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { EnvironmentChip } from "~/components/Chips/index";
interface TenantDataTableMultipleProjectsEnvironmentsCellProps {
    hasMissingVariables: boolean;
    environments: string[];
}
export function TenantDataTableMultipleProjectsEnvironmentsCell({ hasMissingVariables, environments }: TenantDataTableMultipleProjectsEnvironmentsCellProps) {
    if (environments.length === 1) {
        return (<div>
                <MissingVariablesIcon show={hasMissingVariables}/>
                <EnvironmentChip environmentName={environments[0]} key={environments[0]}/>
            </div>);
    }
    return (<div>
            <MissingVariablesIcon show={hasMissingVariables}/>
            <EnvironmentChip noTooltip={true} environmentName={"Multiple environments"} key={"Multiple environments"}/>
        </div>);
}
interface TenantDataTableEnvironmentsCellProps {
    environments?: SummaryView<string>;
    allEnvironments?: string[];
    hasMissingVariables: boolean;
    onShowAll?: (category: string) => Promise<void>;
    isTenantExpanded?: boolean;
}
export function TenantDataTableEnvironmentsCell({ environments, allEnvironments, hasMissingVariables, onShowAll, isTenantExpanded }: TenantDataTableEnvironmentsCellProps) {
    const [showAll, setShowAll] = useState(false);
    async function handleOnShowAll() {
        if (onShowAll) {
            await onShowAll("Environment");
        }
        setShowAll(!showAll);
    }
    const environmentsIsUndefined = !environments && !allEnvironments;
    const noEnvironments = (environments && environments.Items.length === 0) || (allEnvironments && allEnvironments.length === 0);
    if (environmentsIsUndefined || noEnvironments) {
        return <div>No environments</div>;
    }
    const environmentsToShow = (isTenantExpanded || showAll) && allEnvironments ? allEnvironments : environments?.Items ?? [];
    const displayEnvironments = environmentsToShow.map((e) => <EnvironmentChip environmentName={e} key={e}/>);
    return (<div>
            <MissingVariablesIcon show={hasMissingVariables}/>
            {displayEnvironments}
            {!isTenantExpanded && <Leftovers count={environments?.MoreItems} name={"environment"} onShowAllChanged={handleOnShowAll} showAll={showAll}/>}
        </div>);
}
