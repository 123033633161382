import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonConfigurationUserOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Manage permissions by assigning <ExternalLink href="ManagingUsersAndTeams">users</ExternalLink> to teams that are accessible in one space or all spaces (system team).
                </p>
                <p>
                    Learn more about <ExternalLink href="OctopusInvites">user invites</ExternalLink>.
                </p>
                <p>
                    Octopus Cloud user? Learn more about <ExternalLink href="OctopusCloudInvites">user invites for Octopus Cloud</ExternalLink>.
                </p>
                <ExternalVideoLink href="ManagingUsersAndTeamsVideo"/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonConfigurationUserOverview.displayName = "CommonConfigurationUserOverview"
export const CommonConfigurationUserTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ManagingUsersAndTeams">Users and Teams</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="OctopusCloudInvites">User invites for Octopus Cloud</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonConfigurationUserTopics.displayName = "CommonConfigurationUserTopics"
