import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryVariableSetOverview, CommonLibraryVariableSetTopics } from "../../CommonComponents/CommonLibraryVariableSetViews";
export const LibraryVariableSetsNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.variableSets.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryVariableSetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryVariableSetTopics />;
    },
};
