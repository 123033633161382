import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./ProcessActionErrorIndicator.module.less";
interface ProcessActionWarningIndicatorProps {
    actionWarnings: string[];
    accessibleName?: string;
}
export const ProcessActionWarningIndicator: React.FC<ProcessActionWarningIndicatorProps> = ({ actionWarnings, accessibleName }) => {
    const theme = useOctopusTheme();
    const items = actionWarnings.map((x, index) => <li key={index}>{x}</li>);
    return (<ToolTip content={<ul className={styles.itemContainer}>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa-solid fa-exclamation-triangle" style={{ color: theme.alert }} role="img" aria-label={accessibleName}/>
            </div>
        </ToolTip>);
};
ProcessActionWarningIndicator.displayName = "ProcessActionWarningIndicator"
