import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationRoleOverview, CommonConfigurationRoleTopics } from "../../CommonComponents/CommonConfigurationRoleViews";
export const ConfigurationRoleRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.role,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationRoleOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationRoleTopics />;
    },
};
