/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ListItem } from "material-ui";
import * as React from "react";
import { withTheme } from "~/components/Theme";
interface FocusListItemProps {
    containerElement?: JSX.Element;
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode;
    secondaryTextLines?: 1 | 2;
    leftIcon?: JSX.Element;
    isFocused: boolean;
    onRequestClose: (event?: React.MouseEvent<{}, MouseEvent>) => void;
    accessibleRole?: string;
}
export default class FocusListItem extends React.Component<FocusListItemProps> {
    element: ListItem = undefined!;
    componentDidMount() {
        if (this.props.isFocused) {
            this.element.applyFocusState("keyboard-focused");
        }
    }
    componentDidUpdate(prevProps: FocusListItemProps, prevState: FocusListItemProps) {
        if (prevProps.isFocused === this.props.isFocused) {
            return;
        }
        if (this.props.isFocused) {
            this.element.applyFocusState("keyboard-focused");
        }
    }
    handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && this.props.isFocused) {
            this.props.onRequestClose();
        }
    };
    render() {
        return withTheme((theme) => (<ListItem role={this.props.accessibleRole} ref={this.setRef} containerElement={this.props.containerElement} primaryText={this.props.primaryText} secondaryText={this.props.secondaryText} secondaryTextLines={this.props.secondaryTextLines} leftIcon={this.props.leftIcon} onKeyUp={this.handleKeyUp} onClick={this.props.onRequestClose} hoverColor={theme.hover} innerDivStyle={{ padding: "1rem" }} disableTouchRipple={DISABLE_LEGACY_MUI_RIPPLES} disableFocusRipple={DISABLE_LEGACY_MUI_RIPPLES}/>));
    }
    private setRef = (el: ListItem) => {
        this.element = el;
    };
    static displayName = "FocusListItem";
}
