/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ActivityElement, ActivityLogElement, ArtifactResource } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory, ActivityStatus } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import _, { flatten } from "lodash";
import * as React from "react";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import { withTheme } from "~/components/Theme";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import TextWithLinks from "../../../../../components/TextWithLinks/TextWithLinks";
import { RetryCountdown } from "../RetryCountdown";
import styles from "./style.module.less";
interface TaskSummaryLineProps {
    element: UniqueActivityElement;
    artifacts: ArtifactResource[];
    showFatalsInline?: boolean;
    onLogNavigation(taskLogLineId: string): void;
}
class TaskSummaryLine extends React.PureComponent<TaskSummaryLineProps> {
    render(): false | JSX.Element {
        const element = this.props.element;
        const childrenToShow = ((element.Children as UniqueActivityElement[]) || []).filter((e) => e.ShowAtSummaryLevel);
        const highlights = this.getLogsWithCategory(element, childrenToShow.length === 0, ActivityLogEntryCategory.Highlight).map((h, i) => (<div key={i}>
                <TextWithLinks message={h.MessageText} allowHtml={false}/>
            </div>));
        const fatals = this.getLogsWithCategory(element, false, ActivityLogEntryCategory.Fatal).map((h, i) => (<div key={i}>
                <TextWithLinks message={h.MessageText} allowHtml={false}/>
            </div>));
        const header = this.props.showFatalsInline || childrenToShow.length === 0 ? (<div>
                    <div className={styles.taskSummaryLine}>
                        {this.getStatusIcon(element.Status!)}&nbsp;
                        <span className={styles.taskSummaryLineText} onClick={() => this.props.onLogNavigation(element.uniqueId)}>
                            {element.Name}
                        </span>
                    </div>
                    {fatals && fatals.length > 0 && <div className={styles.fatalBlock}>{fatals}</div>}
                </div>) : fatals && fatals.length > 0 ? (<Callout title="Failed" type={CalloutType.Danger}>
                    {fatals}
                </Callout>) : null;
        const childStyle = this.props.showFatalsInline ? styles.taskSummaryChildren : styles.taskSummaryTopLevel;
        const children = childrenToShow.length > 0 && (<div className={childStyle}>
                {childrenToShow.map((e) => (<TaskSummaryLine key={e.uniqueId} element={e} artifacts={this.props.artifacts} onLogNavigation={this.props.onLogNavigation} showFatalsInline={true}/>))}
            </div>);
        const lastLogElement = _.last(element.LogElements);
        const showRetryCountdown = lastLogElement && lastLogElement.Category === ActivityLogEntryCategory.Retry;
        return (<React.Fragment>
                {element.Name && (<div>
                        {header}
                        {showRetryCountdown && (<div className={styles.retryBlock}>
                                <RetryCountdown to={lastLogElement.MessageText}/>
                            </div>)}
                        {children}
                        {highlights && highlights.length > 0 && <div className={styles.highlightBlock}>{highlights}</div>}
                        {this.getArtifacts(childrenToShow.length === 0)}
                    </div>)}
            </React.Fragment>);
    }
    private getLogsWithCategory(element: ActivityElement, recurse: boolean, category: ActivityLogEntryCategory): ActivityLogElement[] {
        const highlights = (element.LogElements || []).filter((e) => e.Category === category);
        return recurse ? highlights.concat(flatten(element.Children.map((c) => this.getLogsWithCategory(c, recurse, category)))) : highlights;
    }
    private getArtifacts(isLeaf: boolean) {
        const element = this.props.element;
        const artifactResources = this.props.artifacts.filter((a) => (a.LogCorrelationId && isLeaf ? a.LogCorrelationId.startsWith(element.Id) : a.LogCorrelationId === element.Id));
        const elements = artifactResources.map((a) => (<div>
                <ArtifactLink artifact={a}/>
            </div>));
        return elements.length > 0 && <div className={styles.artifactsBlock}>{elements}</div>;
    }
    private getStatusIcon(nodeStatus: ActivityStatus) {
        return withTheme((theme) => {
            switch (nodeStatus) {
                case ActivityStatus.Skipped:
                    return <em className={cn("fa-solid fa-minus", styles.summaryIcon)} style={{ color: theme.secondaryText }}/>;
                case ActivityStatus.SuccessWithWarning:
                    return (<span style={{ position: "relative" }}>
                            <em className={cn("fa-solid fa-check", styles.summaryIcon)} style={{ color: theme.successText }}/>
                            <span className="status-overlay" style={{ color: theme.alertText, position: "absolute", bottom: "-8px", right: "-2px", fontSize: "10px" }}>
                                <em className="fa-solid fa-exclamation-triangle"/>
                            </span>
                        </span>);
                case ActivityStatus.Failed:
                    return <em className={cn("fa-solid fa-xmark", styles.summaryIcon)} style={{ color: theme.dangerText }}/>;
                case ActivityStatus.Running:
                    return <em className={cn("fa-solid fa-spinner fa-pulse", styles.summaryIcon)} style={{ color: theme.primaryDark }}/>;
                case ActivityStatus.Pending:
                    return <em className={cn("fa-regular fa-clock", styles.summaryIcon)} style={{ color: theme.primaryLight }}/>;
                case ActivityStatus.Success:
                    return <em className={cn("fa-solid fa-check", styles.summaryIcon)} style={{ color: theme.successText }}/>;
                case ActivityStatus.Canceled:
                    return <em className={cn("fa-solid fa-xmark", styles.summaryIcon)} style={{ color: theme.primaryText }}/>;
                default:
                    return <ThirdPartyIcon iconType={ThirdPartyIconType.Clear} color={theme.primaryText} style={{ width: "16px", height: "16px" }}/>;
            }
        });
    }
    static displayName = "TaskSummaryLine";
}
export default TaskSummaryLine;
