import type { StructuredConfigurationProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import Checkbox from "../../../primitiveComponents/form/Checkbox/Checkbox";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import type { ScriptPackageProperties } from "../../Actions/script/ScriptPackageReferenceDialog";
import { toggleFeature } from "../../Features/enabledFeaturesHelpers";
import { ExpandableFormSection, Summary } from "../../form";
const StructuredConfigurationVariablesToggle = (props: ActionEditProps<StructuredConfigurationProperties, ScriptPackageProperties>) => {
    const featureEnabled = props.properties["Octopus.Action.EnabledFeatures"]?.includes("Octopus.Features.JsonConfigurationVariables");
    function summary() {
        if (featureEnabled) {
            return Summary.summary(<span>Variable replacement will be performed on the target files</span>);
        }
        else {
            return Summary.placeholder(<span>No variable replacement will be performed</span>);
        }
    }
    function handleChange() {
        props.setProperties({ ["Octopus.Action.EnabledFeatures"]: toggleFeature(props.properties["Octopus.Action.EnabledFeatures"], "Octopus.Features.JsonConfigurationVariables") }, true);
    }
    return (<ExpandableFormSection errorKey="jsonvariables" isExpandedByDefault={props.expandedByDefault} title="Structured Configuration Variables" summary={summary()} help="Enable file variable replacements">
            <Checkbox label="Enable" value={featureEnabled} onChange={handleChange} error={props.getFieldError("Octopus.Action.Package.JsonConfigurationVariablesTargets")}/>
        </ExpandableFormSection>);
};
export default StructuredConfigurationVariablesToggle;
