import type { GitBranchResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { ValidateGitRefV2ResponseType, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useNotifyCreateBranch } from "~/areas/projects/components/BranchSelector/Analytics/useNotifyCreateBranch";
import { GitRefSelector } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { useProjectContext } from "~/areas/projects/context";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import { Snackbar } from "~/primitiveComponents/feedback/Snackbar";
import styles from "./style.module.less";
interface BranchSelectorProps {
    isPageVersionControlled: boolean;
    disabled?: boolean;
}
export function BranchSelector(props: BranchSelectorProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [newGitRef, setNewGitRef] = useState<string | null>(null);
    const createBranchDispatcher = useNotifyCreateBranch();
    const onCreateBranch = async (newBranchName: string, parentGitRef: GitRef): Promise<GitBranchResource> => {
        const newBranchResource = await createBranchDispatcher({
            source: "Branch switcher",
            createBranchAction: () => projectContext.state.projectContextRepository.Branches.createBranch(project, newBranchName, parentGitRef),
        });
        await projectContext.actions.onBranchSelected(project, newBranchResource.CanonicalName);
        return newBranchResource;
    };
    const onChange = (gitRef: GitRef) => {
        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        if (gitRef) {
            projectContext.actions.changeGitRef(gitRef);
            setNewGitRef(toGitRefShort(gitRef));
            setShowSnackbar(true);
        }
    };
    const getInitialGitRef = (): GitRef | undefined => {
        return projectContext.state.gitRef?.CanonicalName;
    };
    const isVersionControlled = projectContext.state.model.IsVersionControlled;
    const emptyElementNecessaryForActionListSpacing = <div />;
    if (!isVersionControlled)
        return emptyElementNecessaryForActionListSpacing;
    const gitRefSelector = (<GitRefSelector onChange={onChange} project={projectContext.state.model} initialGitRef={getInitialGitRef()} onCreateBranch={onCreateBranch} gitError={projectContext.state.gitRefValidationError} disabled={props.disabled || !props.isPageVersionControlled}/>);
    if (projectContext.state.gitRefValidationError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed) {
        return (<>
                <ToolTip content="We were unable to connect to your remote repository. Please check your version control settings.">{gitRefSelector}</ToolTip>
                <div className={styles.errorMessage}>Check your version control settings</div>
            </>);
    }
    if (!props.isPageVersionControlled) {
        return (<>
                <ToolTip content="Switching branches is only allowed on a version-controlled page">{gitRefSelector}</ToolTip>
                <div className={styles.disabledMessage}>Current page is not version-controlled</div>
            </>);
    }
    return (<div id="branchSelector" className={styles.selectContainer}>
            <>
                {gitRefSelector}
                <Snackbar open={showSnackbar} autoHideDuration={3500} onClose={() => setShowSnackbar(false)} content={<div className={styles.snackbarContent}>
                            <div className={styles.snackbarMessage}>
                                Switched to <strong>{newGitRef}</strong>
                            </div>
                        </div>}/>
            </>
        </div>);
}
