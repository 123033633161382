import { pageId } from "@octopusdeploy/portal-routes";
import releasePageIds from "../Releases/ReleasesRoutes/releasePageIds";
import runbookSnapshotPageIds from "../Runbooks/RunbookSnapshots/runbookSnapshotPageIds";
export default () => ({
    projects: {
        root: pageId({ area: "Project", id: "Projects", name: "Projects" }),
    },
    importExport: {
        root: pageId({ area: "Project", id: "ImportExportTasks", name: "Import/Export" }),
        export: pageId({ area: "Project", id: "ExportProjects", name: "Export" }),
        exportTask: pageId({ area: "Project", id: "ExportTask", name: "Export Task" }),
        import: pageId({ area: "Project", id: "ImportProjects", name: "Import" }),
        importTask: pageId({ area: "Project", id: "ImportTask", name: "Import Task" }),
    },
    project: () => {
        return {
            root: pageId({ area: "Project", id: "Project", name: "Project" }),
            ...releasePageIds(),
            overview: pageId({ area: "Project", id: "Project.Overview", name: "Overview" }),
            tasks: pageId({ area: "Project", id: "Project.Tasks", name: "Tasks" }),
            insights: {
                overview: pageId({ area: "Project", id: "Project.Insights.Overview", name: "Insights Overview" }),
                leadTime: pageId({ area: "Project", id: "Project.Insights.LeadTime", name: "Lead Time" }),
                frequency: pageId({ area: "Project", id: "Project.Insights.Frequency", name: "Deployment Frequency" }),
                failureRate: pageId({ area: "Project", id: "Project.Insights.FailureRate", name: "Failure Rate" }),
                timeToRecovery: pageId({ area: "Project", id: "Project.Insights.TimeToRecovery", name: "Mean Time To Recovery" }),
                deploymentDuration: pageId({ area: "Project", id: "Project.Insights.DeploymentDuration", name: "Deployment Duration" }),
            },
            settings: {
                root: pageId({ area: "Project", id: "Project.Settings", name: "Settings" }),
                versionControl: pageId({ area: "Project", id: "Project.VersionControlSettings", name: "Version Control Settings" }),
                dynamicEnvironments: pageId({ area: "Project", id: "Project.DynamicEnvironmentSettings", name: "Dynamic Environment Settings" }),
            },
            channels: pageId({ area: "Project", id: "Project.Channels", name: "Channels" }),
            channel: pageId({ area: "Project", id: "Project.Channel", name: "Channel" }),
            channelNew: pageId({ area: "Project", id: "Project.ChannelNew", name: "Add New Channels" }),
            triggers: pageId({ area: "Project", id: "Project.Triggers", name: "Triggers" }),
            deploymentSettings: pageId({ area: "Project", id: "Project.DeploymentSettings", name: "Settings" }),
            deploymentTargetTrigger: pageId({ area: "Project", id: "Project.DeploymentTargetTrigger", name: "Deployment Target Trigger" }),
            deploymentTargetTriggerNew: pageId({ area: "Project", id: "Project.DeploymentTargetTriggerNew", name: "Add New Deployment Target Trigger" }),
            scheduledTrigger: pageId({ area: "Project", id: "Project.ScheduledTrigger", name: "Scheduled Trigger" }),
            scheduledTriggerNew: pageId({ area: "Project", id: "Project.ScheduledTriggerNew", name: "Add New Scheduled Trigger" }),
            variables: {
                root: pageId({ area: "Project", id: "Project.Variables", name: "Variables" }),
                projectTemplates: pageId({ area: "Project", id: "Project.VariableTemplates", name: "Variable Templates" }),
                commonTemplates: pageId({ area: "Project", id: "Project.VariableCommonTemplates", name: "Common Variable Templates" }),
                library: pageId({ area: "Project", id: "Project.VariableLibrary", name: "Variables Library" }),
                preview: pageId({ area: "Project", id: "Project.VariablePreview", name: "Variables Preview" }),
                all: pageId({ area: "Project", id: "Project.VariableAll", name: "All Variables" }),
            },
            process: {
                root: pageId({ area: "Project", id: "Project.Process", name: "Deployment Process" }),
                overview: pageId({ area: "Project", id: "Project.Process", name: "Deployment Overview" }),
                stepTemplates: pageId({ area: "Project", id: "Project.ProcessStepTemplates", name: "Deployment Process Step Templates" }),
                childStepTemplates: pageId({ area: "Project", id: "Project.ProcessChildStepTemplates", name: "Deployment Process Child Step Templates" }),
                stepNew: pageId({ area: "Project", id: "Project.ProcessStepNew", name: "Add New Deployment Process Step" }),
                step: pageId({ area: "Project", id: "Project.ProcessStep", name: "Deployment Process Step" }),
            },
            childStepTemplates: pageId({ area: "Project", id: "Project.ChildStepTemplates", name: "Child Step Templates" }),
            steptemplates: pageId({ area: "Project", id: "Project.StepTemplates", name: "Step Templates" }),
            operations: {
                root: pageId({ area: "Project", id: "Project.Operations", name: "Operations" }),
                runbooks: pageId({ area: "Project", id: "Project.Operations.Runbooks", name: "Runbooks" }),
                triggers: pageId({ area: "Project", id: "Project.Operations.Triggers", name: "Runbook Triggers" }),
                scheduledTrigger: pageId({ area: "Project", id: "Project.Operations.Triggers.ScheduledTrigger", name: "Runbook Scheduled Trigger" }),
                scheduledTriggerNew: pageId({ area: "Project", id: "Project.Operations.Triggers.ScheduledTriggerNew", name: "Add New Runbook Scheduled Trigger" }),
            },
            runbook: {
                root: pageId({ area: "Project", id: "Project.Runbook.Overview", name: "Runbook Overview" }),
                runsList: pageId({ area: "Project", id: "Project.Runbook.RunsList", name: "Runbook Runs List" }),
                process: {
                    root: pageId({ area: "Project", id: "Project.Runbook.Process", name: "Runbook Process" }),
                    stepTemplates: pageId({ area: "Project", id: "Project.Runbook.ProcessStepTemplates", name: "Runbook Process Step Templates" }),
                    childStepTemplates: pageId({ area: "Project", id: "Project.Runbook.ProcessChildStepTemplates", name: "Runbook Process Child Step Templates" }),
                    stepNew: pageId({ area: "Project", id: "Project.Runbook.ProcessStepNew", name: "Add New Runbook Process Step" }),
                    step: pageId({ area: "Project", id: "Project.Runbook.ProcessStep", name: "Runbook Process Step" }),
                },
                runNow: pageId({ area: "Project", id: "Project.Runbook.Run", name: "Runbook Run" }),
                settings: pageId({ area: "Project", id: "Project.Runbook.Settings", name: "Runbook Settings" }),
                ...runbookSnapshotPageIds(),
                triggers: pageId({ area: "Project", id: "Project.Runbook.Triggers", name: "Runbook Triggers" }),
                scheduledTrigger: pageId({ area: "Project", id: "Project.Runbook.ScheduledTrigger", name: "Runbook Scheduled Trigger" }),
                scheduledTriggerNew: pageId({ area: "Project", id: "Project.Runbook.ScheduledTriggerNew", name: "Add New Runbook Scheduled Trigger" }),
            },
        };
    },
});
