import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectVariableOverview, CommonProjectVariableTopics } from "../../CommonComponents/CommonProjectVariableViews";
export const ProjectVariablesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().variables.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectVariableOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectVariableTopics />;
    },
};
