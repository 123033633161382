/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import { apply as JsonPatch } from "json-patch";
import { cloneDeep } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import DataLoader from "~/components/DataLoader";
import DisplayDiff from "~/components/DisplayDiff";
import StringHelper from "~/utils/StringHelper/StringHelper";
import styles from "./style.module.less";
interface EventDetailData {
    details: string | null;
    jsonBefore: string | null;
    jsonAfter: string | null;
}
interface ElementCoordinates {
    top: number;
    height: number;
}
const EventDetailsDataLoader = DataLoader<EventDetailData>();
interface EventDetailsProps extends React.HTMLProps<HTMLElement> {
    eventId: string;
    spaceId?: string;
}
interface EventDetailsState {
    highlightCoordinates: ElementCoordinates[];
}
class EventDetails extends React.Component<EventDetailsProps, EventDetailsState> {
    private boundingDiv: HTMLElement | null = null;
    private shouldUpdateOverlay: boolean = true;
    constructor(props: EventDetailsProps) {
        super(props);
        this.state = {
            highlightCoordinates: [],
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.calculateHeight);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight);
    }
    calculateOverlayDivHeights() {
        const insertHighlighCoords = this.getTagLocations(this.boundingDiv?.getElementsByTagName("ins"));
        const deleteHighlightCoords = this.getTagLocations(this.boundingDiv?.getElementsByTagName("del"));
        const uniqueHighlightCoords = insertHighlighCoords.concat(deleteHighlightCoords).sort((a, b) => a.top - b.top);
        this.setState({ highlightCoordinates: uniqueHighlightCoords });
    }
    calculateHeight = () => {
        // Hack to get the heights to refresh on window resize.
        this.setState({ highlightCoordinates: [] }, () => {
            this.calculateOverlayDivHeights();
        });
    };
    getTagLocations(tags: HTMLCollectionOf<HTMLModElement> | null | undefined): ElementCoordinates[] {
        const tops: ElementCoordinates[] = [];
        if (tags) {
            for (let i = 0; i < tags.length; i++) {
                const tag = tags[i];
                tops.push({ top: tag.offsetTop, height: tag.offsetHeight });
            }
        }
        return tops;
    }
    render() {
        return <EventDetailsDataLoader operationName="LoadEventDetails" load={() => this.loadExtraDetails()} renderWhenLoaded={(data) => this.renderDetails(data)} renderAlternate={({ busy }) => this.renderAlternate(busy)}/>;
    }
    private async loadExtraDetails() {
        const repo = !!this.props.spaceId ? await repository.forSpace(this.props.spaceId) : repository.forSystem();
        const event = await repo.Events.get(this.props.eventId, { excludeDifference: false });
        const before = event?.ChangeDetails?.DocumentContext;
        const patches = event?.ChangeDetails?.Differences;
        const after = before && cloneDeep(before);
        if (after && patches) {
            JsonPatch(after, patches);
        }
        return {
            details: event.Details,
            jsonBefore: before && JSON.stringify(before, null, 4),
            jsonAfter: after && JSON.stringify(after, null, 4),
        };
    }
    private renderDetails(data: EventDetailData) {
        if (!(data.details || data.jsonAfter)) {
            return null;
        }
        const { eventId, spaceId, ...rest } = this.props;
        const diffView = data.jsonAfter ? (<DisplayDiff oldValue={data.jsonBefore || ""} newValue={data.jsonAfter}/>) : (<pre className={styles.preWrapped}>
                <div className={styles.changeIndicatorOverlay}>
                    {this.state.highlightCoordinates.map((x, index) => {
                return <div key={index} className={styles.changeLine} style={{ top: x.top, height: x.height }}/>;
            })}
                </div>
                <div ref={(div) => (this.boundingDiv = div)} className={styles.changeSet} dangerouslySetInnerHTML={{ __html: StringHelper.formatDiff(data.details || "") }}/>
            </pre>);
        // Schedule the highlight overlay to be re-calculated once rendering is finished
        if (data.details && this.shouldUpdateOverlay) {
            this.shouldUpdateOverlay = false;
            window.requestAnimationFrame(this.calculateHeight);
        }
        return <div {...(rest as any)}>{diffView}</div>;
    }
    private renderAlternate(busy: boolean) {
        return (<div className={styles.busyIndicator}>
                <LinearProgress show={busy}/>
            </div>);
    }
    static displayName = "EventDetails";
}
export default EventDetails;
