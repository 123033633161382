/* eslint-disable @typescript-eslint/no-explicit-any */
import URI from "urijs";
import type { DashboardFilters } from "../DashboardDataSource/DataCube";
import { DimensionTypes } from "../DashboardDataSource/DataCube";
function ensureArray(obj: any) {
    if (!obj) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj;
    }
    return [obj];
}
export function filtersFromQueryString(queryString: string): any {
    const uri = new URI();
    const original = uri.search(queryString).search(true);
    if (Object.keys(original).length) {
        const filt: DashboardFilters = {};
        const groupBy = ensureArray(original.groupby);
        if (groupBy && groupBy[0] === "channel") {
            filt.groupBy = DimensionTypes.Channel;
        }
        else if (groupBy && groupBy[0].startsWith("tag:")) {
            filt.groupBy = DimensionTypes.TagSet;
            filt.groupByExtra = groupBy[0].substring(groupBy[0].indexOf(":") + 1);
        }
        if (original.release) {
            filt[DimensionTypes.Release] = ensureArray(original.release).reduce((a: any, b: any) => {
                a[b] = true;
                return a;
            }, {});
        }
        if (original.tenant) {
            filt[DimensionTypes.Tenant] = ensureArray(original.tenant).reduce((a: any, b: any) => {
                a[b] = true;
                return a;
            }, {});
        }
        if (original.tags) {
            filt[DimensionTypes.TagSet] = ensureArray(original.tags).reduce((a: any, b: any) => {
                a[b] = true;
                return a;
            }, {});
        }
        return filt;
    }
    return {};
}
export function queryStringFromFilters(filters: DashboardFilters): string {
    const search: any = {};
    switch (filters.groupBy) {
        case DimensionTypes.Channel:
            search["groupby"] = "channel";
            break;
        case DimensionTypes.TagSet:
            search["groupby"] = `tag:${filters.groupByExtra}`;
            break;
    }
    if (filters[DimensionTypes.Release]) {
        search["release"] = Object.keys(filters[DimensionTypes.Release])[0];
    }
    if (filters[DimensionTypes.Tenant]) {
        search["tenant"] = Object.keys(filters[DimensionTypes.Tenant]);
    }
    if (filters[DimensionTypes.TagSet]) {
        search["tags"] = Object.keys(filters[DimensionTypes.TagSet]);
    }
    return new URI().search(search).search();
}
