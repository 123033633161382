import * as React from "react";
import type { SFC } from "react";
import MarkdownDescription from "~/components/MarkdownDescription";
import SimpleDataTable from "~/components/SimpleDataTable/SimpleDataTable";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import type { FeatureItem } from "./ConfigureFeaturesContent";
import styles from "./style.module.less";
interface FeatureListProps {
    features: FeatureItem[];
    onIsEnabledChanged(value: boolean, featureName: string): void;
}
class FeatureTable extends SimpleDataTable<FeatureItem> {
}
const FeatureList: SFC<FeatureListProps> = (props) => {
    return (<FeatureTable data={props.features} headerColumns={["Feature", "Description"]} headerColumnClassNames={[styles.headerColumn, styles.headerColumn]} rowColumnClassName={styles.rowColumn} onRow={(res) => buildResultRow(res, props.onIsEnabledChanged)}/>);
};
const buildResultRow = (item: FeatureItem, onIsEnabledChanged: (value: boolean, featureName: string) => void) => {
    return [<Checkbox value={item.isEnabled} onChange={(val) => onIsEnabledChanged(val, item.featureName)} label={item.title}/>, <MarkdownDescription markup={item.description}/>];
};
FeatureList.displayName = "FeatureList";
export default FeatureList;
