import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { LibraryLayout } from "~/areas/library/components/LibraryLayout/LibraryLayout";
import AddVariableSetButton from "~/areas/library/components/VariableSets/AddVariableSetButton";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { List } from "~/components/List/List";
import MarkdownDescription from "~/components/MarkdownDescription";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface VariableSetsState extends DataBaseComponentState {
    variableSets?: ResourceCollection<LibraryVariableSetResource>;
}
export default class VariableSets extends DataBaseComponent<RouteComponentProps<{}>, VariableSetsState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadVariableSets(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<LibraryLayout {...this.props}>
                <PaperLayout title="Variable Sets" sectionControl={<AddVariableSetButton />} busy={this.state.busy} errors={this.errors}>
                    {this.state.variableSets && (<VariableSetList initialData={this.state.variableSets} onRow={(variableSet: LibraryVariableSetResource) => this.buildRow(variableSet)} onFilter={filterVariableSets} filterHintText="Filter by name..." filterSearchEnabled={true} apiSearchParams={["partialName"]} match={this.props.match} additionalRequestParams={new Map([["contentType", ["Variables"]]])}/>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private buildRow(tagSet: LibraryVariableSetResource) {
        return (<div>
                <ListTitle>{tagSet.Name}</ListTitle>
                <MarkdownDescription markup={tagSet.Description}/>
            </div>);
    }
    private async loadVariableSets() {
        this.setState({ variableSets: await repository.LibraryVariableSets.list({ contentType: VariableSetContentType.Variables }) });
    }
    static displayName = "VariableSets";
}
function filterVariableSets(filter: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filter.toLowerCase());
}
class VariableSetList extends List<LibraryVariableSetResource> {
}
