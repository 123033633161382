import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import BuildProgressSnackbar from "./BuildProgressSnackbar";
import { DevDrawer } from "./DevDrawer";
import DevOnly from "./DevOnly";
import { useDevToolsDispatch, actions, useDevToolsState } from "./DevToolsContext";
export const DevTools: React.FC = () => {
    const dispatcher = useDevToolsDispatch();
    const stateContext = useDevToolsState();
    const open = stateContext?.drawer.open || false;
    const fullscreen = stateContext?.drawer.fullscreen || false;
    useHotkeys("ctrl+shift+`", () => dispatcher?.dispatch(actions.toggleDrawer()));
    return (<React.Fragment>
            <DevOnly>
                <BuildProgressSnackbar />
            </DevOnly>
            <DevDrawer open={open} onRequestClose={() => dispatcher?.dispatch(actions.closeDrawer())} fullscreen={fullscreen} onToggleFullscreen={() => dispatcher?.dispatch(actions.toggleFullscreen())}/>
        </React.Fragment>);
};
DevTools.displayName = "DevTools"
export default DevTools;
