/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Cancelable } from "lodash";
import { debounce, isEqual } from "lodash";
import * as React from "react";
interface ValueProps<TValue> {
    value?: TValue;
    onChange?(value: TValue): void;
}
interface DebounceValueState<TValue> {
    value?: TValue;
}
interface DebounceValueProps {
    debounceDelay?: number;
    innerRef?: (component: any) => void; // It would be nice to tighten up the typing here
}
export type ExtendPropsWithDebounceValueProps<TProps> = TProps & DebounceValueProps;
function DebounceValue<TProps extends ValueProps<TValue>, TValue>(Comp: React.ComponentClass<TProps>): React.ComponentClass<ExtendPropsWithDebounceValueProps<TProps>> {
    type InternalProps = ExtendPropsWithDebounceValueProps<TProps>;
    // eslint-disable-next-line react/no-unsafe
    return class DebounceValueInternal extends React.Component<InternalProps, DebounceValueState<TValue>> {
        private readonly onChange: ((value: TValue) => void) & Cancelable;
        constructor(props: InternalProps) {
            super(props);
            this.state = {
                value: props.value,
            };
            this.onChange = debounce((value: TValue) => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }, this.props.debounceDelay ? this.props.debounceDelay : 250);
        }
        UNSAFE_componentWillReceiveProps(nextProps: Readonly<InternalProps>) {
            if (!isEqual(nextProps.value, this.props.value)) {
                this.setState({ value: nextProps.value });
                this.onChange.cancel();
            }
        }
        render() {
            const { debounceDelay, innerRef, ...otherProps } = this.props as any; // `as any` because object rest not support in generics yet, tracked by https://github.com/Microsoft/TypeScript/issues/10727
            return (<Comp {...otherProps} value={this.state.value} ref={(c) => {
                    if (innerRef) {
                        innerRef(c);
                    }
                }} onChange={(value) => {
                    this.setState({ value });
                    this.onChange(value);
                }}/>);
        }
    };
}
export type DebounceValueFunction = typeof DebounceValue;
export default DebounceValue;
