/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { logger } from "@octopusdeploy/logging";
import type { ProjectResource, PerformanceConfigurationResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { DynamicDeploymentsOverview } from "app/areas/projects/components/DynamicDeploymentsOverview/DynamicDeploymentsOverview";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticActionDispatch } from "~/analytics/Analytics";
import DashboardDataSource from "~/areas/projects/components/DashboardDataSource";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import type { DashboardFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OverflowMenuGenericItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SampleProcessButtonWithRedirect from "~/components/ProjectBasedActivation/SampleProcessButtonWithRedirect";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import { DataBaseComponent } from "../../../../components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "../../../../components/DataBaseComponent/DataBaseComponent";
import type { DashboardDataSourceState, RenderDashboardProps } from "../DashboardDataSource/DashboardDataSource";
import ProjectDashboard from "../ProjectDashboard";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import Onboarding from "./Onboarding";
import OverviewFilters from "./OverviewFilters";
import { filtersFromQueryString, queryStringFromFilters } from "./queryStringFilters";
import styles from "./style.module.less";
interface OverviewState extends DataBaseComponentState {
    project: ProjectResource;
    filters: DashboardFilters;
    tenants: TenantResource[];
    hasSteps: boolean;
    hasReleases: boolean;
    failedChecks: Array<{
        permission: Permission;
        isNotAllowed: boolean;
    }>;
    dashboardRenderMode: DashboardRenderMode;
}
type DeploymentsOverviewProps = RouteComponentProps<ProjectRouteParams>;
interface DeploymentsOverviewPropsInternal extends DeploymentsOverviewProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
}
class DeploymentsOverviewInternal extends DataBaseComponent<DeploymentsOverviewPropsInternal, OverviewState> {
    dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    constructor(props: DeploymentsOverviewPropsInternal) {
        super(props);
        this.state = {
            hasSteps: false,
            hasReleases: false,
            project: null!,
            tenants: [],
            filters: {},
            failedChecks: [],
            dashboardRenderMode: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? await repository.Tenants.all() : [];
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const performanceConfiguration = await repository.PerformanceConfiguration.get();
            await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const requiredPermissions = [
                { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.ReleaseView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.EnvironmentView, wildcard: true },
            ];
            const failedChecks = requiredPermissions
                .map((check) => ({
                permission: check.permission,
                isNotAllowed: !isAllowed(check),
            }))
                .filter((check) => check.isNotAllowed);
            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    failedChecks,
                });
                return;
            }
            let hasSteps = false;
            try {
                const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
                hasSteps = deploymentProcess.Steps.length > 0;
            }
            catch (exception) {
                logger.error(exception, "Failed to get deployment processes for {project}", { project });
            }
            let hasReleases = false;
            try {
                const releases = await repository.Projects.getReleases(project, { take: 1 });
                hasReleases = releases.TotalResults > 0;
            }
            catch (exception) {
                logger.error(exception, "Failed to get releases for {project}", { project });
            }
            this.setState({
                project,
                tenants,
                filters: {
                    ...this.createDefaultFilter(project),
                    ...filtersFromQueryString(this.props.location.search),
                },
                hasSteps,
                hasReleases,
                dashboardRenderMode: this.getDashboardRenderMode(project, performanceConfiguration),
            });
        });
    }
    shouldComponentUpdate(nextProps: RouteComponentProps<ProjectRouteParams>, nextState: OverviewState) {
        if (nextState.failedChecks !== this.state.failedChecks) {
            return true;
        }
        if (nextState.hasSteps !== this.state.hasSteps) {
            return true;
        }
        if (nextState.hasReleases !== this.state.hasReleases) {
            return true;
        }
        if (nextState.project !== this.state.project) {
            return true;
        }
        if (nextState.tenants !== this.state.tenants) {
            return true;
        }
        if (nextState.filters !== this.state.filters) {
            return true;
        }
        if (nextState.dashboardRenderMode !== this.state.dashboardRenderMode) {
            return true;
        }
        return false;
    }
    render() {
        if (!this.state.project) {
            return <PaperLayout busy={true} errors={this.errors}/>;
        }
        if (this.state.failedChecks.length > 0) {
            return (<PaperLayout busy={this.state.busy} errors={this.errors} title="Deployments" breadcrumbTitle={this.state.project.Name}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {this.state.failedChecks[0].permission} permission is required to view project overview details
                    </Callout>
                </PaperLayout>);
        }
        if (this.state.project && (this.state.project.IsVersionControlled ? !this.state.hasReleases : !this.state.hasSteps)) {
            return (<PaperLayout busy={this.state.busy} errors={this.errors} title="Deployments" breadcrumbTitle={this.state.project.Name} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                    <Onboarding project={this.state.project} actionButtons={<PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                                <NavigationButton label="Create Process" href={routeLinks.project(this.state.project).deployments.process.stepTemplates} type={NavigationButtonType.Primary} onClick={() => {
                        const ev: ActionEvent = {
                            action: Action.Add,
                            resource: "Deployment Process",
                        };
                        this.props.dispatchAction("Define your Deployment Process", ev);
                    }}/>
                                <SampleProcessButtonWithRedirect classNames={[styles.loadSampleButton]} href={routeLinks.project(this.state.project).deployments.process.loadSampleProcess}/>
                            </PermissionCheck>}/>
                    <AnalyticView resource="Project"/>
                </PaperLayout>);
        }
        return (<div className={styles.overviewContainer}>
                <DashboardDataSource project={this.state.project} filters={this.state.filters} render={this.renderDashboard} dashboardRenderMode={this.state.dashboardRenderMode}/>
                {this.dynamicEnvironmentsEnabled && (<PaperLayout fullWidth={true}>
                        <DynamicDeploymentsOverview spaceId={this.state.project.SpaceId} projectSlug={this.state.project.Slug} doBusyTask={this.doBusyTask}/>
                    </PaperLayout>)}
            </div>);
    }
    private showLoadingDashboard(dataSource: DashboardDataSourceState) {
        return <ProjectDashboard cube={dataSource.cube!} filters={this.state.filters} allowDeployments={true} flatStyle={true} dashboardRenderMode={this.state.dashboardRenderMode}/>;
    }
    private localStorageKeyForVirtualDashboard(project: ProjectResource): string {
        return "virtualDashboard" + project.Id;
    }
    private getDashboardRenderMode(project: ProjectResource, performanceConfiguration: PerformanceConfigurationResource): DashboardRenderMode {
        const localRenderMode = localStorage.getItem(this.localStorageKeyForVirtualDashboard(project));
        if (localRenderMode !== null) {
            const typedRenderModeString = localRenderMode as keyof typeof DashboardRenderMode;
            return DashboardRenderMode[typedRenderModeString];
        }
        return performanceConfiguration.DefaultDashboardRenderMode;
    }
    private setDashboardRenderMode(value: DashboardRenderMode) {
        localStorage.setItem(this.localStorageKeyForVirtualDashboard(this.state.project), value.toString());
        this.setState({ dashboardRenderMode: value });
    }
    private filtersUpdated = (filters: DashboardFilters) => {
        const newQS = queryStringFromFilters(filters);
        this.setState({ filters }, () => {
            if (this.props.location.search !== newQS) {
                const location = { ...this.props.history, search: newQS };
                this.props.history.replace(location);
            }
        });
    };
    private renderDashboard = (dataSource: RenderDashboardProps) => {
        const auditTrailButton = OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProject(this.state.project.Id), {
            permission: Permission.EventView,
            wildcard: true,
        });
        const overflowMenuItems = [this.getDashboardRenderModeOverflowMenuItem(), auditTrailButton];
        return (<PaperLayout title="Overview" breadcrumbTitle={this.state.project.Name} busy={Promise.all([this.state.busy, dataSource.busy])} errors={dataSource.errors} sectionControl={<OverflowMenu menuItems={overflowMenuItems}/>} disableHeaderAnimations={true} // Disabled because of the way the ProjectOverview renders multiple PaperLayouts.
         fullWidth={true} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                {dataSource.hasInitialLoaded && dataSource.cube && (<OverviewFilters cube={dataSource.cube} tenants={this.state.tenants} filters={this.state.filters} doBusyTask={this.doBusyTask} defaultFilter={this.createDefaultFilter(this.state.project)} onFiltersUpdated={this.filtersUpdated} project={this.state.project} render={() => this.showLoadingDashboard(dataSource)}/>)}
            </PaperLayout>);
    };
    private getDashboardRenderModeOverflowMenuItem = (): OverflowMenuGenericItem => {
        const text = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? "Switch to fast rendering" : "Switch to full rendering";
        const oppositeSetting = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? DashboardRenderMode.VirtualizeRowsAndColumns : DashboardRenderMode.VirtualizeColumns;
        return OverflowMenuItems.item(text, () => this.setDashboardRenderMode(oppositeSetting), undefined, "Full rendering will render all rows, fast rendering will render only visible rows");
    };
    private createDefaultFilter = (project: ProjectResource): DashboardFilters => {
        return {
            [DimensionTypes.Project]: {
                [project.Id]: true,
            },
            columnDimension: DimensionTypes.Environment,
            groupBy: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Channel : DimensionTypes.None,
            rowDimension: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Release : DimensionTypes.Tenant,
        };
    };
    static displayName = "DeploymentsOverviewInternal";
}
export function DeploymentsOverview(props: DeploymentsOverviewProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticActionDispatch(projectContext.state.model.Id);
    return <DeploymentsOverviewInternal {...props} projectContext={projectContext} dispatchAction={dispatchAction}/>;
}
