/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ResourcesById, SubscriptionResource, TeamResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { TeamChip, DisabledChip } from "~/components/Chips/index";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import List from "~/components/List";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import TimeSpanHelper from "~/utils/TimeSpanHelper";
import styles from "./style.module.less";
class SubscriptionList extends List<SubscriptionResource> {
}
interface SubscriptionLayoutState extends DataBaseComponentState {
    subscriptionsResponse: ResourceCollection<SubscriptionResource>;
    teamIndex: ResourcesById<TeamResource>;
}
export default class SubscriptionsLayout extends DataBaseComponent<RouteComponentProps<void>, SubscriptionLayoutState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            subscriptionsResponse: null!,
            teamIndex: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const subscriptionsResponse = await repository.Subscriptions.list();
            const teamIndex = await repository.Teams.allById();
            this.setState({ subscriptionsResponse, teamIndex });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addButton = (<PermissionCheck permission={Permission.SubscriptionCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add subscription" href={`${this.props.match.url}/create`}/>
            </PermissionCheck>);
        return (<PaperLayout busy={this.state.busy} errors={this.errors} title="Subscriptions" sectionControl={addButton}>
                {this.state.subscriptionsResponse && (<SubscriptionList initialData={this.state.subscriptionsResponse} onRow={(item: SubscriptionResource) => this.buildSubscriptionRow(item)} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} match={this.props.match} filterHintText="Filter by name..."/>)}
            </PaperLayout>);
    }
    private filter(filter: string, resource: SubscriptionResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private handleEnabledToggle = async (model: SubscriptionResource) => {
        model.IsDisabled = !model.IsDisabled; // Toggle the current value.
        await this.doBusyTask(async () => {
            const result = await repository.Subscriptions.save(model);
            const response: ResourceCollection<SubscriptionResource> = this.state.subscriptionsResponse;
            response.Items.forEach((item: SubscriptionResource, i: number) => {
                if (item.Id === result.Id) {
                    response.Items[i] = result;
                }
            });
            this.setState({
                subscriptionsResponse: response,
            });
        });
    };
    private buildSubscriptionRow(subscription: SubscriptionResource) {
        const emailTeams = subscription.EventNotificationSubscription.EmailTeams.map((id) => this.state.teamIndex[id]).filter((t) => !!t);
        const emailFrequencyPeriodText = TimeSpanHelper.timeSpanTextToHumanReadableString(subscription.EventNotificationSubscription.EmailFrequencyPeriod);
        const webhookTeams = subscription.EventNotificationSubscription.WebhookTeams.map((id) => this.state.teamIndex[id]).filter((t) => !!t);
        return (<div className={subscription.IsDisabled ? styles.disabled : null}>
                <ListItem overflowMenuItems={[OverflowMenuItems.item(subscription.IsDisabled ? "Enable" : "Disable", () => this.handleEnabledToggle(subscription), { permission: Permission.SubscriptionEdit })]}>
                    <ListTitle>
                        {subscription.Name}
                        {subscription.IsDisabled && (<span>
                                {" "}
                                <DisabledChip />
                            </span>)}
                    </ListTitle>

                    {emailTeams.length > 0 && (<span>
                            Email the teams{" "}
                            {emailTeams.map((t) => (<TeamChip key={t.Id} team={t}/>))}{" "}
                            every {emailFrequencyPeriodText}
                        </span>)}
                    {emailTeams.length > 0 && subscription.EventNotificationSubscription.WebhookURI && <br />}
                    {subscription.EventNotificationSubscription.WebhookURI && (<span>
                            Send a POST to webhook <code>{subscription.EventNotificationSubscription.WebhookURI}</code>
                            {webhookTeams.length > 0 && (<span>
                                    {" "}
                                    with events scoped to those viewable by teams:{" "}
                                    {webhookTeams.map((t) => (<TeamChip key={t.Id} team={t}/>))}
                                </span>)}
                        </span>)}
                </ListItem>
            </div>);
    }
    static displayName = "SubscriptionsLayout";
}
