import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NavItem } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";
export function IndentedNavItemLink(props: {
    label: string | JSX.Element;
}) {
    return <div className={styles.indentedNavItemLinkContainer}>{props.label}</div>;
}
function createStaticEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, routeLinks.infrastructure.environments.root, undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
function createDynamicEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, routeLinks.infrastructure.dynamicEnvironments.root(), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
function InfrastructureLayoutInternal({ children }: React.PropsWithChildren<{}>) {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const navLinks: Array<NavItem | null> = [];
    navLinks.push(Navigation.navItem("Overview", routeLinks.infrastructure.overview, undefined, { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true }), Navigation.navItem("Deployment Targets", routeLinks.infrastructure.machines.root, undefined, { permission: [Permission.MachineView], wildcard: true }));
    if (dynamicEnvironmentsEnabled) {
        const environmentsNavItems = compact([createStaticEnvironmentsNavItem(<IndentedNavItemLink label="Static"/>), createDynamicEnvironmentsNavItem(<IndentedNavItemLink label="Dynamic"/>)]);
        const environmentsGroup = Navigation.navGroup("Environments", routeLinks.infrastructure.environments.root, environmentsNavItems, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true }, true);
        navLinks.push(environmentsGroup);
    }
    else {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
    }
    navLinks.push(Navigation.navItem("Workers", routeLinks.infrastructure.workerMachines.root, undefined, { permission: [Permission.WorkerView], wildcard: true }), Navigation.navItem("Worker Pools", routeLinks.infrastructure.workerPools.root, undefined), Navigation.navItem("Machine Policies", routeLinks.infrastructure.machinePolicies.root, undefined, { permission: Permission.MachinePolicyView, wildcard: true }), Navigation.navItem("Machine Proxies", routeLinks.infrastructure.proxies.root, undefined, { permission: Permission.ProxyView, wildcard: true }), Navigation.navItem("Accounts", routeLinks.infrastructure.accounts.root, undefined, { permission: Permission.AccountView, wildcard: true }));
    // Any nav links that aren't available to user because of permissions will be null and will be compacted out
    const availableNavLinks = compact(navLinks);
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.infrastructure.root} title="Infrastructure"/>
            <NavigationSidebarLayout navLinks={availableNavLinks} content={children}/>
        </main>);
}
class InfrastructureLayout extends BaseComponent<{}, {}> {
    render() {
        return <InfrastructureLayoutInternal>{this.props.children}</InfrastructureLayoutInternal>;
    }
    static displayName = "InfrastructureLayout";
}
export const InfrastructureLayoutBusy: React.FC<PaperLayoutProps> = (props: PaperLayoutProps) => {
    return (<InfrastructureLayout>
            <PaperLayout {...props}/>
        </InfrastructureLayout>);
};
InfrastructureLayoutBusy.displayName = "InfrastructureLayoutBusy"
export default InfrastructureLayout;
