import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import ActionButton, { ActionButtonType } from "~/components/Button";
import type { NavigationButtonProps } from "~/components/Button/NavigationButton";
import { PermissionCheck } from "~/components/PermissionCheck";
import { processScopedEditPermission, isRunbookProcessState } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
import { useProcessQueryStringContext } from "../Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & {
    href?: NavigationButtonProps["href"];
    label?: NavigationButtonProps["label"];
};
type AddRunbookStepButtonProps = {
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
} & AddRunbookStepButtonPropChanges;
const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useAnalyticActionDispatch();
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Runbook)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={() => {
            processQueryStringContext.actions.showProcessStepTemplates();
            dispatchAction("Add Deployment Step", { resource: "Runbook Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
};
AddRunbookStepButton.displayName = "AddRunbookStepButton"
type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
};
const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useAnalyticActionDispatch();
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Deployment)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={() => {
            processQueryStringContext.actions.showProcessStepTemplates();
            dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
};
AddDeploymentsStepButton.displayName = "AddDeploymentsStepButton"
export type ContextAddStepButtonProps = AddRunbookStepButtonPropChanges;
const ContextAddStepButton: React.FC<ContextAddStepButtonProps> = (props) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const processContext = useProcessContext();
    const process = processContext.selectors.getOptionalStoredProcess();
    if (isRunbookProcessState(process)) {
        return <AddRunbookStepButton projectSlug={project.Slug} projectId={project.Id} processId={process.Id} runbookId={process.RunbookId} {...props}/>;
    }
    else {
        return <AddDeploymentsStepButton projectSlug={project.Slug} projectId={project.Id} {...props}/>;
    }
};
ContextAddStepButton.displayName = "ContextAddStepButton"
export default ContextAddStepButton;
