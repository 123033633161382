import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "~/routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface RouteParams {
    accountId: string;
}
const EditAccount: React.SFC<RouteComponentProps<{
    accountId: string;
}>> = (props) => <InternalRedirect to={{ pathname: routeLinks.infrastructure.account(props.match.params.accountId), search: props.location.search }}/>;
EditAccount.displayName = "EditAccount"
export class V3AccountRoutes extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        const match = this.props.match;
        return (<Switch>
                <ReloadableRoute path={"/accounts/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.accounts.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/accounts/:accountId"} component={EditAccount}/>
                <ReloadableRoute>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.accounts.root, search: this.props.location.search }}/>
                </ReloadableRoute>
            </Switch>);
    }
    static displayName = "V3AccountRoutes";
}
export default V3AccountRoutes;
