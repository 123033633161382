import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter, Route } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import DeploymentProcessSettings from "../DeploymentProcessSettings";
export const DeploymentProcessSettingsPage = withPage({ page: pageIds.project().deploymentSettings })(DeploymentProcessSettings);
type DeploymentSettingsRouteProps = {
    path: string;
};
type Props = DeploymentSettingsRouteProps & RouteComponentProps<{
    projectSlug: string;
}>;
class DeploymentSettingsRoute extends React.Component<Props> {
    render() {
        return (<BranchAwareRedirect>
                <ErrorContextProvider>
                    <Switch>
                        <Route path={`${this.props.path}`} exact={true} render={() => <DeploymentProcessSettingsPage />}/>
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>);
    }
    static displayName = "DeploymentSettingsRoute";
}
const EnhancedDeploymentSettingsRoute = withRouter(DeploymentSettingsRoute);
export default EnhancedDeploymentSettingsRoute;
