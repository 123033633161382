import * as React from "react";
import type { SpaceAwareNavigation } from "./SpaceAwareNavigation/SpaceAwareNavigation";
import { useSpaceAwareNavigation } from "./SpaceAwareNavigation/useSpaceAwareNavigation";
interface NavigateProps {
    render: (navigation: SpaceAwareNavigation) => React.ReactElement | null;
}
export const Navigate: React.FC<NavigateProps> = ({ render }) => {
    const navigation = useSpaceAwareNavigation();
    return render(navigation);
};
Navigate.displayName = "Navigate"
export function withNavigation<T>(Component: React.ComponentType<T & {
    navigation: SpaceAwareNavigation;
}>) {
    const WithNavigation: React.FC<T> = (props: T) => {
        return <Navigate render={(navigation) => <Component navigation={navigation} {...props}/>}/>;
    };
    WithNavigation.displayName = "WithNavigation"
    return WithNavigation;
}
export default Navigate;
