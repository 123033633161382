/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { session } from "~/clientInstance";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { createFromLocationState } from "./createFromLocationState";
export default class SecureRoute extends React.Component<any> {
    render() {
        // Test for analyticsSession is for debug mode recompile relaunches, otherwise it causes the user to be authenticated (session is a global statid), but the session to not be started (analyticsSession is in context)
        const { component: Component, analyticsSession, ...rest } = this.props;
        return (<ReloadableRoute {...rest} render={(props) => {
                return session.isAuthenticated() && analyticsSession !== undefined ? <Component {...rest}/> : <InternalRedirect to={{ pathname: routeLinks.currentUser.signIn, state: createFromLocationState(this.props.location) }}/>;
            }}/>);
    }
    static displayName = "SecureRoute";
}
