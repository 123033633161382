import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./ProcessActionErrorIndicator.module.less";
interface ProcessActionErrorIndicatorProps {
    actionErrors: string[];
    accessibleName?: string;
}
export const ProcessActionErrorIndicator: React.FC<ProcessActionErrorIndicatorProps> = ({ actionErrors, accessibleName }) => {
    const theme = useOctopusTheme();
    const items = actionErrors.map((x, index) => <li key={index}>{x}</li>);
    return (<ToolTip content={<ul className={styles.itemContainer}>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa-solid fa-exclamation-triangle" style={{ color: theme.danger }} role="img" aria-label={accessibleName}/>
            </div>
        </ToolTip>);
};
ProcessActionErrorIndicator.displayName = "ProcessActionErrorIndicator"
