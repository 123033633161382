import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";
export const CommonUserProfileOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};
CommonUserProfileOverview.displayName = "CommonUserProfileOverview"
export const CommonUserProfileTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
CommonUserProfileTopics.displayName = "CommonUserProfileTopics"
