import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryFeedOverview, CommonLibraryFeedTopics } from "../../CommonComponents/CommonLibraryFeedViews";
export const LibraryFeedsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.feeds,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryFeedOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryFeedTopics />;
    },
};
