/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Repository } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import PaperLayout from "~/components/PaperLayout/index";
import { ProjectTriggerRedirect } from "~/components/RootRoutes/ProjectTriggerRedirect";
import { repository } from "../../clientInstance";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
interface ProjectChildRedirectState extends DataBaseComponentState {
    redirectTo: string;
}
interface ProjectChildRedirectProps {
    getProjectId: (repository: Repository) => Promise<string>;
    getRedirectPath: (projectId: string) => string;
}
class ProjectChildRedirect extends DataBaseComponent<ProjectChildRedirectProps, ProjectChildRedirectState> {
    constructor(props: ProjectChildRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectId = await this.props.getProjectId(repository);
            const redirectTo = this.props.getRedirectPath(projectId);
            this.setState({
                redirectTo,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={{ pathname: redirectTo }}/>;
    }
    static displayName = "ProjectChildRedirect";
}
export const ReleaseRedirect: React.SFC<{
    releaseId: string;
}> = (props) => {
    return <ProjectChildRedirect getProjectId={async (r) => (await r.Releases.get(props.releaseId)).ProjectId} getRedirectPath={(projectId: string) => routeLinks.project(projectId).release(props.releaseId).root}/>;
};
ReleaseRedirect.displayName = "ReleaseRedirect"
export const TriggerRedirect: React.SFC<{
    triggerId: string;
}> = (props) => {
    return <ProjectTriggerRedirect triggerId={props.triggerId}/>;
};
TriggerRedirect.displayName = "TriggerRedirect"
export const ChannelRedirect: React.SFC<{
    channelId: string;
}> = (props) => {
    return <ProjectChildRedirect getProjectId={async (r) => (await r.Channels.get(props.channelId)).ProjectId} getRedirectPath={(projectId: string) => routeLinks.project(projectId).channel(props.channelId)}/>;
};
ChannelRedirect.displayName = "ChannelRedirect"
export const RunbookRedirect: React.SFC<{
    runbookId: string;
}> = (props) => {
    return <ProjectChildRedirect getProjectId={async (r) => (await r.Runbooks.get(props.runbookId)).ProjectId} getRedirectPath={(projectId: string) => routeLinks.project(projectId).operations.runbook(props.runbookId).root}/>;
};
RunbookRedirect.displayName = "RunbookRedirect"
