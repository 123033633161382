/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import { GetNamedPackageReferences, GetPrimaryPackageReference, InitialisePrimaryPackageReference, IsPrimaryPackageReference, SetPrimaryPackageReference, ActionExecutionLocation, FeedType, PackageAcquisitionLocation, DOCKER_IMAGE_REFERENCE_PURPOSE, } from "@octopusdeploy/octopus-server-client";
import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import StringCheckbox from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import RadioButton from "../../../primitiveComponents/form/RadioButton/RadioButton";
import { DebounceText } from "../../../primitiveComponents/form/Text/Text";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper/CommonSummaryHelper";
import ActionButton from "../../Button";
import { TextFormat, VariableLookupCodeEditor } from "../../CodeEditor/CodeEditor";
import DialogOpener from "../../Dialog/DialogOpener";
import KeyValueEditList from "../../EditList/KeyValueEditList";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import { Note, StringRadioButtonGroup, Summary } from "../../form";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { getKubernetesTargetDiscoveryCloudProviders } from "../kubernetes/getKubernetesTargetDiscoveryCloudProviders";
import DockerReferenceList from "../packageReferences";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import { useScriptActionContext } from "../script/ScriptActionContext";
import type { WithScriptActionContextInjectedProps } from "../script/withScriptActionContext";
import type { PackagedHelmValuesProperties, PackagedHelmValuesReference } from "./PackagedHelmValuesDialog";
import { PackagedHelmValuesDialog } from "./PackagedHelmValuesDialog";
class HelmChartUpgradeActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Upgrade a Helm chart</div>;
    }
    static displayName = "HelmChartUpgradeActionSummary";
}
class PackageReferenceList extends RemoveItemsList<PackagedHelmValuesReference> {
}
enum HelmClientVersion {
    V3 = "V3",
    V2 = "V2"
}
enum HelmLocation {
    Default = "default",
    Local = "local",
    Package = "package"
}
interface HelmChartUpgradeActionEditState {
    editPackageReference?: PackagedHelmValuesReference;
    editPackageReferenceIndex?: number;
    customHelmExecutable: HelmLocation;
    variableServerNames: string[];
}
type HelmChartUpgradeActionEditProps = ActionEditProps<HelmChartUpgradeProperties, PackagedHelmValuesProperties>;
type HelmChartUpgradeActionEditInternalProps = HelmChartUpgradeActionEditProps & ActionWithFeeds & WithScriptActionContextInjectedProps;
class HelmChartUpgradeActionEditInternal extends BaseComponent<HelmChartUpgradeActionEditInternalProps, HelmChartUpgradeActionEditState> {
    constructor(props: HelmChartUpgradeActionEditInternalProps) {
        super(props);
        this.state = {
            customHelmExecutable: HelmLocation.Default,
            variableServerNames: [],
        };
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
        await this.props.doBusyTask(async () => {
            if (GetHelmExePackageReference(this.props.packages)) {
                this.setState({ customHelmExecutable: HelmLocation.Package });
            }
            else if (this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]) {
                this.setState({ customHelmExecutable: HelmLocation.Local });
            }
            this.setState({ variableServerNames: (await this.props.scriptActionContext?.loadVariables()) ?? [] });
        });
        if (this.props.properties["Octopus.Action.Helm.ResetValues"] === undefined) {
            this.props.setProperties({ ["Octopus.Action.Helm.ResetValues"]: "True" }, true);
        }
        if (this.props.properties["Octopus.Action.Helm.ClientVersion"] === undefined) {
            this.props.setProperties({ "Octopus.Action.Helm.ClientVersion": HelmClientVersion.V3 });
        }
    }
    render() {
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const editPackageReferenceDialog = (<DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <PackagedHelmValuesDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} feeds={this.props.feeds} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={this.loadFeeds}/>
            </DialogOpener>);
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const helmExepkg = GetHelmExePackageReference(this.props.packages)!;
        const yamlAutocompleteResults = codeEditorVariablesList(this.state.variableServerNames, localNames ?? []);
        return (<div>
                {editPackageReferenceDialog}
                <ExpanderSectionHeading title="Chart"/>
                <ExpandableFormSection errorKey="package" isExpandedByDefault={this.props.expandedByDefault} title="Chart" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={<span>Choose the chart you want to deploy</span>}>
                    <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} feedType={[FeedType.Helm, FeedType.BuiltIn, FeedType.Maven]} refreshFeeds={this.loadFeeds} parameters={this.props.parameters} packageSelectionMode={pkg.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Upgrade Options"/>
                <ExpandableFormSection errorKey="ReleaseName" isExpandedByDefault={this.props.expandedByDefault} title="Kubernetes Release" summary={this.releaseNameSummary()} help="if a release by this name doesn't already exist, run an install">
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.ReleaseName"]} placeholder="#{Octopus.Action.Name | ToLower}-#{Octopus.Environment.Name | ToLower}" onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.ReleaseName"]: x })} label="Kubernetes Release Name"/>
                    <Note>
                        Due to Helm limitations, the release name must be unique across a cluster as the name is shared across namespace boundaries.
                        <br />
                        The Octopus variable syntax is supported, however the final release name must consist of only lower case alphanumeric and dash characters.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection title="Namespace" summary={this.namespaceSummary()} errorKey="Namespace" help="Specify the namespace the chart will be installed into">
                    <VariableLookupText label="Namespace" value={this.props.properties["Octopus.Action.Helm.Namespace"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.Namespace"]: x })}/>
                    <Note>
                        By default the chart will be installed into the namespace from the Kubernetes deployment target. If set, this value will override the target namespace and be passed as the <code>--namespace</code> option to the Helm client.
                    </Note>
                    <Note>Setting this option only works for the initial installation of a Helm release. Subsequent deploys attempt to upgrade the release, and will fail if the namespace does not match.</Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="ResetValues" title="Reset Values" summary={this.resetSummary()} help="When upgrading, reset the values to the ones built into the chart with those provided in the current deployment">
                    <StringCheckbox value={this.props.properties["Octopus.Action.Helm.ResetValues"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.ResetValues"]: x })} label="Reset Values" note={<span>
                                Corresponds to <code>--reset-values</code> Helm argument
                            </span>}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Timeout" title="Timeout" summary={this.summaryTimeout()} help={<span>Duration to wait for any individual Kubernetes operation (like Jobs for hooks)</span>}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.Timeout"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.Timeout"]: x })} label="Timeout"/>
                    <Note>
                        By default Helms uses a default timeout of <code>300 seconds</code>. Helm 2 expects an integer like <code>300</code> and Helm 3 expects a duration like <code>300s</code>.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Helm.AdditionalArgs" title="Additional Arguments" summary={this.summaryAdditionalArguments()} help={<span>
                            Provide additional arguments that will be passed to the <code>helm upgrade</code> command.
                        </span>}>
                    <VariableLookupText localNames={this.props.localNames} label="Additional arguments" multiline={true} value={this.props.properties["Octopus.Action.Helm.AdditionalArgs"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Helm.AdditionalArgs"]: val })}/>
                    <Note>
                        A complete list of the additional arguments which can be supplied can be found in the <ExternalLink href="HelmUpgradeOptions">Helm upgrade documentation</ExternalLink>.
                    </Note>
                    <Note>
                        E.g: <code>--recreate-pods</code>
                    </Note>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Template Values"/>
                <ExpandableFormSection errorKey="Octopus.Action.Helm.KeyValues" title="Explicit Key Values" summary={this.summaryVariables()} help={<span>
                            Adds values into custom <code>values.yaml</code> and includes via <code>--values</code> Helm argument
                        </span>}>
                    <KeyValueEditList items={this.props.properties["Octopus.Action.Helm.KeyValues"]} name="Key/Value" separator="=" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Helm.KeyValues"]: val })} valueLabel="Value" projectId={this.props.projectId} gitRef={this.props.gitRef} keyLabel="Key"/>
                </ExpandableFormSection>

                <ExpandableFormSection summary={this.summaryRawYaml()} title={"Raw Values YAML"} errorKey={"Octopus.Action.Helm.YamlValues"}>
                    <VariableLookupCodeEditor allowFullScreen value={this.props.properties["Octopus.Action.Helm.YamlValues"]} language={TextFormat.YAML} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.YamlValues"]: x })} autoComplete={yamlAutocompleteResults}/>
                    <Note>
                        Enter the raw YAML that will be provided as a values file. This field supports the <ExternalLink href="VariableSubstitutionSyntaxExtended">extended template syntax</ExternalLink>.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Package.ValuesFilePath" title="Files in Chart Package" summary={this.summaryChartPackageVariables(pkg as PackagedHelmValuesReference)} help={<span>Files sourced from the Chart package</span>}>
                    <VariableLookupText localNames={this.props.localNames} value={pkg.Properties["ValuesFilePath"]} onChange={(x) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, ValuesFilePath: x } }, this.props.packages))} label="File(s)" multiline={true}/>
                    <Note>
                        A newline-separated list of file names, relative to the chart root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before used. Extended template and wildcard syntax is
                        supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                    </Note>
                </ExpandableFormSection>
                {this.packagesFormSection()}

                <ExpanderSectionHeading title="Connection"/>

                <ExpandableFormSection errorKey="Octopus.Action.Helm.ClientVersion" title="Helm Client Version" summary={this.summaryHelmClientVersion()} help={"Select the Helm client version"}>
                    <Note>Please ensure this matches the version of the Helm tool below.</Note>
                    <StringRadioButtonGroup value={this.props.properties["Octopus.Action.Helm.ClientVersion"]} onChange={(val) => this.props.setProperties({ "Octopus.Action.Helm.ClientVersion": val as HelmClientVersion })}>
                        <RadioButton value={HelmClientVersion.V2} label={"V2"}/>
                        <RadioButton value={HelmClientVersion.V3} label={"V3"}/>
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="HelmExePackage" title="Helm Client Tool" summary={this.summaryCustomHelmExePackage(helmExepkg)} help={<span>Select the Helm client tool to invoke</span>}>
                    {this.props.properties["Octopus.Action.Helm.ClientVersion"] === HelmClientVersion.V2 && <Note>Helm requires that the client tool be the same minor version as the Tiller service installed in the kubernetes cluster.</Note>}
                    <StringRadioButtonGroup value={this.state.customHelmExecutable} onChange={(val) => {
                this.setState({ customHelmExecutable: val as HelmLocation }, () => {
                    if (val !== HelmLocation.Package) {
                        this.props.setPackages(ClearHelmExePackage(this.props.packages));
                    }
                    if (val === HelmLocation.Default) {
                        this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: null! });
                    }
                });
            }}>
                        <RadioButton value={HelmLocation.Default} label={<span>
                                    Invoke <code>helm</code> command
                                </span>}/>
                        <Note>
                            The Helm client will be available on the worker as the <code>helm</code> command.
                        </Note>
                        <RadioButton value={HelmLocation.Local} label="Helm client tool available from another path"/>
                        <Note>The Helm client is available via a specific path.</Note>
                        {this.state.customHelmExecutable === HelmLocation.Local && (<div>
                                <DebounceText label="Helm executable location" value={this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: x })}/>
                                <Note>The path to the Helm executable.</Note>
                            </div>)}
                        <RadioButton value={HelmLocation.Package} label="Custom packaged Helm client tool"/>
                        <Note>
                            Upload a custom Helm client to use during deployment. See our <ExternalLink href="HelmClient">documentation</ExternalLink> for more information on client requirements.
                        </Note>
                        {this.state.customHelmExecutable === HelmLocation.Package && (<PackageSelector packageId={helmExepkg?.PackageId} feedId={helmExepkg?.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetHelmExePackage({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetHelmExePackage({ FeedId: feedId }, this.props.packages))} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} feedType={[FeedType.Nuget, FeedType.BuiltIn]} refreshFeeds={this.loadFeeds}/>)}
                        {this.state.customHelmExecutable === HelmLocation.Package && (<div>
                                <DebounceText label="Helm executable location" value={this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: x })}/>
                                <Note>The path to the Helm executable. This can be a path relative to the package root.</Note>
                            </div>)}
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Helm.ClientVersion"] === HelmClientVersion.V2 && (<React.Fragment>
                        <ExpandableFormSection errorKey="TillerNamespace" title="Tiller Namespace" summary={this.summaryTillerNamespace()} help={<span>Namespace of Tiller</span>}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.TillerNamespace"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.TillerNamespace"]: x })} label="Tiller Namespace"/>
                            <Note>
                                By default Helms looks for Tiller in the <code>kube-system</code> namespace.
                            </Note>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="TillerConnectionTimeout" title="Connection Timeout" summary={this.summaryTillerTimeout()} help={<span>Duration (in seconds) Helm will wait to establish a connection to tiller</span>}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.TillerTimeout"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.TillerTimeout"]: x })} label="Tiller Connection Timeout"/>
                            <Note>
                                By default Helms uses a default timeout of <code>300 seconds</code>.
                            </Note>
                        </ExpandableFormSection>
                    </React.Fragment>)}
                <DockerReferenceList projectId={this.props.projectId} gitRef={this.props.gitRef} packages={this.props.packages} plugin={this.props.plugin} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} feeds={this.props.feeds} refreshFeeds={this.props.refreshFeeds} setProperties={this.props.setProperties} properties={this.props.properties} parameters={this.props.parameters}/>
            </div>);
    }
    packagesFormSection = () => {
        const packageReferences = GetNamedPackageReferences(this.props.packages).filter((pkg) => !IsHelmExePackageReference(pkg) && !IsDockerImageReference(pkg)) as PackagedHelmValuesReference[];
        return (<ExpandableFormSection errorKey="Octopus.Action.Script.Packages" title={"Files in Additional Packages"} summary={this.packageReferenceSummary()} help={<span>Includes yaml files from packages</span>}>
                <Note>Acquired files have Octopus variable replacement on them and must result in the standard yaml format</Note>

                <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()}/>]} data={packageReferences} onRow={(p) => this.packageReferenceListItem(p)} onRowTouch={(pkg) => this.editPackageReference(pkg)} onRemoveRow={(pkg) => this.removePackageReference(pkg)}/>
            </ExpandableFormSection>);
    };
    packageVariableNames = (): string[] => {
        return _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
            `Octopus.Action.Package[${pkg.Name}].PackageId`,
            `Octopus.Action.Package[${pkg.Name}].FeedId`,
            `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
            `Octopus.Action.Package[${pkg.Name}].Path`,
        ]));
    };
    packageReferenceSummary = () => {
        const packageReferences = GetNamedPackageReferences(this.props.packages).filter((pkg) => !IsHelmExePackageReference(pkg) && !IsPrimaryPackageReference(pkg) && !IsDockerImageReference(pkg));
        if (!packageReferences || packageReferences.length === 0) {
            return Summary.placeholder("No additional value files");
        }
        return Summary.summary(`${packageReferences.length} package references`);
    };
    addPackageReference = () => {
        const additionalPackage: PackagedHelmValuesReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
            Properties: { ValuesFilePath: "", PerformVariableReplace: "False", Extract: "true" },
        };
        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null! });
    };
    summaryHelmClientVersion = () => {
        const version = this.props.properties["Octopus.Action.Helm.ClientVersion"];
        if (version) {
            switch (version) {
                case HelmClientVersion.V2:
                    return Summary.summary("V2");
                case HelmClientVersion.V3:
                    return Summary.summary("V3");
            }
        }
        else {
            return Summary.placeholder("No Helm Client version has been supplied");
        }
    };
    summaryTillerNamespace = () => {
        const tillerNamespace = this.props.properties["Octopus.Action.Helm.TillerNamespace"];
        if (tillerNamespace) {
            return Summary.summary(tillerNamespace);
        }
        else {
            return Summary.placeholder("No Tiller namespace override supplied");
        }
    };
    summaryTimeout = () => {
        const timeout = this.props.properties["Octopus.Action.Helm.Timeout"];
        if (timeout) {
            return Summary.summary(<span>{timeout} second(s)</span>);
        }
        else {
            return Summary.placeholder("No timeout override supplied");
        }
    };
    summaryAdditionalArguments = () => {
        const additionalArgs = this.props.properties["Octopus.Action.Helm.AdditionalArgs"];
        if (additionalArgs) {
            return Summary.summary(<span>{additionalArgs}</span>);
        }
        else {
            return Summary.placeholder("No additional arguments");
        }
    };
    summaryTillerTimeout = () => {
        const tillerTimeout = this.props.properties["Octopus.Action.Helm.TillerTimeout"];
        if (tillerTimeout) {
            return Summary.summary(<span>{tillerTimeout} second(s)</span>);
        }
        else {
            return Summary.placeholder("No timeout override supplied");
        }
    };
    summaryCustomHelmExePackage = (pkg: PackagedHelmValuesReference) => {
        const helmPkg = GetHelmExePackageReference(this.props.packages);
        const helmPath = this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"];
        if (helmPkg) {
            return Summary.summary(<span>
                    Helm client available at <strong>{helmPath}</strong> will be invoked from inside package <strong>{helmPkg.PackageId}</strong>
                </span>);
        }
        else if (helmPath) {
            return Summary.summary(<span>
                    Helm client available at <strong>{helmPath}</strong> will be invoked
                </span>);
        }
        else {
            return Summary.default(<span>
                    Default Helm client available via <code>helm</code> path will be invoked
                </span>);
        }
    };
    summaryChartPackageVariables = (pkg: PackagedHelmValuesReference) => {
        const valuesFilePath = pkg.Properties["ValuesFilePath"];
        if (valuesFilePath) {
            return Summary.summary(<span>
                    values file(s) <strong>{valuesFilePath}</strong> from the chart package will be included in the upgrade
                </span>);
        }
        return Summary.placeholder("No additional values files provided");
    };
    editPackageReference = (pkg: PackagedHelmValuesReference) => {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    };
    removePackageReference = (pkg: PackagedHelmValuesReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };
    packageReferenceListItem = (pkg: PackagedHelmValuesReference) => {
        return (<ProcessFeedLookup feedId={pkg.FeedId}>
                {(feed) => (<div>
                        <div>
                            <strong>{pkg.Properties["ValuesFilePath"]}</strong> in <strong>{pkg.PackageId}</strong> from feed <strong>{feed ? feed.Name : pkg.FeedId}</strong>
                        </div>
                    </div>)}
            </ProcessFeedLookup>);
    };
    savePackageReference(packageReference: PackagedHelmValuesReference) {
        const packageReferences = [...this.props.packages];
        packageReference = { ...packageReference, Name: "ValuesPack-" + (this.state.editPackageReferenceIndex || packageReferences.length) };
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        }
        else {
            packageReferences[this.state.editPackageReferenceIndex!] = packageReference;
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }
    resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        });
    };
    private summaryRawYaml = () => {
        const rawYaml = this.props.properties["Octopus.Action.Helm.YamlValues"];
        if (rawYaml) {
            return Summary.summary("An inline YAML values file has been provided");
        }
        return Summary.placeholder("No inline YAML file has been provided");
    };
    private summaryVariables() {
        const variables = JSON.parse(this.props.properties["Octopus.Action.Helm.KeyValues"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No explicit value overrides supplied");
        }
        else {
            const text = Object.keys(variables)
                .map((m) => m + " = " + variables[m])
                .join(", ");
            return Summary.summary(text);
        }
    }
    private releaseNameSummary = () => {
        const releaseName = this.props.properties["Octopus.Action.Helm.ReleaseName"];
        if (this.props.properties["Octopus.Action.Helm.ReleaseName"]) {
            return Summary.summary(<span>
                    The release name will be <strong>{releaseName}</strong>
                </span>);
        }
        return Summary.default(<span>
                The default release name is{" "}
                <strong>
                    #{`{Octopus.Action.Name}`}-#{`{Octopus.Environment}`}
                </strong>
            </span>);
    };
    // private installSummary = () => {
    //     if (this.props.properties["Octopus.Action.Helm.Install"]) {
    //         return Summary.default("The release will be created if none yet exist with this name");
    //     }
    //     return Summary.placeholder(<span>The release will <strong>not</strong> be created if none yet exist with this name</span>);
    // }
    private resetSummary = () => {
        if (this.props.properties["Octopus.Action.Helm.ResetValues"]) {
            return Summary.default("Previous deployment variables will be replaced");
        }
        return Summary.placeholder(<span>
                Previous deployment variables will <code>not</code> be replaced if no new values are provided
            </span>);
    };
    private namespaceSummary = () => {
        const namespace = this.props.properties["Octopus.Action.Helm.Namespace"];
        if (!!namespace) {
            return Summary.summary(<span>
                    The chart will be installed into the namespace <strong>{namespace}</strong>
                </span>);
        }
        return Summary.default(<span>The chart will be installed into the namespace specified on the Kubernetes deployment target, or the default namespace if none is specified on the target.</span>);
    };
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "HelmChartUpgradeActionEditInternal";
}
function GetHelmExePackageReference(packages: Array<PackageReference<PackagedHelmValuesProperties>>): PackageReference<PackagedHelmValuesProperties> | undefined {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return packages?.find((pkg) => IsHelmExePackageReference(pkg))!;
}
function IsHelmExePackageReference(pkg: PackageReference<PackagedHelmValuesProperties>): boolean {
    return pkg.Name === "HelmExe";
}
function IsDockerImageReference(pkg: PackageReference<PackagedHelmValuesProperties>): boolean {
    return pkg.Properties.Purpose === DOCKER_IMAGE_REFERENCE_PURPOSE;
}
function ClearHelmExePackage(packages: Array<PackageReference<PackagedHelmValuesProperties>>): Array<PackageReference<PackagedHelmValuesProperties>> {
    return packages.filter((pkg) => !IsHelmExePackageReference(pkg));
}
function SetHelmExePackage(updated: Partial<PackageReference<PackagedHelmValuesProperties>>, packages: Array<PackageReference<PackagedHelmValuesProperties>>): Array<PackageReference<PackagedHelmValuesProperties>> {
    const helmExePkg = {
        FeedId: null!,
        PackageId: null!,
        AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
        Properties: { PerformVariableReplace: "False", Extract: "True" } as unknown as PackagedHelmValuesProperties,
        ...GetHelmExePackageReference(packages),
        ...updated,
        Name: "HelmExe",
    } as PackageReference<PackagedHelmValuesProperties>;
    const newPackages = _.map(packages, (pkg) => {
        if (!IsHelmExePackageReference(pkg)) {
            return pkg;
        }
        return helmExePkg;
    });
    if (!newPackages.includes(GetHelmExePackageReference(newPackages)!)) {
        newPackages.push(helmExePkg);
    }
    return newPackages;
}
export function HelmChartUpgradeActionEdit(props: React.PropsWithChildren<HelmChartUpgradeActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const scriptActionContext = useScriptActionContext();
    return <HelmChartUpgradeActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} scriptActionContext={scriptActionContext}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.HelmChartUpgrade",
    summary: (properties, targetRolesAsCSV) => <HelmChartUpgradeActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: HelmChartUpgradeActionEdit,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts"],
    },
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
});
