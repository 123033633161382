import React from "react";
import { Switch } from "react-router-dom";
import LicenseChecker from "~/areas/insights/components/InsightsRoutes/LicenseChecker";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
import { InsightsReportLayout } from "../InsightsReportLayout/InsightsReportLayout";
import { ReportDeploymentDuration } from "../Reports/ReportDeploymentDuration";
import { ReportDeploymentFailureRate } from "../Reports/ReportDeploymentFailureRate";
import { ReportDeploymentFrequency } from "../Reports/ReportDeploymentFrequency";
import { ReportDeploymentLeadTime } from "../Reports/ReportDeploymentLeadTime";
import { ReportList } from "../Reports/ReportList/ReportList";
import { ReportMeanTimeToRecovery } from "../Reports/ReportMeanTimeToRecovery";
import { ReportOverview } from "../Reports/ReportOverview/ReportOverview";
import { ReportSettings } from "../Reports/ReportSettings";
const InsightsReportListPage = withPage({ page: pageIds.insights.reports })(ReportList);
const InsightsReportOverviewPage = withPage({ page: pageIds.insights.report.overview })(ReportOverview);
const InsightsReportDeploymentFrequencyPage = withPage({ page: pageIds.insights.report.frequency })(ReportDeploymentFrequency);
const InsightsReportDeploymentLeadTimePage = withPage({ page: pageIds.insights.report.leadTime })(ReportDeploymentLeadTime);
const InsightsReportDeploymentFailureRatePage = withPage({ page: pageIds.insights.report.failureRate })(ReportDeploymentFailureRate);
const InsightsReportDeploymentMeanTimeToRecoveryPage = withPage({ page: pageIds.insights.report.timeToRecovery })(ReportMeanTimeToRecovery);
const InsightsReportSettingsPage = withPage({ page: pageIds.insights.report.settings })(ReportSettings);
const InsightsReportDeploymentDurationPage = withPage({ page: pageIds.insights.report.deploymentDuration })(ReportDeploymentDuration);
const InsightsRoutes = () => {
    const reportLinks = routeLinks.insights.report(":reportId");
    return (<Switch>
            <ReloadableRoute path={routeLinks.insights.root}>
                <LicenseChecker>
                    <Switch>
                        <ReloadableRoute path={routeLinks.insights.reports} exact component={InsightsReportListPage}/>
                        <ReloadableRoute path={reportLinks.root}>
                            <InsightsReportLayout>
                                {({ report, refreshReport }) => (<Switch>
                                        <ReloadableRoute path={reportLinks.overview} render={() => <InsightsReportOverviewPage report={report}/>}/>
                                        <ReloadableRoute path={reportLinks.frequency} exact render={() => <InsightsReportDeploymentFrequencyPage report={report}/>}/>
                                        <ReloadableRoute path={reportLinks.leadTime} exact render={() => <InsightsReportDeploymentLeadTimePage report={report}/>}/>
                                        <ReloadableRoute path={reportLinks.failureRate} exact render={() => <InsightsReportDeploymentFailureRatePage report={report}/>}/>
                                        <ReloadableRoute path={reportLinks.timeToRecovery} exact render={() => <InsightsReportDeploymentMeanTimeToRecoveryPage report={report}/>}/>
                                        <ReloadableRoute path={reportLinks.settings} exact render={() => <InsightsReportSettingsPage report={report} refreshReport={refreshReport}/>}/>
                                        <ReloadableRoute path={reportLinks.deploymentDuration} exact render={() => <InsightsReportDeploymentDurationPage report={report}/>}/>
                                        <RedirectAs404 />
                                    </Switch>)}
                            </InsightsReportLayout>
                        </ReloadableRoute>
                        <RedirectAs404 />
                    </Switch>
                </LicenseChecker>
            </ReloadableRoute>
        </Switch>);
};
export default InsightsRoutes;
