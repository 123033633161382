import { WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { WorkerPoolChip } from "~/components/Chips";
import type { WorkerPoolIndex } from "../workerPools";
interface ReadonlyWorkerPoolProps {
    poolIndex: WorkerPoolIndex;
    pool: string;
}
const ReadonlyWorkerPool: React.SFC<ReadonlyWorkerPoolProps> = (props) => {
    const poolResource = props.poolIndex && props.poolIndex[props.pool];
    const poolName = poolResource ? poolResource.Name : props.pool;
    const poolType = poolResource ? poolResource.WorkerPoolType : WorkerPoolType.Static;
    return <WorkerPoolChip workerPoolName={poolName} workerPoolType={poolType} key={props.pool}/>;
};
ReadonlyWorkerPool.displayName = "ReadonlyWorkerPool"
export default ReadonlyWorkerPool;
