import React from "react";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { DefaultTopics } from "./DefaultViews";
export const CommonTaskOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The <b>tasks</b> section is where you can check on the status and history of the background tasks your Octopus Server is managing.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonTaskOverview.displayName = "CommonTaskOverview"
export const CommonTaskTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
CommonTaskTopics.displayName = "CommonTaskTopics"
