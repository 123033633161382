import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import { FormTitleForRunbook } from "../../Runbooks/LastPublishedChip";
import RunbooksFormPaperLayout from "../../Runbooks/Layouts/RunbooksFormPaperLayout";
import PublishButton from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useRunbookContext, useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { ContextAddStepButton } from "../../Steps";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProjectContextFormPaperLayout } from "./ProjectContextFormPaperLayout";
type ProcessContextFormPaperLayoutPropsInternal = {
    doBusyTask: DoBusyTask;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
};
type ProcessContextFormPaperLayoutProps = FormPaperLayoutProps & ProcessContextFormPaperLayoutPropsInternal & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = ({ children, ...rest }) => {
    const processContext = useProcessContext();
    const type = processContext.selectors.getProcessType();
    const hasSteps = processContext.selectors.hasSteps();
    const projectSlug = rest.match.params.projectSlug;
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    if (type === ProcessType.Runbook) {
        return (<RunbooksProcessEditorFormPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} projectSlug={projectSlug} disableAnimations={true} {...rest}>
                {children}
            </RunbooksProcessEditorFormPaperLayout>);
    }
    else {
        return (<DeploymentsProcessEditorFormPaperLayout title={"Process Editor"} breadcrumbTitle="Process" projectSlug={projectSlug} disableAnimations={true} {...rest}>
                {children}
            </DeploymentsProcessEditorFormPaperLayout>);
    }
};
ProcessContextFormPaperLayout.displayName = "ProcessContextFormPaperLayout"
export const EnhancedProcessContextFormPaperLayout = withRouter(ProcessContextFormPaperLayout);
const DeploymentsProcessEditorFormPaperLayout: React.FC<FormPaperLayoutProps & {
    projectSlug: string;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
}> = ({ projectSlug, ...props }) => {
    const projectLinks = routeLinks.project(projectSlug);
    const { title = "Process Editor", breadcrumbPath = projectLinks.deployments.process.root, breadcrumbTitle = "Process", fullWidth = true, ...rest } = props;
    return (<ProjectContextFormPaperLayout title={title} secondaryAction={<ContextAddStepButton />} breadcrumbPath={breadcrumbPath} breadcrumbTitle={breadcrumbTitle} fullWidth={fullWidth} {...rest}>
            {props.children}
        </ProjectContextFormPaperLayout>);
};
DeploymentsProcessEditorFormPaperLayout.displayName = "DeploymentsProcessEditorFormPaperLayout"
const RunbooksProcessEditorFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps & {
    projectSlug: string;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ projectSlug, hasSteps, doBusyTask, ...props }) => {
    const runbooksContext = useRunbookContext();
    const title = <FormTitleForRunbook runbookName={runbooksContext.state.runbook?.Name ?? ""}/>;
    // Our secondaryAction here cannot be an ActionList, as these actions end up in an ActionList via the PaperLayout anyway, and if you
    // nest an ActionList in another ActionList, you get weird margin issues.
    const secondaryAction = (<>
            <PublishButton doBusyTask={doBusyTask}/>
            <ContextualRunNowButton isDisabled={!hasSteps}/>
            <ContextAddStepButton />
        </>);
    return (<RunbooksFormPaperLayout title={title} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(projectSlug).operations.runbooks} secondaryAction={secondaryAction} {...props}>
            {props.children}
        </RunbooksFormPaperLayout>);
};
RunbooksProcessEditorFormPaperLayout.displayName = "RunbooksProcessEditorFormPaperLayout"
