import { compact } from "lodash";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle";
import type { NavItem } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import routeLinks from "~/routeLinks";
import { UxPatternsLogo } from "./UxGuide";
interface UxPatternsLayoutProps {
    children: React.ReactNode;
}
const UxPatternsLayout: React.FC<UxPatternsLayoutProps> = ({ children }) => {
    const navLinks: Array<NavItem | null> = [];
    navLinks.push(Navigation.navItem("Overview", routeLinks.uxGuide.patterns.overview));
    navLinks.push(Navigation.navItem("Something 1", routeLinks.uxGuide.patterns.whatever));
    const someSubLinks: Array<NavItem | null> = [];
    someSubLinks.push(Navigation.navItem("Something 2.1", routeLinks.uxGuide.patterns.whatever, true));
    someSubLinks.push(Navigation.navItem("Something 2.2", routeLinks.uxGuide.patterns.whatever));
    navLinks.push(Navigation.navGroup("Something 2", routeLinks.uxGuide.patterns.whatever, compact(someSubLinks), // Strip any that failed permission checks.
    undefined, true));
    navLinks.push(Navigation.navItem("Something 3", routeLinks.uxGuide.patterns.whatever));
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.uxGuide.patterns.overview} title={"UX Patterns"} breadcrumbTitle={"UX Guide"} breadcrumbPath={routeLinks.uxGuide.root}/>
            <NavigationSidebarLayout navLinks={compact(navLinks)} image={UxPatternsLogo} content={children}/>
        </main>);
};
UxPatternsLayout.displayName = "UxPatternsLayout"
export default UxPatternsLayout;
