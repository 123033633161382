/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ResourcesById, ProjectResource, TenantResource, RunbookResource, EnvironmentResource, RunbookProgressionResource, RunbooksDashboardItemResource, RunbookSnapshotResource, ResourceCollection, TriggerResource, TriggerScheduleResource, RunRunbookActionResource, } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import Card from "~/components/Card/Card";
import { environmentChipListIncludingMissing } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import { FormTitleForRunbook } from "./LastPublishedChip";
import RunbooksPaperLayout from "./Layouts/RunbooksPaperLayout";
import { NextScheduledRuns } from "./NextScheduledRuns";
import PublishButton from "./PublishButton";
import RunNowButton from "./RunNowButton";
import type { WithRunbookContextInjectedProps } from "./RunbookContext";
import { withRunbookContext } from "./RunbookContext";
import RunbookOnboarding from "./RunbookOnboarding";
import styles from "./RunbookOverviewLayout.module.less";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
const untenantedDeploymentLogo = require("./un-tenanted-deployment-logo.svg");
export function isRunbookConsumerOnly(projectId: string) {
    const hasRunbookEditPermissions = isAllowed({ permission: Permission.RunbookEdit, project: projectId, wildcard: true });
    const hasRunbookRunCreatePermissions = isAllowed({ permission: Permission.RunbookRunCreate, project: projectId, wildcard: true });
    return !hasRunbookEditPermissions && hasRunbookRunCreatePermissions;
}
export function isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project: ProjectResource, runbook: RunbookResource, runbookSnapshotId: string) {
    if (!project || !runbook) {
        return false;
    }
    const isConsumerOnly = isRunbookConsumerOnly(project.Id);
    if (!isConsumerOnly) {
        return false;
    }
    // Consumers must be running a published runbook.
    if (!runbook.PublishedRunbookSnapshotId) {
        return true;
    }
    // Consumers must be running the published snapshot only.
    return runbook.PublishedRunbookSnapshotId !== runbookSnapshotId;
}
interface RunbookOverviewLayoutState extends DataBaseComponentState {
    project: ProjectResource;
    runbook: RunbookResource;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    progression: RunbookProgressionResource;
    tenants: TenantResource[];
    environmentsById: ResourcesById<EnvironmentResource>;
    hasSteps: boolean;
    failedChecks: Array<{
        permission: Permission;
        isNotAllowed: boolean;
    }>;
    triggersResponse: ResourceCollection<TriggerResource>;
}
export interface RunbookOverviewLayoutRouteProps {
    runbookId: string;
}
const refreshIntervalInMs = 15000;
type RunbookOverviewLayoutProps = RouteComponentProps<ProjectRouteParams & RunbookOverviewLayoutRouteProps> & WithProjectContextInjectedProps & WithRunbookContextInjectedProps;
class RunbookOverviewLayoutInternal extends DataBaseComponent<RunbookOverviewLayoutProps, RunbookOverviewLayoutState> {
    constructor(props: RunbookOverviewLayoutProps) {
        super(props);
        this.state = {
            hasSteps: false,
            project: null!,
            runbook: null!,
            publishedRunbookSnapshot: null!,
            progression: null!,
            tenants: [],
            environmentsById: null!,
            failedChecks: [],
            triggersResponse: null!,
        };
    }
    async componentDidMount() {
        // noinspection ES6MissingAwait This is an async method, but we don't care about the result, so fire and forget (no await).
        this.props.projectContext.actions.refreshGitVariableErrors();
        await this.doBusyTask(async () => {
            await this.reload();
            await this.startRefreshLoop(() => this.getData(), refreshIntervalInMs, true, timeOperationOptions.forRefresh());
        });
    }
    async componentDidUpdate(prevProps: RunbookOverviewLayoutProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.doBusyTask(async () => {
                return await this.reload();
            });
        }
    }
    getData = async () => {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook) {
            return null;
        }
        const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
        const [tenants, environmentsById] = await Promise.all([tenantsPromise, repository.Environments.allById()]);
        const requiredPermissions = [
            { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
            { permission: Permission.EnvironmentView, wildcard: true },
        ];
        const failedChecks = requiredPermissions
            .map((check) => ({
            permission: check.permission,
            isNotAllowed: !isAllowed(check),
        }))
            .filter((check) => check.isNotAllowed);
        if (failedChecks.length > 0) {
            this.setState({
                project,
                runbook,
                failedChecks,
            });
            return null;
        }
        const gitRef = this.props.projectContext.state.gitRef;
        const [runbookProcess, progression, publishedRunbookSnapshot, triggersResponse] = await Promise.all([
            repository.RunbookProcess.get(runbook.RunbookProcessId)!,
            repository.Progression.getRunbookProgression(runbook),
            runbook.PublishedRunbookSnapshotId ? repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : Promise.resolve(null!),
            repository.Projects.getTriggers(project, gitRef, 0, 5, null!, TriggerActionCategory.Runbook, [runbook.Id]),
        ]);
        return {
            project: project!,
            runbook: runbook!,
            progression,
            tenants,
            environmentsById,
            hasSteps: runbookProcess.Steps.length > 0,
            publishedRunbookSnapshot,
            triggersResponse,
        };
    };
    async reload() {
        this.setState(await timeOperation(timeOperationOptions.forInitialLoad(), () => this.getData()));
    }
    renderDashboard() {
        if (!this.state.progression || Object.keys(this.state.progression.RunbookRuns).length === 0) {
            const runbook = this.state.runbook;
            return (<>
                    <div className={styles.emptyCell}>
                        There are no runs for this Runbook yet.&nbsp;<InternalLink to={routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook && runbook.Id).runNow.root}>Run Now</InternalLink>
                    </div>
                    <NoResults />
                </>);
        }
        const progression = this.state.progression;
        const environments = progression.Environments;
        const environmentKeys = Object.keys(progression.RunbookRuns);
        const rowsByTenant: Record<string, RunbooksDashboardItemResource[]> = {};
        environmentKeys.map((i) => {
            const runbookRuns = progression.RunbookRuns[i];
            runbookRuns.forEach((r) => {
                const untenantedRowKey = "";
                const rowKey = r.TenantId ? this.state.tenants!.find((t) => t.Id === r.TenantId)!.Name : untenantedRowKey;
                if (!rowsByTenant[rowKey]) {
                    rowsByTenant[rowKey] = [];
                }
                rowsByTenant[rowKey].push(r);
            });
        });
        const rowsBySortedTenantKeys = Object.keys(rowsByTenant).sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        const isTenanted = this.state.runbook.MultiTenancyMode !== TenantedDeploymentMode.Untenanted;
        return (<div>
                <div className={styles.dashboardCards}>
                    {rowsBySortedTenantKeys.map((tenantName, tenantRowIndex) => {
                const tenant = this.state.tenants.find((t) => t.Name === tenantName);
                const tenantId = tenant ? tenant.Id : null;
                return (<div className={styles.bodyRow} key={tenantRowIndex}>
                                {isTenanted && (<Section className={cn(styles.tenantName, styles.groupHeader)} key={tenantRowIndex}>
                                        {tenant && this.tile(tenant.Links.Logo, tenant.Name, routeLinks.tenant(tenantId!).root)}
                                        {!tenant && this.tile(untenantedDeploymentLogo, "Untenanted")}
                                    </Section>)}
                                {environmentKeys.map((x, environmentRowIndex) => {
                        const runbookRuns = progression.RunbookRuns[x];
                        const environment = environments.find((e) => e.Id === x);
                        const thisRunbookRun = runbookRuns.find((o) => o.TenantId === tenantId);
                        if (!environment || !thisRunbookRun) {
                            return null;
                        }
                        else {
                            const menuItems = [];
                            if (!isRunbookConsumerTryingToRunAnUnpublishedSnapshot(this.state.project, this.state.runbook, thisRunbookRun.RunbookSnapshotId)) {
                                menuItems.push(OverflowMenuItems.navItem("Run on...", routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.props.match.params.runbookId).runNow.runbookSnapshot(thisRunbookRun.RunbookSnapshotId), {
                                    permission: Permission.RunbookRunCreate,
                                    project: this.props.projectContext.state.model.Id,
                                    wildcard: true,
                                }));
                            }
                            menuItems.push(OverflowMenuItems.navItem("View snapshot", routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.props.match.params.runbookId).runbookSnapshot(thisRunbookRun.RunbookSnapshotId).root, {
                                permission: Permission.RunbookView,
                                project: this.props.projectContext.state.model.Id,
                                wildcard: true,
                            }));
                            return (<Card key={environmentRowIndex} className={styles.cardContainer} contentClassName={styles.cardContentContainer} leftAlign={true} logo={null} header={<div className={styles.runTaskEnvironment}>{environment.Name}</div>} content={<div>
                                                        <div className={styles.runTaskDetails}>
                                                            <RunbookTaskStatusDetails project={this.state.project} item={thisRunbookRun}/>
                                                            <div className={styles.runActions}>
                                                                <OverflowMenu menuItems={menuItems} tabIndex={-1}/>
                                                            </div>
                                                        </div>
                                                        {thisRunbookRun.RunbookSnapshotNotes ? <div className={styles.runTaskNotes}>{thisRunbookRun.RunbookSnapshotNotes}</div> : null}
                                                    </div>}/>);
                        }
                    })}
                            </div>);
            })}
                </div>
            </div>);
    }
    tile(logoUrl: string, name: string, toUrl?: string) {
        if (toUrl) {
            return (<InternalLink to={toUrl} className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem"/>
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </InternalLink>);
        }
        else {
            return (<div className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem"/>
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </div>);
        }
    }
    render() {
        const runbook = this.state.runbook;
        const project = this.state.project;
        const breadcrumbTitle = "Runbooks";
        const breadcrumbPath = routeLinks.project(this.props.match.params.projectSlug).operations.runbooks;
        if (!project || !runbook) {
            return <RunbooksPaperLayout busy={true} errors={this.errors}/>;
        }
        if (this.state.failedChecks.length > 0) {
            return (<RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} busy={this.state.busy} errors={this.errors}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {this.state.failedChecks[0].permission} permission is required to view project overview details
                    </Callout>
                </RunbooksPaperLayout>);
        }
        const nextScheduledElement = this.renderNextScheduled();
        if (project && !this.state.hasSteps) {
            const actions: JSX.Element[] = [
                <PermissionCheck permission={Permission.RunbookEdit} project={this.state.project.Id} wildcard={true}>
                    <NavigationButton label="Define your Runbook Process" href={routeLinks.project(project).operations.runbook(runbook.Id).runbookProcess.runbookProcess(runbook.RunbookProcessId).root} type={NavigationButtonType.Primary}/>
                </PermissionCheck>,
            ];
            const actionSection = <ActionList actions={actions}/>;
            return (<RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} busy={this.state.busy} errors={this.errors} sectionControl={actionSection} sidebar={nextScheduledElement}>
                    <RunbookOnboarding />
                </RunbooksPaperLayout>);
        }
        const sectionControlActions = [];
        if (this.state.hasSteps) {
            sectionControlActions.push(<PublishButton doBusyTask={this.doBusyTask}/>);
        }
        sectionControlActions.push(<RunNowButton isDisabled={!this.state.hasSteps}/>);
        const overFlowActions = [];
        overFlowActions.push(OverflowMenuItems.navItem("View Snapshots", routeLinks.project(project.Slug).operations.runbook(runbook.Id).runbookSnapshots, {
            permission: Permission.RunbookView,
            project: project.Id,
            projectGroup: project.ProjectGroupId,
            wildcard: true,
        }));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(project.Id, runbook.Id), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        sectionControlActions.push(<OverflowMenu menuItems={overFlowActions}/>);
        const sectionControl = <ActionList actions={sectionControlActions}/>;
        const layoutTitle = <FormTitleForRunbook runbookName={runbook.Name}/>;
        return (<RunbooksPaperLayout title={layoutTitle} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} sectionControl={sectionControl} sidebar={nextScheduledElement} busy={this.state.busy} errors={this.errors}>
                {this.renderDashboard()}
            </RunbooksPaperLayout>);
    }
    private renderNextScheduled() {
        const project = this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state.runbook;
        return (<NextScheduledRuns project={project} runbook={runbook!} triggersResponse={this.state.triggersResponse} triggerActionCategory={TriggerActionCategory.Runbook} renderBuildTriggerRow={(renderProps) => {
                const trigger = renderProps.trigger;
                const description = this.getTriggerDescription(trigger);
                return (<div className={styles.nextScheduledRow}>
                            <div>
                                <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTrigger(trigger.Id)}>{trigger.Name}</InternalLink>
                            </div>
                            <div>{description}</div>
                        </div>);
            }}/>);
    }
    private getTriggerDescription(trigger: TriggerResource) {
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter as TriggerScheduleResource);
        const destinationEnvironmentIds = (trigger.Action as RunRunbookActionResource).EnvironmentIds;
        const environments = Object.values(this.state.environmentsById);
        const environmentChips = environmentChipListIncludingMissing(environments, destinationEnvironmentIds);
        return (<span>
                Run {scheduleDescription} on {environmentChips}.
            </span>);
    }
    static displayName = "RunbookOverviewLayoutInternal";
}
const RunbookOverviewLayoutWithContext = withRunbookContext(withProjectContext(RunbookOverviewLayoutInternal));
export default RunbookOverviewLayoutWithContext;
