import * as React from "react";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import type { RunbookRoutingProps } from "../RunbookRoutingProps";
import RunbookRunDetails from "../RunbookRunDetails";
import RunbookRunNowLayout from "../RunbookRunNowLayout";
import RunbookSnapshotEdit from "./RunbookSnapshotEdit";
import RunbookSnapshotInfo from "./RunbookSnapshotInfo";
import RunbookSnapshots from "./RunbookSnapshots";
const runbookSnapshotInstancePages = pageIds.project().runbook.runbookSnapshot();
export const RunbookSnapshotPage = withPage({ page: runbookSnapshotInstancePages.root })(RunbookSnapshotInfo);
export const CreateRunbookRunPage = withPage({ page: runbookSnapshotInstancePages.runbookRuns.create })(RunbookRunNowLayout);
export const CreateRunbookSnapshotPage = withPage({ page: pageIds.project().runbook.runbookSnapshotCreate })(RunbookSnapshotEdit);
export const RunbookSnapshotsPage = withPage({ page: pageIds.project().runbook.runbookSnapshots })(RunbookSnapshots);
export const EditRunbookSnapshotPage = withPage({ page: runbookSnapshotInstancePages.edit })(RunbookSnapshotEdit);
export const RunbookRunDetailPage = withPage({ page: runbookSnapshotInstancePages.runbookRuns.specific })(RunbookRunDetails);
export class RunbookSnapshotsRoute extends React.Component<RunbookRoutingProps> {
    render() {
        const runbookSnapshotsLinks = routeLinks.project(":projectSlug").operations.runbook(":runbookId").runbookSnapshots;
        const runbookSnapshotInstanceLinks = routeLinks.project(":projectSlug").operations.runbook(":runbookId").runbookSnapshot(`:runbookSnapshotId`);
        return (<Switch>
                <ReloadableRoute path={routeLinks.project(":projectSlug").operations.runbook(":runbookId").runbookSnapshotCreate} component={CreateRunbookSnapshotPage}/>
                <ReloadableRoute path={runbookSnapshotInstanceLinks.root}>
                    <Switch>
                        <ReloadableRoute path={runbookSnapshotInstanceLinks.edit} component={EditRunbookSnapshotPage}/>
                        <ReloadableRoute path={runbookSnapshotInstanceLinks.runbookRuns.create(":goal?", ":previousId?", ":tenantIds?")} component={CreateRunbookRunPage}/>
                        <ReloadableRoute path={runbookSnapshotInstanceLinks.runbookRuns.specific(":runbookRunId")} component={RunbookRunDetailPage}/>
                        <ReloadableRoute exact path={runbookSnapshotInstanceLinks.root} component={RunbookSnapshotPage}/>
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={runbookSnapshotsLinks} component={RunbookSnapshotsPage}/>
            </Switch>);
    }
    static displayName = "RunbookSnapshotsRoute";
}
