/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { RefreshIndicator } from "material-ui";
import * as React from "react";
import { withTheme } from "~/components/Theme";
interface BusyRefreshIndicatorProps {
    show: boolean;
}
const BusyRefreshIndicator: React.FC<BusyRefreshIndicatorProps> = (props) => withTheme((theme) => (<React.Fragment>
            {props.show && (<RefreshIndicator size={28} /* This size matches the .title class line-height */ left={10} top={0} status="loading" loadingColor={theme.secondaryText} style={{
            display: "inline-block",
            position: "relative",
            boxShadow: "unset",
            backgroundColor: "transparent",
        }} role="progressbar"/>)}
        </React.Fragment>));
BusyRefreshIndicator.displayName = "BusyRefreshIndicator"
BusyRefreshIndicator.displayName = "BusyRefreshIndicator";
export default BusyRefreshIndicator;
