import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { BooleanRadioButtonGroup, ExpandableFormSection, Note, RadioButton, Summary } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
type KubernetesCloudTargetDiscoveryProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function KubernetesCloudTargetDiscovery({ isEnabled, onChange }: KubernetesCloudTargetDiscoveryProps) {
    return (<ExpandableFormSection key="IsKubernetesCloudTargetDiscoveryEnabled" errorKey="IsKubernetesCloudTargetDiscoveryEnabled" title="Kubernetes Cloud Target Discovery" summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")} help="Discover kubernetes cloud targets during deployment using tags on cloud resources">
            <Callout title="Early Access" type={CalloutType.Warning}>
                This feature is still in development. We'd love to hear <ExternalLink href={"CloudTargetDiscoveryFeedbackForm"}> your feedback</ExternalLink> after using the Kubernetes cloud target discovery feature.
            </Callout>
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled"/>
                <RadioButton value={false} label="Disabled" isDefault={true}/>
            </BooleanRadioButtonGroup>
            <Note style={{ marginTop: "1rem" }}>
                Octopus can discover kubernetes deployment targets during deployments using tags added to your cloud resources. Learn more about <ExternalLink href="KubernetesCloudTargetDiscovery">kubernetes cloud target discovery</ExternalLink>.
            </Note>
        </ExpandableFormSection>);
}
