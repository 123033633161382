/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TaskResource, DeploymentResource, ProjectResource, ReleaseResource, ResourcesById, EnvironmentResource, LifecycleProgressionResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type * as Analytics from "~/analytics/Analytics";
import { AnalyticView, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { DeployButton } from "~/areas/projects/components/Releases/DeployButton/DeployButton";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { repository } from "../../../../../clientInstance";
import { NavigationButton, NavigationButtonType } from "../../../../../components/Button/NavigationButton";
import type { DataBaseComponentState } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../../routeLinks";
import Task from "../../../../tasks/components/Task/Task";
import TaskHistory from "../../../../tasks/components/Task/TaskHistory/TaskHistory";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
interface DeploymentDetailsState extends DataBaseComponentState {
    project?: ProjectResource;
    deployment?: DeploymentResource;
    release?: ReleaseResource;
    progression?: LifecycleProgressionResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}
type DeploymentDetailsProps = RouteComponentProps<ProjectRouteParams & {
    deploymentId: string;
}>;
interface DeploymentDetailsPropsInternal extends DeploymentDetailsProps, WithProjectContextInjectedProps {
    trackAction: Analytics.AnalyticTrackedActionDispatcher;
}
class DeploymentDetailsInternal extends DataBaseComponent<DeploymentDetailsPropsInternal, DeploymentDetailsState> {
    constructor(props: DeploymentDetailsPropsInternal) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            //TODO: We don't need to store project in state here as we can get it from context and it's not mutated.
            const { model: project } = this.props.projectContext.state;
            const deployment = await repository.Deployments.get(this.props.match.params.deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            this.setState({ deployment, release, project });
        });
    }
    renderAdditionalActions = (task: TaskResource<any>) => {
        const actions = [];
        if (task.IsCompleted && !task.FinishedSuccessfully) {
            const tryAgainLink = routeLinks.project(this.state.project!).release(this.state.release!).deployments.retry(this.state.deployment!.Id);
            actions.push(<PermissionCheck permission={Permission.DeploymentCreate} project={this.state.deployment!.ProjectId} environment={this.state.deployment!.EnvironmentId} tenant="*">
                    <NavigationButton label="Try again..." href={tryAgainLink} type={NavigationButtonType.Primary}/>
                </PermissionCheck>);
        }
        if (task.FinishedSuccessfully && this.state.progression && this.state.project) {
            actions.push(<DeployButton releaseVersion={this.state.release!.Version} projectSlug={this.state.project.Slug} projectId={this.state.project.Id} nextDeployments={this.state.progression.NextDeployments} environmentsById={this.state.environmentsById!} tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}/>);
        }
        return actions;
    };
    additionalRefresh = async (task: TaskResource<any>) => {
        if (task.FinishedSuccessfully && !this.state.progression) {
            const progression = repository.Releases.progression(this.state.release!);
            const environmentsById = repository.Environments.allById();
            this.setState({ progression: await progression, environmentsById: await environmentsById });
        }
    };
    render() {
        const deployment = this.state.deployment;
        if (deployment) {
            const deployments = (<div>
                    <TaskHistory doBusyTask={this.doBusyTask} header={<h4>Previous Deployments</h4>} currentDeploymentId={this.props.match.params.deploymentId} projectIds={[deployment.ProjectId]} tenantIds={[deployment.TenantId!]} environmentIds={[deployment.EnvironmentId]}/>
                </div>);
            return (<>
                    <AnalyticView name="View a Deployment" resource="Deploy Release"/>
                    <Task taskId={deployment.TaskId} delayRender={() => false} projectId={this.state.project!.Id} environmentId={deployment.EnvironmentId} tenantId={deployment.TenantId} additionalSidebar={deployments} additionalActions={this.renderAdditionalActions} additionalRefresh={this.additionalRefresh} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}/>
                </>);
        }
        return <PaperLayout busy={this.state.busy} title="Task" errors={this.errors}/>;
    }
    static displayName = "DeploymentDetailsInternal";
}
export function DeploymentDetails(props: DeploymentDetailsProps) {
    const projectContext = useProjectContext();
    const trackAction = useAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    return <DeploymentDetailsInternal {...props} projectContext={projectContext} trackAction={trackAction}/>;
}
