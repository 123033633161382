/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import TaskLayout from "~/areas/tasks/TaskLayout";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
type TasksLayoutProps = RouteComponentProps<any>;
const TasksLayout: React.FC<TasksLayoutProps> = (props) => {
    return (<TaskLayout>
            <Tasks {...props}/>
        </TaskLayout>);
};
TasksLayout.displayName = "TasksLayout"
export default TasksLayout;
