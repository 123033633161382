import { css } from "@emotion/css";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Logo from "~/components/Logo/Logo";
import InternalLink from "~/components/Navigation/InternalLink/index";
import routeLinks from "~/routeLinks";
interface TenantsDataTableTenantNameProps {
    tenantId: string;
    tenantName: string;
    tenantLogoLink: string;
}
export function TenantsDataTableNameCell({ tenantId, tenantName, tenantLogoLink }: TenantsDataTableTenantNameProps) {
    const dispatchAction = useAnalyticActionDispatch();
    function handleClick() {
        dispatchAction("View Tenant", { resource: "Tenant", action: Action.View });
    }
    return (<InternalLink to={routeLinks.tenant(tenantId).root} onClick={handleClick}>
            <div className={style}>
                <div className={logoStyle}>
                    <Logo url={tenantLogoLink}/>
                </div>
                <div>{tenantName}</div>
            </div>
        </InternalLink>);
}
const centeredFlex = css({
    display: "flex",
    alignItems: "center",
});
const logoScale = css({
    scale: "0.7",
});
const style = css(centeredFlex);
const logoStyle = css(logoScale);
