/* eslint-disable no-eq-null */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { TenantMissingVariableResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import type { Errors } from "../../../components/DataBaseComponent";
import DataLoader from "../../../components/DataLoader";
import ErrorPanel from "../../../components/ErrorPanel";
import NavigationSidebarLayout, { Navigation } from "../../../components/NavigationSidebarLayout";
import routeLinks from "../../../routeLinks";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { tenantsActions } from "../tenantsArea";
import type { TenantState } from "../tenantsArea";
interface RouteProps {
    tenantId: string;
}
interface TenantLayoutInternalProps {
    tenant: TenantState;
}
const TenantLoader = DataLoader<{}>();
const TenantLayoutLoader: React.FC<RouteComponentProps<RouteProps>> = (props) => {
    const tenantState = useSelector<GlobalState, TenantState | null>((s) => s.tenantsArea.currentTenant);
    const dispatch = useDispatch();
    return (<TenantLoader load={async () => {
            if (tenantState?.id !== props.match.params.tenantId) {
                const tenant = await repository.Tenants.get(props.match.params.tenantId);
                onTenantFetched(tenant);
                const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
                onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            }
            return {};
        }} operationName="TenantLayout" renderWhenLoaded={(_) => {
            if (tenantState == null || tenantState.id !== props.match.params.tenantId) {
                throw new Error("Tenant loaded and stored in redux does not exist, or does not match the current route");
            }
            return <TenantLayoutInternal tenant={tenantState}>{props.children}</TenantLayoutInternal>;
        }} renderAlternate={({ busy, errors }) => {
            return <Layout busy={busy} errors={errors} renderContent={null}/>;
        }}/>);
    function onTenantFetched(tenant: TenantResource) {
        dispatch(tenantsActions.tenantFetched(tenant));
    }
    function onTenantVariablesFetched(tenantMissingVariables?: TenantMissingVariableResource) {
        dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
    }
};
TenantLayoutLoader.displayName = "TenantLayoutLoader"
interface LayoutProps {
    errors: Errors | undefined;
    busy: boolean;
    renderContent: React.ReactElement | null;
}
const Layout: React.FC<LayoutProps> = (props) => {
    return (<main id="maincontent">
            {renderAreaTitle()}
            {renderErrors()}
            {props.renderContent}
        </main>);
    function renderErrors() {
        const errors = props.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>;
    }
    function renderAreaTitle() {
        return <AreaTitle link={routeLinks.tenants} title="Tenants" busyIndicator={renderBusy()}/>;
    }
    function renderBusy() {
        return <LinearProgress show={props.busy}/>;
    }
};
Layout.displayName = "Layout"
const TenantLayoutInternal: React.FC<TenantLayoutInternalProps> = (props) => {
    const variableLink = props.tenant.hasMissingVariables ? (<span>
            Variables <MissingVariablesIcon show={true}/>
        </span>) : ("Variables");
    const tenantLinks = routeLinks.tenant(props.tenant.id);
    const sidebarLinks = compact([
        Navigation.navItem("Overview", tenantLinks.overview),
        Navigation.navItem(variableLink, tenantLinks.variables().pathname),
        Navigation.navItem("Tasks", tenantLinks.tasks, undefined, {
            permission: Permission.TaskView,
            tenant: props.tenant.id,
        }),
        Navigation.navItem("Settings", tenantLinks.settings),
    ]);
    return (<Layout busy={false} errors={undefined} renderContent={<NavigationSidebarLayout logoUrl={props.tenant.logoUrl} name={props.tenant.name} navLinks={sidebarLinks} content={props.children} description={props.tenant.description ?? undefined}/>}/>);
};
TenantLayoutInternal.displayName = "TenantLayoutInternal"
const TenantLayout = withRouter(TenantLayoutLoader);
export default TenantLayout;
