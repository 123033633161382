/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import type { History } from "history";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
type LocationDescriptor = History.LocationDescriptor;
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import BusyRefreshIndicator from "~/components/BusyRefreshIndicator";
import { toggleDrawer, isDrawerOpen } from "~/components/ContextualHelpLayout/reducers";
import InternalLink from "~/components/Navigation/InternalLink";
import type { OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import Breadcrumb from "~/primitiveComponents/navigation/Breadcrumb";
import styles from "./style.module.less";
interface SectionTitleProps {
    title: React.ReactNode;
    titlePath?: LocationDescriptor;
    className?: string;
    sectionControl?: React.ReactNode;
    titleLogo?: JSX.Element;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbOverflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    breadcrumbChip?: JSX.Element; // If specified, this will put the chip next to the  breadcrumb title.
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    hideHelpIcon?: boolean;
}
interface SectionTitlePropsInternal extends SectionTitleProps {
    isDrawerOpen: boolean;
    isHelpSidebarEnabled: boolean;
    toggleDrawer: () => void;
}
class SectionTitleInternal extends React.Component<SectionTitlePropsInternal, never> {
    render() {
        const props = this.props;
        return (<div className={cn(styles.container, props.className)}>
                <div className={styles.titleContainer}>
                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb title={props.breadcrumbTitle} path={props.breadcrumbPath} overflow={props.breadcrumbOverflow}/>
                        {props.breadcrumbChip}
                    </div>
                    <div className={styles.title}>
                        {props.titleLogo && <div className={styles.logo}>{props.titleLogo}</div>}
                        <h2 className={styles.header}>
                            {props.titlePath ? (<InternalLink to={props.titlePath} className={styles.pageTitleLink}>
                                    {props.title}
                                </InternalLink>) : (<span>{props.title}</span>)}
                        </h2>
                        {/* We show a less intrusive refresh indicator for screens that have auto-refresh implemented (because the main horizontal loading bar is annoying). */}
                        {props.enableLessIntrusiveLoadingIndicator && (<div className={styles.refreshIndicator}>
                                <BusyFromPromise promise={props.busy}>
                                    {(busy: boolean) => {
                    return busy && props.enableLessIntrusiveLoadingIndicator ? (<div className={styles.refreshContainer}>
                                                <BusyRefreshIndicator show={true}/>
                                            </div>) : (null!);
                }}
                                </BusyFromPromise>
                            </div>)}
                    </div>
                </div>
                {props.sectionControl}
            </div>);
    }
    static displayName = "SectionTitleInternal";
}
const SectionTitle = (props: SectionTitleProps) => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(isDrawerOpen);
    const helpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((t) => t.isHelpSidebarEnabled));
    const dispatchToggleDrawer = React.useCallback(() => dispatch(toggleDrawer()), [dispatch]);
    return <SectionTitleInternal isDrawerOpen={drawerOpen} isHelpSidebarEnabled={helpSidebarEnabled} toggleDrawer={dispatchToggleDrawer} {...props}/>;
};
export default SectionTitle;
