import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import ActionTemplateLayout from "../ActionTemplates/ActionTemplateLayout";
import ActionTemplateUsage from "../ActionTemplates/ActionTemplateUsage";
import BuiltinTemplates from "../ActionTemplates/BuiltinTemplates";
import CommunityTemplate from "../ActionTemplates/CommunityTemplate";
import CommunityTemplates from "../ActionTemplates/CommunityTemplates";
import ActionTemplate from "./ActionTemplate/ActionTemplate";
import ActionTemplates from "./ActionTemplates";
import RunActionTemplate from "./RunActionTemplate";
const actionTemplate = renderWithLayout(ActionTemplateLayout)(ActionTemplate);
const actionTemplateUsage = renderWithLayout(ActionTemplateLayout)(ActionTemplateUsage);
const libraryLinks = routeLinks.library;
const libraryPages = pageIds.library;
export const BuiltinTemplatesPage = withPage({ page: libraryPages.stepTemplates.builtIn })(BuiltinTemplates);
export const CommunityTemplatePage = withPage({ page: libraryPages.stepTemplates.communityTemplate.root })(CommunityTemplate);
export const CommunityTemplatesPage = withPage({ page: libraryPages.stepTemplates.community })(CommunityTemplates);
export const ActionTemplatePage = withPage({ page: libraryPages.stepTemplate.root })(actionTemplate);
export const ActionTemplateCreatePage = withPage({ page: libraryPages.stepTemplates.new })(actionTemplate);
export const RunActionTemplatePage = withPage({ page: libraryPages.stepTemplate.run })(RunActionTemplate);
export const ActionTemplateUsagePage = withPage({ page: libraryPages.stepTemplate.usage })(actionTemplateUsage);
export const ActionTemplatesPage = withPage({ page: libraryPages.stepTemplates.root })(ActionTemplates);
export class ActionTemplateRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <ReloadableRoute path={libraryLinks.stepTemplates.builtIn} component={BuiltinTemplatesPage}/>
                <ReloadableRoute path={libraryLinks.stepTemplates.communityTemplate(":templateId").details} render={(props: RouteComponentProps<{
            templateId: string;
        }>) => <InternalRedirect to={libraryLinks.stepTemplates.communityTemplate(props.match.params.templateId).root}/>}/>
                <ReloadableRoute path={libraryLinks.stepTemplates.communityTemplate(":templateId").root} component={CommunityTemplatePage}/>
                <ReloadableRoute path={libraryLinks.stepTemplates.community} component={CommunityTemplatesPage}/>
                <ReloadableRoute path={libraryLinks.stepTemplates.new(":actionType")} component={ActionTemplateCreatePage}/>
                <ReloadableRoute path={libraryLinks.stepTemplate(":templateId").run} component={RunActionTemplatePage}/>
                <ReloadableRoute path={libraryLinks.stepTemplate(":templateId").usage} component={ActionTemplateUsagePage}/>
                <ReloadableRoute path={libraryLinks.stepTemplate(":templateId").root} component={ActionTemplatePage}/>
                <ReloadableRoute component={ActionTemplatesPage}/>
            </Switch>);
    }
    static displayName = "ActionTemplateRoutes";
}
export default ActionTemplateRoutes;
