import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { Note } from "~/components/form";
import { OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer, LearnMoreResources, VideoResources } from "./CommonViews";
const HelpImageTeams = require("../../Images/Teams.svg");
export const CommonConfigurationTeamOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Users can be organized into <ExternalLink href="ManagingUsersAndTeams">teams</ExternalLink> and given various permissions by including user roles. Teams can be restricted to the system or a space and scoped to particular projects,
                    environments or tenants.
                </p>
                <p>
                    <ExternalVideoLink href="ManagingUsersAndTeamsVideo"/>
                </p>
                <ImageWithPlaceholder src={HelpImageTeams} alt={"Teams"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Types of permissions"}>
                    <li>
                        <b>System level permissions</b> are available to teams accessible in <i>all spaces</i> and involve administering the entire system, but do not include permissions within an individual space. An example of system level
                        permissions are the `User` permissions, since users are not scoped to a space.
                    </li>
                </Note>
                <Note>
                    <li>
                        <b>Space level permissions</b> are available for teams accessible in <i>all spaces and an individual space</i>, and apply to resources within spaces, for example, projects and environments.
                    </li>
                </Note>
            </TermsText>
        </OverviewContainer>);
};
CommonConfigurationTeamOverview.displayName = "CommonConfigurationTeamOverview"
export const CommonConfigurationTeamTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <VideoResources>
                <div>
                    <ExternalLink href="AskOctopusEpSix">Ask Octopus - We discuss all things Spaces!</ExternalLink>
                </div>
            </VideoResources>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ManagingUsersAndTeams">Users and Teams</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonConfigurationTeamTopics.displayName = "CommonConfigurationTeamTopics"
