import { ExternalFeedsFilterTypes, Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, ExternalFeedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PagingDataTable } from "~/components/PagingDataTable/PagingDataTable";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import ExternalLink from "../../../../components/Navigation/ExternalLink/index";
import styles from "./style.module.less";
interface FeedListProps {
    feeds: ResourceCollection<ExternalFeedResource>;
    onDelete(feed: ExternalFeedResource): Promise<boolean>;
}
class PagingFeedsTable extends PagingDataTable<ExternalFeedResource> {
}
class ExternalFeedList extends React.Component<FeedListProps> {
    private additionalRequestParams = new Map<string, string[] | string>();
    constructor(props: FeedListProps) {
        super(props);
        this.additionalRequestParams.set("feedType", ExternalFeedsFilterTypes.defaultFilterTypes);
    }
    render() {
        return (<PagingFeedsTable initialData={this.props.feeds} headerColumns={["Name", "URL", ""]} headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn]} rowColumnClassName={styles.rowColumn} filterSearchEnabled={true} additionalRequestParams={this.additionalRequestParams} autoFocusOnFilterSearch={false} apiSearchParams={["partialName"]} filterHintText="Filter by name..." onRowRedirectUrl={(feed: ExternalFeedResource) => routeLinks.library.feed(feed.Id).root} onRow={this.buildFeedRow}/>);
    }
    private buildFeedRow = (feed: ExternalFeedResource) => {
        return [
            <div className={styles.feedName}>{feed.Name}</div>,
            <ExternalLink href={feed.FeedUri} openInSelf={false}>
                {feed.FeedUri}
            </ExternalLink>,
            <PermissionCheck permission={Permission.FeedEdit} wildcard={true}>
                <OverflowMenu menuItems={[
                    // normally, we wouldn't have an Edit/Delete menu item here, but we've had
                    // customers confused how to edit an item (due to the prominence of the feed url)
                    OverflowMenuItems.navItem("Edit", routeLinks.library.feed(feed.Id).root),
                    OverflowMenuItems.deleteItemDefault("feed", () => this.deleteFeed(feed), { permission: [Permission.AdministerSystem, Permission.FeedEdit] }),
                ]}/>
            </PermissionCheck>,
        ];
    };
    private deleteFeed = async (feed: ExternalFeedResource): Promise<boolean> => {
        await repository.Feeds.del(feed);
        return this.props.onDelete(feed);
    };
    static displayName = "ExternalFeedList";
}
export default ExternalFeedList;
