import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
export const ProjectDeploymentsSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().deploymentSettings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>The settings on this page control how releases are created and deployed.</p>
                    <p>
                        If your settings are in version control, view <ExternalLink href="ConfigAsCodeEditing">Editing a project with version control enabled</ExternalLink>.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <div>
                        <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="ConfigAsCodeEditing">Editing version-controlled projects</ExternalLink>
                    </div>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
};
