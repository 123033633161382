import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonConfigurationSubscriptionOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Subscriptions">Subscriptions</ExternalLink> allow you to subscribe to events that are happening within Octopus, so you can be notified when events have occurred and react accordingly.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonConfigurationSubscriptionOverview.displayName = "CommonConfigurationSubscriptionOverview"
export const CommonConfigurationSubscriptionTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="Subscriptions">Subscriptions</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonConfigurationSubscriptionTopics.displayName = "CommonConfigurationSubscriptionTopics"
