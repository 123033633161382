import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { StringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { ExpandableFormSection, Summary } from "../../form";
const StringProperties = {
    "Octopus.Action.PowerShell.Edition": "",
};
type SelectPowerShellEditionProperties = {
    [P in keyof typeof StringProperties]: string;
};
class SelectPowerShellEditionEdit extends React.Component<ActionEditProps<SelectPowerShellEditionProperties>> {
    shouldComponentUpdate(nextProps: ActionEditProps<SelectPowerShellEditionProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }
    summary() {
        const edition = this.props.properties["Octopus.Action.PowerShell.Edition"] || "";
        const summary = [];
        edition === "" ? summary.push(<span>Please select the edition of PowerShell</span>) : summary.push(<span>{edition === "Core" ? "PowerShell Core" : "Windows PowerShell (Desktop)"} will be used during Windows deployments</span>);
        return Summary.summary(React.Children.toArray(summary));
    }
    render() {
        const psEdition = this.props.properties["Octopus.Action.PowerShell.Edition"] || "Desktop";
        return (<ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.PowerShell.Edition" title="PowerShell Edition" summary={this.summary()} help="Please select the PowerShell edition used during Windows deployments">
                <StringRadioButtonGroup value={psEdition} onChange={(e) => this.props.setProperties({ ["Octopus.Action.PowerShell.Edition"]: e })}>
                    <RadioButton value="Desktop" label="Windows PowerShell (Desktop)" key="Desktop"/>
                    <RadioButton value="Core" label="PowerShell Core" key="Core"/>
                </StringRadioButtonGroup>
                <Note>
                    <p>For non-Windows deployments PowerShell Core will be used</p>
                </Note>
            </ExpandableFormSection>);
    }
    static displayName = "SelectPowerShellEditionEdit";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.SelectPowerShellEditionForWindows",
    title: "PowerShell Edition",
    description: "Specify the edition of PowerShell used during deployments",
    edit: SelectPowerShellEditionEdit,
    priority: 23,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.PowerShell.Edition"] = "Desktop";
    },
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.PowerShell.Edition"];
    },
});
