/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GetNamedPackageReferences, isContainerImageRegistry, PackageAcquisitionLocation, DOCKER_IMAGE_REFERENCE_PURPOSE, PackageSelectionMode, GetPackageVariableNames } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { ProcessFeedLookup } from "../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import ListTitle from "../../primitiveComponents/dataDisplay/ListTitle";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import ActionButton from "../Button";
import DialogOpener from "../Dialog/DialogOpener";
import ExternalLink from "../Navigation/ExternalLink";
import { RemoveItemsList } from "../RemoveItemsList/RemoveItemsList";
import { Note, Summary } from "../form";
import ExpandableFormSection from "../form/Sections/ExpandableFormSection";
import { default as FormSectionHeading } from "../form/Sections/FormSectionHeading";
import type { ActionWithFeeds } from "./commonActionHelpers";
import type { ActionEditProps } from "./pluginRegistry";
import { ScriptPackageReferenceDialog } from "./script/ScriptPackageReferenceDialog";
import type { ScriptPackageProperties, ScriptPackageReference } from "./script/ScriptPackageReferenceDialog";
interface AdditionalDockerImageReferenceActionEditState {
    editDockerImageReference?: ScriptPackageReference;
    editDockerImageReferenceIndex?: number;
}
class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {
}
type Props = ActionEditProps<{}, ScriptPackageProperties> & ActionWithFeeds;
export default class DockerReferenceList extends BaseComponent<Props, AdditionalDockerImageReferenceActionEditState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        const dialog = this.openDialog();
        const packageReferences = this.getDockerImageReferences();
        return (<div>
                {dialog}
                <FormSectionHeading title="Docker image references"/>
                <ExpandableFormSection title={"Referenced Images"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.DockerImageReferences" summary={this.packageReferenceSummary()} help={"Add docker images to be referenced by your templates at execution-time"}>
                    <Note>
                        Docker images referenced by this step have their version (tag) selected at release creation. Properties relating to the images are then accessible via special package variables. Learn more about{" "}
                        <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={this.addDockerPackageReference()}/>]} data={packageReferences} onRow={(p: ScriptPackageReference) => this.packageReferenceListItem(p)} onRowTouch={(pkg: ScriptPackageReference) => this.editDockerImageReference(pkg)} onRemoveRow={(pkg: ScriptPackageReference) => this.removePackageReference(pkg)}/>
                </ExpandableFormSection>
            </div>);
    }
    private addDockerPackageReference() {
        return () => {
            const additionalPackage: ScriptPackageReference = {
                Id: null!,
                Name: null!,
                FeedId: null!,
                PackageId: null!,
                // The packages are docker images to be acquired by the cluster, not the target
                AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
                Properties: {
                    Extract: "false",
                    Purpose: DOCKER_IMAGE_REFERENCE_PURPOSE,
                },
            };
            this.setState({ editDockerImageReference: additionalPackage, editDockerImageReferenceIndex: null! });
        };
    }
    private editDockerImageReference(pkg: ScriptPackageReference) {
        this.setState({
            editDockerImageReference: _.clone(pkg),
            editDockerImageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    }
    private removePackageReference(pkg: ScriptPackageReference) {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    }
    private packageReferenceListItem(pkg: ScriptPackageReference) {
        return (<ProcessFeedLookup feedId={pkg.FeedId}>
                {(feed) => (<div>
                        <ListTitle>{pkg.Name}</ListTitle>
                        <div>
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<React.Fragment>
                                    <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                                </React.Fragment>)}
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                        </div>
                    </div>)}
            </ProcessFeedLookup>);
    }
    private getDockerImageReferences() {
        return GetNamedPackageReferences(this.props.packages).filter((p) => p.Properties.Purpose === DOCKER_IMAGE_REFERENCE_PURPOSE);
    }
    private packageReferenceSummary() {
        const namedPackageReferences = this.getDockerImageReferences();
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    }
    private resetSelectedPackageReference() {
        this.setState({
            editDockerImageReference: null!,
            editDockerImageReferenceIndex: null!,
        });
    }
    private async loadFeeds() {
        return await this.props.refreshFeeds();
    }
    private savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (!this.state.editDockerImageReferenceIndex) {
            packageReferences.push(packageReference);
        }
        else {
            packageReferences[this.state.editDockerImageReferenceIndex] = packageReference;
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }
    private packageVariableNames(): string[] {
        return _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => GetPackageVariableNames(pkg, this.props.feeds)));
    }
    private openDialog() {
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        console.table(localNames);
        return (<DialogOpener open={!!this.state.editDockerImageReference} onClose={() => this.resetSelectedPackageReference()}>
                <ScriptPackageReferenceDialog packageReference={this.state.editDockerImageReference!} runOn={this.props.runOn} 
        // The docker images will be acquired by the k8s cluster, not by Octopus or targets
        feeds={this.props.feeds
                .filter((f) => isContainerImageRegistry(f.FeedType))
                .map((f) => {
                f.PackageAcquisitionLocationOptions = [PackageAcquisitionLocation.NotAcquired];
                return f;
            })} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={() => this.loadFeeds()} parameters={this.props.parameters}/>
            </DialogOpener>);
    }
    static displayName = "DockerReferenceList";
}
