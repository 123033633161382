/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type { History } from "history";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
type LocationDescriptor = History.LocationDescriptor;
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import { toggleDrawer, isDrawerOpen } from "~/components/ContextualHelpLayout/reducers";
import Breadcrumb from "~/primitiveComponents/navigation/Breadcrumb";
import PageTitleHelper from "~/utils/PageTitleHelper";
import type { ComponentProps } from "~/utils/types";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";
interface AreaTitleProps {
    title?: string;
    link?: string;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    busyIndicator?: JSX.Element;
}
interface AreaTitlePropsInternal extends AreaTitleProps {
    isDrawerOpen: boolean;
    isHelpSidebarEnabled: boolean;
    toggleDrawer: () => void;
}
class AreaTitleInternal extends React.Component<AreaTitlePropsInternal, never> {
    constructor(props: AreaTitlePropsInternal) {
        super(props);
        PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
    }
    render() {
        return (<div className={styles.titleBar}>
                <div className={styles.rowSpaced}>
                    <AnalyticLinkLocationProvider location="Area Title">
                        <div className={styles.title}>
                            <Breadcrumb title={this.props.breadcrumbTitle} path={this.props.breadcrumbPath} isAreaLevelBreadcrumb={true}/>
                            <h1>
                                <InternalNavLink className={styles.pageTitleLink} to={this.props.link!}>
                                    {this.props.title}
                                </InternalNavLink>
                            </h1>
                        </div>
                        <div className={styles.actions}>{this.props.children}</div>
                    </AnalyticLinkLocationProvider>
                </div>
                {this.props.busyIndicator}
            </div>);
    }
    static displayName = "AreaTitleInternal";
}
const AreaTitle = (props: AreaTitleProps) => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(isDrawerOpen);
    const helpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((t) => t.isHelpSidebarEnabled));
    const dispatchToggleDrawer = React.useCallback(() => dispatch(toggleDrawer()), [dispatch]);
    return <AreaTitleInternal isDrawerOpen={drawerOpen} isHelpSidebarEnabled={helpSidebarEnabled} toggleDrawer={dispatchToggleDrawer} {...props}/>;
};
export default AreaTitle as React.ComponentType<ComponentProps<typeof AreaTitle>>;
