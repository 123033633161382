import React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
export const CommonInsightsDeploymentFailureRateOverview: React.FC = (props) => (<OverviewContainer>
        <OverviewHighlightText>
            <p>
                <b>Deployment failure rate</b> is the percentage of changes which cause a failure in a given environment.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                Failure rate is calculated by taking the total number of deployments, and dividing them by the number of failures as a percentage.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>);
CommonInsightsDeploymentFailureRateOverview.displayName = "CommonInsightsDeploymentFailureRateOverview"
export const CommonInsightsDeploymentFailureRateTopics: React.FC = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="InsightsDeploymentFailureRate">Deployment failure rate</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonInsightsDeploymentFailureRateTopics.displayName = "CommonInsightsDeploymentFailureRateTopics"
