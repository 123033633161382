import type { ProjectSummaryResource, SensitiveValue, SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import ActionButton, { ActionButtonType } from "~/components/Button";
import { projectChipList } from "~/components/Chips";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import PaperLayout from "~/components/PaperLayout";
import { FormSection, Note, Sensitive, Summary } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import routeLinks from "~/routeLinks";
import ImportExportMenu from "./ImportExportMenu";
import MissingImportExportPermissionsCallout from "./MissingImportExportPermissionCallout";
import styles from "./style.module.less";
interface ExportProjectsState extends DataBaseComponentState {
    projects: ProjectSummaryResource[];
    space?: SpaceResource;
    includedProjectIds: string[];
    password: SensitiveValue;
    redirectPath?: string;
}
class ExportProjectsInternal extends DataBaseComponent<{}, ExportProjectsState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            projects: [],
            includedProjectIds: [],
            password: { HasValue: false },
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [projects, space] = await Promise.all([repository.Projects.summaries(), repository.Spaces.get(repository.spaceId)]);
            this.setState({
                projects,
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    doExport = async () => {
        await this.doBusyTask(async () => {
            const projectExportResource = await repository.ImportExport.export({ IncludedProjectIds: this.state.includedProjectIds, Password: this.state.password });
            this.setState({
                redirectPath: routeLinks.task(projectExportResource.TaskId).root,
            });
        });
    };
    projectsChanged = (includedProjectIds: string[]) => {
        if (!this.state.projects)
            return;
        this.setState({ includedProjectIds });
    };
    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true}/>;
        }
        const isSpaceManager = this.isSpaceManager();
        const overflowMenu = <ImportExportMenu />;
        return (<main id="maincontent">
                <AreaTitle title="Projects" link={routeLinks.projects.root}>
                    <ActionList actions={[overflowMenu]}/>
                </AreaTitle>
                <ContextualHelpLayout>
                    <div className={styles.paperContainer}>
                        <PaperLayout title="Export Projects" busy={this.state.busy} errors={this.errors} sectionControl={<ActionButton type={ActionButtonType.Primary} label="Export" onClick={() => this.doExport()} disabled={this.state.busy || !this.state.password || !this.state.password.NewValue || this.state.password.NewValue === "" || this.state.includedProjectIds.length === 0}/>}>
                            {this.state.space && !this.isSpaceManager() && <MissingImportExportPermissionsCallout />}
                            {this.state.space && isSpaceManager && (<>
                                    <FormSection title="Projects" help="Which projects would you like to include in the exported file." includeBorder={false}>
                                        <ProjectMultiSelect onChange={(includedProjectIds) => this.projectsChanged(includedProjectIds)} value={this.state.includedProjectIds} items={this.state.projects} accessibleName="Projects"/>
                                    </FormSection>
                                    <FormSection title="Password" help="Create a password to protect sensitive values in the exported data." includeBorder={true}>
                                        <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password })} disabled={this.state.includedProjectIds.length === 0 ? true : false}/>
                                        <Note>Keep this password safe. You will need this password when importing this export file.</Note>
                                    </FormSection>
                                </>)}
                        </PaperLayout>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    projectsSummary(): SummaryNode {
        return this.state.includedProjectIds && this.state.includedProjectIds.length ? Summary.summary(<div>Export project(s) {projectChipList(this.state.projects, this.state.includedProjectIds)}</div>) : Summary.default("No projects selected");
    }
    passwordSummary(): SummaryNode {
        return this.state.password && this.state.password.NewValue && this.state.password.NewValue !== "" ? Summary.summary(<div>Password has been provided</div>) : Summary.default("No password has been set");
    }
    static displayName = "ExportProjectsInternal";
}
const ExportProjectsPage: React.FC = () => {
    return <ExportProjectsInternal />;
};
ExportProjectsPage.displayName = "ExportProjectsPage"
export default ExportProjectsPage;
