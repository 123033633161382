/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isUrlActive, resolvePathWithSpaceId, nameForLink, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import type { LinkEvent } from "~/analytics/Analytics";
import { exposeComponentAsClass } from "../exposeComponentAsClass";
import { toPath } from "../toPath";
interface InternalNavLinkComponentProps extends React.HTMLProps<HTMLElement> {
    className?: string;
    activeClassName?: string;
    to: string;
    exact?: boolean;
}
type InternalNavLinkProps = InternalNavLinkComponentProps & RouteComponentProps<{
    spaceId: string;
}>;
const InternalNavLink: React.SFC<InternalNavLinkProps> = (props: InternalNavLinkProps) => {
    const resolvedTo = resolvePathWithSpaceId(props.to, props.match.params.spaceId);
    const dispatchLink = useDispatchLinkClicked();
    /* eslint-disable react/forbid-elements */
    return (<NavLink className={props.className} key={props.to} to={resolvedTo} exact={props.exact} isActive={() => isUrlActive(props.location.pathname, props.match.params.spaceId, toPath(resolvedTo)!, props.exact!)} activeClassName={props.activeClassName} onClick={(e) => {
            const ev: LinkEvent = {
                linkLabel: e.currentTarget.innerText ?? "",
                documentUrl: undefined,
            };
            dispatchLink(nameForLink(), ev);
        }}>
            {props.children}
        </NavLink>);
};
InternalNavLink.displayName = "InternalNavLink"
export default exposeComponentAsClass(withRouter(InternalNavLink));
