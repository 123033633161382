import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import type { LocationDescriptor } from "history";
import * as React from "react";
import Card from "~/components/Card";
import Logo from "~/components/Logo/Logo";
import MarkdownDescription from "~/components/MarkdownDescription";
import InternalLink from "~/components/Navigation/InternalLink";
import { withTheme } from "~/components/Theme";
import styles from "./styles.module.less";
interface ActionTemplateCardProps {
    template: ActionTemplateSearchResource;
    primaryAction: React.ReactNode;
    primaryRedirect?: LocationDescriptor;
    secondaryAction?: React.ReactNode;
}
const ActionTemplateCard: React.StatelessComponent<ActionTemplateCardProps> = (props: ActionTemplateCardProps) => withTheme((theme) => {
    function formatText(text: string, maxLength: number) {
        text = (text || "").trim().replace(/\r?\n|\r/g, " ");
        return text.length > maxLength ? text.substr(0, maxLength) + " ..." : text;
    }
    function getStatusText(template: ActionTemplateSearchResource) {
        if (template.IsBuiltIn) {
            return <span style={{ color: theme.whiteConstant }}>by Octopus Deploy</span>;
        }
        if (template.IsInstalled) {
            return <span>Installed</span>;
        }
        return <span style={{ color: theme.primaryText }}>{formatText(template.Author, 24)}</span>;
    }
    function getStatusLogo(template: ActionTemplateSearchResource) {
        if (template.IsBuiltIn) {
            return <em className="fontoctopus-octopus" style={{ color: theme.whiteConstant }}/>;
        }
        if (template.IsInstalled) {
            return <em className="regular fa-check-square" aria-hidden="true" style={{ color: theme.primary }}/>;
        }
        return <em className="fa-solid fa-users" aria-hidden="true" style={{ color: theme.primaryText }}/>;
    }
    function getBackgroundColor(template: ActionTemplateSearchResource) {
        if (template.IsBuiltIn) {
            return theme.cardFooterBuiltIn;
        }
        if (template.IsInstalled) {
            return theme.cardFooterInstalled;
        }
        return theme.cardFooterCommunity;
    }
    return (<li>
                <Card logo={<Logo url={props.template.Links.Logo}/>} header={<div className={styles.name}>{formatText(props.template.Name, 46)}</div>} content={<div className={styles.description}>
                            <MarkdownDescription markup={props.template.Description}/>
                        </div>} includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */ footerIcon={getStatusLogo(props.template)} footerText={getStatusText(props.template)} footerBackgroundColor={getBackgroundColor(props.template)} onHoverView={<div className={styles.onHoverActions}>
                            <div className={styles.onHoverActionsBackdrop}/>
                            {props.primaryRedirect && (<InternalLink className={cn(styles.onHoverPrimaryActions, props.primaryRedirect && styles.onHoverPrimaryActionsCursor)} to={props.primaryRedirect}>
                                    <div className={styles.empty}/>
                                    <div className={styles.primaryAction}>{props.primaryAction}</div>
                                </InternalLink>)}
                            {!props.primaryRedirect && (<React.Fragment>
                                    <div className={styles.empty}/>
                                    <div className={styles.primaryAction}>{props.primaryAction}</div>
                                </React.Fragment>)}
                            {props.secondaryAction && <div className={styles.secondaryAction}>{props.secondaryAction}</div>}
                        </div>}/>
            </li>);
});
ActionTemplateCard.displayName = "ActionTemplateCard"
ActionTemplateCard.displayName = "ActionTemplateCard";
export default ActionTemplateCard;
