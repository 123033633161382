import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
type ContainersFeedbackCalloutProps = {
    actionTypes?: string[];
};
export function ContainersFeedbackCallout({ actionTypes = [] }: ContainersFeedbackCalloutProps) {
    const [dateDismissed, setDateDismissed] = useLocalStorage(`Octopus.Callout.ContainersFeedback.DateDismissed`, new Date("2022-01-1"));
    const [numberOfDismissals, setNumberOfDismissals] = useLocalStorage(`Octopus.Callout.ContainersFeedback.NumberOfDismissals`, 0);
    const onCloseCallout = () => {
        setDateDismissed(new Date());
        setNumberOfDismissals(numberOfDismissals + 1);
    };
    const containerActionTypes = ["Octopus.AzureAppService", "Octopus.DockerRun", "Octopus.KubernetesDeployContainers", "Octopus.HelmChartUpgrade", "Octopus.KubernetesDeployRawYaml", "aws-ecs-update-service", "aws-ecs"];
    const hasContainerStep = actionTypes.some((actionType) => containerActionTypes.includes(actionType));
    const today = new Date();
    const timeBombDate = new Date("2033-03-01");
    const dismissUntil = new Date(dateDismissed);
    dismissUntil.setDate(dismissUntil.getDate() + numberOfDismissals * 14);
    if (today < timeBombDate && today > dismissUntil && hasContainerStep) {
        return (<Callout title="Containerized Deployment Feedback" type={CalloutType.Information} canClose={true} onClose={onCloseCallout}>
                We'd love to <ExternalLink href="https://oc.to/ContainersFeedback">hear about your experience</ExternalLink> deploying containers with Octopus Deploy.
            </Callout>);
    }
    else {
        return null;
    }
}
