/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FeaturesConfigurationResource, TaskResource, DynamicExtensionsFeaturesMetadataResource, DynamicExtensionsFeaturesValuesResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep, last, sortBy } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { MapDispatchToProps, MapStateToProps } from "react-redux";
import type { Action, Dispatch } from "redux";
import { bindActionCreators } from "redux";
import type { AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { KubernetesCloudTargetDiscovery } from "~/areas/configuration/components/FeaturesLayout/KubernetesCloudTargetDiscovery";
import { NewTenantsOverview } from "~/areas/configuration/components/FeaturesLayout/NewTenantsOverview";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { FormSectionHeading } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import type { ConfigurationFeaturesState } from "../../reducers/configurationArea";
import { configurationActions } from "../../reducers/configurationArea";
import { BuiltInWorkerFeature } from "./BuiltInWorkerFeature";
import { CommunityActionTemplatesFeature } from "./CommunityActionTemplatesFeature";
import { CompositeDockerHubClientFeature } from "./CompositeDockerHubClientFeature";
import { DynamicExtensionsFeature } from "./DynamicExtensionsFeature";
import { EarlyAccessFeaturesGroup } from "./EarlyAccessFeaturesGroup";
import { ExperimentalFeaturesGroup } from "./ExperimentalFeaturesGroup";
import { ExperimentalUI } from "./ExperimentalUI";
import { GitHubStatusCheckPublishingFeature } from "./GitHubStatusCheckPublishingFeature";
import { HelpSidebarFeature } from "./HelpSidebarFeature";
import { HelpSidebarSupportLink } from "./HelpSidebarSupportLink";
import { StepTemplateUpdates } from "./StepTemplateUpdates";
interface ExposedFeaturesLayoutProps {
    fullWidth?: boolean;
    dirtyTrackingDisabled?: boolean;
}
interface GlobalConnectedProps {
    features: ConfigurationFeaturesState;
}
interface GlobalDispatchProps {
    onFeaturesFetched: (features: FeaturesConfigurationResource) => void;
}
interface FeaturesLayoutAnalyticsProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}
type FeaturesLayoutProps = GlobalConnectedProps & GlobalDispatchProps & ExposedFeaturesLayoutProps;
type FeaturesLayoutPropsInternal = FeaturesLayoutProps & FeaturesLayoutAnalyticsProps;
interface ApiResults {
    featuresConfiguration: FeaturesConfigurationResource;
    dynamicFeaturesMetadata: DynamicExtensionsFeaturesMetadataResource;
    dynamicFeaturesValues: DynamicExtensionsFeaturesValuesResource;
}
interface FeaturesState extends OptionalFormBaseComponentState<ApiResults> {
    lastSyncedCommunityStepsTask?: TaskResource<{}>;
    lastSyncedStepPackageFeedTask?: TaskResource<{}>;
    redirectToTaskId?: string;
}
class FeaturesLayout extends FormBaseComponent<FeaturesLayoutPropsInternal, FeaturesState, ApiResults> {
    constructor(props: FeaturesLayoutPropsInternal) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const featuresConfiguration = await repository.FeaturesConfiguration.get();
            const dynamicFeaturesMetadata = await repository.DynamicExtensions.getFeaturesMetadata();
            const dynamicFeaturesValues = await repository.DynamicExtensions.getFeaturesValues();
            this.props.onFeaturesFetched(featuresConfiguration);
            if (this.props.features.isCommunityActionTemplatesEnabled) {
                await this.loadLastCommunityStepsTask();
            }
            if (this.props.features.isAutomaticStepUpdatesEnabled) {
                await this.loadLastSyncedPackageFeedTask();
            }
            const configurationData: ApiResults = {
                featuresConfiguration,
                dynamicFeaturesMetadata,
                dynamicFeaturesValues,
            };
            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async componentDidUpdate(prevProps: FeaturesLayoutProps) {
        if (!prevProps.features.isCommunityActionTemplatesEnabled && this.props.features.isCommunityActionTemplatesEnabled) {
            await this.loadLastCommunityStepsTask();
        }
        if (!prevProps.features.isAutomaticStepUpdatesEnabled && this.props.features.isAutomaticStepUpdatesEnabled) {
            await this.loadLastSyncedPackageFeedTask();
        }
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true}/>;
        }
        return (<FormPaperLayout title={"Features"} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.ConfigureServer }} onSaveClick={this.handleSaveClick} saveText={"Saved"} expandAllOnMount={false} overFlowActions={[]} fullWidth={this.props.fullWidth} dirtyTrackingDisabled={this.props.dirtyTrackingDisabled}>
                {this.state.model && (<TransitionAnimation>
                        <EarlyAccessFeaturesGroup>
                            <KubernetesCloudTargetDiscovery isEnabled={this.state.model!.featuresConfiguration.IsKubernetesCloudTargetDiscoveryEnabled} onChange={(IsKubernetesCloudTargetDiscoveryEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsKubernetesCloudTargetDiscoveryEnabled } })}/>
                            <NewTenantsOverview isEnabled={this.state.model!.featuresConfiguration.IsNewTenantsOverviewEnabled} onChange={(IsNewTenantsOverviewEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsNewTenantsOverviewEnabled } })}/>
                        </EarlyAccessFeaturesGroup>

                        <ExperimentalFeaturesGroup>
                            <ExperimentalUI isEnabled={this.state.model!.featuresConfiguration.IsExperimentalUIFeatureEnabled} onChange={(IsExperimentalUIFeatureEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsExperimentalUIFeatureEnabled } })}/>
                            <GitHubStatusCheckPublishingFeature isEnabled={this.state.model!.featuresConfiguration.IsGitHubStatusCheckPublishingEnabled} onChange={(IsGitHubStatusCheckPublishingEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsGitHubStatusCheckPublishingEnabled } })}/>
                        </ExperimentalFeaturesGroup>

                        <FormSectionHeading title="Feeds"/>
                        <CompositeDockerHubClientFeature isEnabled={this.state.model!.featuresConfiguration.IsCompositeDockerHubRegistryFeedEnabled} onChange={(IsCompositeDockerHubRegistryFeedEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsCompositeDockerHubRegistryFeedEnabled } })}/>

                        <FormSectionHeading title="Steps"/>
                        <StepTemplateUpdates isEnabled={this.state.model!.featuresConfiguration.IsAutomaticStepUpdatesEnabled} onChange={(IsAutomaticStepUpdatesEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsAutomaticStepUpdatesEnabled } })} isSyncEnabled={this.state.cleanModel?.featuresConfiguration?.IsAutomaticStepUpdatesEnabled === true} onSyncClick={() => this.synchronizeStepsWithPackageFeed()} lastSyncedTask={this.state.lastSyncedStepPackageFeedTask} busy={this.state.busy}/>
                        <CommunityActionTemplatesFeature isEnabled={this.state.model!.featuresConfiguration.IsCommunityActionTemplatesEnabled} lastSyncedTask={this.state.lastSyncedCommunityStepsTask} isSyncEnabled={this.state.cleanModel?.featuresConfiguration?.IsCommunityActionTemplatesEnabled === true} busy={this.state.busy} onSyncClick={() => this.synchronizeLibrarySteps()} onChange={(IsCommunityActionTemplatesEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsCommunityActionTemplatesEnabled } })}/>
                        <BuiltInWorkerFeature isEnabled={this.state.model!.featuresConfiguration.IsBuiltInWorkerEnabled} onChange={(newValue: boolean) => {
                    this.setModelState({
                        featuresConfiguration: {
                            ...this.state.model!.featuresConfiguration,
                            IsBuiltInWorkerEnabled: newValue,
                        },
                    });
                }}/>

                        <FormSectionHeading title="Help Sidebar"/>
                        <HelpSidebarSupportLink link={this.state.model!.featuresConfiguration.HelpSidebarSupportLink!} onChange={(x) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, HelpSidebarSupportLink: x } })} error={this.getFieldError("HelpSidebarSupportLink")}/>
                        <HelpSidebarFeature isEnabled={this.state.model!.featuresConfiguration.IsHelpSidebarEnabled} onChange={(IsHelpSidebarEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsHelpSidebarEnabled } })}/>
                        {this.state.model.dynamicFeaturesMetadata.Features.length > 0 && (<>
                                <FormSectionHeading title="Dynamic Extensions"/>
                                <DynamicExtensionsFeature dynamicFeaturesMetadata={this.state.model.dynamicFeaturesMetadata} dynamicFeaturesValues={this.state.model.dynamicFeaturesValues} onChange={this.updateDynamicFeaturesValues}/>
                            </>)}
                    </TransitionAnimation>)}
            </FormPaperLayout>);
    }
    private updateDynamicFeaturesValues(key: string, value: string) {
        this.setModelState({ dynamicFeaturesValues: { Values: { ...this.state.model!.dynamicFeaturesValues.Values, [key]: value } } });
    }
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const featuresSaveResult = await repository.FeaturesConfiguration.modify(this.state.model!.featuresConfiguration);
            const valuesSaveResult = await repository.DynamicExtensions.putFeaturesValues(this.state.model!.dynamicFeaturesValues);
            this.props.onFeaturesFetched(featuresSaveResult);
            const configurationData: ApiResults = {
                featuresConfiguration: featuresSaveResult,
                dynamicFeaturesMetadata: this.state.model!.dynamicFeaturesMetadata,
                dynamicFeaturesValues: valuesSaveResult,
            };
            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        });
    };
    private async synchronizeLibrarySteps() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeCommunityStepTemplatesTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async synchronizeStepsWithPackageFeed() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeStepsPackageFeedTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async loadLastCommunityStepsTask() {
        const tasks = await repository.Tasks.filter({ name: "SyncCommunityActionTemplates", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedCommunityStepsTask: lastSyncedTask });
        }
    }
    private async loadLastSyncedPackageFeedTask() {
        const tasks = await repository.Tasks.filter({ name: "AcquireStepPackages", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedStepPackageFeedTask: lastSyncedTask });
        }
    }
    static displayName = "FeaturesLayout";
}
export function FeaturesLayoutInternalWithAnalytics(props: FeaturesLayoutProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <FeaturesLayout {...props} dispatchAction={dispatchAction} trackAction={trackAction}/>;
}
const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, ExposedFeaturesLayoutProps, GlobalState> = (state) => {
    return {
        features: state.configurationArea.features,
    };
};
const mapGlobalActionDispatchersToProps: MapDispatchToProps<GlobalDispatchProps, {}> = (dispatch: Dispatch<Action<GlobalState>>) => bindActionCreators({ onFeaturesFetched: configurationActions.featuresFetched }, dispatch);
export default connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(FeaturesLayoutInternalWithAnalytics);
