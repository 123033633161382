import type { DeploymentStepResource } from "@octopusdeploy/octopus-server-client";
export default (deploymentStepResources: DeploymentStepResource[]) => {
    const containerActionTypes = ["Octopus.KubernetesDeployContainers", "Octopus.KubernetesDeployRawYaml", "Octopus.KubernetesDeployService", "Octopus.KubernetesDeployIngress", "Octopus.KubernetesDeployConfigMap", "Octopus.KubernetesDeploySecret"];
    return deploymentStepResources.reduce<string[]>((stepNames, step, stepIndex) => {
        for (let actionIdx = 0; actionIdx < step.Actions.length; actionIdx++) {
            const action = step.Actions[actionIdx];
            if (!action.IsDisabled && containerActionTypes.includes(action.ActionType)) {
                if (step.Actions.length > 1) {
                    stepNames.push(`Step ${stepIndex + 1}.${actionIdx + 1}: ${action.Name}`);
                }
                else {
                    stepNames.push(`Step ${stepIndex + 1}: ${step.Name}`);
                }
            }
        }
        return stepNames;
    }, []);
};
