/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Drawer, makeStyles } from "@material-ui/core";
import type { PropsWithChildren } from "react";
import React from "react";
import { useDevDrawerTabs } from "~/components/DevTools/useDevDrawerTabs";
import type { DoBusyTask } from "../DataBaseComponent";
import { DataBaseComponent } from "../DataBaseComponent";
import { DevToolbar } from "./DevToolbar";
import { useDrawerResizableHeight } from "./useDrawerResizableHeight";
interface DevDrawerProps extends BusyTaskProps {
    open: boolean;
    height?: number;
    onRequestClose: () => void;
    onToggleFullscreen: () => void;
    fullscreen: boolean;
}
interface BusyTaskProps {
    doBusyTask: DoBusyTask;
    isBusy: boolean;
}
const useDrawerStyles = makeStyles({
    paper: (props: DevDrawerProps) => ({
        height: props.open ? (props.fullscreen ? "100%" : props.height ? `${props.height}px` : "500px") : "0",
    }),
});
const useTabPanelStyles = makeStyles({
    root: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
});
const TabPanel = ({ children }: PropsWithChildren<{}>) => {
    const styles = useTabPanelStyles();
    return <div className={styles.root}>{children}</div>;
};
type DragHandleProps = {
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;
};
const useDragHandleStyles = makeStyles({
    root: {
        height: "5px",
        cursor: "ns-resize",
    },
});
const DragHandle: React.FC<DragHandleProps> = (props) => {
    const styles = useDragHandleStyles(props);
    return <div onMouseDown={props.onMouseDown} className={styles.root}/>;
};
DragHandle.displayName = "DragHandle"
export class DevDrawer extends DataBaseComponent<Omit<DevDrawerProps, keyof BusyTaskProps>> {
    state = {
        busy: undefined,
    };
    render() {
        return <DevDrawerInternal {...this.props} doBusyTask={this.doBusyTask} isBusy={!!this.state.busy}/>;
    }
    static displayName = "DevDrawer";
}
const DevDrawerInternal: React.FC<DevDrawerProps> = (props) => {
    const { height, handleMouseDown } = useDrawerResizableHeight(props.height);
    const drawerClasses = useDrawerStyles({ ...props, height });
    const tabs = useDevDrawerTabs(props.doBusyTask);
    const [selectedTabLabel, setSelectedTabLabel] = React.useState(tabs[0] ? tabs[0].label : "");
    const selectedTab = tabs.find((t) => t.label === selectedTabLabel);
    // TODO: This should be an effect
    if (!tabs.some((p) => p.label === selectedTabLabel)) {
        //prevent console warnings if the currently selected tab is not actually visible anymore
        setSelectedTabLabel(tabs[0].label);
    }
    return (<Drawer open={props.open} anchor={"bottom"} variant={"permanent"} classes={drawerClasses}>
            {props.open && (<React.Fragment>
                    <DragHandle onMouseDown={handleMouseDown}/>
                    <DevToolbar tabs={tabs} onSelectTab={setSelectedTabLabel} selectedTab={selectedTabLabel} onRequestClose={props.onRequestClose} onToggleFullscreen={props.onToggleFullscreen} isFullScreen={props.fullscreen}/>
                    <TabPanel>{selectedTab?.content}</TabPanel>
                </React.Fragment>)}
        </Drawer>);
};
DevDrawerInternal.displayName = "DevDrawerInternal"
