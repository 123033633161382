import type { SystemInfoResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { SFC } from "react";
import { ActionButton, ActionButtonType } from "~/components/Button";
import { default as DurationLabel } from "~/components/TimeLabels/DurationLabel";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowHeaderColumn";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter";
interface SystemInformationProps {
    systemInfo: SystemInfoResource;
    onCollectClicked(): void;
}
const SystemInformation: SFC<SystemInformationProps> = (props) => {
    const si = props.systemInfo;
    return (<div>
            <h4>System Information</h4>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn inSidebar={true}>OS Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{si.OSVersion}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn inSidebar={true}>CLR Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{si.ClrVersion}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn inSidebar={true}>Working Set</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {ByteSizeFormatter(si.WorkingSetBytes)}
                            <br />
                            <ActionButton label="Collect" onClick={() => props.onCollectClicked()} type={ActionButtonType.Ternary}/>
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn inSidebar={true}>Threads</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{si.ThreadCount}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn inSidebar={true}>Uptime</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <DurationLabel duration={si.Uptime}/>
                        </DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </div>);
};
SystemInformation.displayName = "SystemInformation";
export default SystemInformation;
