import React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import GitCredential from "./GitCredential";
import GitCredentialsList from "./GitCredentialsList";
const GitCredentialPage = withPage({ page: pageIds.library.gitCredential.root })(GitCredential);
const CreateGitCredentialPage = withPage({ page: pageIds.library.gitCredentials.create })(GitCredential);
const ListGitCredentialPage = withPage({ page: pageIds.library.gitCredentials.root })(GitCredentialsList);
export class GitCredentialRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (<Switch>
                <ReloadableRoute path={routeLinks.library.gitCredentials.create} render={(routeProps) => <CreateGitCredentialPage create {...routeProps}/>}/>
                <ReloadableRoute path={libraryRouteLinks.gitCredentials.credential(":gitCredentialId")} component={GitCredentialPage}/>
                <ReloadableRoute component={ListGitCredentialPage}/>
            </Switch>);
    }
    static displayName = "GitCredentialRoutes";
}
export default GitCredentialRoutes;
