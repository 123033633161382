import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "~/routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface RouteParams {
    environmentId: string;
}
const EditEnvironment: React.SFC<RouteComponentProps<{
    environmentId: string;
}>> = (props) => <InternalRedirect to={{ pathname: routeLinks.infrastructure.environment(props.match.params.environmentId), search: props.location.search }}/>;
EditEnvironment.displayName = "EditEnvironment"
const EditProxy: React.SFC<RouteComponentProps<{
    proxyId: string;
}>> = (props) => <InternalRedirect to={{ pathname: routeLinks.infrastructure.proxy(props.match.params.proxyId), search: props.location.search }}/>;
EditProxy.displayName = "EditProxy"
const EditMachinePolicy: React.SFC<RouteComponentProps<{
    machinePolicyId: string;
}>> = (props) => <InternalRedirect to={{ pathname: routeLinks.infrastructure.machinePolicy(props.match.params.machinePolicyId), search: props.location.search }}/>;
EditMachinePolicy.displayName = "EditMachinePolicy"
export class V3EnvironmentRoutes extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        const match = this.props.match;
        return (<Switch>
                <ReloadableRoute path={"/environments/proxies/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.proxies.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/environments/proxies/:proxyId"} component={EditProxy}/>
                <ReloadableRoute path={"/environments/proxies"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.proxies.root, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/environments/machinepolicies/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.machinePolicies.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/environments/machinepolicies/:machinePolicyId"} component={EditMachinePolicy}/>
                <ReloadableRoute path={"/environments/machinepolicies"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.machinePolicies.root, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/environments/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.environments.create, search: this.props.location.search }}/>
                </ReloadableRoute>
                <ReloadableRoute path={"/environments/:environmentId"} component={EditEnvironment}/>
                <ReloadableRoute>
                    <InternalRedirect to={routeLinks.infrastructure.environments.root}/>
                </ReloadableRoute>
            </Switch>);
    }
    static displayName = "V3EnvironmentRoutes";
}
export default V3EnvironmentRoutes;
