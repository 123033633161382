import classNames from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface ToolbarProps {
    showCount: number | null;
    totalCount: number;
}
function ResultsCount({ showCount, totalCount }: ToolbarProps) {
    const resultWording = totalCount === 1 ? "result" : "results";
    return showCount === null ? (<div className={styles.resultsContainer}>
            {totalCount} {resultWording}
        </div>) : (<div className={styles.resultsContainer}>
            Showing {showCount} of {totalCount}
        </div>);
}
function Separator() {
    return <div className={styles.separator}>|</div>;
}
function ResultsFilteredBy() {
    return <div className={styles.filteredByContainer}>Filtered by: None</div>;
}
function Actions() {
    return (<div className={styles.actionsContainer}>
            {/*
        // No actions for now
        <span>Action 1</span>
        <span>Action 2</span>
        <span>Action 3</span>
        */}
        </div>);
}
export default function Toolbar({ showCount, totalCount }: ToolbarProps) {
    const isFiltered = showCount !== null;
    const containerClasses = isFiltered ? classNames(styles.container, styles.filtered) : styles.container;
    return (<div className={containerClasses}>
            <ResultsCount showCount={showCount} totalCount={totalCount}/>
            {/*<Separator />*/}
            {/*<ResultsFilteredBy />*/}
            <Actions />
        </div>);
}
