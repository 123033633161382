/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { MenuTargetAriaAttributes } from "@octopusdeploy/design-system-components";
import { noOp } from "@octopusdeploy/utilities";
import cn from "classnames";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import * as React from "react";
import BusyFromPromise from "~/components/BusyFromPromise";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import styles from "./style.module.less";
export interface ActionButtonProps {
    innerRef?: any;
    label: string;
    accessibleName?: string;
    title?: string;
    hideTitle?: boolean;
    busyLabel?: string;
    disabled?: Promise<any> | boolean | null; // Currently unsupported for Ternary buttons
    type?: ActionButtonType;
    icon?: JSX.Element;
    iconPosition?: IconPosition;
    tabIndex?: number;
    className?: string;
    keyboardFocused?: boolean;
    style?: object;
    labelProps?: any;
    onFocus?(event: React.FocusEvent<HTMLElement>): void;
    onClick?(event: any): Promise<any> | void;
    menuButtonAttributes?: MenuTargetAriaAttributes;
}
interface ActionButtonState {
    invocation?: Promise<any>;
}
export enum ActionButtonType {
    Primary,
    Secondary,
    Ternary,
    Save,
    Delete,
    CreateRelease,
    Category
}
export enum IconPosition {
    Left,
    Right
}
export default class ActionButton extends React.Component<ActionButtonProps, ActionButtonState> {
    constructor(props: ActionButtonProps) {
        super(props);
        this.state = {};
    }
    onClick(event: any) {
        if (!this.props.onClick) {
            return;
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
        const promise = (async () => this.props.onClick!(event))();
        this.setState({ invocation: promise });
        return promise;
    }
    getButton(busy: boolean, disabled: boolean, theme: OctopusTheme) {
        const { iconPosition = IconPosition.Left, ...props } = this.props;
        const labelPosition = iconPosition === IconPosition.Left ? "after" : "before";
        const label = busy && props.busyLabel ? props.busyLabel : props.label;
        const visualLabel = this.props.children ? undefined : label;
        const title = props.title ? props.title : label;
        const otherPropsForButtons = {
            tabIndex: props.tabIndex,
            "aria-label": props.accessibleName,
        };
        const commonLabelStyle = {
            fontSize: "0.8125rem",
            verticalAlign: "middle",
        };
        const labelStyle = {
            color: props.disabled ? theme.disabledButtonText : theme.secondaryButtonText,
            whiteSpace: "nowrap",
            ...commonLabelStyle,
            ...props.labelProps,
        };
        const onClick = disabled ? noOp : (e: any) => this.onClick(e);
        switch (props.type) {
            case ActionButtonType.Primary:
                return (<RaisedButton ref={this.props.innerRef} type="submit" label={visualLabel} title={props.hideTitle ? undefined : title} disabled={disabled} icon={props.icon} labelPosition={labelPosition} backgroundColor={theme.primaryButtonBackground} // Primary's are the one thing that should stand out on the page.
                 labelColor={theme.primaryButtonText} labelStyle={commonLabelStyle} disabledBackgroundColor={"transparent"} disabledLabelColor={theme.disabledButtonText} onClick={onClick} onFocus={props.onFocus} keyboardFocused={this.props.keyboardFocused} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, styles.primaryButton, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </RaisedButton>);
            case ActionButtonType.Category:
                return (<FlatButton ref={this.props.innerRef} type="submit" label={visualLabel} title={props.hideTitle ? undefined : title} disabled={disabled} icon={props.icon} backgroundColor={theme.secondaryButtonBackground} hoverColor={theme.secondaryButtonBackground} labelStyle={labelStyle} labelPosition={labelPosition} onClick={onClick} onFocus={props.onFocus} keyboardFocused={this.props.keyboardFocused} style={{ border: "1px solid transparent" }} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </FlatButton>);
            case ActionButtonType.CreateRelease:
                return (<RaisedButton ref={this.props.innerRef} type="submit" keyboardFocused={this.props.keyboardFocused} label={visualLabel} title={props.hideTitle ? undefined : title} disabled={disabled} disabledBackgroundColor={theme.disabledButtonBackground} disabledLabelColor={theme.disabledButtonText} backgroundColor={theme.createReleaseBackground} labelColor={theme.createReleaseText} labelStyle={commonLabelStyle} labelPosition={labelPosition} onClick={onClick} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, styles.primaryButton, styles.createRelease, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </RaisedButton>);
            case ActionButtonType.Save:
                return (<RaisedButton ref={this.props.innerRef} type="submit" keyboardFocused={this.props.keyboardFocused} label={visualLabel} title={props.hideTitle ? undefined : title} disabled={disabled} disabledBackgroundColor={"transparent"} disabledLabelColor={theme.disabledButtonText} backgroundColor={theme.primaryButtonBackground} labelColor={theme.primaryButtonText} labelStyle={commonLabelStyle} labelPosition={labelPosition} onClick={onClick} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, styles.primaryButton, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </RaisedButton>);
            case ActionButtonType.Delete:
                return (<RaisedButton ref={this.props.innerRef} type="submit" keyboardFocused={this.props.keyboardFocused} label={visualLabel} title={props.hideTitle ? undefined : title} disabled={disabled} backgroundColor={theme.deleteButtonBackground} labelColor={theme.deleteButtonText} labelStyle={commonLabelStyle} labelPosition={labelPosition} onClick={onClick} disabledBackgroundColor={theme.transparent} disabledLabelColor={theme.disabledButtonText} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, styles.primaryButton, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </RaisedButton>);
            case ActionButtonType.Ternary:
                const ternaryLabelStyle = {
                    opacity: 1,
                    fontSize: "0.8125rem",
                };
                return (<FlatButton onClick={onClick} onFocus={props.onFocus} label={visualLabel} title={props.hideTitle ? undefined : title} icon={props.icon} labelPosition={labelPosition} disabled={disabled} backgroundColor={"transparent"} labelStyle={ternaryLabelStyle} className={cn(styles.globalActionButton, disabled || busy ? styles.ternaryDisabled : null, styles.ternaryButton, props.className)} style={{ boxShadow: "none" }} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </FlatButton>);
            // Fallthrough to Secondary
            default:
                // Div allows margin to be added
                return (<RaisedButton ref={this.props.innerRef} label={visualLabel} title={props.hideTitle ? undefined : title} labelStyle={labelStyle} labelPosition={labelPosition} icon={props.icon} backgroundColor={theme.transparent} labelColor={theme.secondaryButtonText} 
                /*                         hoverColor={props.disabled ? theme.disabledButtonText : theme.secondaryButtonBackground}
                 */ disabled={disabled} disabledBackgroundColor={theme.transparent} onClick={onClick} onFocus={props.onFocus} keyboardFocused={this.props.keyboardFocused} className={cn(styles.globalActionButton, props.disabled || busy ? styles.disabled : null, styles.secondaryButton, props.className)} {...otherPropsForButtons} {...this.props.menuButtonAttributes}>
                        {this.props.children}
                    </RaisedButton>);
        }
    }
    render() {
        return withTheme((theme) => (<BusyFromPromise promise={this.state.invocation}>{(busy: boolean) => <BusyFromPromise promise={this.props.disabled}>{(disabled: boolean) => this.getButton(busy, disabled || busy, theme)}</BusyFromPromise>}</BusyFromPromise>));
    }
    static displayName = "ActionButton";
}
export { ActionButton };
