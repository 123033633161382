/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TaskResource, EventResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository, session } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import { EventFormatter } from "~/components/EventFormatter/EventFormatter";
import { NotPermittedToViewEventsCallout } from "~/components/EventListing/EventListing";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import SimpleDataTable from "../../../../components/SimpleDataTable/SimpleDataTable";
import ToolTip from "../../../../primitiveComponents/dataDisplay/ToolTip/ToolTip";
import styles from "./history.module.less";
interface HistoryProps {
    task: TaskResource<any>;
    projectId: string | undefined;
    environmentId: string | undefined;
    tenantId: string | undefined;
}
class HistoryTable extends SimpleDataTable<EventResource> {
}
const HistoryDataLoader = DataLoader<{
    events: EventResource[];
}>();
export const HistoryForTask: React.FC<HistoryProps> = (props) => {
    const permitEvents: boolean = isAllowed({
        permission: Permission.EventView,
        project: props.projectId,
        environment: props.environmentId,
        tenant: props.tenantId,
    });
    const loadEvents = async () => {
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const events = useOptimization ? await repository.Events.getUnpaginatedEvents({ regardingAny: [props.task.Id] }) : await repository.Events.list({ regardingAny: [props.task.Id] });
        return { events: events.Items };
    };
    const displayHistoryTable = (data: EventResource[]) => {
        return data.length > 0 ? (<HistoryTable rowColumnClassName={styles.row} data={data} headerColumns={["When", "Who", "What"]} headerColumnClassNames={[styles.row, styles.row, styles.row]} onRow={(event) => [
                <ToolTip content={DateFormatter.dateToShortFormat(event.Occurred) || undefined}>
                        <div style={{ minWidth: "5rem" }}>{DateFormatter.momentAgo(event.Occurred)}</div>
                    </ToolTip>,
                event.Username,
                <EventFormatter event={event}/>,
            ]}/>) : (<div>{"There is no data to show"}</div>);
    };
    const showLoadingAndBusyState = (busy: boolean, errors: Errors | undefined) => {
        return <PaperLayout busy={busy} errors={errors}/>;
    };
    return (<div className={styles.container}>
            <h4>Task History</h4>
            {permitEvents ? (<HistoryDataLoader operationName="LoadHistoryForTask" load={loadEvents} renderWhenLoaded={(data) => displayHistoryTable(data.events)} renderAlternate={({ busy, errors }) => showLoadingAndBusyState(busy, errors)}/>) : (<NotPermittedToViewEventsCallout />)}
        </div>);
};
HistoryForTask.displayName = "HistoryForTask"
