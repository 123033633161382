import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonLibraryVariableSetOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="LibraryVariableSets">Variable sets</ExternalLink> are collections of variables that can be be shared between multiple projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonLibraryVariableSetOverview.displayName = "CommonLibraryVariableSetOverview"
export const CommonLibraryVariableSetTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="LibraryVariableSets">Variable Sets</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonLibraryVariableSetTopics.displayName = "CommonLibraryVariableSetTopics"
