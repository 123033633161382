import cn from "classnames";
import React from "react";
import { Icon } from "~/components/IconButton/IconButton";
import IconButton from "~/components/IconButton/index";
import styles from "./styles.module.less";
interface HelpPanelProps {
    image: string;
    altImageText?: string;
    content?: React.ReactNode;
    className?: string;
    onClose: () => void;
    showToggle: boolean;
}
function HelpPanel({ image, altImageText, content, className, onClose, showToggle }: HelpPanelProps) {
    return (<div className={cn(styles.helpPanel, className)}>
            {showToggle && (<span className={styles.helpPanelBtn}>
                    <IconButton toolTipContent={"Collapse help"} onClick={() => onClose()} icon={Icon.ChevronIcon}/>
                </span>)}
            <img src={image} className={styles.helpPanelImage} alt={altImageText}/>
            <div className={styles.helpPanelText}>{content}</div>
        </div>);
}
export default HelpPanel;
