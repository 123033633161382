/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ListItem } from "material-ui";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { withTheme } from "~/components/Theme";
const BorderedListItem: React.SFC<any> = (props) => withTheme((theme) => {
    const disableRippleProps = getDisableRippleProps(props);
    return (<ListItem style={{
            border: "1px solid",
            borderColor: theme.divider,
            borderRadius: "3px",
            margin: "16px 0",
        }} {...props} {...disableRippleProps}>
                {props.children}
            </ListItem>);
});
BorderedListItem.displayName = "BorderedListItem"
// This is a workaround as if you pass disableFocusRipple and disableTouchRipple into ListItem
// when either disabled === true or simpleLabel === true an error will be written to console eg:
// "Warning: React does not recognize the 'disableFocusRipple' prop on a DOM element.
// If you intentionally want it to appear in the DOM as a custom attribute,
// spell it as lowercase 'disablefocusripple' instead. If you accidentally passed it
// from a parent component, remove it from the DOM element."
function getDisableRippleProps(props: PropsWithChildren<any>) {
    const simpleLabel = !props.primaryTogglesNestedList && (props.leftCheckbox || props.rightToggle);
    return !props.disabled && !simpleLabel ? { disableTouchRipple: DISABLE_LEGACY_MUI_RIPPLES, disableFocusRipple: DISABLE_LEGACY_MUI_RIPPLES } : {};
}
export default BorderedListItem;
