import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Projects.svg");
export const CommonProjectView: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink> let you manage multiple software applications. For each project, you define a deployment process, configuration variables, and the environments the software
                    will be deployed to.
                </p>
                <p>
                    <ExternalVideoLink href="OnboardingProjectsVideo"/>
                </p>
                <ImageWithPlaceholder src={image} alt={"Projects"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonProjectView.displayName = "CommonProjectView"
export const CommonProjectTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonProjectTopics.displayName = "CommonProjectTopics"
