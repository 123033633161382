import * as React from "react";
import { repository, session } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import { ActionButton, ActionButtonType } from "~/components/Button";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import PageTitleHelper from "~/utils/PageTitleHelper";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import styles from "./style.module.less";
interface SignOutError {
    message: string;
    details: string[];
}
interface SignOutProps {
    history: {
        goBack: () => void;
    };
    onSessionEnded?: () => void;
}
interface SignOutState {
    isSignedOut: boolean;
    error?: SignOutError;
}
export default class SignOut extends BaseComponent<SignOutProps, SignOutState> {
    constructor(props: SignOutProps) {
        super(props);
        PageTitleHelper.setRootPageTitle();
        this.state = { isSignedOut: false };
    }
    render() {
        return this.state.isSignedOut ? (<InternalRedirect to={routeLinks.currentUser.signIn}/>) : (<div className={styles.content}>
                {!this.state.error && (<div className={styles.logo}>
                        <em className="fontoctopus-octopus"/>
                    </div>)}
                {this.state.error && this.showError(this.state.error)}
                <div className={styles.description}>
                    <h3>Are you sure you want to sign out?</h3>
                    <p>
                        Clicking <strong>Sign out</strong> will clear our cookies and log you out on this device.
                    </p>
                    <div>
                        <ActionButton type={ActionButtonType.Primary} label="SIGN OUT" busyLabel="SIGNING OUT..." onClick={this.signOut}/>
                    </div>
                    <div className={styles.back}>
                        <a href="#" onClick={this.goBack}>
                            No, I want to stay!
                        </a>
                    </div>
                </div>
            </div>);
    }
    private goBack = () => {
        this.props.history.goBack();
    };
    private signOut = async () => {
        try {
            await repository.Users.signOut();
            if (this.props.onSessionEnded) {
                this.props.onSessionEnded();
            }
            session.end();
            this.setState({ isSignedOut: true });
        }
        catch (err) {
            const error = {
                message: err.ErrorMessage || err.message || err.toString(),
                details: err.Errors || [],
            };
            this.setState({ error });
        }
    };
    private showError(error: SignOutError) {
        return (<div className={styles.authenticationError}>
                <ErrorPanel message={error.message} errors={error.details}/>
            </div>);
    }
    static displayName = "SignOut";
}
