import * as React from "react";
import type { PagingEvent } from "~/analytics/Analytics";
import { Action, useAnalyticPagingDispatch } from "~/analytics/Analytics";
import styles from "~/areas/tenants/components/Paging/style.module.less";
import Select from "~/primitiveComponents/form/Select/Select";
interface PagingSelectionProps {
    pageSize: number;
    onPageSizeChanged: (size: number) => void;
}
function PagingSelection({ pageSize, onPageSizeChanged }: PagingSelectionProps) {
    const dispatchAction = useAnalyticPagingDispatch();
    const options = ["30", "50", "100"];
    async function handlePageSizeChanged(value: number) {
        const ev: PagingEvent = {
            resource: "Pagination",
            action: Action.Update,
            itemsPerPage: value,
        };
        await dispatchAction("Update Items Per Page", ev, async () => {
            await onPageSizeChanged(value);
            return true;
        });
    }
    return (<div className={styles.pagingSelectContainer}>
            <span className={styles.pagingSelectText}>Show</span>
            <Select sortItems={false} items={options.map((n) => ({ value: n, text: n }))} value={pageSize.toLocaleString()} onChange={(val) => handlePageSizeChanged(Number(val) ?? 30)}/>
            <span className={styles.pagingSelectText}>per page</span>
        </div>);
}
export default PagingSelection;
