/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { FeedType, FeedResource } from "@octopusdeploy/octopus-server-client";
import type { DoBusyTask } from "../../../../../components/DataBaseComponent/DataBaseComponent";
interface FeedTypeRegistration<T extends FeedResource = FeedResource> {
    type: FeedType;
    edit: React.ComponentType<FeedEditProps<T>>;
    text: string;
    uriNotes?: string | JSX.Element;
    uriDefault?: string;
    hasUri?: boolean;
}
interface FeedEditProps<T extends FeedResource> {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | boolean;
    feed: T;
    onChange(newValue: T): void;
    getFieldError(field: string): string;
}
export { FeedTypeRegistration, FeedEditProps };
class FeedTypeRegistry {
    private registeredFeedTypes: {
        [feedType: string]: FeedTypeRegistration;
    } = {};
    registerFeedType(registration: FeedTypeRegistration) {
        if (registration.hasUri === undefined) {
            registration.hasUri = true;
        }
        this.registeredFeedTypes[registration.type] = registration;
    }
    getRegistration(feedType: FeedType): FeedTypeRegistration {
        return this.registeredFeedTypes[feedType];
    }
    getAllTypes(): FeedType[] {
        return Object.keys(this.registeredFeedTypes) as FeedType[];
    }
}
const feedTypeRegistry = new FeedTypeRegistry();
export default feedTypeRegistry;
