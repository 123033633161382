import cn from "classnames";
import React from "react";
import { useRouteMatch } from "react-router";
import { generatePath } from "react-router-dom";
import type { ActionEvent } from "~/analytics/Analytics";
import { useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { TaskState } from "./ProjectStatus";
import { ProjectStatusIcon } from "./ProjectStatusIcon";
import { RightArrowIcon } from "./RightArrowIcon";
import styles from "./style.module.less";
interface ProjectStatusItemProps {
    name: string;
    href: string;
    state: TaskState;
    eventName: string;
    eventAction: ActionEvent;
}
export const ProjectStatusItem = ({ name, state, href, eventName, eventAction }: ProjectStatusItemProps): JSX.Element => {
    const dispatchAction = useAnalyticActionDispatch();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const match = useRouteMatch()!;
    const currentLocation = generatePath(match.path, { ...match.params });
    const desiredLocation = generatePath(href, { ...match.params });
    const [redirect, setRedirect] = React.useState(false);
    if (redirect && href)
        return <InternalRedirect to={desiredLocation} push={true}/>;
    if (state === "Current") {
        return (<a className={cn(styles.statusItemContainer, styles.active)} href={desiredLocation} onClick={(e) => {
                e.preventDefault();
                if (currentLocation === desiredLocation)
                    return;
                dispatchAction(eventName, eventAction);
                setRedirect(true);
            }}>
                <ProjectStatusIcon state={state}/>
                {name}
                <RightArrowIcon />
            </a>);
    }
    return (<div className={cn(styles.statusItemContainer)}>
            <ProjectStatusIcon state={state}/>
            {name}
        </div>);
};
