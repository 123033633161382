import { azureCloudServiceEndpointRegistration } from "./AzureCloudServiceEndpoint";
import { azureServiceFabricClusterEndpointRegistration } from "./AzureServiceFabricClusterEndpoint";
import { azureVmRegistration } from "./AzureVmEndpointRegistration";
import { azureWebAppEndpointRegistration } from "./AzureWebAppEndpoint";
import { cloudRegionEndpointRegistration } from "./CloudRegionEndpoint";
import { kubernetesEndpointRegistration } from "./KubernetesEndpoint";
import { offlineDropEndpointRegistration } from "./OfflineDropEndpoint";
import { sshEndpointRegistration } from "./SshEndpoint";
import { activeTentacleEndpointRegistration } from "./TentacleActiveEndpoint";
import { passiveTentacleEndpointRegistration } from "./TentaclePassiveEndpoint";
import endpointRegistry from "./endpointRegistry";
endpointRegistry.registerEndpoint(activeTentacleEndpointRegistration);
endpointRegistry.registerEndpoint(passiveTentacleEndpointRegistration);
endpointRegistry.registerEndpoint(sshEndpointRegistration);
endpointRegistry.registerEndpoint(offlineDropEndpointRegistration);
endpointRegistry.registerEndpoint(azureCloudServiceEndpointRegistration);
endpointRegistry.registerEndpoint(azureServiceFabricClusterEndpointRegistration);
endpointRegistry.registerEndpoint(azureWebAppEndpointRegistration);
endpointRegistry.registerEndpoint(azureVmRegistration);
endpointRegistry.registerEndpoint(kubernetesEndpointRegistration);
endpointRegistry.registerEndpoint(cloudRegionEndpointRegistration);
