import { AccountType } from "@octopusdeploy/octopus-server-client";
import type { SensitiveValue, TokenAccountResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import AccountEditBase from "./AccountEditBase";
import type { AccountEditModel } from "./AccountEditBase";
interface TokenModel extends AccountEditModel {
    token: SensitiveValue;
}
class TokenAccountEdit extends AccountEditBase<TokenAccountResource, TokenModel> {
    getPartialModel(account?: TokenAccountResource): Partial<TokenModel> {
        if (!account) {
            return {
                token: {
                    HasValue: false,
                },
            };
        }
        return {
            token: account.Token,
        };
    }
    getPartialResource(): Partial<TokenAccountResource> & {
        AccountType: AccountType;
    } {
        return {
            AccountType: AccountType.Token,
            Token: this.state.model.token,
        };
    }
    tokenSummary() {
        return this.state.model.token && this.state.model.token.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No token yet provided");
    }
    customSecondaryAction(): React.ReactElement | null {
        return null;
    }
    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];
        baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="token" errorKey="token" title="Token" summary={this.tokenSummary()} help="The password to use to when authenticating against the remote host.">
                <Sensitive value={this.state.model.token} onChange={(token) => this.setModelState({ token })} label="Token"/>
            </ExpandableFormSection>);
        return baseElements;
    }
}
export default TokenAccountEdit;
