/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { EnvironmentResource, EnvironmentSettingsMetadata, ExtensionSettingsValues, DynamicEnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { DeleteEnvironmentDialogLayout } from "~/areas/infrastructure/components/EnvironmentLayout/DeleteEnvironmentDialogLayout";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Checkbox, ExpandableFormSection, FormSectionHeading, required, Summary, Text } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
interface EnvironmentModel {
    name: string;
    slug: string;
    description: string;
    useGuidedFailure: boolean;
    allowDynamicInfrastructure: boolean;
    sortOrder: number;
    extensionSettings: ExtensionSettingsValues[];
}
const defaultModel: EnvironmentModel = {
    name: "",
    slug: "",
    description: "",
    useGuidedFailure: false,
    allowDynamicInfrastructure: false,
    sortOrder: -1,
    extensionSettings: [],
};
interface EnvironmentLayoutPageProps {
    newOrExistingEnvironment: {
        createNewEnvironment: true;
    } // Is this case still possible?
     | {
        existingEnvironmentId: string;
        createNewEnvironment: false;
    };
}
interface StaticEnvironmentData {
    environment: EnvironmentResource;
    environmentType: "static";
}
interface EnvironmentLayoutPageData extends StaticEnvironmentData {
    metadata: EnvironmentSettingsMetadata[];
}
interface DynamicEnvironmentData {
    environment: DynamicEnvironmentResource;
    environmentType: "dynamic";
}
const EnvironmentLayoutFormPage = FormPage<EnvironmentLayoutPageData | DynamicEnvironmentData>();
const Title = "Environments";
type EnvironmentLayoutInternalProps = {
    initialData: EnvironmentLayoutPageData;
    trackAction: AnalyticTrackedActionDispatcher;
};
interface InternalState extends FormBaseComponentState<EnvironmentModel> {
    deleted: boolean;
}
interface InternalStateWithEnvironment extends InternalState {
    environment: EnvironmentResource;
    metadata: EnvironmentSettingsMetadata[];
}
type EnvironmentLayoutInternalState = InternalState | InternalStateWithEnvironment;
const EnvironmentsLayoutPage: React.FC<EnvironmentLayoutPageProps> = (props) => {
    if (props.newOrExistingEnvironment.createNewEnvironment) {
        throw Error("Environment Id must be supplied");
    }
    const environmentId = props.newOrExistingEnvironment.existingEnvironmentId;
    const trackAction = useAnalyticTrackedActionDispatch();
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    return (<EnvironmentLayoutFormPage title={Title} load={async () => {
            const environmentData = await loadEnvironment(environmentId, dynamicEnvironmentsEnabled);
            if (environmentData.environmentType === "static") {
                const metadata = repository.Environments.getMetadata(environmentData.environment);
                return {
                    metadata: await metadata,
                    ...environmentData,
                };
            }
            return environmentData;
        }} renderWhenLoaded={(data) => 
        // If the environment that has been requested is a dynamic environment, we redirect them to the dynamic environment overview page.
        // This may occur when the ID is used to deduce the document type by taking the string to the left of the '-'.
        // eg: "Environments-43" => "Environments", this happens in EventFormatter.tsx
        // In this case it's impossible to differentiate between static and dynamic environments so a link to the static environment page may be
        // used with a dynamic environment id.
        data.environmentType === "dynamic" ? (<InternalRedirect to={routeLinks.forSpace(data.environment.SpaceId).infrastructure.dynamicEnvironment(data.environment.Id).overview} push={false}/>) : (<EnvironmentLayoutInternal initialData={data} trackAction={trackAction}/>)} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
EnvironmentsLayoutPage.displayName = "EnvironmentsLayoutPage"
const loadEnvironment = async (environmentId: string, dynamicEnvironmentsEnabled: boolean): Promise<StaticEnvironmentData | DynamicEnvironmentData> => {
    try {
        const environment = await repository.Environments.get(environmentId);
        return {
            environment,
            environmentType: "static",
        };
    }
    catch (e) {
        if (!dynamicEnvironmentsEnabled) {
            throw e;
        }
        try {
            const dynamicEnvironment = await repository.DynamicEnvironments.get(environmentId);
            return {
                environment: dynamicEnvironment,
                environmentType: "dynamic",
            };
        }
        catch {
            throw e;
        }
    }
};
class EnvironmentLayoutInternal extends FormBaseComponent<EnvironmentLayoutInternalProps, EnvironmentLayoutInternalState, EnvironmentModel> {
    constructor(props: EnvironmentLayoutInternalProps) {
        super(props);
        const data = props.initialData;
        this.state = {
            environment: data.environment,
            model: this.buildModel(data.environment),
            cleanModel: cloneDeep(this.buildModel(data.environment)),
            deleted: false,
            metadata: data.metadata,
        };
    }
    descriptionSummary() {
        return this.state.model.description ? Summary.summary(<Markdown markup={this.state.model.description}/>) : Summary.placeholder("No description provided");
    }
    renderOverflowActions(state: InternalStateWithEnvironment) {
        return [
            OverflowMenuItems.dialogItem("Delete", <DeleteEnvironmentDialogLayout environment={state.environment} onDelete={this.handleDeleteConfirm}/>, {
                permission: Permission.EnvironmentDelete,
                environment: "*",
            }),
            [
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForEnvironment(state.environment.Id), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ],
        ];
    }
    renderExtensionSettings(state: InternalStateWithEnvironment) {
        return state.metadata.map((m) => {
            let valuesForExtension = state.environment.ExtensionSettings.find((e) => e.ExtensionId === m.ExtensionId);
            if (!valuesForExtension || !valuesForExtension.Values) {
                valuesForExtension = {
                    ExtensionId: m.ExtensionId,
                    Values: {},
                };
                this.state.model.extensionSettings.push(valuesForExtension);
            }
            return (<div>
                    <FormSectionHeading title={m.Metadata.Description}/>
                    <DynamicForm types={m.Metadata.Types} values={valuesForExtension.Values} onChange={() => {
                    this.setState({
                        model: this.state.model,
                    });
                }}/>
                </div>);
        });
    }
    hasEnvironment = (variableToCheck: object): variableToCheck is InternalStateWithEnvironment => (variableToCheck as InternalStateWithEnvironment).environment !== undefined;
    render() {
        const state = this.state;
        let overFlowActions;
        if (this.hasEnvironment(state)) {
            overFlowActions = this.renderOverflowActions(state);
        }
        return (<InfrastructureLayout {...this.props}>
                <AnalyticView resource="Environment"/>
                <FormPaperLayout title={this.state.model.name} breadcrumbTitle={"Environments"} breadcrumbPath={routeLinks.infrastructure.environments.root} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.EnvironmentEdit, environment: "*" }} onSaveClick={this.handleSaveClick} saveText={"Environment details updated"} expandAllOnMount={false} overFlowActions={overFlowActions} secondaryAction={this.addEnvironmentButton()}>
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.environments.root}/>}
                    {this.hasEnvironment(this.state) && <InternalRedirect to={routeLinks.infrastructure.environment(this.state.environment.Id)}/>}
                    {<TransitionAnimation>
                            <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.state.model.name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.name} slug={this.state.model.slug}/>) : Summary.placeholder("Please enter a name for your environment")} help="A short, memorable, unique name for this environment. Example: Development.">
                                <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Name" validate={required("Please enter a environment name")} autoFocus={true}/>

                                <SlugEditor value={this.state.model.slug} name={this.state.model.name} originalSlug={this.state.cleanModel?.slug ?? ""} onChange={(slug) => this.setModelState({ slug })} label="Slug" validate={required("Please enter an environment slug")} error={this.getFieldError("slug")}/>
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your environment.">
                                <MarkdownEditor value={this.state.model.description} label="Environment description" onChange={(description) => this.setModelState({ description })}/>
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="useGuidedFailure" title="Default Guided Failure Mode" summary={this.state.model.useGuidedFailure ? Summary.summary("Yes") : Summary.default("No")} help="Select whether guided failure mode is enabled by default">
                                <div>
                                    <Checkbox value={this.state.model.useGuidedFailure} label="Use guided failure mode by default" onChange={(useGuidedFailure) => this.setModelState({ useGuidedFailure })} note={<span>If guided failure is enabled for an environment, Octopus Deploy will prompt for user intervention if a deployment fails in the environment.</span>}/>
                                </div>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="dynamicInfrastructure" title="Dynamic Infrastructure" summary={this.state.model.allowDynamicInfrastructure ? Summary.default("Yes") : Summary.summary("No")} help="Select whether dynamic infrastructure is allowed in this environment">
                                <div>
                                    <Checkbox value={this.state.model.allowDynamicInfrastructure} label="Allow dynamic infrastructure" onChange={(allowDynamicInfrastructure) => this.setModelState({ allowDynamicInfrastructure })} note={<span>
                                                In many deployment scenarios, <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment. If dynamic infrastructure is enabled for an
                                                environment, deployments to this environment are allowed to create infrastructure, such as targets and accounts.
                                            </span>}/>
                                </div>
                            </ExpandableFormSection>
                            {this.hasEnvironment(state) && this.renderExtensionSettings(state)}
                        </TransitionAnimation>}
                </FormPaperLayout>
            </InfrastructureLayout>);
    }
    private handleSaveClick = async () => {
        const model = this.state.model;
        const environment: EnvironmentResource = {
            Id: this.props.initialData.environment.Id,
            Name: model.name,
            Slug: model.slug,
            Description: model.description,
            UseGuidedFailure: model.useGuidedFailure,
            AllowDynamicInfrastructure: model.allowDynamicInfrastructure,
            SortOrder: model.sortOrder,
            ExtensionSettings: model.extensionSettings,
            SpaceId: this.props.initialData.environment.SpaceId,
            Links: this.props.initialData.environment.Links,
        };
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Environment",
            };
            await this.props.trackAction("Save Environment", ev, async () => {
                const result = await repository.Environments.save(environment);
                this.setState({
                    environment: result,
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    deleted: false,
                });
            });
        });
    };
    private buildModel(environment: EnvironmentResource): EnvironmentModel {
        const model: EnvironmentModel = {
            name: environment.Name,
            slug: environment.Slug ?? "",
            description: environment.Description,
            useGuidedFailure: environment.UseGuidedFailure,
            allowDynamicInfrastructure: environment.AllowDynamicInfrastructure,
            sortOrder: environment.SortOrder,
            extensionSettings: environment.ExtensionSettings,
        };
        return model;
    }
    private handleDeleteConfirm = () => {
        if (this.hasEnvironment(this.state)) {
            this.setState({
                model: cloneDeep(defaultModel),
                cleanModel: cloneDeep(defaultModel),
                deleted: true,
            });
            return true;
        }
        return false;
    };
    private addEnvironmentButton() {
        let environmentId;
        if (this.hasEnvironment(this.state)) {
            environmentId = this.state.environment.Id;
        }
        return (<PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.machines.new(environmentId)} label="Add deployment target"/>
            </PermissionCheck>);
    }
    static displayName = "EnvironmentLayoutInternal";
}
export default EnvironmentsLayoutPage;
