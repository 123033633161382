import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { useLocation, useParams } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import type { Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { ErrorPanel } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import routeLinks from "../../../../routeLinks";
interface InsightsReportRouteParams {
    reportId: string;
}
interface RenderProps {
    report: InsightsReportResource;
    refreshReport: () => Promise<void>;
}
interface InsightsReportLayoutProps {
    children: (renderProps: RenderProps) => React.ReactNode;
}
interface InsightsReportLayoutInnerProps {
    report: InsightsReportResource;
    children: React.ReactNode;
}
interface Props extends InsightsReportLayoutProps {
    reportId: string;
}
interface State {
    report: InsightsReportResource | null;
}
class InsightsReportLayoutLoader extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            report: null,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async refreshReport() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.for("RefreshByLayout") });
    }
    private async load() {
        const report = await repository.InsightsReports.get(this.props.reportId);
        this.setState({ report });
    }
    render() {
        return this.state.report ? (<InsightsReportLayoutInner report={this.state.report}>{this.props.children({ report: this.state.report, refreshReport: () => this.refreshReport() })}</InsightsReportLayoutInner>) : (<LoadingLayout busy={Boolean(this.state.busy)} errors={this.errors}/>);
    }
    static displayName = "InsightsReportLayoutLoader";
}
interface LoadingLayoutProps {
    errors: Errors | undefined;
    busy: boolean;
}
function LoadingLayout({ busy, errors }: LoadingLayoutProps) {
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.insights.reports} title="Insights" busyIndicator={<LinearProgress show={busy}/>}/>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>}
        </main>);
}
function InsightsReportLayoutInner({ report, children }: InsightsReportLayoutInnerProps) {
    const { search } = useLocation();
    const reportLinks = routeLinks.insights.report(report.Id);
    const sidebarLinks = [
        Navigation.navItem("Overview", reportLinks.overview),
        Navigation.navItem("Deployment frequency", reportLinks.frequency + search),
        Navigation.navItem("Deployment lead time", reportLinks.leadTime + search),
        Navigation.navItem("Deployment failure rate", reportLinks.failureRate + search),
        Navigation.navItem("Mean time to recovery", reportLinks.timeToRecovery + search),
        Navigation.navItem("Deployment duration", reportLinks.deploymentDuration + search),
    ];
    sidebarLinks.push(Navigation.navItem("Settings", reportLinks.settings));
    const computedSidebarLinks = compact(sidebarLinks);
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.insights.reports} title="Insights"/>
            <NavigationSidebarLayout logoUrl={report.Links.Logo} description={report.Description} name={report.Name} navLinks={computedSidebarLinks} content={children}/>
        </main>);
}
export function InsightsReportLayout({ children }: InsightsReportLayoutProps) {
    const { reportId } = useParams<InsightsReportRouteParams>();
    return <InsightsReportLayoutLoader reportId={reportId}>{children}</InsightsReportLayoutLoader>;
}
