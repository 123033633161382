import type { RunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Chip from "~/components/Chips/Chip";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { withTheme } from "~/components/Theme";
import WarningIcon from "~/components/WarningIcon/WarningIcon";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./LastPublishedChip.module.less";
export const RunbookSnapshotInformation: React.FC<{
    runbookSnapshot: RunbookSnapshotResource;
    isOutOfDate?: boolean;
}> = ({ runbookSnapshot, isOutOfDate }) => withTheme((theme) => {
    const content = runbookSnapshot && (<div className={styles.lastPublishedTooltipContent}>
                {isOutOfDate && (<>
                        <div>
                            <WarningIcon color={theme.whiteConstant} iconOverride="fa-circle-info"/> The runbook has changed since this snapshot was published
                        </div>
                        <hr />
                    </>)}
                <div>
                    Name: <strong>{runbookSnapshot.Name}</strong>
                </div>
                {runbookSnapshot.Notes && <div>{runbookSnapshot.Notes}</div>}
                <div>
                    Created: <strong>{DateFormatter.dateToShortFormat(runbookSnapshot.Assembled)}</strong>
                </div>
            </div>);
    return content;
});
RunbookSnapshotInformation.displayName = "RunbookSnapshotInformation"
type FormTitleAndLastPublishedChipProps = {
    runbookName: string;
};
export const FormTitleForRunbook: React.FC<FormTitleAndLastPublishedChipProps> = (props: FormTitleAndLastPublishedChipProps) => {
    return (<div className={styles.formTitle}>
            <div>{props.runbookName}</div>
        </div>);
};
FormTitleForRunbook.displayName = "FormTitleForRunbook"
export interface LastPublishedChipProps {
    projectSlug: string;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    isOutOfDate?: boolean;
}
export const LastPublishedChip: React.FC<LastPublishedChipProps> = ({ projectSlug, publishedRunbookSnapshot, isOutOfDate }) => withTheme((theme) => {
    if (!publishedRunbookSnapshot) {
        return null;
    }
    const lastPublishedTooltip = <RunbookSnapshotInformation runbookSnapshot={publishedRunbookSnapshot} isOutOfDate={isOutOfDate}/>;
    const chipColor = isOutOfDate ? theme.info : theme.success;
    return (<div className={styles.lastPublishedTooltipContainer}>
                <InternalLink to={routeLinks.project(projectSlug).operations.runbook(publishedRunbookSnapshot.RunbookId).runbookSnapshot(publishedRunbookSnapshot.Id).root}>
                    <Chip fullWidth={true} description={lastPublishedTooltip} borderColor={chipColor} labelColor={chipColor} backgroundColor={theme.whiteConstant}>
                        {isOutOfDate && <WarningIcon color={theme.info} iconOverride="fa-circle-info"/>}
                        Published {DateFormatter.dateToShortFormat(publishedRunbookSnapshot.Assembled)}
                    </Chip>
                </InternalLink>
            </div>);
});
LastPublishedChip.displayName = "LastPublishedChip"
export default LastPublishedChip;
