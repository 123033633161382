import * as React from "react";
import { ActionButton } from "~/components/Button/ActionButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
interface DialogProps {
    open: boolean;
    title: string;
    onCloseClick(): void;
}
export default class NoActionDialog extends DataBaseComponent<DialogProps, DataBaseComponentState> {
    constructor(props: DialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (<Dialog open={this.props.open}>
                <DialogLayout actions={<ActionButton label="Close" onClick={() => this.props.onCloseClick()}/>} title={this.props.title} closeDialog={this.props.onCloseClick}>
                    {this.props.children}
                </DialogLayout>
            </Dialog>);
    }
    static displayName = "NoActionDialog";
}
