import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationTeamOverview, CommonConfigurationTeamTopics } from "../../CommonComponents/CommonConfigurationTeamViews";
export const ConfigurationTeamRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.team,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTeamOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTeamTopics />;
    },
};
