import { throttle } from "lodash";
import { useEffect, useState } from "react";
import * as React from "react";
import EventListener, { withOptions } from "react-event-listener";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import { MeasureWidth } from "~/components/Measure/Measure";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import styles from "./style.module.less";
interface VariableEditorHeadingsProps {
    cellAligner: CellAligner;
    cells: JSX.Element[];
    isDisplayedFullWidth: boolean;
    columnWidths: ReadonlyArray<number>;
    onWidthMeasured(cellIndex: number, width: number): void;
}
export function VariableEditorHeadings({ cellAligner, cells, isDisplayedFullWidth, columnWidths, onWidthMeasured }: VariableEditorHeadingsProps) {
    const [resizeCount, setResizeCount] = useState(0);
    const columnWidthsKey = columnWidths.map((c) => c.toString()).join("-");
    const [measurerKey, setMeasurerKey] = useState(`${isDisplayedFullWidth}-${columnWidthsKey}-${resizeCount}`);
    const throttledRemeasure = throttle(() => setResizeCount(resizeCount + 1), 100);
    // If any of these things change, we need to re-measure
    useEffect(() => setMeasurerKey(`${isDisplayedFullWidth}-${columnWidthsKey}-${resizeCount}`), [columnWidthsKey, resizeCount, isDisplayedFullWidth]);
    return cellAligner(cells.map((c, index) => (<VariableCell key={index}>
                <EventListener target="window" onResize={withOptions(throttledRemeasure, { passive: true })}/>
                <MeasureWidth key={measurerKey} onMeasured={(width) => onWidthMeasured(index, width)}>
                    <div className={styles.headerRow}>{c}</div>
                </MeasureWidth>
            </VariableCell>)), { showResizeHandles: true });
}
