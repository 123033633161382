/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { GitRefResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import { KubernetesLabelKeyRegex } from "~/components/Actions/kubernetes/kubernetesValidation";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { ExtendedKeyValueEditList } from "~/components/EditList/ExtendedKeyValueEditList";
import isBound from "~/components/form/BoundField/isBound";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Note from "../../../primitiveComponents/form/Note/Note";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { VariableLookupText } from "../../form/VariableLookupText";
import { DoesNotExistOperator, ExistsOperator, InOperator, NotInOperator, PreferredAffinity, RequiredAffinity } from "./kubernetesDeployContainersAction";
import type { PodAffinityDetails } from "./kubernetesDeployContainersAction";
interface PodAffinityState extends DataBaseComponentState {
    podAffinityDetails: PodAffinityDetails;
    project?: ProjectResource;
}
interface PodAffinityProps {
    podAffinityDetails: PodAffinityDetails;
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    antiAffinity: boolean;
    onAdd(Binding: PodAffinityDetails): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
class PodAffinityDialog extends DataBaseComponent<PodAffinityProps, PodAffinityState> {
    constructor(props: PodAffinityProps) {
        super(props);
        this.state = {
            podAffinityDetails: null!,
            project: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null!;
            const podAffinityDetails = { ...this.props.podAffinityDetails };
            this.setState({
                podAffinityDetails,
                project,
            });
        });
    }
    save = () => {
        let valid = true;
        const binding = this.state.podAffinityDetails;
        if (!binding.Type || !binding.Type.trim()) {
            this.setValidationErrors("The pod affinity rule type must be defined.", { PodAffinityType: "The pod affinity rule type must be defined." });
            valid = false;
        }
        if (binding.Type === PreferredAffinity && (!binding.Weight || (!isBound(binding.Weight) && (isNaN(parseInt(binding.Weight, 10)) || parseInt(binding.Weight, 10) < 1 || parseInt(binding.Weight, 10) > 100)))) {
            this.setValidationErrors("The pod affinity rule weight must be defined as a number between 1 and 100.", { PodAffinityWeight: "The pod affinity rule weight must be defined as a number between 1 and 100." });
            valid = false;
        }
        if (!binding.TopologyKey || !binding.TopologyKey.trim()) {
            this.setValidationErrors("The pod affinity rule topology key must be defined.", { PodAffinityTopologyKey: "The pod affinity rule topology key must be defined." });
            valid = false;
        }
        if (binding.InMatch && !binding.InMatch.every((i) => !!i.key && !!i.key.trim() && !!i.value && !!i.value.trim() && !!i.option && !!i.option.trim())) {
            this.setValidationErrors("All \"In\" and \"Not in\" rules must define the label key, operator and label values.", { PodAffinityInRules: "All \"In\" and \"Not in\" rules must define the label key, operator and label values." });
            valid = false;
        }
        if (binding.ExistMatch && !binding.ExistMatch.every((i) => !!i.key && !!i.key.trim() && !!i.value && !!i.value.trim())) {
            this.setValidationErrors("All \"Exists\" and \"Does not exist\" rules must define the label key and operator.", { PodAffinityInRules: "All \"Exists\" and \"Does not exist\" rules must define the label key and operator." });
            valid = false;
        }
        if (valid && binding.InMatch && !binding.InMatch.every((i) => isBound(i.key) || !!KubernetesLabelKeyRegex.exec(i.key.trim()))) {
            this.setValidationErrors("All \"In\" and \"Not in\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.", {
                PodAffinityInRules: "All \"In\" and \"Not in\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }
        if (valid && binding.ExistMatch && !binding.ExistMatch.every((i) => isBound(i.key) || !!KubernetesLabelKeyRegex.exec(i.key.trim()))) {
            this.setValidationErrors("All \"Exists\" and \"Does not exist\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.", {
                PodAffinityInRules: "All \"Exists\" and \"Does not exist\" rule label keys must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }
        if (!((binding.ExistMatch && binding.ExistMatch.length !== 0) || (binding.InMatch && binding.InMatch.length !== 0))) {
            this.setValidationErrors("At least 1 rule must be defined.", { PodAffinityInRules: "At least 1 rule must be defined." });
            valid = false;
        }
        if (valid) {
            return this.props.onAdd(binding);
        }
        return valid;
    };
    render() {
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Define Pod " + (this.props.antiAffinity ? "Anti-Affinity" : "Affinity") + " Rule"}>
                {this.state.podAffinityDetails && (<div>
                        <RadioButtonGroup value={this.state.podAffinityDetails.Type} onChange={(Type: string) => {
                    this.setPodAffinityState({ Type });
                    this.repositionDialog();
                }} error={this.getFieldError("PodAffinityType")}>
                            <RadioButton value={RequiredAffinity} label={RequiredAffinity}/>
                            <RadioButton value={PreferredAffinity} label={PreferredAffinity}/>
                        </RadioButtonGroup>
                        <Note>A required affinity rule must be met for the pod to be deployed. A preferred {this.props.antiAffinity ? "anti-affinity" : "affinity"} rule will attempt to be satisfied, but if not the pod will still be deployed.</Note>
                        {this.state.podAffinityDetails.Type === PreferredAffinity && (<div>
                                <VariableLookupText label={"Weight"} localNames={this.props.localNames} error={this.getFieldError("PodAffinityWeight")} value={this.state.podAffinityDetails.Weight} onChange={(Weight) => this.setPodAffinityState({ Weight })}/>
                                <Note>An integer value between 1 and 100 defining the weight of the {this.props.antiAffinity ? "anti-affinity" : "affinity"} rule.</Note>
                            </div>)}
                        <VariableLookupText label={"Topology key"} localNames={this.props.localNames} error={this.getFieldError("PodAffinityTopologyKey")} value={this.state.podAffinityDetails.TopologyKey} onChange={(TopologyKey) => this.setPodAffinityState({ TopologyKey })}/>
                        <Note>
                            Each node must have a label with the key <code>topologyKey</code>. The topology key defined here matches the value of the label with the key <code>topologyKey</code>. Matching nodes are then inspected for pods to match
                            with the {this.props.antiAffinity ? "anti-affinity" : "affinity"} rules below.
                        </Note>
                        <VariableLookupText label={"Namespaces"} localNames={this.props.localNames} error={this.getFieldError("PodAffinityNamespaces")} value={this.state.podAffinityDetails.NamespacesList} onChange={(NamespacesList) => this.setPodAffinityState({ NamespacesList })}/>
                        <Note>
                            A comma separated list of namespaces that pods must be in to be matched with the
                            {this.props.antiAffinity ? "anti-affinity" : "affinity"} rules below. Leave this field empty to match pods in the same namespace as the deployment resource.
                        </Note>
                        <ExtendedKeyValueEditList items={() => (_.isArray(this.state.podAffinityDetails.InMatch) ? this.state.podAffinityDetails.InMatch : [])} name={"In Rule"} onChange={(InMatch) => this.setPodAffinityState({ InMatch })} keyLabel="Label key" valueLabel="Operation" valueValues={[
                    { text: "In", value: InOperator },
                    { text: "Not in", value: NotInOperator },
                ]} optionLabel="Label values" optionHintText="Comma separated label values" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true} onAdd={this.repositionDialog}/>
                        <ExtendedKeyValueEditList items={() => (_.isArray(this.state.podAffinityDetails.ExistMatch) ? this.state.podAffinityDetails.ExistMatch : [])} name={"Exists Rule"} onChange={(ExistMatch) => this.setPodAffinityState({ ExistMatch })} keyLabel="Label key" valueLabel="Operation" valueValues={[
                    { text: "Exists", value: ExistsOperator },
                    { text: "Does not exist", value: DoesNotExistOperator },
                ]} hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true} onAdd={this.repositionDialog}/>
                    </div>)}
            </OkDialogLayout>);
    }
    protected getFieldError = (fieldName: string) => {
        if (this.errors && this.errors.fieldErrors) {
            const found = Object.keys(this.errors.fieldErrors).find((k) => k.toLowerCase() === fieldName.toLowerCase());
            if (found) {
                return this.errors.fieldErrors[found];
            }
            const foundPartialMatch = Object.keys(this.errors.fieldErrors).find((k) => k.endsWith("." + fieldName));
            if (foundPartialMatch) {
                return this.errors.fieldErrors[foundPartialMatch];
            }
        }
        return "";
    };
    private setPodAffinityState<K extends keyof PodAffinityDetails>(state: Pick<PodAffinityDetails, K>, callback?: () => void) {
        this.setChildState1("podAffinityDetails", state, callback);
    }
    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    }
    static displayName = "PodAffinityDialog";
}
export default PodAffinityDialog;
