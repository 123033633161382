import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import ChannelsRoute from "../Channels/ChannelsRoute";
import DeploymentSettingsRoute from "../DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import { DeploymentsOverview } from "../DeploymentsOverview";
import DeploymentProcessRoute from "../Process/Routing/ProcessRoute";
import { ReleasesRoute } from "../Releases";
import { deploymentLinks } from "./deploymentRouteLinks";
export const DeploymentsOverviewPage = withPage({ page: pageIds.project().overview })(DeploymentsOverview);
type DeploymentsRouteProps = {
    path: string;
} & RouteComponentProps;
export class DeploymentsRoute extends React.Component<DeploymentsRouteProps> {
    render() {
        const links = deploymentLinks(this.props.path);
        const match = this.props.match;
        return (<ReloadableRoute path={links.root}>
                <Switch>
                    <DeploymentProcessRoute path={`${match.path}/process`} processType={ProcessType.Deployment}/>
                    <ChannelsRoute path={`${match.path}/channels`}/>
                    <ReloadableRoute path={`${match.path}/releases`} component={ReleasesRoute}/>
                    <DeploymentSettingsRoute path={`${match.path}/settings`}/>
                    <ReloadableRoute path={`${match.path}`} component={DeploymentsOverviewPage}/>
                </Switch>
            </ReloadableRoute>);
    }
    static displayName = "DeploymentsRoute";
}
const EnhancedDeploymentsRoute = withRouter(DeploymentsRoute);
export default EnhancedDeploymentsRoute;
