import type { AmazonWebServicesAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ActionButton } from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import type { AccountEditModel } from "./AccountEditBase";
import AccountEditBase from "./AccountEditBase";
import { SaveAndTestAmazonWebServicesAccountDialog } from "./SaveAndTestAccountDialog";
interface AmazonWebServicesAccountModel extends AccountEditModel {
    accessKey: string;
    secretKey: SensitiveValue;
}
class AmazonWebServicesAccountEdit extends AccountEditBase<AmazonWebServicesAccountResource, AmazonWebServicesAccountModel> {
    getPartialModel(account?: AmazonWebServicesAccountResource): Partial<AmazonWebServicesAccountModel> {
        if (!account) {
            return {
                accessKey: "",
                secretKey: {
                    HasValue: false,
                },
            };
        }
        return {
            accessKey: account.AccessKey,
            secretKey: account.SecretKey,
        };
    }
    getPartialResource(): (Partial<AmazonWebServicesAccountResource> & {
        AccountType: AccountType;
    }) | undefined {
        return {
            AccountType: AccountType.AmazonWebServicesAccount,
            AccessKey: this.state.model.accessKey,
            SecretKey: this.state.model.secretKey,
        };
    }
    accessKeySummary() {
        return this.state.model.accessKey ? Summary.summary(this.state.model.accessKey) : Summary.placeholder("No access key provided");
    }
    secretKeySummary() {
        return this.state.model.secretKey && this.state.model.secretKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No secret key provided");
    }
    customSecondaryAction(): React.ReactElement {
        return (<div>
                <ActionButton onClick={() => this.handleSaveClick(true)} label="Save and Test"/>
                {this.state.accountData !== undefined && (<Dialog open={this.state.showTestDialog}>
                        <SaveAndTestAmazonWebServicesAccountDialog onOkClick={() => this.testDone()} accountId={this.state.accountData.account.Id}/>
                    </Dialog>)}
            </div>);
    }
    customExpandableFormSections(): React.ReactElement[] {
        const baseElements: React.ReactElement[] = [];
        baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="accessKey" errorKey="accessKey" title="Access Key" summary={this.accessKeySummary()} help="The AWS access key to use when authenticating against Amazon Web Services.">
                <Text value={this.state.model.accessKey} onChange={(accessKey) => this.setModelState({ accessKey })} label="Access Key" error={this.getFieldError("AccessKey")}/>
                <Note>
                    Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for information on generating access and secret keys.
                </Note>
            </ExpandableFormSection>, <ExpandableFormSection key="secretKey" errorKey="secretKey" title="Secret Key" summary={this.secretKeySummary()} help="The AWS secret key to use when authenticating against Amazon Web Services.">
                <Sensitive value={this.state.model.secretKey} onChange={(secretKey) => this.setModelState({ secretKey })} label="Secret Key" error={this.getFieldError("SecretKey")}/>
            </ExpandableFormSection>);
        return baseElements;
    }
}
export default AmazonWebServicesAccountEdit;
