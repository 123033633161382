import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { sortBy } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button/NavigationButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { List } from "~/components/List/List";
import MarkdownDescription from "~/components/MarkdownDescription";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import Tag from "~/components/Tag/index";
import { withTheme } from "~/components/Theme";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ActionList from "../../../../components/ActionList/ActionList";
import OpenDialogButton from "../../../../components/Dialog/OpenDialogButton";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import Onboarding from "./Onboarding";
import TagSetsSorter from "./TagSetsSorter";
import styles from "./style.module.less";
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
interface TagSetListState extends DataBaseComponentState {
    tagSetsResponse?: ResourceCollection<TagSetResource>;
}
class TagSetsResourceList extends List<TagSetResource> {
}
type Props = GlobalConnectedProps & RouteComponentProps;
class TagSetsList extends DataBaseComponent<Props, TagSetListState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await timeOperation(timeOperationOptions.forInitialLoad(), () => this.loadData());
    }
    renderOnBoarding() {
        return <Onboarding />;
    }
    renderTagSets() {
        return (<div>
                <TagSetsResourceList initialData={this.state.tagSetsResponse} onRow={(tagSet) => this.buildTagSetsRow(tagSet)} match={this.props.match} onFilter={this.filterTagSets} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>
            </div>);
    }
    render() {
        if (!this.props.isMultiTenancyEnabled) {
            return <InternalRedirect to={routeLinks.library.root}/>;
        }
        return (<LibraryLayout {...this.props}>
                <PaperLayout title="Tenant Tag Sets" sectionControl={this.buildSectionControl()} busy={this.state.busy} errors={this.errors}>
                    {!this.state.tagSetsResponse ? <div /> : this.state.tagSetsResponse.TotalResults > 0 ? this.renderTagSets() : this.renderOnBoarding()}
                </PaperLayout>
            </LibraryLayout>);
    }
    private buildTagSetsRow(tagSet: TagSetResource) {
        return (<div>
                <ListTitle>{tagSet.Name}</ListTitle>
                <div className={styles.helpText}>
                    <MarkdownDescription markup={tagSet.Description}/>
                </div>
                <div>{sortBy(tagSet.Tags, (t) => t.SortOrder).map((t) => withTheme((theme) => <Tag tagName={t.Name} tagColor={t.Color} key={t.Id} description={t.Description} borderColor={theme.divider}/>))}</div>
            </div>);
    }
    private filterTagSets(filter: string, resource: TagSetResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildSortDialog = () => {
        return (this.state.tagSetsResponse &&
            this.state.tagSetsResponse.Items.length > 0 && (<OpenDialogButton label="Reorder">
                    <TagSetsSorter onSaved={this.loadData}/>
                </OpenDialogButton>));
    };
    private buildSectionControl = () => {
        const actions = [this.buildSortDialog(), <NavigationButton type={NavigationButtonType.Primary} label="Add Tag Set" href={`${this.props.match.url}/create`}/>];
        return (<PermissionCheck permission={Permission.TagSetCreate}>
                <ActionList actions={actions}/>
            </PermissionCheck>);
    };
    private loadData = async () => {
        await this.doBusyTask(async () => {
            this.setState({ tagSetsResponse: await repository.TagSets.list() });
        });
    };
    static displayName = "TagSetsList";
}
const mapGlobalStateToProps = (state: GlobalState, props: RouteComponentProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
export default connect(mapGlobalStateToProps)(TagSetsList);
