/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
import * as React from "react";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import type { TextInput } from "../../primitiveComponents/form/Text/Text";
import type { BoundFieldProps } from "../Actions/pluginRegistry";
import ActionButton from "../Button/ActionButton";
import { RemoveItemsList } from "../RemoveItemsList/RemoveItemsList";
import { VariableLookupText } from "../form/VariableLookupText";
class StringRemoveItemsList extends RemoveItemsList<string> {
}
interface SomethingProps {
    items: string;
    name?: string;
    label: string;
    placeholder?: string;
    onChange(items: string): void;
}
interface SomethingState {
    source: string[];
}
export default class StringEditList extends React.PureComponent<SomethingProps & BoundFieldProps, SomethingState> {
    adding = false;
    constructor(props: SomethingProps & BoundFieldProps) {
        super(props);
        this.state = {
            source: [],
        };
    }
    componentDidMount() {
        this.setInternalState(this.props);
    }
    setInternalState(props: SomethingProps) {
        this.setState({ source: ParseHelper.parseCSV(this.props.items || "") });
    }
    handleRemoveRow = (item: any) => {
        const data = this.state.source;
        data.splice(data.indexOf(item), 1);
        this.invokeOnChange(data);
    };
    handleRowRef = (idx: number) => (input: TextInput | null) => {
        if (input && this.adding && idx === 0) {
            input.focus();
            this.adding = false;
        }
    };
    handleRowChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx] = val;
        this.invokeOnChange(data);
    };
    invokeOnChange = (data: any) => {
        this.props.onChange(ParseHelper.encodeCSV(data.filter((t: string) => t != null && t !== "")));
    };
    handleAddClick = () => {
        const data = this.state.source;
        this.adding = true;
        this.setState({ source: ["", ...data] });
    };
    renderRow = (item: any, idx: number) => {
        const data = this.state.source;
        const warning = data.filter((t) => t === item).length > 1 ? "This value has already been added" : null;
        return (<VariableLookupText localNames={this.props.localNames} textInputRef={this.handleRowRef(idx)} key={"KEYY-" + idx} value={item} onChange={this.handleRowChange(idx)} warning={warning} label={this.props.label} placeholder={this.props.placeholder}/>);
    };
    render() {
        const name = this.props.name || this.props.label;
        const actionButton = <ActionButton key="Add" label={`Add ${name}`} onClick={this.handleAddClick}/>;
        return <StringRemoveItemsList listActions={[actionButton]} data={this.state.source} onRemoveRow={this.handleRemoveRow} onRow={this.renderRow} clearButtonToolTip={`Remove ${name}`}/>;
    }
    static displayName = "StringEditList";
}
