import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryStepTemplateOverview, CommonLibraryStepTemplateTopics } from "../../CommonComponents/CommonLibraryStepTemplateViews";
export const LibraryStepTemplatesInstalledStepRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.stepTemplates.installedStep,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryStepTemplateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryStepTemplateTopics />;
    },
};
