/* eslint-disable @typescript-eslint/no-explicit-any */
import { logger } from "@octopusdeploy/logging";
import * as PropTypes from "prop-types";
import * as React from "react";
import RadioButton from "./RadioButton";
// import {PropTypes} from "prop-types";
import styles from "./style.module.less";
interface RadioButtonGroupProps {
    /**
     * The CSS class name of the root element.
     */
    className: string;
    /**
     * The `value` property of the radio button that will be
     * selected by default. This takes precedence over the `checked` property
     * of the `RadioButton` elements.
     */
    defaultSelected?: any;
    /**
     * Where the label will be placed for all child radio buttons.
     * This takes precedence over the `labelPosition` property of the
     * `RadioButton` elements.
     */
    labelPosition?: "left" | "right";
    /**
     * Name that will be applied to the radio group aria-label property.
     */
    accessibleName?: string;
    /**
     * Callback function that is fired when a radio button has
     * been checked.
     *
     * @param {object} event `change` event targeting the selected
     * radio button.
     * @param {*} value The `value` of the selected radio button.
     */
    onChange: any;
    /**
     * Override the inline-styles of the root element.
     */
    style?: object;
    /**
     * The `value` of the currently selected radio button.
     */
    valueSelected: any;
    floatingLabelText: string | JSX.Element;
    autoFocus?: boolean;
}
// modified to handle children which aren't a radio button
//eslint-disable-next-line react/no-unsafe
class RadioButtonGroup extends React.Component<RadioButtonGroupProps, any> {
    public static defaultProps: Partial<RadioButtonGroupProps> = {
        style: {},
    };
    public static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };
    constructor(props: RadioButtonGroupProps) {
        super(props);
        let cnt = 0;
        let selected = "";
        const { valueSelected, defaultSelected } = this.props;
        if (valueSelected !== undefined) {
            selected = valueSelected;
        }
        else if (defaultSelected !== undefined) {
            selected = defaultSelected;
        }
        React.Children.forEach(this.props.children, (option) => {
            if (!!option && this.hasCheckAttribute(option)) {
                cnt++;
            }
        });
        this.state = {
            numberCheckedRadioButtons: cnt,
            selected,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps.hasOwnProperty("valueSelected")) {
            this.setState({
                selected: nextProps.valueSelected,
            });
        }
    }
    hasCheckAttribute(radioButton: any) {
        return radioButton.props && radioButton.props.hasOwnProperty("checked") && radioButton.props.checked;
    }
    updateRadioButtons(newSelection: any) {
        if (this.state.numberCheckedRadioButtons === 0) {
            this.setState({ selected: newSelection });
        }
        else {
            logger.warn(`Material-UI: Cannot select a different radio button while another radio button
        has the "checked" property set to true.`);
        }
    }
    handleChange = (event: any, newSelection: any) => {
        this.updateRadioButtons(newSelection);
        // Successful update
        if (this.state.numberCheckedRadioButtons === 0) {
            if (this.props.onChange) {
                this.props.onChange(event, newSelection);
            }
        }
    };
    getSelectedValue() {
        return this.state.selected;
    }
    setSelectedValue(newSelectionValue: any) {
        this.updateRadioButtons(newSelectionValue);
    }
    clearValue() {
        this.setSelectedValue("");
    }
    isRadioButton(element: any) {
        return element.props && element.props.hasOwnProperty("checked");
    }
    render() {
        const { prepareStyles } = this.context.muiTheme;
        const autoFocus = this.props.autoFocus;
        const options = React.Children.map(this.props.children, (child: any) => {
            if (!child || !this.isRadioButton(child)) {
                return child;
            }
            const { name, value, label, onCheck, ...other } = child.props;
            const checked = child.props.value === this.state.selected;
            return (<RadioButton {...other} className={styles.radioButton} ref={child.props.value} key={child.props.value} value={child.props.value} label={child.props.label} labelPosition={this.props.labelPosition} onCheck={this.handleChange} checked={checked} autoFocus={checked && autoFocus}/>);
        });
        return (<div role="radiogroup" aria-label={this.props.accessibleName} style={prepareStyles({ ...this.props.style })} className={this.props.className}>
                {options}
            </div>);
    }
    static displayName = "RadioButtonGroup";
}
export default RadioButtonGroup;
