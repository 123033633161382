import { ActionHandlerCategory, ProcessType } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateCategoryResource, ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import { Environment } from "@octopusdeploy/utilities";
import * as React from "react";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import CommunityActionTemplateList from "~/components/ActionTemplates/CommunityActionTemplateList";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { createSampleStepActionTemplateResource } from "~/components/Actions/sample/SampleStepPackage";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import Section from "~/components/Section";
import routeLinks from "~/routeLinks";
import { withProcessContext } from "../Contexts/ProcessContext";
import type { WithProcessContextInjectedProps } from "../Contexts/ProcessContext";
import { InstalledActionTemplateList } from "./InstalledActionTemplateList";
import styles from "./style.module.less";
interface ActionTemplateSelectorState extends DataBaseComponentState {
    isLoaded: boolean;
    templates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    childActionTypes: string[];
    filterText?: string;
    showCommunityTemplates: boolean;
    selectedCategory?: ActionHandlerCategory;
}
export type ActionTemplateSelectorProps = {
    runbookId?: string;
    processId: string;
    parentStepId?: string;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
} & WithProcessContextInjectedProps & WithProjectContextInjectedProps;
class ActionTemplateSelector extends DataBaseComponent<ActionTemplateSelectorProps, ActionTemplateSelectorState> {
    constructor(props: ActionTemplateSelectorProps) {
        super(props);
        this.state = {
            isLoaded: false,
            templates: [],
            categories: [],
            childActionTypes: [],
            showCommunityTemplates: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const actionsPlugins = pluginRegistry.getAllActions();
            const categories = repository.ActionTemplates.categories();
            const templates = fetchTemplates();
            const childActionTypes = actionsPlugins.filter((action) => action.canBeChild).map((action) => action.actionType);
            this.setState({
                isLoaded: true,
                categories: await categories,
                templates: await templates,
                childActionTypes,
            });
        });
    }
    render() {
        const communityTemplates = this.state.templates.filter((at) => !at.IsInstalled);
        return (<PaperLayout title="Choose Step Template" busy={this.state.busy} errors={this.errors} fullWidth={true} flatStyle={true} hideHelpIcon={true} disableStickyHeader={true}>
                {this.state.isLoaded && (<div className={styles.container}>
                        <Section>
                            <FilterSearchBox placeholder="Filter by name, category or description..." onChange={(value) => this.setState({ filterText: value.toLowerCase() })} fullWidth={true} containerClassName={styles.filterSearchBoxContainer} autoFocus={true} debounceDelay={500}/>
                        </Section>
                        <InstalledActionTemplateList categories={this.state.categories} templates={this.installedTemplates()} communityTemplates={communityTemplates} onDetailsUrlRequested={this.getDetailsUrl} onPostSelectionUrlRequested={this.getNewStepUrl} filter={this.state.filterText} onCategorySelected={async (category: ActionHandlerCategory) => {
                    this.setState({ selectedCategory: category });
                }}/>
                        {this.shouldShowCommunityTemplateList(this.state.selectedCategory) && (<CommunityActionTemplateList templates={communityTemplates} filter={this.state.filterText ? this.state.filterText : this.state.selectedCategory && this.state.selectedCategory !== ActionHandlerCategory.Community ? this.state.selectedCategory.toLowerCase() : undefined} isFilterFromCategory={!!this.state.selectedCategory} installationActionName="Install and add" onDetailsUrlRequested={this.getDetailsUrl} onPostSelectionUrlRequested={this.getNewStepUrl}/>)}
                    </div>)}
            </PaperLayout>);
    }
    private shouldShowCommunityTemplateList(category: ActionHandlerCategory | undefined): boolean {
        if (this.state.filterText) {
            return true;
        }
        return !!category && category !== ActionHandlerCategory.BuiltInStep && category !== ActionHandlerCategory.StepTemplate && category !== ActionHandlerCategory.Other;
    }
    private installedTemplates(): ActionTemplateSearchResource[] {
        const installed = this.state.templates.filter((at) => at.IsInstalled);
        const parentStepId = this.props.parentStepId;
        if (!parentStepId) {
            return installed;
        }
        return installed.filter((at) => this.state.childActionTypes.includes(at.Type));
    }
    private getDetailsUrl = (template: ActionTemplateSearchResource): string => {
        return routeLinks.library.stepTemplates.communityTemplate(template, this.props.projectContext.state.model.Slug).root;
    };
    private getNewStepUrl = (template: {
        Type: string;
        Id: string;
    }): string => {
        //TODO: ActionTemplateSelector can be made agnostic if we make getNewStepUrl a callback
        const projectSlug = this.props.projectContext.state.model.Slug;
        const { processId } = this.props;
        const runbookId = this.props.runbookId;
        return this.props.processContext.selectors.getProcessType() === ProcessType.Runbook && !!runbookId
            ? routeLinks.project(projectSlug).operations.runbook(runbookId).runbookProcess.runbookProcess(processId).process.new(template.Type, this.props.parentStepId, template.Id)
            : routeLinks.projectBranch(projectSlug, this.props.projectContext.state.gitRef).deploymentProcess.new(template.Type, this.props.parentStepId, template.Id);
    };
    static displayName = "ActionTemplateSelector";
}
async function fetchTemplates(): Promise<ActionTemplateSearchResource[]> {
    const templates = await repository.ActionTemplates.search();
    if (Environment.isInDevelopmentMode()) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const inDevelopmentTemplates = [createSampleStepActionTemplateResource(repository.spaceId!)];
        const templateIds = new Set(templates.map((t) => t.Type));
        const inDevelopmentTemplatesThatArentReturnedByServer = inDevelopmentTemplates.filter((t) => !templateIds.has(t.Type));
        return [...templates, ...inDevelopmentTemplatesThatArentReturnedByServer];
    }
    return templates;
}
const EnhancedActionTemplateSelecotr = withProjectContext(withProcessContext(ActionTemplateSelector));
export type EnhancedActionTemplateSelectorProps = Parameters<typeof EnhancedActionTemplateSelecotr>[0];
export default EnhancedActionTemplateSelecotr;
