import type { RunbookResource, RunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks/useRequiredContext";
export type RunbookContextProps = ReturnType<typeof useRunbookSetup>;
const RunbookContext = React.createContext<RunbookContextProps | undefined>(undefined);
interface RunbookContextState {
    runbook: RunbookResource | undefined;
}
interface RunbookContextProviderProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (props: RunbookContextProps) => React.ReactNode;
}
const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<RunbookContextState>>) => {
    return {
        onRunbookUpdated: (runbook: RunbookResource) => {
            setState((current) => ({ ...current, runbook }));
        },
    };
};
const useRunbookSetup = (id: string, doBusyTask: DoBusyTask) => {
    const [state, setState] = React.useState<RunbookContextState>({
        runbook: undefined,
    });
    const projectContext = useProjectContext();
    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);
    const projectContextRepository = projectContext.state.projectContextRepository;
    const refreshRunbook = useDoBusyTaskEffect(doBusyTask, async () => {
        const runbook = await repository.Runbooks.get(id);
        updaters.onRunbookUpdated(runbook);
    }, [id, projectContextRepository]);
    const publishSnapshot = React.useCallback((snapshot: RunbookSnapshotResource) => {
        if (!snapshot) {
            return;
        }
        return doBusyTask(async () => {
            const result = await repository.Runbooks.get(id);
            result.PublishedRunbookSnapshotId = snapshot.Id;
            updaters.onRunbookUpdated(await repository.Runbooks.modify(result));
        });
    }, [doBusyTask, id, updaters]);
    const supportedActions = {
        refreshRunbook,
        publishSnapshot,
        ...updaters,
    };
    return {
        actions: supportedActions,
        state,
        setState,
    };
};
export const RunbookContextProvider: React.FC<RunbookContextProviderProps> = ({ children, id, doBusyTask }) => {
    const value = useRunbookSetup(id, doBusyTask);
    return <RunbookContext.Provider value={value}>{children(value)}</RunbookContext.Provider>;
};
RunbookContextProvider.displayName = "RunbookContextProvider"
export interface WithRunbookContextInjectedProps {
    runbookContext: RunbookContextProps;
}
export type WithOptionalRunbookContextInjectedProps = Partial<WithRunbookContextInjectedProps>;
export const withRunbookContext = <T>(Component: React.ComponentType<T & WithRunbookContextInjectedProps>) => {
    const WithRunbookContext: React.FC<T> = (props) => {
        const context = useRunbookContext();
        return <Component runbookContext={context} {...props}/>;
    };
    WithRunbookContext.displayName = "WithRunbookContext"
    return WithRunbookContext;
};
export const withOptionalRunbookContext = <T>(Component: React.ComponentType<T & WithOptionalRunbookContextInjectedProps>) => {
    const WithRunbookContext: React.FC<T> = (props) => {
        const context = useOptionalRunbookContext();
        return <Component runbookContext={context} {...props}/>;
    };
    WithRunbookContext.displayName = "WithRunbookContext"
    return WithRunbookContext;
};
export const useRunbookContext = () => {
    return useRequiredContext(RunbookContext, "Runbook");
};
export const useOptionalRunbookContext = () => {
    return React.useContext(RunbookContext);
};
