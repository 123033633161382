import * as React from "react";
import { useQueryStringParam } from "~/hooks/useQueryStringParam";
import { ControlledTabsContainer } from "./ControlledTabsContainer";
import type { RenderTabCallback } from "./useTabConversion";
export interface UrlNavigationTabsContainerProps {
    defaultValue: string;
    urlParameterName?: string;
    renderTab?: RenderTabCallback;
}
export const UrlNavigationTabsContainer: React.FC<UrlNavigationTabsContainerProps> = ({ defaultValue, urlParameterName = "activeTab", renderTab, children }) => {
    const [value, setValue] = useQueryStringParam(urlParameterName);
    return (<ControlledTabsContainer renderTab={renderTab} value={value ?? defaultValue} onChange={setValue}>
            {children}
        </ControlledTabsContainer>);
};
UrlNavigationTabsContainer.displayName = "UrlNavigationTabsContainer"
export default UrlNavigationTabsContainer;
