import type { EnvironmentsSummaryResource, EnvironmentSummaryResource } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import FilterSearchBox from "~/components/FilterSearchBox";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import routeLinks from "~/routeLinks";
import { ResultsLink } from "../ResultsLink";
import styles from "../style.module.less";
import { EnvironmentSummaryContentRow } from "./EnvironmentsContentRows";
interface EnvironmentsContentWithThemeProps {
    summaryResource: EnvironmentsSummaryResource;
    numberOfEnvironmentsToRender: number;
    theme: OctopusTheme;
}
const onFilter = (filter: string, resource: EnvironmentSummaryResource) => {
    return !filter || filter.length === 0 || !resource || resource.Environment.Name.toLowerCase().includes(filter.toLowerCase());
};
const EnvironmentsContentWithTheme = ({ summaryResource, numberOfEnvironmentsToRender, theme }: EnvironmentsContentWithThemeProps) => {
    const [filterText, setFilterText] = useState<string>("");
    const filteredEnvironmentSummaries = summaryResource.EnvironmentSummaries.filter((item: EnvironmentSummaryResource) => onFilter(filterText, item)).slice(0, numberOfEnvironmentsToRender);
    return (<>
            {(filteredEnvironmentSummaries.length > 1 || filterText) && (<FilterSearchBox placeholder="Filter..." onChange={(value) => setFilterText(value.toLowerCase())} containerClassName={styles.cardContentContainerFilter} inputClassName={styles.dashboardFilterInputClassName} iconColor={theme.secondaryText} iconStyle={{ width: "20px", height: "20px", top: "14px" }}/>)}
            <div className={styles.cardContentContainerRows}>
                {filteredEnvironmentSummaries.map((environmentSummary: EnvironmentSummaryResource) => {
            return <EnvironmentSummaryContentRow key={environmentSummary.Environment.Id} environmentSummary={environmentSummary}/>;
        })}
                {<ResultsLink totalCount={summaryResource.EnvironmentSummaries.length} renderedCount={filteredEnvironmentSummaries.length} link={routeLinks.infrastructure.environments.root}/>}
            </div>
        </>);
};
interface EnvironmentsContentProps {
    summaryResource: EnvironmentsSummaryResource;
    numberOfEnvironmentsToRender: number;
}
export const EnvironmentsContent = (props: EnvironmentsContentProps) => {
    const theme = useOctopusTheme();
    return <EnvironmentsContentWithTheme {...props} theme={theme}/>;
};
