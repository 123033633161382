import type { DeploymentFrequencyDeployment, DeploymentFrequencyTrends } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import { CommonSparklineTrends } from "./CommonSparklineTrends";
import { frequencyTrendColors, SparklineTrendPair } from "./SparklineTrendPair/SparklineTrendPair";
import { TrendTable } from "./TrendTable/TrendTable";
interface Props {
    trends: DeploymentFrequencyTrends;
}
export function DeploymentFrequencyTrendTables({ trends }: Props) {
    return (<>
            <CommonSparklineTrends trends={trends} colorLookup={frequencyTrendColors} metricDescription={<>
                        weekly average <ExternalLink href="InsightsDeploymentFrequency">deployment frequency</ExternalLink>
                    </>}/>
            {trends.ProjectsWithLowestFrequency && (<SparklineTrendPair sectionTitle="Projects with lowest frequency" leftData={trends.ProjectsWithLowestFrequency} directionColorLookup={frequencyTrendColors} valueFormatter={(val) => (val ? val.toLocaleString() : 0)} note={<>
                            The projects that had the lowest deployment frequency in the last 28 days. The first 10 in descending order by number of deployments are shown. The value shown is the weekly average{" "}
                            <ExternalLink href="InsightsDeploymentFrequency">deployment frequency</ExternalLink> for the last 28 days.
                        </>}/>)}
            <DeploymentsTable title="Longest time since previously successful deployment" note="Project, environment and tenant combinations with their most recent deployment that matches the report filter. The first 10 in descending order by deployment completed time are shown." data={trends.LongestTimeSincePreviouslySuccessfulDeployment}/>
            <DeploymentsTable title="Most recent successful deployments" note="The most recent successful deployment for each project, environment and tenant combination. The first 10 in descending order by deployment completed time are shown." data={trends.MostRecentSuccessful} hideTimeSince/>
        </>);
}
interface DeploymentTableProps {
    title: string;
    note: string;
    data: DeploymentFrequencyDeployment[];
    hideTimeSince?: boolean;
}
function DeploymentsTable({ title, note, data, hideTimeSince }: DeploymentTableProps) {
    const hasTenants = data.some((d) => d.TenantName !== null);
    const hasTimeSinceDeployment = data.some((d) => d.TimeSincePreviouslySuccessfulDeployment !== null);
    return (<TrendTable title={title} isEmpty={data.length === 0} note={note} headerContent={<DataTableRow>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    {hasTenants && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    {hasTimeSinceDeployment && !hideTimeSince && <DataTableHeaderColumn>Time since successful deployment</DataTableHeaderColumn>}
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).root}>{r.ProjectName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.EnvironmentName}</DataTableRowColumn>
                            {hasTenants && <DataTableRowColumn>{r.TenantName ?? "Untenanted"}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <ToolTip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </ToolTip>
                            </DataTableRowColumn>
                            {hasTimeSinceDeployment && !hideTimeSince && <DataTableRowColumn>{formatDurationInWords(r.TimeSincePreviouslySuccessfulDeployment)}</DataTableRowColumn>}
                        </DataTableRow>))}
                </>}/>);
}
