import type { ActionTemplateUsageResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";
export const ActionTemplateUsageProcessLink: React.FC<{
    usage: ActionTemplateUsageResource;
}> = ({ usage }) => {
    switch (usage.ProcessType) {
        case ProcessType.Runbook:
            return <InternalLink to={routeLinks.project(usage.ProjectSlug).operations.runbook(usage.RunbookId).runbookProcess.runbookProcess(usage.ProcessId).process.root}>{`Runbook: ${usage.RunbookName}`}</InternalLink>;
        case ProcessType.Deployment:
            return <InternalLink to={routeLinks.project(usage.ProjectSlug).deployments.process.root}>{usage.ProcessType}</InternalLink>;
    }
    exhaustiveCheck(usage.ProcessType, "Not all process types have been handled.");
};
ActionTemplateUsageProcessLink.displayName = "ActionTemplateUsageProcessLink"
export const ActionTemplateUsageStepLink: React.FC<{
    usage: ActionTemplateUsageResource;
}> = ({ usage }) => {
    const projectSlug = usage.Branch ? `${usage.ProjectSlug}/branches/${encodeURIComponent(usage.Branch.substr("refs/heads/".length))}` : usage.ProjectSlug;
    return (<InternalLink to={usage.ProcessType === ProcessType.Deployment
            ? routeLinks.project(projectSlug).deployments.process.step(usage.ActionId)
            : routeLinks.project(usage.ProjectSlug).operations.runbook(usage.RunbookId).runbookProcess.runbookProcess(usage.ProcessId).process.step(usage.ActionId)} openInSelf={false}>
            {usage.ActionName}
        </InternalLink>);
};
ActionTemplateUsageStepLink.displayName = "ActionTemplateUsageStepLink"
