import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import routeLinks from "../../../../routeLinks";
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
class InternalLibraryLayout extends BaseComponent<GlobalConnectedProps & {}> {
    constructor(props: GlobalConnectedProps) {
        super(props);
    }
    render() {
        const navLinks = compact([
            Navigation.navItem("Certificates", routeLinks.library.certificates.root, undefined, { permission: Permission.CertificateView, wildcard: true }),
            Navigation.navItem("External Feeds", routeLinks.library.feeds, undefined, { permission: Permission.FeedView }),
            Navigation.navItem("Git Credentials", routeLinks.library.gitCredentials.root, undefined, { permission: [Permission.GitCredentialView], wildcard: true }),
            Navigation.navItem("Lifecycles", routeLinks.library.lifecycles, undefined, { permission: Permission.LifecycleView, wildcard: true }),
            Navigation.navItem("Packages", routeLinks.library.builtInRepository.root, undefined, { permission: Permission.FeedView }),
            Navigation.navItem("Build Information", routeLinks.library.buildInformationRepository.root, undefined, { permission: Permission.FeedView }),
            Navigation.navItem("Script Modules", routeLinks.library.scripts.root, undefined, { permission: Permission.VariableView, wildcard: true }),
            Navigation.navItem("Step Templates", routeLinks.library.stepTemplates.root, undefined, { permission: Permission.ActionTemplateView }),
            this.props.isMultiTenancyEnabled && Navigation.navItem("Tenant Tag Sets", routeLinks.library.tagSets.root, undefined, { permission: Permission.VariableView, wildcard: true }),
            Navigation.navItem("Variable Sets", routeLinks.library.variableSets, undefined, { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }),
        ]);
        return (<main id="maincontent">
                <AreaTitle link={routeLinks.library.root} title="Library"/>
                <NavigationSidebarLayout navLinks={navLinks} content={this.props.children}/>
            </main>);
    }
    static displayName = "InternalLibraryLayout";
}
export function InternalLibraryLayoutWithFeatureFlags(props: GlobalConnectedProps) {
    return <InternalLibraryLayout {...props}/>;
}
const mapGlobalStateToProps = (state: GlobalState, props: {}): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
export const LibraryLayout = connect(mapGlobalStateToProps)(InternalLibraryLayoutWithFeatureFlags);
export default LibraryLayout;
