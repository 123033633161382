/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ProjectResource, ChannelResource, DeploymentResource, TagSetResource, DeploymentPromotionTarget, DeploymentPromotionTenant, DeploymentTemplateResource, DynamicEnvironmentResource, EnvironmentResource, LatestReleaseResource, ReleaseResource, TagTestResult, TenantResource, } from "@octopusdeploy/octopus-server-client";
import { ProcessType, Permission, TaskState, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { compact } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import { ChipIcon, DynamicEnvironmentChip, EnvironmentChip, LookupTenantChip, TenantChip } from "~/components/Chips/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState, DoBusyTaskOptions } from "~/components/DataBaseComponent/DataBaseComponent";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { LookupResourceChipComponent } from "~/components/LookupResourceChip/LookupResourceChip";
import { DynamicEnvironmentMultiSelect } from "~/components/MultiSelect/DynamicEnvironmentMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import { Text } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import UnstructuredFormSection from "~/components/form/Sections/UnstructuredFormSection";
import { expanderActions } from "~/components/form/Sections/reducers/expanders";
import * as tenantTagsets from "~/components/tenantTagsets";
import { Callout } from "~/primitiveComponents/dataDisplay/Callout";
import { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup, { BooleanRadioButtonGroup, StringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import store from "~/store";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import { DeploymentCreateGoal } from "../../ReleasesRoutes/releaseRouteLinks";
import type { EnvironmentType } from "../EnvironmentType";
import type { DynamicEnvironmentSelection, EnvironmentSelection, StaticEnvironmentSelection } from "./EnvironmentSelection";
import HelpText from "./HelpText";
import SkipTenantsPanel from "./SkipTenantsPanel";
import styles from "./style.module.less";
enum DeploymentMode {
    Untenanted,
    Tenanted
}
interface EnvironmentAndTenantSelectorProps {
    project: ProjectResource;
    template: DeploymentTemplateResource;
    channel: ChannelResource;
    previousDeployment: DeploymentResource;
    tenantedDeploymentMode: TenantedDeploymentMode;
    tenantsWithMissingVariables: string[];
    release: ReleaseResource;
    latestReleases: LatestReleaseResource[];
    allTenants: TenantResource[];
    allEnvironments: EnvironmentResource[];
    allDynamicEnvironments: DynamicEnvironmentResource[];
    previousId?: string;
    goal?: string;
    tenantIds?: string;
    tags?: string;
    search?: string;
    onSelectionUpdated(environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean): void;
    onDoingBusyTask(action: () => Promise<any>, options: DoBusyTaskOptions): Promise<boolean>;
}
interface EnvironmentAndTenantSelectorState extends DataBaseComponentState {
    showEnvironmentsInitiallyExpanded: boolean;
    showTenantsInitiallyExpanded: boolean;
    selectedTenantIds: string[];
    tenantIdsFromQueryString: string[];
    selectedTenantTagIds: string[];
    tenantTagIdsFromQueryString: string[];
    selectedEnvironmentIds: string[];
    availableEnvironmentsForTenantPromotion: DeploymentPromotionTarget[];
    doAnyTenantsMatchProject: boolean;
    doAnyTenantsMatchChannel: boolean;
    availableTenants: TenantResource[];
    resultantTenants: TenantResource[];
    tagSets: TagSetResource[];
    tenantsWhoGotThisReleaseAsCurrent: TenantResource[];
    skipTenantsWhoHaveTheReleaseAsCurrent: boolean;
    showTenantsWhoGotThisReleaseAsCurrent: boolean;
    showSkipPanel: boolean;
    deployToAllAvailableTenants: boolean;
    deploymentMode: DeploymentMode;
    isTryingAgain: boolean;
    isShowingAdvancedTenantsOptions: boolean;
    environmentType: EnvironmentType;
    selectedDynamicEnvironmentIds: string[];
    createNewDynamicEnvironment: boolean;
    newDynamicEnvironmentName: string | undefined;
}
type InitialEnvironmentSelection = {
    environmentType: EnvironmentType;
    environmentIds: string[];
};
class EnvironmentAndTenantSelector extends DataBaseComponent<EnvironmentAndTenantSelectorProps, EnvironmentAndTenantSelectorState> {
    private deployToAllAvailableTenantsWarningThreshold: number = 10;
    private tenantTagTestRaceConditioner = new RequestRaceConditioner();
    constructor(props: EnvironmentAndTenantSelectorProps) {
        super(props);
        const initialEnvironments = this.getInitialEnvironments();
        this.state = {
            showEnvironmentsInitiallyExpanded: initialEnvironments.environmentIds.length === 0,
            showTenantsInitiallyExpanded: false,
            selectedTenantTagIds: [],
            selectedTenantIds: [],
            selectedEnvironmentIds: initialEnvironments.environmentType === "Static" ? initialEnvironments.environmentIds : [],
            availableEnvironmentsForTenantPromotion: [],
            doAnyTenantsMatchProject: false,
            doAnyTenantsMatchChannel: false,
            availableTenants: [],
            resultantTenants: [],
            tagSets: [],
            tenantsWhoGotThisReleaseAsCurrent: null!,
            skipTenantsWhoHaveTheReleaseAsCurrent: true,
            tenantIdsFromQueryString: [],
            tenantTagIdsFromQueryString: [],
            showTenantsWhoGotThisReleaseAsCurrent: false,
            showSkipPanel: true,
            deployToAllAvailableTenants: false,
            deploymentMode: DeploymentMode.Untenanted,
            isTryingAgain: false,
            isShowingAdvancedTenantsOptions: false,
            environmentType: initialEnvironments.environmentType,
            selectedDynamicEnvironmentIds: initialEnvironments.environmentType === "Dynamic" ? initialEnvironments.environmentIds : [],
            createNewDynamicEnvironment: initialEnvironments.environmentType === "Static",
            newDynamicEnvironmentName: undefined,
        };
    }
    getInitialEnvironments(): InitialEnvironmentSelection {
        let environmentIds: string[] = [];
        if (this.props.previousDeployment && this.props.goal === DeploymentCreateGoal.TryAgain) {
            environmentIds = [this.props.previousDeployment.EnvironmentId];
        }
        else if (this.props.goal === DeploymentCreateGoal.To) {
            environmentIds = (this.props.previousId || "").split(",").filter((id: string) => id !== "");
        }
        if (this.props.allDynamicEnvironments.find((env) => environmentIds.includes(env.Id)) !== undefined) {
            return {
                environmentType: "Dynamic",
                environmentIds,
            };
        }
        else {
            return {
                environmentType: "Static",
                environmentIds,
            };
        }
    }
    async componentDidMount() {
        if (this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            this.notifyParentForSelectionChangedFromState();
            return;
        }
        const [tenantIdsFromQueryString, tenantTagIdsFromQueryString, isTryingAgain] = this.props.previousDeployment && this.props.goal === DeploymentCreateGoal.TryAgain ? [[this.props.previousDeployment.TenantId!], [], true] : [this.loadTenantIdsFromQueryString(), this.loadTenantTagsFromQueryString(), false];
        const availableEnvironmentsForTenantPromotion = this.loadAvailableEnvironmentsForTenant();
        const tagSetsPromise = tenantTagsets.getAll();
        const tenantsWhoMatchProject = this.props.allTenants.filter((tenant) => Object.keys(tenant.ProjectEnvironments).includes(this.props.project.Id)).map((tenant) => tenant.Id);
        const tenantsWhoMatchChannel = this.props.channel.TenantTags.length > 0
            ? this.determineTagsMatchedTenantIds(await repository.Tenants.tagTest([], this.props.channel.TenantTags)).filter((tenantId) => tenantsWhoMatchProject.includes(tenantId))
            : tenantsWhoMatchProject.slice();
        const availableTenants = this.getTenantsWhoCanBePromoted(this.state.selectedEnvironmentIds, this.props.allTenants);
        const selectedTenantIds = compact(tenantIdsFromQueryString.filter((tenantId) => availableTenants.some((t) => t.Id === tenantId)));
        const canOnlyDeployTenanted = this.canOnlyDeployTenanted();
        // Auto-select the tenant for them if they only have one choice.
        if (selectedTenantIds.length === 0 && canOnlyDeployTenanted && availableTenants.length === 1) {
            selectedTenantIds.push(availableTenants[0].Id);
        }
        const selectedTenantTagIds = canOnlyDeployTenanted && this.props.channel.TenantTags.length > 0
            ? tenantTagIdsFromQueryString.length === 0 && tenantIdsFromQueryString.length === 0
                ? this.props.channel.TenantTags
                : tenantTagIdsFromQueryString
            : tenantTagIdsFromQueryString;
        const tagsMatchedTenants = await this.loadTagMatched(availableTenants, selectedTenantIds, selectedTenantTagIds);
        const tenantsWhoGotThisReleaseAsCurrent = this.getTenantsWhoHaveThisReleaseAsCurrent(tagsMatchedTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
        // By default, only deploy to those tenants who haven't got this release as their current release,
        // dashboard status will be ignored for try again deployment
        const resultantTenants = isTryingAgain ? tagsMatchedTenants : tagsMatchedTenants.filter((t) => this.getTenantIdsWhoDoNotHaveThisRelease(tagsMatchedTenants, tenantsWhoGotThisReleaseAsCurrent).some((tt) => tt === t.Id));
        const showTenantsInitiallyExpanded = this.recommendedTenantDeploymentOption(selectedTenantTagIds) === DeploymentMode.Tenanted ? true : (tenantIdsFromQueryString.length > 0 || tenantTagIdsFromQueryString.length > 0) && resultantTenants.length === 0;
        if (showTenantsInitiallyExpanded) {
            this.expandTheTenantExpander();
        }
        const deploymentMode = this.recommendedTenantDeploymentOption(selectedTenantTagIds, selectedTenantIds);
        this.setState({
            availableEnvironmentsForTenantPromotion,
            selectedTenantTagIds,
            selectedTenantIds,
            doAnyTenantsMatchChannel: tenantsWhoMatchChannel.length > 0,
            doAnyTenantsMatchProject: tenantsWhoMatchProject.length > 0,
            availableTenants,
            resultantTenants,
            showTenantsInitiallyExpanded,
            tagSets: await tagSetsPromise,
            tenantsWhoGotThisReleaseAsCurrent,
            tenantIdsFromQueryString,
            tenantTagIdsFromQueryString,
            deploymentMode,
            isTryingAgain,
        }, () => this.notifyParentForSelectionChangedFromState());
    }
    render() {
        return (<div className={styles.envAndTenantSelectorContainer}>
                {this.props.template && this.props.project && (<div>
                        <ExpandableFormSection title="Environments" errorKey="environments" help={this.areTenantsRequiredForThisProject() ? "Select an environment" : "Select one or many environments"} isExpandedByDefault={this.state.showEnvironmentsInitiallyExpanded} summary={this.buildEnvironmentSummary()}>
                            {this.areTenantsRequiredForThisProject() ? (<Select value={this.state.selectedEnvironmentIds[0]} items={this.props.template.PromoteTo.map((e: DeploymentPromotionTarget) => {
                        return { text: e.Name, value: e.Id };
                    })} onChange={(id) => this.onStaticEnvironmentsSelected([id!])}/>) : (<FeatureToggleVisibility toggle="DynamicEnvironmentsFeatureToggle" disabledContent={this.getStaticEnvironmentMultiSelect()}>
                                    <StringRadioButtonGroup accessibleName="Environment Type" label="Which type of environment would you like to deploy to?" value={this.state.environmentType} onChange={(newValue) => this.setEnvironmentType(newValue)}>
                                        <RadioButton label="Static" value="Static" isDefault={true}/>
                                        <RadioButton label="Dynamic" value="Dynamic"/>
                                    </StringRadioButtonGroup>
                                    {this.state.environmentType === "Static" && this.getStaticEnvironmentMultiSelect()}
                                    {this.state.environmentType === "Dynamic" && (<BooleanRadioButtonGroup accessibleName="Create new dynamic environment" label="Would you like to create a new dynamic environment to deploy to?" value={this.state.createNewDynamicEnvironment} onChange={(newValue) => this.setCreateNewDynamicEnvironment(newValue)}>
                                            <RadioButton label="Create new" value={true}/>
                                            <RadioButton label="Select existing" value={false}/>
                                        </BooleanRadioButtonGroup>)}
                                    {this.state.environmentType === "Dynamic" && this.state.createNewDynamicEnvironment && (<Text accessibleName="New dynamic environment name" label="Dynamic environment name" value={this.state.newDynamicEnvironmentName || ""} onChange={(newValue) => this.setNewDynamicEnvironmentName(newValue)}/>)}
                                    {this.state.environmentType === "Dynamic" && !this.state.createNewDynamicEnvironment && (<DynamicEnvironmentMultiSelect accessibleName="Dynamic environments that this release will be deployed to" onChange={this.onDynamicEnvironmentsSelected} value={this.state.selectedDynamicEnvironmentIds} items={this.props.allDynamicEnvironments}/>)}
                                </FeatureToggleVisibility>)}
                        </ExpandableFormSection>
                        {this.isMultiTenancyEnabledForThisProject() &&
                    (!this.canDeploymentBeTenanted() ? (this.explainLackOfTenantSelection()) : (<ExpandableFormSection title="Tenants" errorKey="tenants" help="Select one or many tenants" summary={this.buildTenantSummary()}>
                                    {!this.canOnlyDeployTenanted() && this.buildTenantedOrUntenantedRadioGroup()}
                                    {this.state.deploymentMode === DeploymentMode.Tenanted && (<div>
                                            <HelpText channel={this.props.channel} allTenants={this.props.allTenants} availableTenants={this.state.availableTenants} tenantsWithMissingVariables={this.props.tenantsWithMissingVariables} tenantedDeploymentMode={this.props.tenantedDeploymentMode} availableEnvironmentsForTenantPromotion={this.state.availableEnvironmentsForTenantPromotion} doAnyTenantsMatchChannel={this.state.doAnyTenantsMatchChannel} doAnyTenantsMatchProject={this.state.doAnyTenantsMatchProject} processType={ProcessType.Deployment}/>
                                            {this.state.availableTenants && this.state.availableTenants.length > 0 && (<div>
                                                    {this.props.channel.TenantTags.length > 0 && (<div className={styles.channelCallout}>
                                                            <Callout type={CalloutType.Information} title={"Tenant selection limited"}>
                                                                Only the tenants that match the <InternalLink to={routeLinks.project(this.props.project).channel(this.props.channel)}>{this.props.channel.Name}</InternalLink> channel settings are
                                                                available for selection.
                                                            </Callout>
                                                        </div>)}
                                                    {!this.state.isShowingAdvancedTenantsOptions && (<ActionButton type={ActionButtonType.Secondary} label={"Advanced selection options"} onClick={() => this.setState({ isShowingAdvancedTenantsOptions: true })}/>)}
                                                    {this.state.isShowingAdvancedTenantsOptions && (<React.Fragment>
                                                            <Checkbox label={`Include all connected tenants (${this.state.availableTenants.length})`} value={this.state.deployToAllAvailableTenants} onChange={this.toggleAllApplicableTenants}/>
                                                            {this.state.deployToAllAvailableTenants && (<Callout type={this.state.availableTenants.length > this.deployToAllAvailableTenantsWarningThreshold ? CalloutType.Warning : CalloutType.Information} title={`${this.state.availableTenants.length} connected tenants`}>
                                                                    This will deploy to {this.state.availableTenants.length > 1 ? <strong>all {this.state.availableTenants.length} tenants</strong> : <strong>1 tenant</strong>}. Please make sure you
                                                                    review the Preview section below before deploying.
                                                                </Callout>)}
                                                        </React.Fragment>)}
                                                    {!this.state.deployToAllAvailableTenants && (<AdvancedTenantsAndTenantTagsSelector tenants={this.state.availableTenants} selectedTenantIds={this.state.selectedTenantIds} selectedTenantTags={this.state.selectedTenantTagIds} doBusyTask={this.doBusyTask} onChange={this.onTenantsOrTenantTagsSelected} showPreviewButton={true}/>)}
                                                    <SkipTenantsPanel release={this.props.release} selectedEnvironmentIds={this.state.selectedEnvironmentIds} showSkipPanel={this.state.showSkipPanel} tenantsWhoGotThisReleaseAsCurrent={this.state.tenantsWhoGotThisReleaseAsCurrent} showTenantsWhoGotThisReleaseAsCurrent={this.state.showTenantsWhoGotThisReleaseAsCurrent} skipTenantsWhoHaveTheReleaseAsCurrent={this.state.skipTenantsWhoHaveTheReleaseAsCurrent} allEnvironments={this.props.allEnvironments} onTenantFilterRuleChange={this.onTenantFilterRuleChange} onTenantsToggled={() => this.setState((prev) => ({
                                    showTenantsWhoGotThisReleaseAsCurrent: !prev.showTenantsWhoGotThisReleaseAsCurrent,
                                }))}/>
                                                </div>)}
                                        </div>)}
                                </ExpandableFormSection>))}
                    </div>)}
            </div>);
    }
    private getStaticEnvironmentMultiSelect() {
        return <EnvironmentMultiSelect accessibleName="Environments that this release will be deployed to" onChange={this.onStaticEnvironmentsSelected} value={this.state.selectedEnvironmentIds} items={this.props.template.PromoteTo}/>;
    }
    private notifyParentForSelectionChangedFromState(resultantTenantIds?: string[]) {
        const environments: EnvironmentSelection[] = [];
        if (this.state.environmentType === "Static") {
            const staticEnvironments = this.state.selectedEnvironmentIds.map<StaticEnvironmentSelection>((environmentId) => {
                return { type: "Static", environmentId: environmentId, name: this.props.allEnvironments.find((e) => e.Id === environmentId)?.Name || "(unknown environment)" };
            });
            environments.push(...staticEnvironments);
        }
        else if (this.state.createNewDynamicEnvironment && this.state.newDynamicEnvironmentName) {
            environments.push({
                type: "New",
                name: this.state.newDynamicEnvironmentName,
            });
        }
        else {
            const dynamicEnvironments = this.state.selectedDynamicEnvironmentIds.map<DynamicEnvironmentSelection>((environmentId) => {
                return { type: "Dynamic", environmentId: environmentId, name: this.props.allDynamicEnvironments.find((e) => e.Id === environmentId)?.Name || "(unknown environment)" };
            });
            environments.push(...dynamicEnvironments);
        }
        this.notifyParentForSelectionChanged(environments, resultantTenantIds ?? this.state.resultantTenants.map((t) => t.Id), this.state.selectedTenantTagIds.length > 0);
    }
    private setEnvironmentType(environmentType: string) {
        if (environmentType !== "Static" && environmentType !== "Dynamic")
            throw new Error("Unknown environment type");
        this.setState({
            environmentType: environmentType,
        }, () => {
            this.notifyParentForSelectionChangedFromState();
        });
    }
    private setCreateNewDynamicEnvironment(createNewDynamicEnvironment: boolean) {
        this.setState({
            createNewDynamicEnvironment: createNewDynamicEnvironment,
        }, () => {
            this.notifyParentForSelectionChangedFromState();
        });
    }
    private setNewDynamicEnvironmentName(newDynamicEnvironmentName: string) {
        this.setState({
            newDynamicEnvironmentName: newDynamicEnvironmentName,
        }, () => {
            this.notifyParentForSelectionChangedFromState();
        });
    }
    private async setMatchedTenants() {
        await this.props.onDoingBusyTask(async () => {
            await this.tenantTagTestRaceConditioner.avoidStaleResponsesForRequest(this.loadTagMatched(this.state.availableTenants, this.state.selectedTenantIds, this.state.selectedTenantTagIds), (resultantTenants) => {
                const tenantsWhoGotThisReleaseAsCurrent = this.getTenantsWhoHaveThisReleaseAsCurrent(resultantTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
                this.setState({ resultantTenants, tenantsWhoGotThisReleaseAsCurrent }, () => {
                    const resultTenantIds = this.state.skipTenantsWhoHaveTheReleaseAsCurrent ? this.getTenantIdsWhoDoNotHaveThisRelease(resultantTenants, this.state.tenantsWhoGotThisReleaseAsCurrent) : resultantTenants.map((x) => x.Id);
                    this.notifyParentForSelectionChangedFromState(resultTenantIds);
                });
            });
        }, { preserveCurrentErrors: true });
    }
    private determineTagsMatchedTenantIds(testResult: TagTestResult): string[] {
        return Object.keys(testResult).filter((tenantId) => testResult[tenantId].IsMatched);
    }
    private getTenantsWhoCanBePromoted(selectedEnvironmentIds: string[], allTenants: TenantResource[]) {
        if (selectedEnvironmentIds.length !== 1) {
            return [];
        }
        // Check whether the tenants can be promote to the selected environment
        const matchedTenants = this.props.template.TenantPromotions.filter((tenantPromotion: DeploymentPromotionTenant) => tenantPromotion.PromoteTo.some((p) => p.Id === selectedEnvironmentIds[0]));
        return allTenants.filter((t) => matchedTenants.some((dt) => dt.Id === t.Id));
    }
    private loadAvailableEnvironmentsForTenant() {
        // the connected environments for tenant this release can be deployed to
        const availableEnvironmentsForTenantPromotion = _.uniqBy(_.flatten(this.props.template.TenantPromotions.map((tenantPromotion: DeploymentPromotionTenant) => tenantPromotion.PromoteTo)), (promoteTo: DeploymentPromotionTarget) => promoteTo.Id);
        return availableEnvironmentsForTenantPromotion;
    }
    private onDynamicEnvironmentsSelected = (environmentIds: string[]) => {
        if (_.isEqual(this.state.selectedDynamicEnvironmentIds, environmentIds)) {
            return;
        }
        // We don't support tenanted deployments for dynamic environments at the moment
        this.setState({
            selectedDynamicEnvironmentIds: environmentIds,
            selectedTenantIds: [],
            selectedTenantTagIds: [],
            resultantTenants: [],
            availableTenants: [],
            deploymentMode: DeploymentMode.Untenanted,
            deployToAllAvailableTenants: false,
        }, () => {
            this.notifyParentForSelectionChangedFromState();
        });
    };
    private onStaticEnvironmentsSelected = (environmentIds: string[]) => {
        if (_.isEqual(this.state.selectedEnvironmentIds, environmentIds)) {
            return;
        }
        const tenantsWhoCanDeploy = this.getTenantsWhoCanBePromoted(environmentIds, this.props.allTenants);
        const availableTenants = this.props.allTenants.filter((t) => tenantsWhoCanDeploy.some((dt) => dt.Id === t.Id));
        const deploymentMode = environmentIds.length === 0 || environmentIds.length > 1 ? this.recommendedTenantDeploymentOption() : this.state.deploymentMode;
        const canOnlyDeployTenanted = this.canOnlyDeployTenanted();
        const tenantTagsIds = canOnlyDeployTenanted ? (this.props.channel.TenantTags.length > 0 ? this.props.channel.TenantTags : []) : [];
        this.setState({
            selectedEnvironmentIds: environmentIds,
            selectedTenantIds: [],
            selectedTenantTagIds: tenantTagsIds,
            resultantTenants: [],
            availableTenants,
            deploymentMode,
            deployToAllAvailableTenants: false,
        }, () => {
            canOnlyDeployTenanted ? this.setMatchedTenants() : this.notifyParentForSelectionChangedFromState();
        });
    };
    private onTenantsOrTenantTagsSelected = (tenantIds: string[], tenantTags: string[]) => {
        const tenantsGotThisRelease = this.getTenantsWhoHaveThisReleaseAsCurrent(this.state.availableTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
        const explicitSelectedTenantsHaveGotThisRelease = tenantIds.length > 0 ? tenantsGotThisRelease.some((t) => tenantIds.includes(t.Id)) : false;
        this.setState({
            selectedTenantTagIds: tenantTags,
            selectedTenantIds: tenantIds,
            skipTenantsWhoHaveTheReleaseAsCurrent: explicitSelectedTenantsHaveGotThisRelease,
            showSkipPanel: explicitSelectedTenantsHaveGotThisRelease,
        }, this.setMatchedTenants);
    };
    private async loadTagMatched(availableTenants: TenantResource[], selectedTenantIds: string[], selectedTenantTagIds: string[]): Promise<TenantResource[]> {
        if (selectedTenantIds.length === 0 && selectedTenantTagIds.length === 0) {
            return [];
        }
        const matchedTenantIds = selectedTenantTagIds.length === 0 ? selectedTenantIds : this.determineTagsMatchedTenantIds(await repository.Tenants.tagTest(selectedTenantIds || [], selectedTenantTagIds));
        return availableTenants.filter((t) => matchedTenantIds.includes(t.Id));
    }
    private loadTenantIdsFromQueryString(): string[] {
        if (this.props.tenantIds) {
            return this.props.tenantIds ? this.props.tenantIds.split(",") : [];
        }
        if (this.props.search) {
            // Old portal supports this route
            const params = this.props.search.split("=");
            const tenantIds = params.length > 1 && (params[0].toLowerCase() === "?tenantid" || params[0].toLowerCase() === "?tenantids") ? params[1] : "";
            return tenantIds !== "" ? tenantIds.split(",") : [];
        }
        return [];
    }
    private loadTenantTagsFromQueryString(): string[] {
        if (this.props.tags) {
            return this.props.tags ? this.props.tags.split(",") : [];
        }
        if (this.props.search) {
            // Old portal supports this route
            const params = this.props.search.split("=");
            const tags = params.length > 1 && (params[0].toLowerCase() === "?tag" || params[0].toLowerCase() === "?tags") ? params[1] : "";
            return tags !== "" ? tags.split(",") : [];
        }
        return [];
    }
    private onTenantFilterRuleChange = (value: boolean) => {
        this.setState({ skipTenantsWhoHaveTheReleaseAsCurrent: value }, this.setMatchedTenants);
    };
    private buildTenantedOrUntenantedRadioGroup = () => {
        return (<RadioButtonGroup value={this.state.deploymentMode} onChange={this.onDeploymentModeChanged} autoFocus>
                <RadioButton value={DeploymentMode.Untenanted} label="Untenanted"/>
                <RadioButton value={DeploymentMode.Tenanted} label="Tenanted"/>
            </RadioButtonGroup>);
    };
    private onDeploymentModeChanged = (deploymentMode: DeploymentMode) => {
        this.setState((prev) => ({
            deploymentMode,
            deployToAllAvailableTenants: deploymentMode === DeploymentMode.Untenanted ? false : prev.deployToAllAvailableTenants,
        }), () => {
            if (deploymentMode === DeploymentMode.Untenanted) {
                this.onTenantsOrTenantTagsSelected([], []);
            }
            else {
                const tagIds = this.props.channel.TenantTags && this.props.channel.TenantTags.length > 0 && this.state.selectedTenantTagIds.length === 0 ? this.props.channel.TenantTags : this.state.selectedTenantTagIds;
                this.onTenantsOrTenantTagsSelected(this.state.selectedTenantIds, tagIds);
            }
        });
    };
    private toggleAllApplicableTenants = (selectAll: boolean) => {
        selectAll
            ? this.setState({
                selectedTenantIds: this.state.availableTenants.map((x) => x.Id),
                deployToAllAvailableTenants: selectAll,
                skipTenantsWhoHaveTheReleaseAsCurrent: true,
                showSkipPanel: true,
            }, this.setMatchedTenants)
            : this.setState({
                selectedTenantIds: [],
                selectedTenantTagIds: [],
                deployToAllAvailableTenants: selectAll,
            }, this.setMatchedTenants);
    };
    private getTenantIdsWhoDoNotHaveThisRelease = (tenantsToFilter: TenantResource[], tenantsWhoGotThisReleaseAsCurrent: TenantResource[]) => {
        return tenantsToFilter.filter((t) => !tenantsWhoGotThisReleaseAsCurrent.some((tt) => tt.Id === t.Id)).map((x) => x.Id);
    };
    private getTenantsWhoHaveThisReleaseAsCurrent(tenantsToBeFilter: TenantResource[], latestReleases: LatestReleaseResource[], selectedEnvironmentIds: string[], releaseId: string) {
        return selectedEnvironmentIds.length === 1
            ? tenantsToBeFilter.filter((t) => {
                return latestReleases.some((d) => d.TenantId === t.Id && d.EnvironmentId === selectedEnvironmentIds[0] && d.ReleaseId === releaseId && d.ReleaseVersion === this.props.release.Version && d.State === TaskState.Success);
            })
            : [];
    }
    private expandTheTenantExpander = () => {
        store.dispatch(expanderActions.onExpanderStateChanged({ containerKey: "", key: "tenants", expanded: true }));
    };
    private notifyParentForSelectionChanged = (environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean) => {
        if (this.state.deploymentMode === DeploymentMode.Tenanted && tenantIds.length === 0) {
            // Tenanted deployment but no tenants, should not fall back to environment
            this.props.onSelectionUpdated([], [], tenantTagsUsed);
        }
        else {
            this.props.onSelectionUpdated(environments, tenantIds, tenantTagsUsed);
        }
    };
    private canDeploymentBeTenanted = (): boolean => {
        if (this.state.environmentType === "Dynamic")
            return false;
        return this.state.selectedEnvironmentIds.length === 1;
    };
    private canOnlyDeployTenanted = (): boolean => {
        const userCanNotPerformUntenantedDeployments = !isAllowed({ permission: Permission.DeploymentCreate, environment: "*", project: this.props.project.Id });
        return this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted || userCanNotPerformUntenantedDeployments;
    };
    private recommendedTenantDeploymentOption = (selectedTenantTagIds: string[] = [], selectedTenantIds: string[] = []): DeploymentMode => {
        return selectedTenantTagIds.length > 0 || selectedTenantIds.length > 0 || this.canOnlyDeployTenanted() ? DeploymentMode.Tenanted : DeploymentMode.Untenanted;
    };
    private isMultiTenancyEnabledForThisProject = (): boolean => {
        return this.props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted || this.props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted;
    };
    private buildTenantSummary = () => {
        const tagChips = <TenantTagsList tags={this.state.selectedTenantTagIds}/>;
        const onlyTenantsSelected = () => {
            const tenantChips = this.state.resultantTenants && this.state.resultantTenants.length > 0 ? this.state.resultantTenants.map((tenant) => <TenantChip tenantName={tenant.Name} key={tenant.Id}/>) : [];
            return Summary.summary(<div className={styles.envAndTenantSummary}>{tenantChips}</div>);
        };
        const onlyTenantTagsSelected = () => {
            return Summary.summary(<div className={styles.envAndTenantSummary}>Tenants tagged with {tagChips}</div>);
        };
        const bothTenantsAndTagsSelected = () => {
            const tenantChips = this.state.selectedTenantIds.map((id) => <LookupTenantChip lookupTenants={this.props.allTenants} id={id}/>);
            return Summary.summary(<div className={styles.envAndTenantSummary}>
                    {tenantChips} and tenants tagged with {tagChips}
                </div>);
        };
        if (this.state.selectedTenantTagIds.length === 0 && this.state.selectedTenantIds.length > 0) {
            return onlyTenantsSelected();
        }
        if (this.state.selectedTenantTagIds.length > 0 && this.state.selectedTenantIds.length === 0) {
            return onlyTenantTagsSelected();
        }
        if (this.state.selectedTenantTagIds.length > 0 && this.state.selectedTenantIds.length > 0) {
            return bothTenantsAndTagsSelected();
        }
        return Summary.placeholder("No tenants are selected");
    };
    private buildEnvironmentSummary = () => {
        const LookupStaticEnvironmentChip = LookupResourceChipComponent<DeploymentPromotionTarget>();
        const staticEnvironmentChips = this.state.selectedEnvironmentIds
            ? this.state.selectedEnvironmentIds.map((envId) => (<LookupStaticEnvironmentChip lookupCollection={this.props.template.PromoteTo} key={envId} lookupId={envId} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name}/>}/>))
            : [];
        const LookupDynamicEnvironmentChip = LookupResourceChipComponent<DynamicEnvironmentResource>();
        const dynamicEnvironmentChips = this.state.selectedDynamicEnvironmentIds
            ? this.state.selectedDynamicEnvironmentIds.map((envId) => (<LookupDynamicEnvironmentChip lookupCollection={this.props.allDynamicEnvironments} key={envId} lookupId={envId} type={ChipIcon.Environment} chipRender={(item) => <DynamicEnvironmentChip environmentName={item.Name}/>}/>))
            : [];
        if (staticEnvironmentChips.length > 0) {
            return Summary.summary(<div className={styles.envAndTenantSummary}>{staticEnvironmentChips}</div>);
        }
        else if (dynamicEnvironmentChips.length > 0) {
            return Summary.summary(<div className={styles.envAndTenantSummary}>{dynamicEnvironmentChips}</div>);
        }
        return Summary.placeholder("No environments are selected");
    };
    private explainLackOfTenantSelection() {
        if (this.state.environmentType === "Dynamic") {
            return (<UnstructuredFormSection>
                    <Callout type={CalloutType.Information} title={<span>Tenanted deployments are not yet supported for dynamic environments, deployment will be Untenanted</span>}/>
                </UnstructuredFormSection>);
        }
        const selectedEnvironmentCount = this.state.selectedEnvironmentIds.length;
        return (<UnstructuredFormSection>
                <Callout type={CalloutType.Information} title={<span>
                            {selectedEnvironmentCount > 1 && "Tenant selection is only available for deployments targeting a single environment."}
                            {selectedEnvironmentCount === 0 && "Tenant selection requires an environment."}
                        </span>}/>
            </UnstructuredFormSection>);
    }
    private areTenantsRequiredForThisProject() {
        return this.props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted;
    }
    static displayName = "EnvironmentAndTenantSelector";
}
export default EnvironmentAndTenantSelector;
