/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { TabProps } from "@material-ui/core/Tab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import { useProjectContext } from "~/areas/projects/context";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme, withTheme } from "~/components/Theme";
import routeLinks from "~/routeLinks";
import type { RunbookRouteLinks } from "../OperationsRoute";
import { useRunbookContext } from "../Runbooks/RunbookContext";
type NavTabProps = TabProps;
interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}
const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primaryText }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.primary }),
}));
const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: PropsWithOctopusTheme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: props.octopusTheme.paper0,
        borderTopStyle: "solid",
        borderTopWidth: "0.0625rem",
        borderTopColor: props.octopusTheme.dividerLight,
        borderBottomStyle: "solid",
        borderBottomWidth: "0.0625rem",
        borderBottomColor: props.octopusTheme.dividerLight,
    }),
    rootTab: {
        minWidth: "7.5rem",
        textTransform: "none",
        fontWeight: "normal",
        letterSpacing: "0",
        fontSize: "0.9125rem",
    },
}));
const useNavTabStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        "&:hover": {
            opacity: 1,
            color: props.octopusTheme.primaryDark,
            transition: "backgroundColor 10s",
        },
    }),
}));
const NavTab: React.FC<NavTabProps> = (props) => {
    const octopusTheme = useOctopusTheme();
    const tabStyles = useNavTabStyles({ octopusTheme });
    return <Tab classes={tabStyles} {...props}/>;
};
NavTab.displayName = "NavTab"
const projectHasTaskViewPermission = (projectId: string) => {
    return isAllowed({
        permission: Permission.TaskView,
        project: projectId,
        wildcard: true,
    });
};
const renderProcessLabelWithWarning = () => {
    return withTheme((theme) => (<div>
            Process&nbsp;
            <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.errorText }}/>
        </div>));
};
const RunbooksNavigationTabsInternal: React.FC<RouteComponentProps<{
    projectSlug: string;
    spaceId: string;
    runbookId: string;
}>> = (props) => {
    const octopusTheme = useOctopusTheme();
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ octopusTheme });
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const projectLinks = routeLinks.project(props.match.params.projectSlug);
    const tabs: {
        label: React.ReactNode;
        link: string;
        partialLink: string;
        disabled: boolean;
    }[] = [];
    function pushTab(label: React.ReactNode, generateLink: (runbookLinks: RunbookRouteLinks) => string, disabled: boolean = false) {
        const link = generateLink(projectLinks.operations.runbook(props.match.params.runbookId));
        const partialLink = link.replace(projectLinks.root, "");
        tabs.push({ label, link, partialLink, disabled });
    }
    pushTab("Overview", (links) => links.overview);
    if (projectHasTaskViewPermission(projectContext.state.model.Id))
        pushTab("Runs", (links) => links.runslist);
    const runbook = runbookContext.state.runbook;
    if (runbook) {
        const label = projectContext.state.gitVariablesHasError ? renderProcessLabelWithWarning() : "Process";
        pushTab(label, (links) => links.runbookProcess.runbookProcess(runbook.RunbookProcessId).root);
    }
    pushTab("Settings", (links) => links.settings);
    // The Tabs element gets very grumpy (logs console errors) if its 'value' property doesn't match the 'link' of one of the NavTabs, so if we can't find a match, default
    // to the whatever the first tab is. This only happens during the initial renders - later on we get the correct value and so always finds a match.
    const tabThatMatchesUrl = tabs.find((x) => props.location.pathname.includes(x.partialLink)) || tabs[0];
    const urlOfSelectedTab = tabThatMatchesUrl.link;
    return (<div className={tabStyles.tabsContainer}>
            <Tabs value={urlOfSelectedTab} classes={classes} onChange={(_, val) => {
            props.history.push(resolveStringPathWithSpaceId(val, props.match.params.spaceId));
        }} variant="scrollable" scrollButtons="off">
                {tabs.map((x) => (<NavTab key={x.link} label={x.label} value={x.link} disabled={x.disabled} className={tabStyles.rootTab}/>))}
            </Tabs>
        </div>);
};
RunbooksNavigationTabsInternal.displayName = "RunbooksNavigationTabsInternal"
const RunbooksNavigationTabs = withRouter(RunbooksNavigationTabsInternal);
export default RunbooksNavigationTabs;
