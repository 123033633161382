/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CodeEditorLanguage } from "~/components/CodeEditor/CodeEditor";
import CodeEditor from "~/components/CodeEditor/CodeEditor";
import CopyToClipboard from "~/components/CopyToClipboardButton";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomSaveDialogActions, CustomSaveDialogTitleBar, CustomDialogActions, CustomFlexDialogContent } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
interface SourceCodeDialogProps extends RenderProps {
    title?: string;
    value: string;
    language: CodeEditorLanguage;
    autocomplete: Array<{
        display: string;
        code: string;
    }>;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
    saveDone(value: string): void;
}
const SourceCodeDialog: React.FC<SourceCodeDialogProps> = (props) => {
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(props.value);
    React.useEffect(() => {
        setErrors(null);
        setSourceCode(props.value);
    }, [props.value]);
    const editorRef = React.useRef<CodeEditor>(null);
    const save = async () => {
        if (props.validate !== undefined) {
            const validationErrors = await props.validate(sourceCode);
            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            }
            else {
                props.saveDone(sourceCode);
                return true;
            }
        }
        else {
            props.saveDone(sourceCode);
            return true;
        }
    };
    const onFocusedEditorEscapePressed = () => {
        editorRef?.current?.blur();
    };
    return (<CustomDialog open={props.open} close={props.close} render={(customDialogRenderProps) => (<CustomSaveDialogLayout {...customDialogRenderProps} renderTitle={() => <CustomSaveDialogTitleBar title={props.title ? props.title : "Edit Source"}/>} {...(errors && OctopusError.isOctopusError(errors) && errors.Errors ? { errors: { errors: errors.Errors.map((x: string) => x.toString()) ?? [], message: errors.ErrorMessage, fieldErrors: {}, details: {} } } : {})} onSaveClick={() => save()} renderActions={(renderProps) => (<CustomDialogActions actions={<CustomSaveDialogActions saveButtonLabel="Done" close={renderProps.close} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission}/>} additionalActions={<CopyToClipboard value={sourceCode}/>}/>)} renderContent={() => (<CustomFlexDialogContent>
                            <CodeEditor value={sourceCode} ref={editorRef} language={props.language} allowFullScreen={false} onChange={(value) => {
                    setSourceCode(value);
                }} autoComplete={props.autocomplete} autoFocus={props.value == null || props.value === undefined || props.value.length === 0} onEscPressed={() => onFocusedEditorEscapePressed()}/>
                        </CustomFlexDialogContent>)}/>)}/>);
};
SourceCodeDialog.displayName = "SourceCodeDialog"
export default SourceCodeDialog;
