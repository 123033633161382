import type { AwsDeleteCloudFormationProperties } from "@octopusdeploy/legacy-action-properties";
import type { AccountResource, FeedResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "~/components/form";
import { default as FormSectionHeading } from "~/components/form/Sections/FormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import { repository } from "../../../clientInstance";
import Note from "../../../primitiveComponents/form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps, ActionPlugin } from "../pluginRegistry";
import { default as AwsLoginComponent } from "./awsLoginComponent";
class AwsCloudFormationActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Delete an AWS CloudFormation stack
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "AwsCloudFormationActionSummary";
}
interface AwsRemoveCloudFormationActionEditState {
    feeds: FeedResource[];
    accounts: AccountResource[];
}
class AwsRemoveCloudFormationActionEdit extends BaseComponent<ActionEditProps<AwsDeleteCloudFormationProperties>, AwsRemoveCloudFormationActionEditState> {
    constructor(props: ActionEditProps<AwsDeleteCloudFormationProperties>) {
        super(props);
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.Aws.WaitForCompletion"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: "True" }, true);
            }
            if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
            }
            if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
                this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
            }
            this.setState({
                feeds: await repository.Feeds.all(),
                accounts: await repository.Accounts.all(),
            });
        });
    }
    render() {
        const properties = this.props.properties;
        return (<div>
                <FormSectionHeading title="AWS"/>
                <AwsLoginComponent projectId={this.props.projectId} gitRef={this.props.gitRef} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault}/>
                <ExpandableFormSection errorKey="Octopus.Action.Aws.Region|Octopus.Action.Aws.CloudFormationStackName" isExpandedByDefault={this.props.expandedByDefault} title="CloudFormation" summary={this.cloudFormationSummary()} help={"Specify the details of the CloudFormation stack"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Aws.Region"]} label="Region" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })} error={this.props.getFieldError("Octopus.Action.Aws.Region")}/>
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Aws.CloudFormationStackName"]} label="CloudFormation stack name" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationStackName"]: val })} error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationStackName")}/>
                    <BoundStringCheckbox resetValue={"True"} variableLookup={{
                localNames: this.props.localNames,
            }} value={properties["Octopus.Action.Aws.WaitForCompletion"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: x })} label="Wait for completion"/>
                    <Note>
                        Select this checkbox to force the step to wait until the CloudFormation stack has been deleted before finishing the step. Be aware that unselecting this option means the step will not indicate an error if the stack was not
                        removed successfully.
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    private cloudFormationSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.Aws.CloudFormationStackName"]) {
            return Summary.summary(<span>
                    Deleting stack <strong>{properties["Octopus.Action.Aws.CloudFormationStackName"]}</strong>
                    {properties["Octopus.Action.Aws.Region"] && (<span>
                            {" "}
                            in <strong>{properties["Octopus.Action.Aws.Region"]}</strong>
                        </span>)}
                    {properties["Octopus.Action.Aws.WaitForCompletion"] && <span> waiting for completion</span>}
                    {!properties["Octopus.Action.Aws.WaitForCompletion"] && <span> not waiting for completion</span>}
                </span>);
        }
        return Summary.placeholder("Specify the details of the CloudFormation stack");
    }
    static displayName = "AwsRemoveCloudFormationActionEdit";
}
export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsDeleteCloudFormation",
    summary: (properties, targetRolesAsCSV) => <AwsCloudFormationActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: AwsRemoveCloudFormationActionEdit,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
});
