import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";
export const CommonConfigurationLetsEncryptOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};
CommonConfigurationLetsEncryptOverview.displayName = "CommonConfigurationLetsEncryptOverview"
export const CommonConfigurationLetsEncryptTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
CommonConfigurationLetsEncryptTopics.displayName = "CommonConfigurationLetsEncryptTopics"
