import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "../../../../routeLinks";
const CertificateSidebar = () => {
    return (<div>
            <h4>Expiry Notifications</h4>
            <p>
                Certificate expiry notifications can be configured using <InternalLink to={routeLinks.configuration.subscriptions.root}>Subscriptions</InternalLink>.
            </p>
            <Note>
                See our <ExternalLink href="CertificateExpiryNotifications">documentation</ExternalLink> for more information.
            </Note>
        </div>);
};
export default CertificateSidebar;
