import React from "react";
import { ActionButtonType, OpenDeleteDialogButton } from "~/components/Button";
export type DynamicEnvironmentDeleteDialogButtonProps = {
    environmentId: string;
    environmentName: string;
    disabled: boolean;
    onDeleteEnvironment: (environmentId: string) => Promise<void>;
};
export function DynamicEnvironmentDeleteDialogButton({ environmentId, environmentName, disabled, onDeleteEnvironment }: DynamicEnvironmentDeleteDialogButtonProps) {
    return (<OpenDeleteDialogButton dialogTitle={`Are you sure you want to delete dynamic environment '${environmentName}'?`} dialogButtonLabel="Delete" dialogButtonBusyLabel="Delete" disabled={disabled} onDeleteClick={async () => {
            await onDeleteEnvironment(environmentId);
            return true;
        }} label="Delete" type={ActionButtonType.Delete}>
            <div>
                <p>Deleting this environment is permanent. There is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>
        </OpenDeleteDialogButton>);
}
