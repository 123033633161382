import type { DeploymentTargetResource, ResourceCollection, TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import List from "~/components/List";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
import routeLinks from "../../../../routeLinks";
class RunbookRunTasksList extends List<TaskResource<{}>> {
}
interface MachineRunbookRunsProps extends RouteComponentProps<MachineRunbookRunsParams> {
    initialData: InitialData;
}
interface MachineRunbookRunsParams {
    machineId: string;
}
interface MachineRunbookRunsState extends DataBaseComponentState {
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
interface InitialData {
    machine: DeploymentTargetResource;
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
const Title = "Runbook Runs";
const MachineRunbookRunsLayoutFormPage = FormPage<InitialData>();
const MachineRunbookRunsLayout: React.FC<RouteComponentProps<MachineRunbookRunsParams>> = (props: RouteComponentProps<MachineRunbookRunsParams>) => {
    return (<MachineRunbookRunsLayoutFormPage title={Title} load={async () => {
            const machineId = props.match.params.machineId;
            const machine = await repository.Machines.get(machineId);
            const tasksResponse = await repository.Machines.getRunbookRuns(machine, { skip: 0 });
            return { machine, tasksResponse };
        }} renderWhenLoaded={(data) => <MachineRunbookRunsLayoutInner initialData={data} {...props}/>}/>);
};
MachineRunbookRunsLayout.displayName = "MachineRunbookRunsLayout"
class MachineRunbookRunsLayoutInner extends DataBaseComponent<MachineRunbookRunsProps, MachineRunbookRunsState> {
    constructor(props: MachineRunbookRunsProps) {
        super(props);
        this.state = {
            tasksResponse: props.initialData.tasksResponse,
        };
    }
    render() {
        return (<PaperLayout title={Title} busy={this.state.busy} errors={this.errors}>
                <RunbookRunTasksList initialData={this.state.tasksResponse} onRow={(item: TaskResource<{}>) => this.buildRunbookRunTaskRow(item)} onRowRedirectUrl={(task: TaskResource<{}>) => routeLinks.task(task).root} showPagingInNumberedStyle={true} onPageSelected={async (skip: number, p: number) => {
                await this.loadRunbookRunTasks(skip);
            }}/>
            </PaperLayout>);
    }
    private async loadRunbookRunTasks(skip: number) {
        const tasksResponse = await repository.Machines.getRunbookRuns(this.props.initialData.machine, { skip });
        this.setState({ tasksResponse });
    }
    private buildRunbookRunTaskRow(task: TaskResource<{}>) {
        return <TaskDetails task={task} stripTopBottomPadding={true}/>;
    }
    static displayName = "MachineRunbookRunsLayoutInner";
}
export default MachineRunbookRunsLayout;
