import { Permission } from "@octopusdeploy/octopus-server-client";
import type { EnvironmentResource, ProjectSummaryResource, TagSetResource, TenantsFilterOptions, TagResource } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useState } from "react";
import { repository } from "~/clientInstance";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector";
import { EnvironmentChip, FilterTextChip, ProjectChip } from "~/components/Chips";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import Tag from "~/components/Tag";
import { Select } from "~/components/form";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import * as tenantTagsets from "~/components/tenantTagsets";
interface TenantsFilterProps {
    filter: TenantsFilterOptions;
    onFilterChanged: (newFilter: TenantsFilterOptions) => Promise<void>;
    doBusyTask: DoBusyTask;
}
export default function TenantsFilter(props: React.PropsWithChildren<TenantsFilterProps>) {
    const filter = props.filter;
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState<Array<{
        value: string;
        text: string;
    }>>([]);
    const [environments, setEnvironments] = useState<Array<EnvironmentResource>>([]);
    const [tagSets, setTagSets] = useState<Array<TagSetResource>>([]);
    const [tagLookup, setTagLookup] = useState<Map<string, TagResource>>(new Map<string, TagResource>());
    const loadFilterSelections = async () => {
        const getProjectSummaries = hasPermission(Permission.ProjectView) ? repository.Projects.summaries() : Promise.resolve<ProjectSummaryResource[]>([]);
        const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve<EnvironmentResource[]>([]);
        const getTagSets = tenantTagsets.getAll();
        const [projectSummaries, loadedEnvironments, loadedTagSets] = await Promise.all([getProjectSummaries, getEnvironments, getTagSets]);
        setProjects(projectSummaries.map((p) => ({ value: p.Id, text: p.Name })));
        setEnvironments(loadedEnvironments);
        setTagSets(loadedTagSets.sort((t1, t2) => t1.SortOrder - t2.SortOrder));
        setTagLookup(new Map(loadedTagSets.flatMap((set) => set.Tags).map((tag) => [tag.CanonicalTagName, tag])));
    };
    useEffect(() => {
        (async () => await loadFilterSelections())();
    }, []);
    const onSelectionChanged = async (newFilter: TenantsFilterOptions) => {
        await props.onFilterChanged(newFilter);
    };
    const buildSelectedChips = () => {
        const sets = tenantTagsets.groupByTagSet(filter.filterByTags);
        const tagsFilterByChips = sets.map((set) => {
            return (<div key={set.name}>
                    {set.tags
                    .map((t) => tagLookup.get(t))
                    .map((t) => (t ? <Tag tagName={t.Name} description={t.Description} tagColor={t.Color} key={t.Id} small={false}/> : null))
                    .filter((chip) => !!chip)}
                </div>);
        });
        const nameSearchChip = filter.filterByName === "" ? null : <FilterTextChip key={"search-text"} filterText={filter.filterByName}/>;
        const selectedEnvironment = filter.filterByEnvironment ? environments.find((e) => e.Id === filter.filterByEnvironment) : undefined;
        const selectedProject = filter.filterByProject ? projects.find((p) => p.value === filter.filterByProject) : undefined;
        const environmentChip = selectedEnvironment ? <EnvironmentChip key={"search-environment"} environmentName={selectedEnvironment.Name}/> : null;
        const projectChip = selectedProject ? <ProjectChip key={"search-project"} projectName={selectedProject.text}/> : null;
        return (tagsFilterByChips.length || !!nameSearchChip || !!environmentChip || !!projectChip) && [nameSearchChip, tagsFilterByChips, environmentChip, projectChip];
    };
    return (<TenantsFilterLayout filter={filter} defaultFilter={defaultSelection()} onFilterReset={(newFilter) => onSelectionChanged(newFilter)} additionalHeaderFilters={[<FilterSearchBox placeholder="Filter by name..." value={filter.filterByName} autoFocus={false} onChange={(filterByName) => onSelectionChanged({ ...filter, filterByName })}/>]} filterByChips={buildSelectedChips()} filterSections={[
            {
                render: (<>
                            <PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                                <Select value={filter.filterByProject} onChange={(filterByProject) => onSelectionChanged({ ...filter, filterByProject })} items={projects} allowClear={true} allowFilter={true} fieldName="project" disabled={loading}/>
                            </PermissionCheck>
                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                <EnvironmentSelect value={filter.filterByEnvironment} onChange={(filterByEnvironment) => onSelectionChanged({ ...filter, filterByEnvironment })} environments={environments} allowClear={true} allowFilter={true} fieldName="environment"/>
                            </PermissionCheck>
                            <AdvancedTenantTagsSelector selectedTenantTags={filter.filterByTags} tagSets={tagSets} onChange={(filterByTags) => onSelectionChanged({ ...filter, filterByTags })} doBusyTask={props.doBusyTask} showPreviewButton={false}/>
                        </>),
            },
        ]} renderContent={() => props.children}/>);
}
function defaultSelection(): TenantsFilterOptions {
    return {
        filterByName: "",
        filterByTags: [],
        filterByProject: undefined,
        filterByEnvironment: undefined,
    };
}
class TenantsFilterLayout extends AdvancedFilterLayout<TenantsFilterOptions> {
}
