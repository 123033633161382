//TODO: @team-config-as-code: Re-introduce this only when we need branch support for runbook processes.
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import pageIds from "../../../../pageIds";
import { runbookLinks } from "../OperationsRoute";
import RunbookProcessRoute from "./RunbookProcessRoute";
import RunbookSettingsLayout from "./RunbookSettingsLayout";
export const RunbookSettingsPage = withPage({ page: pageIds.project().runbook.settings })(RunbookSettingsLayout);
class RunbookSettingsAndProcessRoutes extends React.Component<{
    path: string;
}> {
    render() {
        const links = runbookLinks(this.props.path);
        return (<Switch>
                <ReloadableRoute path={links.settings} render={(routeProps: RouteComponentProps<{
            runbookId: string;
        }>) => <RunbookSettingsPage runbookId={routeProps.match.params.runbookId}/>}/>
                <RunbookProcessRoute path={links.runbookProcess.runbookProcess(":processId").root}/>
            </Switch>);
    }
    static displayName = "RunbookSettingsAndProcessRoutes";
}
export { RunbookSettingsAndProcessRoutes };
