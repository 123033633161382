import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";
export const CommonConfigurationDiagnosticsOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};
CommonConfigurationDiagnosticsOverview.displayName = "CommonConfigurationDiagnosticsOverview"
export const CommonConfigurationDiagnosticsTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
CommonConfigurationDiagnosticsTopics.displayName = "CommonConfigurationDiagnosticsTopics"
