/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import cn from "classnames";
import CheckboxField from "material-ui/Checkbox";
import * as React from "react";
import { withTheme } from "~/components/Theme";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import InputLabel from "~/components/form/InputLabel/InputLabel";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import Note from "~/primitiveComponents/form/Note/Note";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import styles from "./style.module.less";
interface CheckboxOtherProps {
    title?: string | JSX.Element;
    noMargin?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    note?: string | JSX.Element;
    disabled?: boolean;
    label?: string | JSX.Element;
    className?: string;
    error?: string;
    warning?: string;
    iconStyle?: string;
    accessibleName?: string;
    validate?(value: boolean): string;
    onValidate?(value: string): void;
}
type CheckboxProps = CheckboxOtherProps & FormFieldProps<boolean>;
export default class Checkbox extends React.Component<CheckboxProps, {}> {
    checkBoxField: CheckboxField | null = undefined!;
    handleChange = (event: any, isChecked: boolean) => {
        if (this.props.validate) {
            const validateResult = this.props.validate(isChecked);
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(isChecked);
        }
    };
    componentDidMount() {
        if (this.props.autoFocus) {
            // this digs into the Checkbox implementation to set focus
            const internalSwitch = this.checkBoxField?.refs.enhancedSwitch as any;
            internalSwitch.refs.checkbox.focus();
            internalSwitch.tabPressed = true;
            internalSwitch.handleFocus();
        }
    }
    render() {
        return withTheme((theme) => {
            const { title, label, className, value, noMargin, onChange, placeholder, note, error, warning, iconStyle, accessibleName, ...otherProps } = this.props;
            const isMessageProvided = (error && error.length > 0) || (warning && warning.length > 0);
            const message = isMessageProvided && (error || warning);
            const messageColor = isMessageProvided ? (message === error ? theme.dangerText : theme.alertText) : undefined;
            return (<div className={cn(className, { [styles.checkboxMargin]: !noMargin })}>
                    {title && <InputLabel label={title}/>}
                    <CheckboxField ref={(ref) => (this.checkBoxField = ref)} label={label} checked={value} aria-label={accessibleName} onCheck={this.handleChange} labelStyle={{ width: "100%", color: theme.primaryText }} checkedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBox} style={{ fill: theme.focus }}/>} uncheckedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBoxOutlineBlank} style={{ fill: theme.primaryText }}/>} iconStyle={{ marginRight: "0.5rem" }} {...otherProps}/>
                    {message && (<div style={{ color: messageColor }} className={styles.message}>
                            {message}
                        </div>)}
                    {note && (<div className={styles.checkboxNote}>
                            <Note>{note}</Note>
                        </div>)}
                </div>);
        });
    }
    static displayName = "Checkbox";
}
export const BoundCheckbox = withBoundField<boolean, CheckboxOtherProps>(Checkbox);
