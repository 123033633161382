/* eslint-disable @typescript-eslint/no-explicit-any */
import { InsightsGranularity } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import { get } from "lodash";
import React from "react";
import type { ParsedInsightsAllMetricsInterval } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import type { ObjectPropertyPath } from "~/utils/ObjectPropertyPath/ObjectPropertyPath";
import { getChartAxisDateFormatter } from "../../../dataTransformation/dateHelpers";
import type { InsightsCadence } from "../../../insightsCadence";
import { insightsCadenceLookup } from "../../../insightsCadence";
import type { RechartsTooltipProps } from "../TooltipBase/TooltipBase";
import { TooltipBase } from "../TooltipBase/TooltipBase";
import styles from "./styles.module.less";
interface MultiSeriesTooltip extends RechartsTooltipProps {
    dataKey: ObjectPropertyPath<ParsedInsightsAllMetricsInterval>;
    cadence: InsightsCadence;
    valueFormatter?: (value: any) => string | number;
    showSeries?: boolean;
}
const defaultValueFormatter = (val: any) => val ?? "No Deployments";
export function InsightsTooltip({ dataKey, valueFormatter = defaultValueFormatter, cadence, showSeries, ...rest }: MultiSeriesTooltip) {
    const { granularity } = insightsCadenceLookup[cadence];
    const dateFormatter = getChartAxisDateFormatter(granularity);
    const labelFormatter = (label: string) => {
        if (granularity === InsightsGranularity.Weekly)
            return `Week starting ${dateFormatter(label)}`;
        return dateFormatter(label);
    };
    return (<TooltipBase<ParsedInsightsAllMetricsInterval> {...rest}>
            {({ payload, label }) => (<>
                    <span className={cn(styles.textColor, styles.label)}>{labelFormatter(label)}</span>
                    <ul className={cn(styles.textColor, styles.list)}>
                        {payload.map((p, idx) => (<li key={`series-${idx}`} className={styles.listItem}>
                                {showSeries && <div className={styles.colorIndicator} style={{ backgroundColor: p.color }}/>}
                                {showSeries && <span>{p.name}: </span>}
                                {valueFormatter(get(p.payload, dataKey))}
                            </li>))}
                    </ul>
                </>)}
        </TooltipBase>);
}
