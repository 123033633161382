import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { ChipIcon, EnvironmentChip, ContextualMissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { RadioButtonGroup, Note, Summary, RadioButton } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import type { WithOptionalRunbookContextInjectedProps } from "../../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { EnvironmentOption } from "../types";
import { isVersionControlledProcess } from "./CommonProcessHelpers";
class EnvironmentOptionRadioButtonGroup extends RadioButtonGroup<EnvironmentOption> {
}
interface EnvironmentProps {
    environmentOption: EnvironmentOption;
    hasHiddenEnvironments: boolean;
    environments: EnvironmentResource[];
    inclusiveEnvironments: string[];
    exclusiveEnvironments: string[];
    onEnvironmentOptionChanged(val: EnvironmentOption): void;
    onInclusiveEnvironmentsChanged(environments: string[]): void;
    onExclusiveEnvironmentsChanged(environments: string[]): void;
    onInclusiveEnvironmentRemoved?: (environment: string) => void;
    onExclusiveEnvironmentRemoved?: (environment: string) => void;
}
type Props = EnvironmentProps & WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps;
export class Environments extends React.Component<Props> {
    get isVersionControlled(): boolean {
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, this.props.runbookContext ? ProcessType.Runbook : ProcessType.Deployment);
        return isVersionControlled;
    }
    shouldComponentUpdate(nextProps: Props) {
        return nextProps.environmentOption !== this.props.environmentOption || nextProps.inclusiveEnvironments !== this.props.inclusiveEnvironments || nextProps.exclusiveEnvironments !== this.props.exclusiveEnvironments;
    }
    render() {
        return (<ExpandableFormSection title="Environments" help="Choose which environments this step applies to." summary={this.environmentSummary()} errorKey="environments">
                <EnvironmentOptionRadioButtonGroup value={this.props.environmentOption} onChange={this.props.onEnvironmentOptionChanged}>
                    <RadioButton value={EnvironmentOption.All} label="Run for any environment" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments} isDefault={true}/>

                    <RadioButton value={EnvironmentOption.Include} label="Run only for specific environments" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments}/>
                    {this.props.environmentOption === EnvironmentOption.Include && (<div>
                            <EnvironmentMultiSelect environments={this.props.environments} onChange={this.props.onInclusiveEnvironmentsChanged} value={this.props.inclusiveEnvironments} onRemove={this.props.onInclusiveEnvironmentRemoved}/>

                            <Note>Choose the specific environments under which you want this step to run.</Note>
                            {!this.isVersionControlled && this.props.hasHiddenEnvironments && (<Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                                    The {Permission.EnvironmentView} permission is preventing the environment condition type from being changed because you do not have access to an environment that is specifically marked for inclusion.
                                </Callout>)}
                        </div>)}

                    <RadioButton value={EnvironmentOption.Exclude} label="Skip specific environments" disabled={!this.isVersionControlled && this.props.hasHiddenEnvironments}/>
                    {this.props.environmentOption === EnvironmentOption.Exclude && (<div>
                            <EnvironmentMultiSelect environments={this.props.environments} onChange={this.props.onExclusiveEnvironmentsChanged} value={this.props.exclusiveEnvironments} onRemove={this.props.onExclusiveEnvironmentRemoved}/>

                            <Note>Choose the environments for which you want this step to be skipped.</Note>
                            {!this.isVersionControlled && this.props.hasHiddenEnvironments && (<Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                                    The {Permission.EnvironmentView} permission is preventing the environment condition type to be changed because you do not have access to an environment that is specifically marked for inclusion.
                                </Callout>)}
                        </div>)}
                </EnvironmentOptionRadioButtonGroup>
            </ExpandableFormSection>);
    }
    private environmentSummary() {
        if (this.props.environmentOption === EnvironmentOption.Include) {
            return Summary.summary(<span>This step will only run in {this.getChipsForEnvironments(this.props.inclusiveEnvironments)}</span>);
        }
        if (this.props.environmentOption === EnvironmentOption.Exclude) {
            return Summary.summary(<span>
                    This step will be run in all applicable Lifecycle environments <em>except</em> {this.getChipsForEnvironments(this.props.exclusiveEnvironments)}
                </span>);
        }
        return Summary.default("This step will run for all applicable Lifecycle environments");
    }
    private getChipsForEnvironments(environmentKeys: string[]) {
        if (environmentKeys.length === 0) {
            return <em>please select environments</em>;
        }
        return environmentKeys.map((key) => (<Lookup key={key} lookupCollection={this.props.environments} lookupId={key} getIdFromElement={(x) => x.Id} render={(item) => <EnvironmentChip environmentName={item.Name}/>} renderFallback={<ContextualMissingChip key={key} lookupKey={key} type={ChipIcon.Environment}/>}/>));
    }
    static displayName = "Environments";
}
const EnhancedEnvironments: React.FC<EnvironmentProps> = (props) => {
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    return <Environments {...props} projectContext={projectContext} runbookContext={runbookContext}/>;
};
EnhancedEnvironments.displayName = "EnhancedEnvironments"
export default EnhancedEnvironments;
