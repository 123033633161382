import type { EventCategoryResource, EventResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import EventListing from "~/components/EventListing/EventListing";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FormPage from "~/components/FormPage/FormPage";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
interface MachineEventsProps {
    initialData: InitialData;
}
interface MachineEventsParams {
    machineId: string;
}
const Title = "Events";
interface InitialData {
    machineId: string;
    eventsResponse: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
}
const MachineEventsFormPage = FormPage<InitialData>();
const MachineEventsLayout: React.FC<RouteComponentProps<MachineEventsParams>> = (props: RouteComponentProps<MachineEventsParams>) => {
    const useOptimization = isFeatureToggleEnabled("PermissionCheckOptimizationFeatureToggle");
    return (<MachineEventsFormPage title={Title} load={async () => {
            const machineId = props.match.params.machineId;
            const eventsResponse = useOptimization ? await repository.Events.getUnpaginatedEvents({ regarding: [machineId] }) : await repository.Events.list({ regarding: [machineId] });
            const eventCategories = await repository.Events.categories({});
            return { machineId, eventsResponse, eventCategories };
        }} renderWhenLoaded={(data) => <MachineEventsLayoutInner initialData={data}/>}/>);
};
MachineEventsLayout.displayName = "MachineEventsLayout"
class MachineEventsLayoutInner extends DataBaseComponent<MachineEventsProps, DataBaseComponentState> {
    constructor(props: MachineEventsProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (<PaperLayout title={Title} busy={this.state.busy} errors={this.errors}>
                {this.props.initialData.eventsResponse && <EventListing data={this.props.initialData.eventsResponse} eventCategories={this.props.initialData.eventCategories} regarding={[this.props.initialData.machineId]}/>}
            </PaperLayout>);
    }
    static displayName = "MachineEventsLayoutInner";
}
export default MachineEventsLayout;
