import type { LibraryVariableSetProjectUsage } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import SimpleDataTable from "~/components/SimpleDataTable";
import routeLinks from "~/routeLinks";
import { pluraliseSummary, pluraliseHelp } from "./VariableSetUsage";
export const VariableSetProjectUsages: React.FC<{
    usage: LibraryVariableSetProjectUsage[];
}> = ({ usage }) => {
    return (<SimpleDataTable<LibraryVariableSetProjectUsage> data={usage} headerColumns={["Project Name"]} onRow={(project) => [<InternalLink to={routeLinks.project(project.ProjectSlug).deployments.process.root}>{project.ProjectName}</InternalLink>]}/>);
};
VariableSetProjectUsages.displayName = "VariableSetProjectUsages"
export function getProjectUsageSummary(type: string, count: number, countOfProjectsUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "is being", "has not been", "project", countOfProjectsUserCannotSee);
}
export function getProjectUsageHelp(type: string, count: number, countOfProjectsUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "is being", "has not been", "project", countOfProjectsUserCannotSee);
}
export function scriptModuleProjectUsageSummary(usagesInProjects: LibraryVariableSetProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageSummary("script module", usagesInProjects.length, countOfProjectsUserCannotSee);
}
export function variableSetProjectUsageSummary(usagesInProjects: LibraryVariableSetProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageSummary("variable set", usagesInProjects.length, countOfProjectsUserCannotSee);
}
export function scriptModuleProjectUsageHelp(usagesInProjects: LibraryVariableSetProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageHelp("script module", usagesInProjects.length, countOfProjectsUserCannotSee);
}
export function variableSetProjectUsageHelp(usagesInProjects: LibraryVariableSetProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageHelp("variable set", usagesInProjects.length, countOfProjectsUserCannotSee);
}
