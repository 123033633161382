import { getGitRefFromRouteParameter, ProcessType } from "@octopusdeploy/octopus-server-client";
import type * as H from "history";
import * as React from "react";
import type { RouteComponentProps, StaticContext } from "react-router";
import { generatePath } from "react-router";
import { Switch } from "react-router-dom";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import PaperLayout from "~/components/PaperLayout";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import SlugSafeRedirect from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import DeploymentSettingsRoute from "../DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import DeploymentsRoute from "../DeploymentsRoute";
import ExportProjects from "../ImportExport/ExportProjects";
import { ExportTaskDetails } from "../ImportExport/ExportTaskDetails";
import ImportExportTasks from "../ImportExport/ImportExportTasks";
import ImportProjects from "../ImportExport/ImportProjects";
import { ImportTaskDetails } from "../ImportExport/ImportTaskDetails";
import OperationsRoute from "../OperationsRoute";
import DeploymentProcessRoute from "../Process/Routing/ProcessRoute";
import ProjectInsightsRoutes from "../ProjectInsights/ProjectInsightsRoutes/ProjectInsightsRoutes";
import { ProjectLayout } from "../ProjectLayout/ProjectLayout";
import ProjectSettingsRoute from "../ProjectSettings/ProjectSettingsRoute";
import ProjectTasks from "../ProjectTasks/ProjectTasks";
import { Projects } from "../Projects";
import { EnhancedRunbooksRoute as RunbooksRoute } from "../Runbooks/RunbooksRoute";
import { TriggersRoute } from "../Triggers";
import AllVariablesRoute from "../Variables/AllVariables/AllVariablesRoute";
import ProjectVariablesRoute from "../Variables/ProjectVariables/ProjectVariablesRoute";
import VariablePreviewRoute from "../Variables/VariablePreview/VariablePreviewRoute";
import VariablesRoute from "../Variables/VariablesRoute";
import { ProjectToOverviewRedirect } from "./ProjectToOverviewRedirect";
const ProjectTasksPage = withPage({ page: pageIds.project().tasks })(ProjectTasks);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);
const ImportExportPage = withPage({ page: pageIds.importExport.root })(ImportExportTasks);
const ExportPage = withPage({ page: pageIds.importExport.export })(ExportProjects);
const ExportTaskDetailsPage = withPage({ page: pageIds.importExport.exportTask })(ExportTaskDetails);
const ImportPage = withPage({ page: pageIds.importExport.import })(ImportProjects);
const ImportTaskDetailsPage = withPage({ page: pageIds.importExport.importTask })(ImportTaskDetails);
interface ProjectLocationState {
    project?: {
        Slug: string;
    };
}
function locationHasProjectState(location: H.Location): location is H.Location<Required<ProjectLocationState>> {
    if (!location || !location?.state) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = location as H.Location<ProjectLocationState>;
    if (!casted.state?.project) {
        return false;
    }
    return typeof casted.state.project.Slug === "string";
}
type ProjectRouteProps = RouteComponentProps<{}, StaticContext, ProjectLocationState>;
export class ProjectRoutes extends React.Component<ProjectRouteProps> {
    async loadSlugFromProjectId(projectId: string, location: H.Location) {
        if (locationHasProjectState(location)) {
            return location.state.project.Slug;
        }
        return (await repository.Projects.get(projectId)).Slug;
    }
    projectSlugLoading() {
        return (<main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title="Projects"/>
                <PaperLayout busy={true} fullWidth={true}/>
            </main>);
    }
    buildProjectRoutes() {
        const projectRouteLinks = routeLinks.projectRoutes();
        const branchRouteLinks = routeLinks.projectBranchRoutes();
        const branchRoutes = (<ReloadableRoute key="branchRoutes" path={branchRouteLinks.root} doNotReloadWhenTheseKeysChange={["branchName"]}>
                {(routeProps: RouteComponentProps<ProjectRouteParams>) => {
                return (<SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                            <ProjectLayout branchName={getGitRefFromRouteParameter(routeProps.match.params.branchName ?? "")} projectSlug={routeProps.match.params.projectSlug}>
                                <Switch>
                                    <DeploymentProcessRoute path={branchRouteLinks.deploymentProcess.root} processType={ProcessType.Deployment}/>
                                    <DeploymentSettingsRoute path={branchRouteLinks.deploymentSettings}/>
                                    <AllVariablesRoute path={branchRouteLinks.variables.all}/>
                                    <VariablePreviewRoute path={branchRouteLinks.variables.preview}/>
                                    <ProjectVariablesRoute path={branchRouteLinks.variables.root}/>
                                    <RunbooksRoute path={branchRouteLinks.runbooks}/>
                                </Switch>
                            </ProjectLayout>
                        </SlugSafeRedirect>);
            }}
            </ReloadableRoute>);
        const projectRoutes = (<ReloadableRoute key="projectRoutes" path={projectRouteLinks.root}>
                {(routeProps: RouteComponentProps<ProjectRouteParams>) => {
                return (<SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                            <ProjectLayout branchName={getGitRefFromRouteParameter(routeProps.match.params.branchName ?? "")} projectSlug={routeProps.match.params.projectSlug} isNewlyCreatedProject={new URLSearchParams(this.props.location.search).get("newlyCreatedProject")}>
                                <Switch>
                                    <ReloadableRoute path={projectRouteLinks.tasks} component={ProjectTasksPage}/>
                                    <ReloadableRoute path={projectRouteLinks.settings.root} component={ProjectSettingsRoute}/>
                                    <ReloadableRoute path={projectRouteLinks.triggers} component={TriggersRoute}/>
                                    <ReloadableRoute path={projectRouteLinks.deployments.root} component={DeploymentsRoute}/>
                                    <OperationsRoute path={projectRouteLinks.operations.root}/>
                                    <ReloadableRoute path={projectRouteLinks.variables.root} component={VariablesRoute}/>
                                    <ReloadableRoute path={projectRouteLinks.insights.root} component={ProjectInsightsRoutes}/>
                                    <ReloadableRoute path={projectRouteLinks.stepTemplates} render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.stepTemplates}/>}/>
                                    <ReloadableRoute exact={true} path={projectRouteLinks.overview} render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root}/>}/>
                                    <ReloadableRoute exact={true} path={projectRouteLinks.root} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectToOverviewRedirect />}/>
                                    <ReloadableRoute path={projectRouteLinks.childStepTemplates(":parentStepId").root} render={(props: RouteComponentProps<ProjectRouteParams & {
                        parentStepId: string;
                    }>) => (<InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.childStepTemplates(props.match.params.parentStepId).root}/>)}/>
                                    <ReloadableRoute path={`${projectRouteLinks.channels}*`} render={(props: RouteComponentProps) => {
                        //Rewrite any old release based routes from project/releases to project/deployments/releases
                        const rewrite = props.match.path.replace(projectRouteLinks.channels, projectRouteLinks.deployments.channels);
                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                        return <InternalRedirect to={destination}/>;
                    }}/>
                                    <ReloadableRoute path={`${projectRouteLinks.releases}*`} render={(props: RouteComponentProps) => {
                        //Rewrite any old release based routes from project/releases to project/deployments/releases
                        const rewrite = props.match.path.replace(projectRouteLinks.releases, projectRouteLinks.deployments.releases);
                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                        return <InternalRedirect to={destination}/>;
                    }}/>
                                    <ReloadableRoute path={`${projectRouteLinks.deployments.process.root}*`} render={(props: RouteComponentProps) => {
                        //Rewrite any old process links to the new route
                        const rewrite = props.match.path.replace(projectRouteLinks.deployments.process.root, projectRouteLinks.deployments.process.root);
                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                        return <InternalRedirect to={destination}/>;
                    }}/>
                                    <RedirectAs404 />
                                </Switch>
                            </ProjectLayout>
                        </SlugSafeRedirect>);
            }}
            </ReloadableRoute>);
        return [branchRoutes, projectRoutes];
    }
    render() {
        const projectRoutes = this.buildProjectRoutes();
        return (<Switch>
                <ReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                    <InternalRedirect to={routeLinks.projects.root}/>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.importExport.root} render={() => <ImportExportPage />}/>
                <ReloadableRoute path={routeLinks.exportTask(":taskId").root} render={() => <ExportTaskDetailsPage />}/>
                <ReloadableRoute path={routeLinks.importTask(":taskId").root} render={() => <ImportTaskDetailsPage />}/>
                <ReloadableRoute path={routeLinks.export.root} render={() => <ExportPage />}/>
                <ReloadableRoute path={routeLinks.import.root} render={() => <ImportPage />}/>
                {projectRoutes}
                <ReloadableRoute path={routeLinks.projects.root} render={() => <ProjectsPage />}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "ProjectRoutes";
}
export default ProjectRoutes;
