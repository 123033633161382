import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
const ProjectTasks: React.FC = () => {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    if (!project) {
        logger.error("Failed to find project from context. This should not happen.");
        return null;
    }
    return <Tasks restrictToProjectId={project.Id} hideScriptConsoleAction={true}/>;
};
ProjectTasks.displayName = "ProjectTasks"
export default ProjectTasks;
