import cn from "classnames";
import * as React from "react";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
import styles from "./style.module.less";
type CommonCardTitleProps = {
    title: JSX.Element;
    forceMobileBehaviour?: boolean;
    useCardTitleContainerStyle?: boolean;
};
type NonExpandableCardTitleProps = CommonCardTitleProps;
interface ExpandableCardTitleProps extends CommonCardTitleProps {
    onToggleExpand: React.MouseEventHandler;
    isExpanded: boolean;
    accessibleName: string;
}
type CardTitleProps = NonExpandableCardTitleProps | ExpandableCardTitleProps;
function CardTitle(props: CardTitleProps) {
    if ("onToggleExpand" in props) {
        const { isExpanded = false } = props;
        return (<div onClick={props.onToggleExpand} className={cn({ [styles.clickable]: Boolean(props.onToggleExpand) })}>
                <div className={cn({ [styles.cardTitleContainer]: Boolean(props.onToggleExpand) || props.useCardTitleContainerStyle }, { [styles.cardTitleContainerForceMobile]: props.forceMobileBehaviour })}>{props.title}</div>
                <div className={styles.cardExpander}>
                    {Boolean(props.onToggleExpand) && <ExpandIconButton isExpanded={isExpanded} onClick={props.onToggleExpand} accessibleName={getExpansionButtonAccessibleName(props.accessibleName, isExpanded)}/>}
                </div>
            </div>);
    }
    return (<div>
            <div className={cn({ [styles.cardTitleContainer]: props.useCardTitleContainerStyle }, { [styles.cardTitleContainerForceMobile]: props.forceMobileBehaviour })}>{props.title}</div>
            <div className={styles.cardExpander}/>
        </div>);
}
function getExpansionButtonAccessibleName(name: string | undefined, expanded: boolean) {
    const action = expanded ? "Collapse" : "Expand";
    return name ? `${action} ${name}` : `${action} expander`;
}
interface ExpandIconButtonProps {
    onClick: ((e: React.MouseEvent) => void) | undefined;
    isExpanded: boolean;
    accessibleName: string;
}
function ExpandIconButton({ isExpanded, onClick, accessibleName }: ExpandIconButtonProps) {
    return <IconButton className={cn(styles.expandIcon, isExpanded ? styles.expandOpen : styles.expandClosed)} onClick={onClick} aria-expanded={isExpanded} accessibleName={accessibleName} icon={Icon.Expand} style={{ marginRight: "1rem" }}/>;
}
export default CardTitle;
