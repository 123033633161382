/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PhaseResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import PhaseStatusIcon from "~/components/PhaseStatusIcon/PhaseStatusIcon";
import iconStyles from "~/components/PhaseStatusIcon/style.module.less";
import routeLinks from "../../../../../routeLinks";
import type { LifecycleStatus } from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import { getSingleDeploymentTarget } from "../../../../../utils/PhaseHelper/phaseHelpers";
import envCount from "./envCount";
import styles from "./style.module.less";
interface Props {
    phases: PhaseResource[];
    environmentsById: Record<string, EnvironmentResource>;
    lifecycleStatus?: LifecycleStatus;
    className?: string;
}
class LifecycleMapInternal extends React.Component<Props, never> {
    render() {
        return (<div className={cn(styles.lifecycleMap, this.props.className)}>
                <ul>
                    {this.props.phases.map((p) => (<li className={styles.phase} key={p.Name}>
                            {this.phaseHasSingleTargetEnvironmentWithSameName(p) ? <this.PhaseOnly phase={p}/> : <this.PhaseAndAllTargetEnvironments phase={p}/>}
                        </li>))}
                </ul>
            </div>);
    }
    phaseHasSingleTargetEnvironmentWithSameName = (p: PhaseResource): boolean => {
        const env = this.props.environmentsById[getSingleDeploymentTarget(p)];
        return envCount(p) === 1 && env && p.Name === env.Name;
    };
    PhaseOnly = (props: {
        phase: PhaseResource;
    }) => {
        const s = this.props.lifecycleStatus!;
        const target = getSingleDeploymentTarget(props.phase);
        const task = s.tasks[target];
        const phaseName = task ? (<a className={styles.phaseName} href={routeLinks.task(task).root}>
                {this.props.environmentsById[target].Name}
            </a>) : (<span className={styles.phaseName}>{this.props.environmentsById[target].Name}</span>);
        return (<div>
                <PhaseStatusIcon phase={props.phase} status={this.props.lifecycleStatus!}/>
                {phaseName}
                <this.PhaseConstraintOptional phase={props.phase}/>
            </div>);
    };
    PhaseAndAllTargetEnvironments = (props: {
        phase: PhaseResource;
    }) => {
        return (<div>
                <PhaseStatusIcon phase={props.phase} status={this.props.lifecycleStatus!}/>
                <span className={styles.phaseName}>{props.phase.Name}</span>
                <this.PhaseConstraintAny phase={props.phase}/>
                <this.PhaseConstraintOptional phase={props.phase}/>
                <ul>
                    {props.phase.AutomaticDeploymentTargets.map((e) => (<this.AutomaticDeploymentTarget key={e} target={e}/>))}
                    {props.phase.OptionalDeploymentTargets.map((e) => (<this.OptionalDeploymentTarget key={e} target={e}/>))}
                </ul>
            </div>);
    };
    PhaseConstraintAny = (props: {
        phase: PhaseResource;
    }) => {
        const p = props.phase;
        const show: boolean = p.MinimumEnvironmentsBeforePromotion > 0 && p.MinimumEnvironmentsBeforePromotion < envCount(p) && !p.IsOptionalPhase;
        return show ? <span className={styles.phaseConstraint}> (any {p.MinimumEnvironmentsBeforePromotion})</span> : null;
    };
    PhaseConstraintOptional = (props: {
        phase: PhaseResource;
    }) => {
        return props.phase.IsOptionalPhase ? <span className={styles.phaseConstraint}> (optional)</span> : null;
    };
    AutomaticDeploymentTarget = (props: {
        target: string;
    }) => {
        return this.props.environmentsById[props.target] ? (<li className={styles.target} title="Automatically deploy to this environment" key={props.target}>
                <span className={cn(iconStyles.phaseIcon, iconStyles.automatic)}/>
                <this.EnvironmentName {...props}/>
            </li>) : null;
    };
    OptionalDeploymentTarget = (props: {
        target: string;
    }) => {
        return this.props.environmentsById[props.target] ? (<li className={styles.target} title="Manually deploy to this environment" key={props.target}>
                <span className={cn(iconStyles.phaseIcon, iconStyles.optional)}/>
                <this.EnvironmentName {...props}/>
            </li>) : null;
    };
    EnvironmentName = (props: {
        target: string;
    }) => {
        return this.props.lifecycleStatus!.tasks[props.target] ? <a href="#/tasks/{{ tasks[e] }}">{this.props.environmentsById[props.target].Name}</a> : <span>{this.props.environmentsById[props.target].Name}</span>;
    };
    static displayName = "LifecycleMapInternal";
}
export default LifecycleMapInternal;
