import React from "react";
import { ActionButton } from "~/components/Button";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import styles from "./style.module.less";
type ChangeStepPackageVersionCalloutProps = {
    highestAvailableMajorVersion: number;
    currentMajorVersion: number;
    previousMajorVersion?: number;
    releaseNotesHref?: string;
    error?: string;
    inProgress: boolean;
    onUpgradeClick: () => void;
    onCancelClick: () => void;
};
export function ChangeStepPackageVersionCallout({ highestAvailableMajorVersion, currentMajorVersion, previousMajorVersion, releaseNotesHref, inProgress, error, onUpgradeClick, onCancelClick }: ChangeStepPackageVersionCalloutProps) {
    const variants = {
        upgradeAvailable: {
            title: `Version ${highestAvailableMajorVersion} of this step is available. You are currently on version ${currentMajorVersion}. `,
            copy: "Additional information may be required. ",
            buttonLabel: "Upgrade",
            clickHandler: onUpgradeClick,
            calloutType: CalloutType.Information,
            buttonType: 1,
            releaseNotesHref,
        },
        inProgress: {
            title: `Step ${previousMajorVersion && previousMajorVersion < currentMajorVersion ? "upgraded" : "changed"} to version ${currentMajorVersion}. `,
            copy: `To complete the ${previousMajorVersion && previousMajorVersion < currentMajorVersion ? "upgrade" : "change"} click Save (or click Cancel to switch back). `,
            buttonLabel: "Cancel",
            clickHandler: onCancelClick,
            calloutType: CalloutType.Success,
            buttonType: 2,
            releaseNotesHref,
        },
        error: {
            title: `Something went wrong.`,
            copy: `${error} `,
            buttonLabel: "Cancel",
            clickHandler: onCancelClick,
            calloutType: CalloutType.Danger,
            buttonType: 2,
            releaseNotesHref: null,
        },
    };
    function getVariant(inProgress: boolean, error?: string) {
        if (error) {
            return variants.error;
        }
        if (inProgress) {
            return variants.inProgress;
        }
        return variants.upgradeAvailable;
    }
    const variant = getVariant(inProgress, error);
    return (<Callout title={variant.title} type={variant.calloutType}>
            <div className={styles.content}>
                <div className={styles.copy}>
                    {variant.copy}
                    {variant.releaseNotesHref && (<ExternalLink href={variant.releaseNotesHref} showIcon={false} aria-label="Release Notes">
                            What's new
                        </ExternalLink>)}
                </div>
                <div className={styles.button}>
                    <ActionButton label={variant.buttonLabel} onClick={variant.clickHandler} type={variant.buttonType}/>
                </div>
            </div>
        </Callout>);
}
