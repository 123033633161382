import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
export const CloseButton = ({ onClick }: {
    onClick: () => void;
}) => (<div className={closeIconStyles} onClick={onClick}>
        <ToolTip content={`Close project status`} position="bottom" distance={25}>
            <em className={`fa fa-times-circle`}/>
        </ToolTip>
    </div>);
const closeIconStyles = css({
    color: colorScales.grey[500],
    fill: colorScales.grey[500],
    fontSize: "20px",
    marginLeft: "auto",
    cursor: "pointer",
});
