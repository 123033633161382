import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type * as H from "history";
import * as React from "react";
import type { match as Match } from "react-router-dom";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "~/areas/projects/context";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { getCommonOverflowMenuItems } from "./Common/CommonProcessHelpers";
import { useMaybeLoadedActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "./Contexts/ProcessContext";
import { useProcessErrorActions } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import { useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { EnhancedProcessContextPaperLayout } from "./CustomPaperLayouts/ProcessContextPaperLayout";
import { EnhancedProcessListPage } from "./Pages";
import type { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
interface ProcessPageProps {
    processType: ProcessType;
    lookups: ProcessListLayoutLoaderLookupData;
    errors?: Errors;
    busy?: boolean;
    doBusyTask: DoBusyTask;
    history: H.History;
    location: H.Location;
    match: Match<ProjectRouteParams>;
    isBuiltInWorkerEnabled: boolean;
}
const ProcessListLayout: React.FC<ProcessPageProps> = (props) => {
    const processContext = useProcessContext();
    const { state: { model: project, projectContextRepository }, actions: projectContextActions, } = useProjectContext();
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const feeds = useFeedsFromContext();
    const actionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const branchProtectionsAreEnabled = isFeatureToggleEnabled("BranchProtectionsFeatureToggle");
    const projectBasedActivationAreEnabled = isFeatureToggleEnabled("ProjectBasedActivationFeatureToggle");
    const hasLoadedNecessaryLookupData = (): boolean => {
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true });
        return actionTemplates !== "NotLoaded" && (!feedViewPermissionGranted || (feedViewPermissionGranted && feeds && feeds.length > 0));
    };
    if (!processContext.selectors.hasValidProcess() || !hasLoadedNecessaryLookupData()) {
        return <EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} disableAnimations={true} overflowMenuItems={[]} doBusyTask={props.doBusyTask}/>;
    }
    const runbook = runbookContext?.state?.runbook;
    const overflowMenuItems = getCommonOverflowMenuItems(projectContextRepository, project, projectContext.state.gitRef, runbook, props.processType, processContext.selectors, processContext.actions, projectContextActions, errorActions, warningActions, undefined, branchProtectionsAreEnabled, projectBasedActivationAreEnabled);
    const { busy, errors, lookups } = props;
    return (<EnhancedProcessContextPaperLayout breadcrumbTitle={projectContext.state.model.Name} processType={props.processType} busy={props.busy} errors={props.errors} overflowMenuItems={overflowMenuItems} doBusyTask={props.doBusyTask}>
            <EnhancedProcessListPage processType={props.processType} lookups={lookups} busy={busy} errors={errors} doBusyTask={props.doBusyTask} location={props.location} history={props.history}/>
        </EnhancedProcessContextPaperLayout>);
};
ProcessListLayout.displayName = "ProcessListLayout"
export default React.memo(ProcessListLayout);
