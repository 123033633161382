import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";
export const CommonConfigurationTestPermissionOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};
CommonConfigurationTestPermissionOverview.displayName = "CommonConfigurationTestPermissionOverview"
export const CommonConfigurationTestPermissionTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
CommonConfigurationTestPermissionTopics.displayName = "CommonConfigurationTestPermissionTopics"
