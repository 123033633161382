import * as React from "react";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import routeLinks from "../../../routeLinks";
const DashboardLayout: React.StatelessComponent<{}> = (props) => {
    return (<main id="maincontent">
            <AreaTitle link={routeLinks.root} title="Dashboard"/>
            <ContextualHelpLayout>{props.children}</ContextualHelpLayout>
        </main>);
};
DashboardLayout.displayName = "DashboardLayout"
export default DashboardLayout;
