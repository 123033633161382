/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import { withTheme } from "~/components/Theme";
import { baseSizeInPx } from "~/fontWeights";
import TimeSpanHelper from "~/utils/TimeSpanHelper";
import type FormFieldProps from "../FormFieldProps";
import styles from "./style.module.less";
const NumericInput = require("react-numeric-input");
interface TimeSpanSelectorProps extends FormFieldProps<string> {
    granularity?: string;
    error?: string;
}
class TimeSpanSelector extends BaseComponent<TimeSpanSelectorProps, {}> {
    public static DayGranularity: string = "dd";
    public static HourGranularity: string = "hh";
    public static MinuteGranularity: string = "mm";
    public static SecondGranularity: string = "ss";
    public static DayHourMinuteGranularity: string = "dd:hh:mm";
    public static HourMinuteSecondGranularity: string = "hh:mm:ss";
    getGranularityOrDefault() {
        return this.props.granularity || TimeSpanSelector.DayHourMinuteGranularity;
    }
    render() {
        const { value } = this.props;
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(value);
        const granularity = this.getGranularityOrDefault();
        return withTheme((theme) => {
            const inputStyle = {
                input: {
                    border: "0",
                    fontSize: baseSizeInPx,
                    height: "100%",
                    backgroundColor: theme.transparent,
                    color: theme.primaryText,
                    borderStyle: "none",
                },
            };
            return (<>
                    <div className={styles.container}>
                        {granularity.includes(TimeSpanSelector.DayGranularity) && [
                    <NumericInput onChange={this.handleDayChange} value={timeSpan!.Days} min={0} max={99} step={1} size={3} mobile={true} key="days" style={inputStyle} className={styles.input}/>,
                    <span className={styles.separator} key="dayslabel">
                                days
                            </span>,
                ]}

                        {granularity.includes(TimeSpanSelector.HourGranularity) && [
                    <NumericInput onChange={this.handleHourChange} value={timeSpan!.Hours} min={0} max={23} step={1} key="hours" size={3} style={inputStyle} mobile={true} className={styles.input}/>,
                    <span className={styles.separator} key="hourslabel">
                                hours
                            </span>,
                ]}
                        {granularity.includes(TimeSpanSelector.MinuteGranularity) && [
                    <NumericInput onChange={this.handleMinuteChange} value={timeSpan!.Minutes} min={0} max={59} step={1} size={3} key="minutes" style={inputStyle} mobile={true} className={styles.input}/>,
                    <span className={styles.separator} key="minuteslabel">
                                minutes
                            </span>,
                ]}
                        {granularity.includes(TimeSpanSelector.SecondGranularity) && [
                    <NumericInput onChange={this.handleSecondChange} value={timeSpan!.Seconds} min={0} max={59} step={1} key="seconds" size={3} style={inputStyle} mobile={true} className={styles.input}/>,
                    <span className={styles.separator} key="secondslabel">
                                seconds
                            </span>,
                ]}
                    </div>
                    {this.props.error && <div className={styles.error}>{this.props.error}</div>}
                </>);
        });
    }
    private notifyChange(value: string) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }
    private handleDayChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Days = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleHourChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Hours = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleMinuteChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Minutes = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleSecondChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Seconds = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    static displayName = "TimeSpanSelector";
}
export default TimeSpanSelector;
