import type { LogEvent } from "@octopusdeploy/logging";
import React, { useEffect, useState } from "react";
import { DevOnly } from "~/components/DevTools/DevOnly";
import { useLogEvents, useRemoveAllLogEvents } from "~/components/LogErrors/LogEventCapturer";
import { Alert } from "~/primitiveComponents/feedback/Alert/Alert";
import { Snackbar } from "~/primitiveComponents/feedback/Snackbar";
export function LogErrorDisplayer() {
    return (<DevOnly>
            <DevOnlyLogErrorDisplayer />
        </DevOnly>);
}
function DevOnlyLogErrorDisplayer() {
    const logEvents = useLogEvents();
    const removeAllLogEvents = useRemoveAllLogEvents();
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {
        // If the log errors have just become 0, then the snackbar should hide.
        // However, we don't want to immediately change the message text until the snackbar has animated away, so keep the old error message around a bit longer.
        const errors = logErrors(logEvents);
        if (errors.length !== 0) {
            setErrorMessage(buildErrorMessage(errors));
        }
    }, [logEvents]);
    return (<>
            <Snackbar open={logErrors(logEvents).length > 0} content={<Alert severity={"error"} onClose={removeAllLogEvents}>
                        {errorMessage}
                    </Alert>}/>
        </>);
}
function logErrors(logEvents: LogEvent[]): LogEvent[] {
    return logEvents.filter((m) => m.logLevel === "error" || m.logLevel === "fatal");
}
function buildErrorMessage(errors: LogEvent[]) {
    if (errors.length > 1) {
        return `There are ${errors.length} errors in the console`;
    }
    else if (errors.length === 1) {
        return `There is 1 error in the console`;
    }
    else {
        return "";
    }
}
