import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import InternalLink from "~/components/Navigation/InternalLink";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { Note } from "~/components/form";
import routeLinks from "~/routeLinks";
import styles from "./ScriptModulesPreview.module.less";
import SelectScriptModules from "./SelectScriptModules";
import type { ScriptModule } from "./model";
export interface OpenScriptModulesDialogButtonProps {
    hasScriptModules: boolean;
    projectId: string;
    permission?: PermissionCheckProps;
    onDataChanged: () => void;
}
export const OpenScriptModulesDialogButton: React.FC<OpenScriptModulesDialogButtonProps> = (props) => {
    //TODO OPS: permissions for runbooks
    const { hasScriptModules, permission = { permission: Permission.ProcessEdit, project: props.projectId, tenant: "*" } } = props;
    return (<OpenDialogButton label={hasScriptModules ? "CHANGE" : "INCLUDE"} permission={permission}>
            <SelectScriptModules projectId={props.projectId} saveDone={() => props.onDataChanged()}/>
        </OpenDialogButton>);
};
OpenScriptModulesDialogButton.displayName = "OpenScriptModulesDialogButton"
export interface RenderScriptModulesActionsProps {
    hasScriptModules: boolean;
    includedScriptModules: ScriptModule[];
}
export interface ScriptModulesProps {
    title?: React.ReactNode;
    includedScriptModules: ScriptModule[];
    renderActions: (renderProps: RenderScriptModulesActionsProps) => React.ReactNode;
}
export const ScriptModules: React.FC<ScriptModulesProps> = ({ includedScriptModules, renderActions, title = <h4>Script Modules</h4> }) => {
    const hasScriptModules = includedScriptModules && includedScriptModules.length > 0;
    return (includedScriptModules && (<React.Fragment>
                {title}
                {hasScriptModules ? (<p className={styles.scriptModulesStatus}>Included script modules:</p>) : (<div>
                        <p>No script modules have been included</p>
                        <Note>
                            Modules can be created in the <InternalLink to={routeLinks.library.scripts.root}>Library</InternalLink>
                        </Note>
                    </div>)}
                <ul className={styles.includedScriptModules}>
                    {includedScriptModules.map((sm) => (<li key={sm.Id}>
                            <InternalLink to={routeLinks.library.script(sm.Id)}>{sm.Name}</InternalLink>
                        </li>))}
                </ul>
                {renderActions({ hasScriptModules, includedScriptModules })}
            </React.Fragment>));
};
ScriptModules.displayName = "ScriptModules"
export interface ScriptModulesWithActionsProps {
    title?: React.ReactNode;
    projectId: string;
    includedScriptModules: ScriptModule[];
    onDataChanged: () => void;
    renderActions?: (props: RenderScriptModulesActionsProps) => React.ReactNode;
}
export const ScriptModulesWithActions: React.FC<ScriptModulesWithActionsProps> = ({ includedScriptModules, onDataChanged, projectId, title, renderActions = (props) => <OpenScriptModulesDialogButton hasScriptModules={props.hasScriptModules} projectId={projectId} onDataChanged={onDataChanged}/>, }) => {
    return <ScriptModules title={title} renderActions={renderActions} includedScriptModules={includedScriptModules}/>;
};
ScriptModulesWithActions.displayName = "ScriptModulesWithActions"
