import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "~/areas//insights/dataTransformation/getChartReadyInsightsData";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import { DownloadProjectInsightsCsvButton } from "../DownloadProjectInsightsCsvButton";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import styles from "./ProjectInsightsDeploymentDuration.module.less";
type ProjectInsightsDeploymentDurationInnerProps = ProjectInsightsPageProps;
function ProjectInsightsDeploymentDurationInner({ project, bffResponse, busy, filter }: ProjectInsightsDeploymentDurationInnerProps) {
    const { cadence } = filter;
    const chartData = getChartReadyInsightsData(bffResponse.Series);
    const { SuccessfulDeploymentDurationKey, DeploymentQueuedDurationKey } = getYAxisUnitOfTimeKeys(chartData);
    const maxRunDurationYAxisValue = getYAxisDataMax(chartData, `SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`);
    const maxQueueDurationYAxisValue = getYAxisDataMax(chartData, `DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`);
    const dispatchAction = useAnalyticSimpleActionDispatch();
    useEffect(() => {
        dispatchAction("View Project Deployment Duration");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const currentGranularity = insightsCadenceLookup[cadence].granularity;
    const dateFormatter = getChartAxisDateFormatter(currentGranularity);
    return (<main>
            <section className={styles.content}>
                <div className={styles.chartContainer}>
                    <h2>Successful deployment duration</h2>
                    <InsightsChartCard height={500}>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`} yAxisLabel={`Avg. no. of ${SuccessfulDeploymentDurationKey.toLowerCase()}`} xAxisDataKey="StartOfInterval" xAxisType="category" yAxisType="number" yAxisDomain={[0, maxRunDurationYAxisValue]} yAxisTickArray={getEvenlySpacedTickArray(maxRunDurationYAxisValue)} xTickFormatter={dateFormatter} tooltip={<InsightsTooltip dataKey="SuccessfulDeploymentDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}/>
                    </InsightsChartCard>
                </div>
                <div className={styles.chartContainer}>
                    <h2>Deployment queued duration</h2>
                    <InsightsChartCard height={500}>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`} yAxisLabel={`Avg. no. of ${DeploymentQueuedDurationKey.toLowerCase()}`} xAxisDataKey="StartOfInterval" xAxisType="category" yAxisType="number" yAxisDomain={[0, maxQueueDurationYAxisValue]} yAxisTickArray={getEvenlySpacedTickArray(maxQueueDurationYAxisValue)} xTickFormatter={dateFormatter} tooltip={<InsightsTooltip dataKey="DeploymentQueuedDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}/>
                    </InsightsChartCard>
                </div>
                <div className={styles.csvDownloadWrap}>
                    <DownloadProjectInsightsCsvButton project={project} filter={filter}/>
                </div>
            </section>
        </main>);
}
export function ProjectInsightsDeploymentDuration() {
    return <ProjectInsightsDataLoader title="Deployment duration">{(props) => <ProjectInsightsDeploymentDurationInner {...props}/>}</ProjectInsightsDataLoader>;
}
