/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import { OpenDeleteDialogButton } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import PagingDataTable from "~/components/PagingDataTable";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";
class BuildInformationDataTable extends PagingDataTable<OctopusPackageVersionBuildInformationMappedResource> {
}
interface BuildInformationVersionsListState extends DataBaseComponentState {
    packagesResponse?: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
    packagesToDelete: string[];
    isSure: boolean;
    confirmationInputValue: string;
}
export class BuildInformationVersionsList extends DataBaseComponent<RouteComponentProps<{
    packageId: string;
}>, BuildInformationVersionsListState> {
    private packageId: string;
    private isBuildInfoAdmin = isAllowed({ permission: Permission.BuildInformationAdminister, project: "*" });
    constructor(props: RouteComponentProps<{
        packageId: string;
    }>) {
        super(props);
        this.packageId = this.props.match.params.packageId;
        this.state = {
            packagesToDelete: [],
            isSure: false,
            confirmationInputValue: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const packagesResponse = await repository.BuildInformationRepository.list({ packageId: this.packageId, take: 30 });
        this.setState({ packagesResponse });
    }
    async delete() {
        await this.doBusyTask(async () => {
            await repository.BuildInformationRepository.deleteMany(this.state.packagesToDelete);
            await this.load();
        });
        return true;
    }
    render() {
        if (this.state.packagesResponse && this.state.packagesResponse.Items.length === 0) {
            return <InternalRedirect to={routeLinks.library.buildInformationRepository.root}/>;
        }
        const sideBar = <p>Build information is snapshotted with releases. Deleting build information makes it unavailable for new releases.</p>;
        const additionalRequestParams = new Map<string, string>();
        additionalRequestParams.set("packageId", this.packageId);
        const table = this.state.packagesResponse && this.state.packagesResponse.Items.length > 0 ? (<BuildInformationDataTable initialData={this.state.packagesResponse} onRow={this.buildRow} apiSearchParams={["filter"]} additionalRequestParams={additionalRequestParams} filterSearchEnabled={true} onFilter={this.filter} headerColumns={["Version", "Created", "Branch", "Build"]} onEmpty={this.handleOnEmpty} rowColumnClassName={styles.packageVersionCell} onItemsChecked={this.isBuildInfoAdmin ? (packagesToDelete: string[]) => this.setState({ packagesToDelete }) : null!}/>) : null;
        return (<LibraryLayout {...this.props}>
                <PaperLayout title={this.packageId} breadcrumbTitle={"Build Information"} breadcrumbPath={routeLinks.library.buildInformationRepository.root} sectionControl={this.deleteSelectedButton()} busy={this.state.busy} errors={this.errors}>
                    <SidebarLayout sideBar={sideBar}>{table}</SidebarLayout>
                </PaperLayout>
            </LibraryLayout>);
    }
    private filter(filter: string, resource: OctopusPackageVersionBuildInformationMappedResource) {
        return !filter || filter.length === 0 || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false) || (resource.Version ? resource.Version.toLowerCase().includes(filter.toLowerCase()) : false);
    }
    private handleOnEmpty = () => {
        return <div>No packages found</div>;
    };
    private deleteSelectedButton = () => {
        const multipleSelected = this.state.packagesToDelete.length > 1;
        const selectedPackageCount = this.state.packagesToDelete.length;
        const message = `Are you sure you want to delete this build information?`;
        const isSure = this.state.isSure;
        return (<PermissionCheck permission={Permission.BuiltInFeedAdminister} project="*">
                <OpenDeleteDialogButton label="Delete Selected" disabled={!this.state.packagesResponse || selectedPackageCount === 0} disableDeleteButton={!isSure} dialogTitle={message} onDeleteClick={() => this.delete()} dialogOnClose={() => this.setState({ confirmationInputValue: "", isSure: false })}>
                    <Callout title="This is a destructive action" type={CalloutType.Danger}>
                        This action <strong>cannot</strong> be undone.
                    </Callout>
                    <div>
                        <p>
                            Please type in <strong>Delete</strong> to confirm.
                        </p>
                        <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange}/>
                    </div>
                </OpenDeleteDialogButton>
            </PermissionCheck>);
    };
    private confirmationOnChange = (value: string) => {
        let isSure = false;
        if (value === "Delete") {
            isSure = true;
        }
        this.setState({ confirmationInputValue: value, isSure });
    };
    private buildRow = (resource: OctopusPackageVersionBuildInformationMappedResource) => {
        return [
            <div className={styles.row}>
                <InternalLink to={routeLinks.library.buildInformationRepository.package(encodeURIComponent(resource.Id))}>{resource.Version}</InternalLink>
            </div>,
            <ToolTip content={DateFormatter.dateToLongFormat(resource.Created)!}>{DateFormatter.dateToShortFormat(resource.Created)}</ToolTip>,
            !resource.Branch ? " " : resource.Branch,
            <ExternalLink href={resource.BuildUrl}>{resource.BuildNumber}</ExternalLink>,
        ].filter((c) => !!c);
    };
    static displayName = "BuildInformationVersionsList";
}
export default BuildInformationVersionsList;
