import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectOverview, CommonProjectTopics } from "../../CommonComponents/CommonProjectOverviewViews";
export const ProjectOverviewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().overview,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTopics />;
    },
};
