import type { EnvironmentSummaryResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import routeLinks from "~/routeLinks";
import styles from "../style.module.less";
interface EnvironmentOverviewRowWithThemeProps {
    environmentSummary: EnvironmentSummaryResource;
    theme: OctopusTheme;
}
const EnvironmentsSummaryContentRowWithTheme = ({ environmentSummary, theme }: EnvironmentOverviewRowWithThemeProps) => {
    const totalMachines = environmentSummary.TotalMachines || 0;
    return (<div className={styles.environmentRowsContainer} key={environmentSummary.Environment.Id}>
            <div className={styles.environmentIcon}>
                <OctopusIcon iconType={OctopusIconType.Environment} color={theme.iconDark}/>
            </div>
            <div className={styles.environmentName}>
                <InternalLink to={routeLinks.infrastructure.environment(environmentSummary.Environment.Id)}>{environmentSummary.Environment.Name}</InternalLink>
            </div>
            <div className={styles.environmentMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>);
};
interface EnvironmentOverviewRowProps {
    environmentSummary: EnvironmentSummaryResource;
}
export const EnvironmentSummaryContentRow = (props: EnvironmentOverviewRowProps) => {
    const theme = useOctopusTheme();
    return <EnvironmentsSummaryContentRowWithTheme {...props} theme={theme}/>;
};
