import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { withTheme } from "~/components/Theme";
import type { CertificateIndex } from "~/components/certificates";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";
interface ReadonlyCertificateProps {
    certificateIndex: CertificateIndex;
    certificate: string;
    className?: string;
}
// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyCertificate: React.SFC<ReadonlyCertificateProps> = (props) => withTheme((theme) => {
    const certName = props.certificateIndex && props.certificateIndex[props.certificate] ? props.certificateIndex[props.certificate] : props.certificate;
    return (<div className={styles.container}>
                <span className={styles.iconContainer}>
                    <ThirdPartyIcon iconType={ThirdPartyIconType.Https} color={theme.secondaryText}/>
                </span>
                <div className={`${props.className} ${styles.content}`} title={certName}>
                    <InternalLink to={routeLinks.library.certificate(props.certificate)}>{certName}</InternalLink>
                </div>
            </div>);
});
ReadonlyCertificate.displayName = "ReadonlyCertificate"
export default ReadonlyCertificate;
