import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonLibraryFeedOverview: React.StatelessComponent<{}> = (props) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Using <ExternalLink href="OnboardingPackageRepositoriesLearnMore">external feeds</ExternalLink>, Octopus can consume packages from TeamCity, VSTS, Docker Registry, MyGet, Maven, locally or hosted remotely.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
CommonLibraryFeedOverview.displayName = "CommonLibraryFeedOverview"
export const CommonLibraryFeedTopics: React.StatelessComponent<{}> = (props) => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingPackageRepositoriesLearnMore">External Feeds</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
CommonLibraryFeedTopics.displayName = "CommonLibraryFeedTopics"
