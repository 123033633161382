/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AzureServiceFabricPowerShellProperties } from "@octopusdeploy/legacy-action-properties";
import { AzureServiceFabricSecurityMode, AADCredentialType, ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import AzureServiceFabricAccountSelector from "~/components/Actions/azure/AzureServiceFabricAccountSelector";
import AzureServiceFabricSdkRequiredInfo from "~/components/Actions/azure/AzureServiceFabricSdkRequiredInfo";
import { ScriptActionEdit } from "~/components/Actions/script/scriptAction";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { ExpandableFormSection, Summary, UnstructuredFormSection } from "~/components/form";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { AzureBundledToolsForServiceFabricScriptsEdit } from "./azureBundledTools";
class AzureServiceFabricPowerShellSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Run a PowerShell script using a Service Fabric cluster context
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "AzureServiceFabricPowerShellSummary";
}
interface AzureServiceFabricPowerShellState {
    resetValues: {
        ClientCertVariable: string;
    };
}
class AzureServiceFabricPowerShellActionEdit extends BaseComponent<ActionEditProps<AzureServiceFabricPowerShellProperties, ScriptPackageProperties>, AzureServiceFabricPowerShellState> {
    constructor(props: ActionEditProps<AzureServiceFabricPowerShellProperties, ScriptPackageProperties>) {
        super(props);
        this.state = {
            resetValues: {
                ClientCertVariable: props.properties["Octopus.Action.ServiceFabric.ClientCertVariable"],
            },
        };
    }
    componentDidUpdate(prevProps: ActionEditProps<AzureServiceFabricPowerShellProperties, ScriptPackageProperties>) {
        if (this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] !== prevProps.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] ||
            this.props.properties["Octopus.Action.ServiceFabric.IsLegacyMode"] !== prevProps.properties["Octopus.Action.ServiceFabric.IsLegacyMode"]) {
            this.props.refreshRunOn && this.props.refreshRunOn();
        }
    }
    componentDidMount() {
        const newProperties: Partial<AzureServiceFabricPowerShellProperties> = {};
        const isLegacyActionType = !!this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"];
        if (isLegacyActionType) {
            if (!this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"]) {
                newProperties["Octopus.Action.ServiceFabric.SecurityMode"] = AzureServiceFabricSecurityMode.Unsecure;
            }
            if (!this.props.properties["Octopus.Action.ServiceFabric.AadCredentialType"]) {
                newProperties["Octopus.Action.ServiceFabric.AadCredentialType"] = AADCredentialType.UserCredential;
            }
            newProperties["Octopus.Action.ServiceFabric.IsLegacyMode"] = "True";
        }
        this.props.setProperties(newProperties, true);
    }
    render() {
        const properties = this.props.properties;
        const isLegacyActionType = this.isLegacyMode();
        return (<div>
                <AzureBundledToolsForServiceFabricScriptsEdit {...this.props}/>

                <UnstructuredFormSection stretchContent={true}>
                    <AzureServiceFabricSdkRequiredInfo />
                </UnstructuredFormSection>

                {isLegacyActionType && (<div>
                        <FormSectionHeading title="Service Fabric Cluster"/>
                        <Callout type={CalloutType.Warning} title={"Legacy mode"}>
                            <strong>
                                This step is referencing a Service Fabric cluster directly, instead of referencing a <ExternalLink href="AzureTargets">Service Fabric Cluster Target</ExternalLink> through Roles. Please read{" "}
                                <ExternalLink href="AzureTargets">our documentation</ExternalLink> to learn how to get started with Azure Targets.
                            </strong>
                        </Callout>
                        <AzureServiceFabricAccountSelector projectId={this.props.projectId!} gitRef={this.props.gitRef} localNames={this.props.localNames!} properties={this.props.properties} clientCertVariableResetValue={this.state.resetValues.ClientCertVariable} setProperties={this.props.setProperties} getFieldError={this.props.getFieldError} doBusyTask={this.props.doBusyTask} expandedByDefault={this.props.expandedByDefault}/>
                    </div>)}

                <ScriptActionEdit plugin={this.props.plugin} projectId={this.props.projectId} gitRef={this.props.gitRef} localNames={this.props.localNames} properties={this.props.properties} packages={this.props.packages} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} supportedLanguages={SupportedLanguage.PowerShell} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} parameters={this.props.parameters}/>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.IsLegacyMode" isExpandedByDefault={this.props.expandedByDefault} title="Enable Legacy Mode" summary={properties["Octopus.Action.ServiceFabric.IsLegacyMode"] && properties["Octopus.Action.ServiceFabric.IsLegacyMode"] !== "False"
                ? Summary.summary(<span>
                                      <strong>Enabled</strong>: Connection-related properties are configured on this step
                                  </span>)
                : Summary.default(<span>Not enabled: Connection-related properties are configured on the Deployment Target</span>)} help={"Select legacy mode if you wish to configure connection-related properties on the step and not through Azure Targets."}>
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} value={properties["Octopus.Action.ServiceFabric.IsLegacyMode"]} onChange={(x) => {
                if (x === "True") {
                    this.props.setProperties({
                        ["Octopus.Action.ServiceFabric.IsLegacyMode"]: "True",
                        ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: "",
                        ["Octopus.Action.ServiceFabric.SecurityMode"]: AzureServiceFabricSecurityMode.Unsecure,
                        ["Octopus.Action.ServiceFabric.AadCredentialType"]: AADCredentialType.UserCredential, // Default
                    });
                }
                else {
                    this.clearLegacyModeProps();
                }
            }} label="Enable Legacy Mode"/>
                    <Callout type={CalloutType.Warning} title={"Not recommended"}>
                        Toggling this <strong>on</strong> will allow connection-related properties on the step <strong>(not recommended)</strong>.<br />
                        Toggling this <strong>off</strong> will clear the connection-related properties on the step and allow these to be configured from your Deployment Targets.
                    </Callout>
                </ExpandableFormSection>
            </div>);
    }
    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties && !!(properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }
    private clearLegacyModeProps = () => {
        this.props.setProperties({
            ["Octopus.Action.ServiceFabric.IsLegacyMode"]: null!,
            ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: null!,
            ["Octopus.Action.ServiceFabric.SecurityMode"]: null!,
            ["Octopus.Action.ServiceFabric.ServerCertThumbprint"]: null!,
            ["Octopus.Action.ServiceFabric.ClientCertVariable"]: null!,
            ["Octopus.Action.ServiceFabric.AadCredentialType"]: null!,
            ["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]: null!,
            ["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]: null!,
            ["Octopus.Action.ServiceFabric.AadUserCredentialPassword"]: null!,
        }, false);
    };
    static displayName = "AzureServiceFabricPowerShellActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureServiceFabricPowerShell",
    summary: (properties, targetRolesAsCSV) => <AzureServiceFabricPowerShellSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    edit: AzureServiceFabricPowerShellActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        // Azure steps (pre 2018.5) allowed you to select connection endpoint directly on the step, making target selection optional.
        const isLegacyActionType = !!(action ? action.Properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || action.Properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True" : null);
        return isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
    },
    hasPackages: (action) => false,
    features: {
        optional: ["Octopus.Features.SubstituteInFiles", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
});
