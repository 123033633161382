import React from "react";
import Logo from "~/components/Logo/index";
import styles from "./style.module.less";
interface LogoGroupProps {
    logoLinks: string[];
}
export default function LogoGroup({ logoLinks }: LogoGroupProps) {
    return (<div className={styles.container}>
            {logoLinks.map((logoLink) => (<div className={styles.logoContainer} key={logoLink}>
                    <Logo url={logoLink}/>
                </div>))}
        </div>);
}
