/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AzureResourceGroupParametersProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { ExpandableFormSection, Summary, Note } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import type { ParameterValue, ParameterValues } from "./AzureTemplateHelper";
import { AzureTemplateHelper, ParameterDataType, Source } from "./AzureTemplateHelper";
interface AzureResourceGroupParametersProps {
    projectId: string;
    localNames: string[];
    properties: AzureResourceGroupParametersProperties;
    setProperties(properties: Partial<AzureResourceGroupParametersProperties>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
interface AzureResourceGroupParametersState {
    paramValues: ParameterValues;
    paramErrors: string[];
}
export default class AzureResourceGroupParameters extends React.Component<AzureResourceGroupParametersProps, AzureResourceGroupParametersState> {
    constructor(props: AzureResourceGroupParametersProps) {
        super(props);
        this.state = {
            paramValues: {},
            paramErrors: [],
        };
    }
    componentDidMount() {
        this.loadParameterValues(true);
    }
    componentDidUpdate(prevProps: AzureResourceGroupParametersProps, prevState: AzureResourceGroupParametersState) {
        if (prevProps.properties["Octopus.Action.Azure.ResourceGroupTemplate"] !== this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]) {
            this.loadParameterValues(false);
        }
    }
    loadParameterValues(initialiseModel: boolean) {
        let paramValues = {};
        const result = AzureTemplateHelper.extractParameters(this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"], this.props.properties["Octopus.Action.Azure.ResourceGroupTemplateParameters"]);
        paramValues = result.value;
        this.setState({ paramValues }, () => this.updateParameterValues(initialiseModel));
    }
    updateParameterValues(initialiseModel: boolean) {
        const result = AzureTemplateHelper.exportParameters(this.state.paramValues);
        this.setState({ paramErrors: result.errors });
        this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupTemplateParameters"]: result.value }, initialiseModel);
    }
    onParameterValueChange(paramName: string, newValue: string | undefined | number, key = "value") {
        this.setState((prevState) => ({
            paramValues: {
                ...prevState.paramValues,
                [paramName]: {
                    ...prevState.paramValues[paramName],
                    [key]: newValue,
                },
            },
        }), () => this.updateParameterValues(false));
    }
    parameterValueSummary(parameter: ParameterValue) {
        if (parameter.type === ParameterDataType.secureString && parameter.source === Source.azureKeyVault) {
            return Summary.summary("Using Azure Key Vault");
        }
        if (parameter.type === ParameterDataType.bool) {
            let result = parameter.value;
            if (!result) {
                result = "False";
            }
            return Summary.summary(result);
        }
        if (parameter.value) {
            return Summary.summary(parameter.value);
        }
        return Summary.placeholder("Value not provided");
    }
    parameterValueHelp(paramName: string, parameter: ParameterValue) {
        if (parameter.description) {
            return parameter.description;
        }
        if (parameter.type === ParameterDataType.bool) {
            return `Select value for ${paramName}`;
        }
        return `Enter a value for ${paramName}`;
    }
    getParamError(paramName: string) {
        const parameter = this.state.paramErrors.find((x) => x === paramName);
        if (parameter) {
            return `${parameter} is not valid`;
        }
        return null;
    }
    render() {
        return (<div>
                {this.state.paramValues &&
                Object.keys(this.state.paramValues).map((paramName) => (<div key={paramName}>
                            {(() => {
                        const paramValue = this.state.paramValues[paramName];
                        if (paramValue.allowedValues) {
                            return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                            <BoundSelect variableLookup={{
                                    localNames: this.props.localNames,
                                }} resetValue={""} label={paramName} value={paramValue.value} items={paramValue.allowedValues.map((item) => ({ value: item, text: item }))} onChange={(val) => this.onParameterValueChange(paramName, val)}/>
                                        </ExpandableFormSection>);
                        }
                        else {
                            switch (paramValue.type) {
                                case ParameterDataType.bool:
                                    return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                                    <BoundStringCheckbox variableLookup={{
                                            localNames: this.props.localNames,
                                        }} resetValue={""} key={paramName} label={paramName} value={paramValue.value} onChange={(val) => this.onParameterValueChange(paramName, val)}/>
                                                </ExpandableFormSection>);
                                case ParameterDataType.int:
                                    return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                                    <VariableLookupText localNames={this.props.localNames} key={paramName} label={paramName} value={paramValue.value} onChange={(val) => {
                                            let result: string | undefined | number = val;
                                            if (val) {
                                                if (!isBound(val)) {
                                                    result = parseInt(val, 10);
                                                }
                                            }
                                            this.onParameterValueChange(paramName, result);
                                        }}/>
                                                </ExpandableFormSection>);
                                case ParameterDataType.string:
                                    return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                                    <VariableLookupText localNames={this.props.localNames} key={paramName} label={paramName} value={paramValue.value} onChange={(val) => this.onParameterValueChange(paramName, val)}/>
                                                </ExpandableFormSection>);
                                case ParameterDataType.secureString:
                                    return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                                    <RadioButtonGroup value={paramValue.source} onChange={(val) => this.onParameterValueChange(paramName, val, "source")}>
                                                        <RadioButton value={Source.octopus} label="From Octopus"/>
                                                        <RadioButton value={Source.azureKeyVault} label="From Azure Key Vault"/>
                                                        <Note>
                                                            <ExternalLink href="https://azure.microsoft.com/en-us/documentation/articles/key-vault-get-started">Azure Key Vault</ExternalLink> allows sensitive data to be stored outside of Octopus and
                                                            then used in <ExternalLink href="https://azure.microsoft.com/en-us/documentation/articles/resource-manager-keyvault-parameter/">Resource Manager templates.</ExternalLink>
                                                        </Note>
                                                    </RadioButtonGroup>

                                                    {paramValue.source === Source.azureKeyVault ? (<div>
                                                            <VariableLookupText localNames={this.props.localNames} label={"Resource Id"} value={paramValue.keyVaultId!} onChange={(val) => this.onParameterValueChange(paramName, val, "keyVaultId")}/>
                                                            <Note>
                                                                <ExternalLink href="https://docs.microsoft.com/en-us/powershell/module/azurerm.keyvault/get-azurermkeyvault">Get-AzureRMKeyVault</ExternalLink> PowerShell cmdlet can be used to retrieve
                                                                this value.
                                                            </Note>
                                                            <VariableLookupText localNames={this.props.localNames} label={"Secret Name"} value={paramValue.keyVaultSecretName!} onChange={(val) => this.onParameterValueChange(paramName, val, "keyVaultSecretName")}/>
                                                        </div>) : (<div>
                                                            <VariableLookupText localNames={this.props.localNames} label={paramName} value={paramValue.value} onChange={(val) => this.onParameterValueChange(paramName, val)}/>
                                                            <Note>
                                                                This parameter's type is 'secureString'. To avoid it being stored in plain text, please do not enter values directly. Instead use either a{" "}
                                                                <ExternalLink href="SensitiveVariables">Sensitive Variable</ExternalLink>
                                                                <span> or </span>
                                                                <ExternalLink href="https://azure.microsoft.com/en-us/documentation/articles/key-vault-get-started">Azure Key Vault</ExternalLink>
                                                            </Note>
                                                        </div>)}
                                                </ExpandableFormSection>);
                                default:
                                    return (<ExpandableFormSection errorKey={paramName} title={paramName} summary={this.parameterValueSummary(paramValue)} help={this.parameterValueHelp(paramName, paramValue)}>
                                                    <VariableLookupText localNames={this.props.localNames} key={paramName} label={paramName} multiline={true} rows={5} value={paramValue.value} error={this.getParamError(paramName)!} onChange={(val) => this.onParameterValueChange(paramName, val)}/>
                                                    {paramValue.type === ParameterDataType.array && (<Note>
                                                            This parameter's type is 'array'. Data should be entered in array format. Examples: <code>[10, 100, 1000]</code>, <code>["Rick", "Morty"]</code>
                                                        </Note>)}
                                                    {paramValue.type === ParameterDataType.object && (<Note>
                                                            This parameter's type is 'object'. Data should be entered in object format. Example: <code>{"{ \"foo\": \"bar\" }"}</code>
                                                        </Note>)}
                                                    {paramValue.type === ParameterDataType.secureObject && (<Note>
                                                            This parameter's type is 'secureObject'. To avoid it being stored in plain text, please do not enter values directly. Instead use a{" "}
                                                            <ExternalLink href="SensitiveVariables">Sensitive Variable</ExternalLink>.
                                                        </Note>)}
                                                </ExpandableFormSection>);
                            }
                        }
                    })()}
                        </div>))}
            </div>);
    }
    static displayName = "AzureResourceGroupParameters";
}
