import { usePageEventDispatcher } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action } from "~/analytics/Analytics";
import { createActionTypeField, createBoolField, createResourceTypeField } from "~/analytics/fields";
import { GitEventFieldKeys } from "./GitEventFields";
export interface ConfigureGitOptions {
    hasInitialCommitBranch: boolean;
}
export function useNotifyConfigureGit() {
    const dispatcher = usePageEventDispatcher();
    return React.useCallback((options: ConfigureGitOptions) => {
        // Even though we use 'Git' rather than 'VCS' throughout the application,
        // these events should retain their existing name so that usages in Amplitude
        // (graphs/reports etc.) keep working as expected.
        dispatcher("Configure VCS", {
            ...createResourceTypeField("Version Control Settings"),
            ...createActionTypeField(Action.Commit),
            ...createBoolField(GitEventFieldKeys.InitialCommitBranch, options.hasInitialCommitBranch),
        });
    }, [dispatcher]);
}
