/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import type { ResourceCollection, ExternalFeedResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { repository } from "../../../../clientInstance";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import ExternalFeedList from "./ExternalFeedList";
import Onboarding from "./Onboarding";
interface FeedsState extends DataBaseComponentState {
    feeds: ResourceCollection<ExternalFeedResource>;
}
export type ExternalFeedsProps = RouteComponentProps<void>;
interface ExternalFeedsPropsInternal extends ExternalFeedsProps {
    dispatchAction: AnalyticActionDispatcher;
}
class ExternalFeedsInternal extends DataBaseComponent<ExternalFeedsPropsInternal, FeedsState> {
    constructor(props: ExternalFeedsPropsInternal) {
        super(props);
        this.state = {
            feeds: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadFeeds(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addFeedButton = (<PermissionCheck permission={[Permission.FeedEdit]}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add feed" href={`${this.props.match.url}/create`} onClick={() => {
                const ev: ActionEvent = {
                    action: Action.Add,
                    resource: "External Feed",
                };
                this.props.dispatchAction("Add Feed", ev);
            }}/>
            </PermissionCheck>);
        return (<LibraryLayout {...this.props}>
                <PaperLayout title="External Feeds" sectionControl={addFeedButton} busy={this.state.busy} errors={this.errors}>
                    {this.state.feeds != null && (<div>
                            {this.state.feeds.TotalResults === 0 && <Onboarding />}
                            {this.state.feeds.TotalResults > 0 && (<React.Fragment>
                                    <ExternalFeedList feeds={this.state.feeds} onDelete={this.handleFeedDelete}/>
                                </React.Fragment>)}
                        </div>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private handleFeedDelete = async () => {
        await this.loadFeeds();
        return true;
    };
    private loadFeeds = async () => {
        const feeds = await repository.Feeds.listExternal();
        this.setState({
            feeds,
        });
    };
    static displayName = "ExternalFeedsInternal";
}
export function ExternalFeeds(props: ExternalFeedsProps) {
    const dispatchAction = useAnalyticActionDispatch();
    return <ExternalFeedsInternal {...props} dispatchAction={dispatchAction}/>;
}
