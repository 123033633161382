/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type * as History from "history";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Prompt, withRouter } from "react-router";
import { ProjectCurrentBranchDisplay } from "~/areas/projects/components/ProjectCurrentBranchDisplay/ProjectCurrentBranchDisplay";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { ActionListWithSubItem } from "~/components/ActionListWithSubItem/ActionListWithSubItem";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import { Snackbar } from "~/primitiveComponents/feedback/Snackbar";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import store from "../../store";
import ActionList from "../ActionList";
import FormComponent from "../FormComponent/FormComponent";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import type { PaperLayoutProps } from "../PaperLayout/PaperLayout";
import PaperLayout from "../PaperLayout/PaperLayout";
import type { PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import ExpansionButtons, { toggleExpandos } from "../form/Sections/ExpansionButtons";
import { DirtyStateDetail } from "./DirtyStateTracking/DirtyStateDetail";
import { createFormPaperLayoutDirtyChangedAction, createFormPaperLayoutMountedAction } from "./reducers";
import styles from "./style.module.less";
export interface PrimaryActionProps {
    label: string;
    busyLabel: string;
    disabled: boolean | undefined;
    onClick: () => Promise<boolean>;
}
export interface FormProps {
    model: object | undefined;
    cleanModel?: object;
    expandAllOnMount?: boolean;
    hideExpandAll?: boolean;
    errors?: Errors;
    callout?: React.ReactNode;
    saveText?: string;
    savePermission?: PermissionCheckProps | undefined;
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
    overFlowActions?: Array<MenuItem | MenuItem[]>;
    secondaryAction?: React.ReactNode;
    hideSectionControls?: boolean;
    disableDirtyFormChecking?: boolean;
    disableKeyboardFormSubmission?: boolean;
    forceDisableFormSaveButton?: boolean;
    forceDisabledReason?: string;
    isNewRecord?: boolean;
    customPrimaryAction?: React.ReactNode | ((props: PrimaryActionProps) => React.ReactNode); // This allows you to override the primary save button that you get by default.
    dirtyTrackingKey?: string;
    dirtyTrackingDisabled?: boolean;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    confirmNavigateSaveLabel?: string;
}
interface FormPaperLayoutState {
    dirty: boolean;
    showSnackbar: boolean;
    redirect: string | null;
}
export type FormPaperLayoutProps = FormProps & PaperLayoutProps;
type InternalPaperLayoutProps = FormPaperLayoutProps & RouteComponentProps;
type IsPageVersionControlledProps = {
    isPageVersionControlled: boolean;
};
type Props = InternalPaperLayoutProps & IsPageVersionControlledProps;
class FormPaperLayoutInternal extends BaseComponent<Props, FormPaperLayoutState> {
    public static defaultProps: Partial<Props> = {
        expandAllOnMount: false,
        saveText: "Details updated",
        dirtyTrackingKey: "Form",
    };
    constructor(props: Props) {
        super(props);
        this.state = {
            dirty: false,
            showSnackbar: false,
            redirect: null,
        };
    }
    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        });
    };
    UNSAFE_componentWillReceiveProps(nextProps: InternalPaperLayoutProps) {
        if (!nextProps.disableDirtyFormChecking) {
            const dirty = !isEqual(nextProps.model, nextProps.cleanModel);
            this.setState({ dirty }, () => store.dispatch(createFormPaperLayoutDirtyChangedAction(dirty)));
        }
    }
    componentDidMount() {
        store.dispatch(createFormPaperLayoutMountedAction(this.saveOnConfirmNavigation, this.props.confirmNavigateSaveLabel));
        store.dispatch(createFormPaperLayoutDirtyChangedAction(this.state.dirty));
    }
    componentWillUnmount() {
        store.dispatch(createFormPaperLayoutMountedAction(undefined, undefined));
        store.dispatch(createFormPaperLayoutDirtyChangedAction(false));
    }
    render() {
        const { children, model, errors, ...rest } = this.props;
        const overFlowMenu = this.props.overFlowActions && this.props.overFlowActions.length > 0 && <OverflowMenu menuItems={this.props.overFlowActions}/>;
        const primaryAction = this.createPrimaryAction();
        if (this.state.redirect) {
            return <InternalRedirect to={this.state.redirect} push={true}/>;
        }
        const sectionControl = this.props.hideSectionControls ? null : (<div className={styles.sectionControlContainer}>
                {this.props.sectionControl ?? <div></div>}
                {this.props.isPageVersionControlled ? (<ActionListWithSubItem actions={[this.props.secondaryAction, primaryAction]} overflowMenuItems={this.props.overFlowActions} subItem={<ProjectCurrentBranchDisplay />}/>) : (<ActionList actions={[this.props.secondaryAction, primaryAction, overFlowMenu].filter((action) => !!action)}/>)}
            </div>);
        return (<AnalyticLinkLocationProvider location="Paper Form">
                <FormComponent onFormSubmit={this.onCtrlEnterPressed}>
                    {!this.props.dirtyTrackingDisabled && (<DevToolsTab name={`Dirty state: ${this.props.dirtyTrackingKey ?? "Form"}`}>
                            <DirtyStateDetail cleanModel={this.props.cleanModel} model={this.props.model}/>
                        </DevToolsTab>)}
                    <PaperLayout {...rest} sectionControl={sectionControl} errors={this.props.errors}>
                        <Prompt when={this.state.dirty && !this.props.disableDirtyFormChecking} message={(location) => this.getPromptMessage(location)}/>
                        {!this.props.hideExpandAll && <ExpansionButtons errors={errors?.fieldErrors} expandAllOnMount={this.props.expandAllOnMount}/>}
                        <div className={styles.formContainer}>{children}</div>
                        {this.props.saveText && <Snackbar open={this.state.showSnackbar} content={this.props.saveText} autoHideDuration={3500} onClose={this.handleSnackbarClose} textAlign="center"/>}
                    </PaperLayout>
                </FormComponent>
            </AnalyticLinkLocationProvider>);
    }
    createPrimaryAction(): React.ReactNode {
        const primaryActionProps = this.primaryActionProps();
        if (!this.props.customPrimaryAction) {
            return (<ActionButton label={primaryActionProps.label} busyLabel={primaryActionProps.busyLabel} type={ActionButtonType.Save} disabled={primaryActionProps.disabled} onClick={(e: React.MouseEvent | undefined) => this.handleSave(primaryActionProps.onClick, e)}/>);
        }
        if (typeof this.props.customPrimaryAction === "function") {
            return this.props.customPrimaryAction(primaryActionProps);
        }
        return this.props.customPrimaryAction;
    }
    primaryActionLabel(): string {
        const disabledDueToPermission = this.isDisableDueToPermission();
        const permissionLabel = this.getPermissionLabel();
        // prettier-ignore
        return disabledDueToPermission
            ? `${permissionLabel} permission required`
            : this.props.forceDisableFormSaveButton && this.props.forceDisabledReason
                ? this.props.forceDisabledReason
                : this.props.saveButtonLabel! || "Save";
    }
    private isDisableDueToPermission = () => {
        return !!this.props.savePermission ? !isAllowed(this.props.savePermission as PermissionCheckProps) : false;
    };
    private getPermissionLabel(): string {
        if (this.props.savePermission === undefined) {
            return "No";
        }
        if (Array.isArray(this.props.savePermission.permission)) {
            return this.props.savePermission.permission.join(", ");
        }
        return this.props.savePermission.permission;
    }
    private shouldBeDisabled = () => {
        return ((!this.state.dirty || this.props.busy) && !this.props.disableDirtyFormChecking && !this.props.isNewRecord) || this.props.forceDisableFormSaveButton;
    };
    private primaryActionProps(): PrimaryActionProps {
        return {
            label: this.primaryActionLabel(),
            busyLabel: this.props.saveButtonBusyLabel || "Saving",
            disabled: this.isDisableDueToPermission() || this.shouldBeDisabled(),
            onClick: () => this.save(false),
        };
    }
    private onCtrlEnterPressed = async () => {
        const disabledDueToPermission = this.isDisableDueToPermission();
        const isSaveDisable = disabledDueToPermission || this.shouldBeDisabled();
        if (!isSaveDisable && !this.props.disableKeyboardFormSubmission) {
            await this.save(false);
        }
    };
    private handleSave = async (saveAction: () => Promise<boolean>, e: React.MouseEvent | undefined): Promise<boolean> => {
        if (e) {
            e.preventDefault();
        }
        return saveAction();
    };
    private saveOnConfirmNavigation = async () => {
        await this.save(true);
    };
    private save = (isNavigationConfirmation: boolean): Promise<boolean> => timeOperation(timeOperationOptions.forSave(), async () => {
        await this.props.onSaveClick(isNavigationConfirmation);
        if (!this.props.errors) {
            if (this.props.saveText) {
                this.setState({ showSnackbar: true });
            }
        }
        if (!this.props.errors) {
            toggleExpandos(false);
        }
        return !this.props.errors;
    });
    private getPromptMessage(location: History.Location) {
        // If the pathname hasn't changed, return true which will allow the transition.
        // This is so we can ignore filter changes which only modify the query string.
        if (location.pathname === this.props.location.pathname) {
            return true;
        }
        return "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?";
    }
    static displayName = "FormPaperLayoutInternal";
}
function FormPaperLayout(props: InternalPaperLayoutProps) {
    const isPageVersionControlled = useIsPageVersionControlled();
    return <FormPaperLayoutInternal {...props} isPageVersionControlled={isPageVersionControlled}/>;
}
export default withRouter(FormPaperLayout);
